import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LoginHeader from "./components/LoginHeader.js";
import Link from "@mui/material/Link";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./Spinner.css";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableFooter from "@mui/material/TableFooter";
import { FaSearch } from "react-icons/fa";
import { appInsights } from "./AppInsights";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import DataTables from "datatables.net";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Paginator } from "primereact/paginator";
import "./table.css";

export default function Home() {
  //let table = new DataTables('#myTable');
  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedOptions, setSelectedOptions] = useState();
  const [listEntities, setListEntities] = useState();
  const [dboptions, setDBOptions] = useState();

  // Array of all options
  const optionList = [];

  {
    listEntities &&
      listEntities.data.map((item) => optionList.push({ label: item.name, value: item.name }));
  }

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  async function fetchLegalEntities() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setListEntities("");
        } else if (responseJson.status == 400) {
          setListEntities("");
        } else {
          responseJson.data.map((item) => optionList.push({ label: item.name, value: item.name }));
          setListEntities(responseJson);
        }
      } else {
        setListEntities("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchLegalEntities();
  }, []);

  function createData(name, revenue, employee, entity) {
    return { name, revenue, employee, entity };
  }

  const [isLoading, setIsLoading] = useState(false);
  const [deletename, setDeleteName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [businessunits, setBusinessUnit] = useState();
  const [activebusinessunits, setActiveBusinessUnit] = useState();
  const [deactivebusinessunits, setDeactiveBusinessUnit] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employees, setEmployees] = useState("");
  const [currency, setCurrency] = useState("");
  const [getcurrency, setGetCurrency] = useState();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [oldname, setOldName] = useState("");
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [inact, setInact] = useState("");
  const [act, setAct] = useState("");
  const [customers, setCustomers] = useState(null);
  const [deactiveData, setDeactiveData] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numberOfEmployees: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numberOfEmployees: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters1 };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };

  const header = renderHeader();
  const header1 = renderHeader1();

  let tcount = 0;

  const showEditModal = (name, revenue, employees, currency, le) => {
    setName(name);
    setOldName(name);
    setRevenue(revenue);
    setEmployees(employees);
    setCurrency(currency);
    const defaultList = [];
    {
      le && le.map((item) => defaultList.push({ label: item, value: item }));
    }
    setDBOptions(defaultList);
    setSelectedOptions(defaultList);
    console.log(le);
    setEditIsOpen(true);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  const showDeleteModal = (name) => {
    console.log(name);
    setDeleteName(name);
    setDeleteMessage("Are you sure you want to deactivate this " + name + "?");
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = async (deletename) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/delete-businessunit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          oldBusinessUnitName: deletename,
        }),
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success === false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status === 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Business unit deactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          fetchBusinessUnits();
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in deactivate business unit",
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      // Close the confirmation modal whether the operation succeeded or failed
      hideConfirmationModal();
    }
  };

  const saveData = async (name, revenue, employees, currencySS) => {
    setLoading(true);
    try {
      if (revenue === "" || revenue === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter revenue",
        });
        return false;
      }
      if (revenue <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Revenue must be greater than zero",
        });
        return false;
      }
      if (employees === "" || employees === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter employees",
        });
        return false;
      }
      if (employees <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "employee must be greater than zero",
        });
        return false;
      }
      if (currency === "" || currency === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select currency",
        });
        return false;
      }
      console.log(selectedOptions);
      var le = [];
      {
        selectedOptions.map((opt, index) => le.push(opt.value));
      }
      let res = await fetch("https://ecoprismapi.azurewebsites.net/update-businessunit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          old: oldname,
        },
        body: JSON.stringify({
          name: name,
          revenue: revenue,
          revenueCurrency: currency,
          associatedLegalEntities: [""],
          numberOfEmployees: employees,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">The BU has been successfully updated</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchBusinessUnits();
        setEditIsOpen(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in update business Unit",
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const reactive = async (name) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/reactive-business-unit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Business unit reactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          fetchBusinessUnits();
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in reactivate business unit",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function fetchBusinessUnits() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-businessunits",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          console.log(responseJson);
          setLoading(false);
          setBusinessUnit("");
        } else if (responseJson.status == 400) {
          setLoading(false);
          setBusinessUnit("");
        } else {
          setLoading(false);
          setBusinessUnit(responseJson);
          setActiveBusinessUnit(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveBusinessUnit(responseJson.data.filter((item) => item.isActive === false));
          setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
          setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
          setCustomers(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveData(responseJson.data.filter((item) => item.isActive === false));
        }
      } else {
        setLoading(false);
        setBusinessUnit("");
      }
      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }

  async function getCurrency() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/currencies");
      const data = await response.json();
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    setActive("block");
    setInactive("none");
    setInact("inactive-buttonred");
    setAct("active-buttongreen");
    const fetchData = async () => {
      setLoading(true);
      try {
        setIsLoading(true);
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/list-businessunits",
          requestOptions
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success == false) {
            console.log(responseJson);
            setLoading(false);
            setBusinessUnit("");
          } else if (responseJson.status == 400) {
            setLoading(false);
            setBusinessUnit("");
          } else {
            setLoading(false);
            setBusinessUnit(responseJson);
            setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
            setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
            const activeUnits = responseJson.data.filter((item) => item.isActive === true);
            const inactiveUnits = responseJson.data.filter((item) => item.isActive === false);
            setActiveBusinessUnit(activeUnits);
            setDeactiveBusinessUnit(inactiveUnits);
            setCustomers(activeUnits);
            setDeactiveData(inactiveUnits);
            console.log(activeUnits);
          }
        } else {
          setLoading(false);
          setBusinessUnit("");
        }
        setIsLoading(false);
      } catch (error) {
        setLoading(false);
        setIsLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchBusinessUnits();
    getCurrency();
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);

  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const toggleTable = (name) => {
    if (name === "active") {
      setActive("block");
      setInactive("none");
      setInact("inactive-buttonred");
      setAct("active-buttongreen");
    } else if (name === "inactive") {
      setInact("active-buttonred");
      setAct("inactive-buttongreen");
      setActive("none");
      setInactive("block");
    }
    setPage(0); // Reset page when switching tables
  };

  const handleEditClick = (event, name, revenue, employees, currency, legalEntities) => {
    const target = event.target;
    if (target.tagName.toLowerCase() === "svg") {
      // Clicked on the EditIcon
      showEditModal(name, revenue, employees, currency, legalEntities);
    }
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex">
            <div className="col-md-9 pd-0">
              <div className="steps__containt-hader">
                <h3 className="title">Business Units</h3>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" className="breadcrumbs-font">
                      Company
                    </Link>
                    <Typography color="text.primary">Business Unit</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div className="col-md-3 pd-0">
              <div className="steps__containt-hader btalignleft">
                <a href="/Businessunit" className="add-cta btalignleft">
                  + Add business unit
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-9 pd-0 mb-25">
            <button id="active" className={act} onClick={() => toggleTable("active")}>
              <span className="record-count">{activeCounter}</span>
              <span>Active</span>
            </button>
            <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
              <span className="record-count">{inactiveCounter}</span> <span>Inactive </span>{" "}
            </button>
          </div>
          <div className="row" style={{ display: active }}>
            <DataTable
              value={customers}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              dataKey="id"
              filters={filters}
              filterDisplay="row"
              loading={loading}
              globalFilterFields={["name", "revenue", "numberOfEmployees"]}
              header={header}
              emptyMessage="No data found."
            >
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    <EditIcon
                      title="Edit"
                      style={{ cursor: "pointer", color: "#407775", width: "20px", height: "20px" }}
                      onClick={() =>
                        showEditModal(
                          rowData.name,
                          rowData.revenue,
                          rowData.numberOfEmployees,
                          rowData.currency,
                          rowData.legalEntities
                        )
                      }
                    />

                    <img
                      className="action-icon"
                      src="assets/img/deactivate.png"
                      alt="Deactive"
                      style={{ cursor: "pointer", color: "#407775" }}
                      onClick={() => showDeleteModal(rowData.name)}
                    />
                  </div>
                )}
                style={{ minWidth: "5rem" }}
              />

              <Column
                sortable
                field="name"
                header="Name"
                filter
                filterPlaceholder="Search by name"
                style={{ minWidth: "5rem" }}
              />
              <Column
                header="Revenue"
                field="revenue"
                sortable
                body={(rowData) => (
                  <div>
                    <div>{rowData.revenueCurrency + " " + rowData.revenue.toLocaleString()}</div>
                  </div>
                )}
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Search by revenue"
                filterMatchMode="custom" // Set filterMatchMode to custom for custom filtering
                filterFunction={(value, filter, rowData) => {
                  const filterValue = filter.value.toLowerCase();
                  console.log(filterValue);
                  return (
                    (rowData && rowData.revenueCurrency.toLowerCase().includes(filterValue)) ||
                    rowData.revenue.toLowerCase().includes(filterValue)
                  );
                }}
              />
              <Column
                sortable
                header="Employees"
                field="numberOfEmployees"
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Search by employees"
              />
            </DataTable>
          </div>
          <div className="row" style={{ display: inactive }}>
            <DataTable
              value={deactiveData}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              dataKey="id"
              filters={filters1}
              filterDisplay="row"
              loading={loading}
              globalFilterFields={["name", "revenue", "numberOfEmployees"]}
              header={header1}
              emptyMessage="No data found."
            >
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    <img
                      className="action-icon"
                      src="assets/img/reactivate.png"
                      alt="Reactive"
                      style={{ cursor: "pointer", color: "#407775" }}
                      onClick={() => reactive(rowData.name)}
                    />
                  </div>
                )}
                style={{ minWidth: "5rem" }}
              />

              <Column
                sortable
                field="name"
                header="Name"
                filter
                filterPlaceholder="Search by name"
                style={{ minWidth: "5rem" }}
              />
              <Column
                header="Revenue"
                field="revenue"
                sortable
                body={(rowData) => (
                  <div>
                    <div>{rowData.revenueCurrency + " " + rowData.revenue.toLocaleString()}</div>
                  </div>
                )}
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Search by revenue"
                filterMatchMode="custom" // Set filterMatchMode to custom for custom filtering
                filterFunction={(value, filter, rowData) => {
                  const filterValue = filter.value.toLowerCase();
                  console.log(filterValue);
                  return (
                    (rowData && rowData.revenueCurrency.toLowerCase().includes(filterValue)) ||
                    rowData.revenue.toLowerCase().includes(filterValue)
                  );
                }}
              />
              <Column
                sortable
                header="Employees"
                field="numberOfEmployees"
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Search by employees"
              />
            </DataTable>
          </div>

          <div className="col-md-12 pd-0">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <DeleteConfirmation
              confirmModal={submitDelete}
              showModal={displayConfirmationModal}
              hideModal={hideConfirmationModal}
              message={deleteMessage}
              deletename={deletename}
            />
            <Modal show={editIsOpen} onHide={hideEditModal} className="custom-modal-width">
              <Modal.Header>
                <div className="steps__containt-hader edit-form">
                  <h3 className="title">Edit Business Unit</h3>
                </div>
              </Modal.Header>

              <Modal.Body>
                <form className="edit-form">
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput">
                      Business unit <span className="mark-form">*</span>
                    </label>
                    <input
                      type="hidden"
                      onChange={(e) => setOldName(e.target.value)}
                      value={oldname}
                    />
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="formGroupExampleInput"
                      placeholder="Enter business unit"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput2">
                      Revenue <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      pattern="[0-9]+([,\.][0-9]+)?"
                      required
                      value={revenue}
                      onChange={(e) => setRevenue(e.target.value)}
                      id="formGroupExampleInput2"
                      placeholder="Revenue"
                    />
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="inputCity">
                      Revenue currency <span className="mark-form">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="Ecodropdown"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option selected value="">
                        Select currency
                      </option>
                      {getcurrency &&
                        getcurrency.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput2">
                      No. of employees <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      pattern="[0-9]+"
                      value={employees}
                      onChange={(e) => setEmployees(e.target.value)}
                      id="formGroupExampleInput2"
                      placeholder="Enter no. of employees"
                    />
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="edit-form">
                  <Button
                    className="btn btn-primary mr10"
                    disabled={loading}
                    onClick={() => saveData(name, revenue, employees, currency)}
                  >
                    Update
                  </Button>
                  <Button className="btn btn-danger mr10" onClick={hideEditModal}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
