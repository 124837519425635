import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import _ from "lodash";
import FormData from "form-data";
import Grid from "@mui/material/Grid";
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import { ChangeEvent } from "react";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import containerTheme from "./theme/containerTheme.js";
import toast, { Toaster } from "react-hot-toast";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Select from "react-select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Icon1 = "./assets/img/Assets/configration_icon/White/1.png";
const Icon2 = "./assets/img/Assets/configration_icon/White/2.png";
const Icon3 = "./assets/img/Assets/configration_icon/White/3.png";
const Icon4 = "./assets/img/Assets/configration_icon/White/4.png";
const Icon5 = "./assets/img/Assets/configration_icon/White/5.png";
const customIcons = [Icon1, Icon2, Icon3, Icon4, Icon5];

const iconDotInactive = "./assets/img/Assets/configration_icon/Teal Green/Ellipse 61.png";
const iconBackgroundInactive = "./assets/img/Assets/configration_icon/Teal Green/Ellipse 59.png";
const steps = [
  {
    label: "Select data type",
    description: ``,
    active: 0,
  },
  {
    label: "Choose data connector",
    description: "",
    active: 1,
  },
  {
    label: "Create connection",
    description: "",
    active: 2,
  },
  {
    label: "Map fields",
    description: "",
    active: 3,
  },
  {
    label: "Review and finish",
    description: "",
    active: 4,
  },
];

var count = 0;
export default function VerticalLinearStepper(props) {
  // const classes = useStyles(props);

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [myloading, setMyLoading] = React.useState(false);
  const [apiDone, setApiDone] = React.useState(false);
  const [result, setResult] = React.useState({});
  const [selectedExcel, setSelectedExcel] = useState("");
  const [showTable, setShowTable] = React.useState(false);
  const [businessunit, setBusinessUnit] = React.useState(false);
  const [modules, setModules] = React.useState(false);
  const [mymodule, setMyModule] = useState("");
  const [importfile, setImportFile] = React.useState("");
  const [excelfields, setFields] = React.useState(false);
  const [headerfields, setHeaderFields] = React.useState(false);
  const [pattern, setPattern] = useState("");
  const [sitelocation, setSiteLocation] = useState("");
  const [activesitelocation, setActiveSiteLocation] = useState("");
  const [sitelocation1, setSiteLocation1] = useState("");
  const [leftheader, setLeftHeader] = useState("");
  const [rightheader, setRightHeader] = useState("");
  const [matrics, setMatrics] = useState("");
  const [mymatrics, setMyMatrics] = useState("");
  const [getappnames, setAppNames] = useState("");
  const [apikey, setApiKey] = useState("");
  const [apivalue, setApiValue] = useState("");
  const [resource, setResource] = useState("");
  const [accesstokenurl, setAccessTokenUrl] = useState("");
  const [clientid, setClientID] = useState("");
  const [clientsecret, setClientSecret] = useState("");
  const [scope, setScope] = useState("");
  const [tenantid, setTenantID] = useState("");
  const [query, setQuery] = useState("");
  const [accountname, setAccoutName] = useState("");
  const [tablename, setTableName] = useState("");
  const [apiclientid, setApiClientId] = useState("");
  const [apisubclientid, setApiSubClientId] = useState("");
  const [hash, setHash] = useState("");
  const [salesResource, setSalesResource] = useState("");

  const cardStyles = {
    heading: {
      marginLeft: "15px",
      marginBottom: "10px",
    },
  };

  const handleNext = () => {
    console.log(activeStep);
    count = count + 1;
    if (activeStep === 3) {
      const inputArray = [];
      const farray = [];
      var excelHeaderss = document.getElementsByName("excelHeaderss[]");
      for (var i = 0; i < excelHeaderss.length; i++) {
        var excelHeaderss1 = excelHeaderss[i];
        var isRequired = excelHeaderss1.required;
        var title = excelHeaderss1.title;
        var id = excelHeaderss1.id;
        var value = excelHeaderss1.value;
        if (isRequired) {
          inputArray.push({ name: title, required: true, id: id });
          if (value !== "") {
          } else {
            farray.push(title);
          }
        } else {
        }
      }
      if (farray.length > 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Fields are required " + farray.join(", "),
        });
        return false;
      }
      const sArray = [];
      var excelLeftHeaders = document.getElementsByName("excelHeaderss[]");
      for (var i = 0; i < excelLeftHeaders.length; i++) {
        sArray.push(excelLeftHeaders[i].options[excelLeftHeaders[i].selectedIndex].value);
      }
      const counts = {};
      sArray.forEach((value) => {
        // Exclude 'None' from counting
        if (value !== "None") {
          counts[value] = (counts[value] || 0) + 1;
        }
      });

      Object.entries(counts).forEach(([key, value]) => {
        if (value > 1) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You can not map multiple colunmns of " + key,
          });
          return false;
          console.log(`Key: ${key}, Value: ${value}`);
        }
      });
    }
    if (activeStep === 0) {
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setMatrics(matrics);
    setMyModule(mymodule);
    console.log(activeStep);
    if (activeStep === 1) {
      fetchFields();
    }
    if (activeStep === 3) {
      var inps = document.getElementsByName("destination[]");
      let destination = [];
      for (var i = 0; i < inps.length; i++) {
        var inp = inps[i];
        destination.push(inp.value);
      }
      setRightHeader(destination);
      var inps1 = document.getElementsByName("excelHeaders[]");
      let excelHeaders1 = [];
      for (var i = 0; i < inps1.length; i++) {
        var inp1 = inps1[i];
        excelHeaders1.push(inp1.value);
      }
      setLeftHeader(excelHeaders1);
    }
  };

  const handleBackConfig = () => {
    setShowTable(false);
  };

  const handleBack = () => {
    count = count - 1;
    const currentstep = activeStep;
    if (currentstep === 2) {
      console.log(currentstep);
      setImportFile("");
    }

    if (currentstep === 3) {
      console.log(currentstep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [personName, setPersonName] = React.useState([]);

  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleESGChange = (value, setMyModule) => {
    if (value == "") {
      setMyMatrics("");
      setMatrics("");
      setMyModule("");
    } else {
      setMyModule(value);
      fetchESGMatrics(value);
    }

    console.log(value);
  };

  async function checkAppName1(site) {
    console.log(site);
    if (matrics !== "" && site !== "") {
      var CHeaders = new Headers();
      CHeaders.append("username", username);
      CHeaders.append("esg", matrics);
      CHeaders.append("site", site);

      var Options = {
        method: "GET",
        headers: CHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/get-allappname",
          Options
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success == false) {
          } else if (responseJson.status == 400) {
          } else {
            var data = responseJson.data;
            console.log(data);
            setAppNames(data);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function checkAppName2(matrics) {
    console.log(matrics);
    if (matrics !== "" && sitelocation1 !== "") {
      var CHeaders = new Headers();
      CHeaders.append("username", username);
      CHeaders.append("esg", matrics);
      CHeaders.append("site", sitelocation1);

      var Options = {
        method: "GET",
        headers: CHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/get-allappname",
          Options
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success == false) {
          } else if (responseJson.status == 400) {
          } else {
            var data = responseJson.data;
            console.log(data);
            setAppNames(data);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchSiteLocation() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitelocation",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setSiteLocation("");
        } else if (responseJson.status == 400) {
          setSiteLocation("");
        } else {
          setSiteLocation(responseJson);
          setActiveSiteLocation(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setSiteLocation("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchESGMatrics(value) {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      myHeaderss.append("param", value);
      var requestOptionss = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/ESG", requestOptionss);
      const data = await response.json();
      setMyMatrics(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchOrganization() {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      var requestOptionss = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/organization-name",
        requestOptionss
      );
      const data = await response.json();
      setApiClientId("Ecoprism");
      setApiSubClientId(data.data.name);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSiteLocation();
    fetchOrganization();
  }, []);

  async function fetchModules() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/list-modules");
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setModules("");
        } else if (responseJson.status == 400) {
          setModules("");
        } else {
          setModules(responseJson.data);
        }
      } else {
        setModules("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchModules();
  }, []);

  async function fetchFields() {
    try {
      var myH = new Headers();
      myH.append("username", username);
      myH.append("modulename", mymodule);
      myH.append("pattern", selectedExcel);
      myH.append("ESGMetric", matrics);
      var requestO = {
        method: "GET",
        headers: myH,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/get-fields", requestO);
      const data = await response.json();
      const inputList = data.data;
      setFields(inputList);
      function combine(collections) {
        const entries = [...Object.entries(collections)];
        const length = entries[0]?.[1]?.length ?? 0;
        const result = [];
        for (const [name, array] of entries) {
          if (array.length !== length) throw new Error("Non-uniform array lengths");
          for (let i = 0; i < length; i += 1) {
            const item = (result[i] ??= {});
            item[name] = array[i];
          }
        }
        return result;
      }
      const values = Object.values(inputList);
      const keys = Object.keys(inputList);
      const merge = () => {
        const merged = combine({
          value: values,
          key: keys,
        });
        console.log(merged);
        setFields(merged);
      };
      merge();
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function uploadFileName(filename) {
    setMyLoading(true);
    const res = await fetch("https://ecoprismapi.azurewebsites.net/GetPdfParameter", {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        filename: filename,
        username: username,
      },
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      if (responseJson.success == false) {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.data,
        });
      } else if (responseJson.status == 400) {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.title,
        });
      } else {
        console.log(responseJson.data);
        function combine(collections) {
          const entries = [...Object.entries(collections)];
          const length = entries[0]?.[1]?.length ?? 0;
          const result = [];
          for (const [name, array] of entries) {
            if (array.length !== length) throw new Error("Non-uniform array lengths");
            for (let i = 0; i < length; i += 1) {
              const item = (result[i] ??= {});
              item[name] = array[i];
            }
          }
          return result;
        }
        const businessUnitValues = Object.values(responseJson.data);
        const businessUnitKeys = Object.keys(responseJson.data);
        const merge = () => {
          const merged = combine({
            businessunitvalue: businessUnitValues,
            businessunit: businessUnitKeys,
          });
          console.log(merged);
          const businessunitvalues = merged.map((item) => item.businessunit);
          const commaSeparatedArray = businessunitvalues.join(",");
          const dataArray = commaSeparatedArray.split(",");
          setHeaderFields(dataArray);
          setMyLoading(false);
          Swal.fire({
            icon: "success",
            html: filename + " uploaded successfully",
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          console.log(dataArray);
        };

        merge();
        setImportFile(filename);
      }
    } else {
      setMyLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pdf Not uploaded",
      });
    }
  }

  //PDF Code
  async function uploadImage(containerName, file) {
    console.log(file);
    let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
      method: "GET",
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      const connectionString = responseJson.data;
      const blobServiceClient = new BlobServiceClient(connectionString);
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(file.name);
      const blockBlobClient = blobClient.getBlockBlobClient();
      const result = await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: (ev) => console.log(ev),
      });
      if (result) {
        console.log(file.name);
        uploadFileName(file.name);
      }

      //console.log(`Upload of file '${file.name}' completed`);
    } else {
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setMyLoading(true);
    const type = localStorage.getItem("type");
    const name = acceptedFiles[0].name;
    const extension = name.split(".").pop();
    let sendObj = {
      startDate: "",
      endDate: "",
      energyCons: 0,
    };
    if (type === "Excel") {
      if (extension === "xls" || extension === "xlsx") {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
            //const workbookHeaders = XLSX.readFile(bstr, { sheetRows: 1 });
            console.log(data);
            if (data.length === 0) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Excel File is not proper or header is not present",
              });
              return false;
              // Perform actions for an empty array
            }
            setImportFile(acceptedFiles[0].name);
            setMyLoading(false);
            Swal.fire({
              icon: "success",
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              html: `<h4 style="color: #407775; font-size: 20px;">Success!</h4>
              ${acceptedFiles[0].name} uploaded successfully`,
            });
            const columnsArray = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
              header: 1,
            })[0];
            console.log(columnsArray);
            setHeaderFields(columnsArray);
            var result = columnsArray
              .map(function (val) {
                return val;
              })
              .join(",");
            var item = "";
            var results = {};

            if (result !== null) {
              result = result.split(",");

              for (var i = 0; i < result.length; i++) {
                item = result[i].trim();

                results[item] = item;
              }
            }
            /* Update state */
            const jsonData = JSON.parse(JSON.stringify(data));
            const newdata = [];
            _.forEach(jsonData, (jd) => {
              sendObj.energyCons = jd.EnergyConsumption;
              sendObj.startDate = jd.StartDate;
              sendObj.endDate = jd.EndDate;
              newdata.push({
                email: "sudies@ecoprism.com",
                energy: sendObj.energyCons,
                region: "DK",
                reportPeriod: 2,
                endTimeEpoch: sendObj.endDate,
                unit: "kWh",
              });
              //console.log(jd, 'hsdajshd')
            });
            setMyLoading(false);
          };
          reader.readAsBinaryString(file);
        });
      } else {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File format is not valid",
        });
        setImportFile();
        return false;
      }
    }
    if (type === "PDF") {
      if (extension === "pdf") {
        uploadImage("ecoprismfilecontainer", acceptedFiles[0]);
      } else {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File format is not valid",
        });
        setImportFile();
        return false;
      }
    }
  }, []);

  const processExcel = async (newdata) => {
    try {
      const requestOptions = newdata;
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/upload-file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestOptions),
      });
      const data = await response.json();
      setMyLoading(false);
      setApiDone(true);
      setResult(data);
      setHeaderFields(data);
      setActiveStep(2);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadExcel = async (newdata) => {
    console.log(newdata);
    try {
      const requestOptions = newdata;
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/file`, {
        method: "POST",
        body: requestOptions,
      });
      const data = await response.json();
      setMyLoading(false);
      setApiDone(true);
      setResult(data);
      setHeaderFields(data);
      setActiveStep(2);
    } catch (error) {
      console.log(error);
    }
  };

  const excelH = async (e, i) => {
    document.getElementById("excel" + i).value = e;
  };

  const handleClick = (item) => {
    localStorage.setItem("type", item);
    setSelectedExcel(item);
  };

  let handleSubmit = async (e) => {
    setMyLoading(true);
    e.preventDefault();
    console.log("Left Header" + leftheader);
    console.log("Right Header" + rightheader);
    var newArray = leftheader.map((e, i) => e + ":" + rightheader[i]);
    console.log(newArray);
    const inputArray = newArray;

    const outputObject = inputArray.reduce((result, item) => {
      const [key, value] = item.split(":");
      result[key] = value;
      return result;
    }, {});

    console.log(outputObject);

    var str = JSON.stringify(newArray);
    var final1 = str
      .replace(/{|},|}/g, "")
      .replace(/\[|]|"/g, "")
      .replace(/,/g, ",");
    console.log(final1);
    var result = newArray.reduce(function (map, obj) {
      console.log(obj);
      map[obj] = obj;
      return map;
    }, {});
    console.log(result);
    console.log(
      Object.entries(newArray).map(
        ([key, value]) => `My key is ${key} and my value is ${JSON.stringify(value)}`
      )
    );
    if (selectedExcel === "API" || selectedExcel === "Azure Table Storage") {
      if (connectionType === "APIkey") {
        try {
          let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorAPI", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              clientid: apiclientid,
              subclientid: apisubclientid,
            },
            body: JSON.stringify({
              ConnectorName: "API",
              authMethod: "APIKey",
              authDetails: {
                Key: apikey,
                Value: apivalue,
              },
              resourceurl: resource,
            }),
          });
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              console.log(responseJson);
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setHash(responseJson.connectionId);
              let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
                method: "POST",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  username: username,
                },
                body: JSON.stringify({
                  siteLocationId: sitelocation1,
                  fileFormat: selectedExcel,
                  esgmetric: matrics,
                  fieldMapping: outputObject,
                  reportFrequency: "",
                  entityUrl: "",
                  clientId: apiclientid,
                  subClientId: apisubclientid,
                  connectionHash: responseJson.connectionId,
                }),
              });
              if (res.status === 200) {
                const responseJson = await res.json();
                if (responseJson.success == false) {
                  console.log(responseJson);
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.data,
                  });
                } else if (responseJson.status == 400) {
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.title,
                  });
                } else {
                  setMyLoading(false);
                  setActiveStep(5);
                }
              } else {
                setMyLoading(false);
                console.log("Error");
              }
            }
          } else {
            setMyLoading(false);
            console.log("Error");
          }
        } catch (err) {
          setMyLoading(false);
          console.log(err);
        }
      } else {
        try {
          let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorAPI", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              clientid: apiclientid,
              subclientid: apisubclientid,
            },
            body: JSON.stringify({
              ConnectorName: selectedExcel,
              authMethod: "OAuth",
              authDetails: {
                type: "OAuth",
                AccessTokenUrl: accesstokenurl,
                ClientId: clientid,
                ClientSecret: clientsecret,
                Scope: scope,
                TenantId: tenantid,
              },
              storageAccountName: accountname,
              ResourceUrl: resource,
              tableName: tablename,
              query: query,
            }),
          });
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              console.log(responseJson);
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setHash(responseJson.connectionId);
              let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
                method: "POST",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  username: username,
                },
                body: JSON.stringify({
                  siteLocationId: sitelocation1,
                  fileFormat: selectedExcel,
                  esgmetric: matrics,
                  fieldMapping: outputObject,
                  reportFrequency: "",
                  entityUrl: "",
                  clientId: apiclientid,
                  subClientId: apisubclientid,
                  connectionHash: responseJson.connectionId,
                }),
              });
              if (res.status === 200) {
                const responseJson = await res.json();
                if (responseJson.success == false) {
                  console.log(responseJson);
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.data,
                  });
                } else if (responseJson.status == 400) {
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.title,
                  });
                } else {
                  setMyLoading(false);
                  setActiveStep(5);
                }
              } else {
                setMyLoading(false);
                console.log("Error");
              }
            }
          } else {
            setMyLoading(false);
            console.log("Error");
          }
        } catch (err) {
          setMyLoading(false);
          console.log(err);
        }
      }
    } else {
      try {
        let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
          },
          body: JSON.stringify({
            siteLocationId: sitelocation1,
            fileFormat: selectedExcel,
            esgmetric: matrics,
            fieldMapping: outputObject,
            reportFrequency: "",
            entityUrl: "",
            clientId: apiclientid,
            subClientId: apisubclientid,
            connectionHash: hash,
          }),
        });
        if (res.status === 200) {
          const responseJson = await res.json();
          if (responseJson.success == false) {
            console.log(responseJson);
            setMyLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseJson.data,
            });
          } else if (responseJson.status == 400) {
            setMyLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseJson.title,
            });
          } else {
            setMyLoading(false);
            setActiveStep(5);
          }
        } else {
          setMyLoading(false);
          console.log("Error");
        }
      } catch (err) {
        setMyLoading(false);
        console.log(err);
      }
    }
  };
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const handleCancel = () => {
    setActiveStep(0); // Reset the active step to 0
  };

  // const delayedCode = setTimeout(() => {
  //   console.log('Code executed after 5 seconds');
  // }, 5000);

  const [connectionType, setConnectionType] = useState("");

  const handleConnectionTypeChange = (e) => {
    setConnectionType(e.target.value);
  };

  const checkApiConnection = async (data) => {
    setMyLoading(true);
    try {
      let res;
      console.log(selectedExcel);
      if (connectionType === "APIkey") {
        if (apikey === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter API Key",
          });
        }
        if (apivalue === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter Value Here",
          });
        }
        if (resource === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter Resource Url",
          });
        }
        console.log("API Call");
        res = await fetch("https://ecoprismapi.azurewebsites.net/checkApiConnection", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
            key: apikey,
            value: apivalue,
            resource: resource,
          },
          body: JSON.stringify({
            ConnectorName: selectedExcel,
            authMethod: "APIKey",
            authDetails: {
              Key: apikey,
              Value: apivalue,
            },
            ResourceUrl: resource,
          }),
        });
      } else {
        console.log("OAuth Call");
        if (clientid === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter client id",
          });
        }
        if (clientsecret === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter client secret",
          });
        }
        if (scope === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter scope",
          });
        }
        if (accesstokenurl === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter access token url",
          });
        }
        if (tenantid === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter tenant id",
          });
        }
        if (resource === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter resource urls",
          });
        }
        res = await fetch("https://ecoprismapi.azurewebsites.net/checkApiConnection", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
          },
          body: JSON.stringify({
            ConnectorName: selectedExcel,
            authMethod: "OAuth",
            authDetails: {
              type: "OAuth",
              AccessTokenUrl: accesstokenurl,
              ClientId: clientid,
              ClientSecret: clientsecret,
              Scope: scope,
              TenantId: tenantid,
            },
            storageAccountName: accountname,
            ResourceUrl: resource,
            tableName: tablename,
            query: query,
          }),
        });
      }

      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson === true) {
          let res1;
          if (connectionType === "APIkey") {
            setImportFile("API");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "APIKey",
                authDetails: {
                  Key: apikey,
                  Value: apivalue,
                },
                ResourceUrl: resource,
              }),
            });
          } else {
            setImportFile("Azure Table Storage");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "OAuth",
                authDetails: {
                  type: "OAuth",
                  AccessTokenUrl: accesstokenurl,
                  ClientId: clientid,
                  ClientSecret: clientsecret,
                  Scope: scope,
                  TenantId: tenantid,
                },
                storageAccountName: accountname,
                ResourceUrl: resource,
                tableName: tablename,
                query: query,
              }),
            });
          }
          if (res1.status === 200) {
            const responseJson1 = await res1.json();
            if (responseJson1.success == false) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson1.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setMyLoading(false);
              Swal.fire({
                icon: "success",
                html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Connection Created successfully</p>',
                iconHtml:
                  '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              });
              setHeaderFields(responseJson1);
            }
          }
        } else {
          setMyLoading(false);
          setImportFile("");
        }
      } else {
        setMyLoading(false);
        console.log("Error");
      }
    } catch (err) {
      setMyLoading(false);
      console.log(err);
    }
  };

  const checkApiConnection1 = async (data) => {
    setMyLoading(true);
    try {
      let res;
      console.log(selectedExcel);
      console.log("OAuth Call");
      if (clientid === "") {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Enter client id",
        });
      }
      if (clientsecret === "") {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Enter client secret",
        });
      }

      if (accesstokenurl === "") {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Enter access token url",
        });
      }

      if (resource === "") {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Enter resource urls",
        });
      }
      res = await fetch("https://ecoprismapi.azurewebsites.net/checkApiConnection", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          ResourceUrl: resource,
          ConnectorName: selectedExcel,
          AuthMethod: "OAuth",
          AuthDetails: {
            Type: "OAuth",
            AccessTokenUrl: accesstokenurl,
            ClientId: clientid,
            ClientSecret: clientsecret,
            Resource: salesResource,
          },
          Query: query,
          HttpMethod: "GET",
        }),
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson === true) {
          let res1;
          if (connectionType === "APIkey") {
            setImportFile("API");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "APIKey",
                authDetails: {
                  Key: apikey,
                  Value: apivalue,
                },
                ResourceUrl: resource,
              }),
            });
          } else {
            setImportFile("Azure Table Storage");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "OAuth",
                authDetails: {
                  type: "OAuth",
                  AccessTokenUrl: accesstokenurl,
                  ClientId: clientid,
                  ClientSecret: clientsecret,
                  Scope: scope,
                  TenantId: tenantid,
                },
                storageAccountName: accountname,
                ResourceUrl: resource,
                tableName: tablename,
                query: query,
              }),
            });
          }
          if (res1.status === 200) {
            const responseJson1 = await res1.json();
            if (responseJson1.success == false) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson1.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setMyLoading(false);
              Swal.fire({
                icon: "success",
                html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Connection Created successfully</p>',
                iconHtml:
                  '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              });
              setHeaderFields(responseJson1);
            }
          }
        } else {
          setMyLoading(false);
          setImportFile("");
        }
      } else {
        setMyLoading(false);
        console.log("Error");
      }
    } catch (err) {
      setMyLoading(false);
      console.log(err);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      border: "1px solid #ebe9e9",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      textAlign: "left",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "14px", // Set the font size for the group heading
      color: "#707070",
      fontWeight: "600",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      textAlign: "left",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };
  const groupedOptions = Object.entries(modules).map(([group, options]) => ({
    label: group,
    options: options.map((option) => ({ label: option, value: option })),
  }));

  return (
    <React.Fragment>
      <div id="wrapper">
        <div>
          {myloading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
        <div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
        <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />
        <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          id="page-content-wrapper"
          className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
        >
          <div class="container formpd">
            <div className="steps__containt-body">
              {loading && <div class="loading">Loading&#8230;</div>}
              <div className="steps__containt-left">
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        icon={
                          <Badge
                            className="badgeRoot badgeContent"
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <>
                                <img
                                  src={
                                    step.active <= count
                                      ? "./assets/img/Assets/configration_icon/White/Outer Circle.png"
                                      : iconDotInactive
                                  }
                                  alt="Outer Circle"
                                  className="outerCircle"
                                />
                                <img
                                  src={
                                    step.active <= count
                                      ? "./assets/img/Assets/configration_icon/White/Inner circle.png"
                                      : iconBackgroundInactive
                                  }
                                  alt="Inner Circle"
                                  className={`${
                                    step.active <= count ? "innerCircle" : "innerCircleInactive"
                                  }`}
                                />
                                <img
                                  src={customIcons[index]}
                                  alt={step.active <= count ? "Custom Icon" : "Inactive Icon"}
                                  className="customIcon"
                                />
                              </>
                            }
                          >
                            <Avatar
                              alt={`Step ${index + 1}`}
                              className={`'avatar' ${step.active ? "activeIcon" : "inactiveIcon"}`}
                            />
                          </Badge>
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                        <Box sx={{ mb: 2 }}>
                          <div></div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div className="steps__containt-right">
                {/* Step 1 */}
                {activeStep === 0 && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Select data type</h3>
                      <p>Select the data parameter to set up the new connection.</p>
                    </div>
                    <hr className="hr-style" />
                    <div className="steps__container">
                      <form>
                        <div className="row">
                          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div className="mb-3">
                              <label className="formlable">
                                Select ESG parameter <span className="mark-form">*</span>
                              </label>

                              <Select
                                className=""
                                value={matrics ? { label: matrics, value: matrics } : null}
                                onChange={(selectedOption) => {
                                  setMatrics(selectedOption.value);
                                  checkAppName2(selectedOption.value);
                                }}
                                options={groupedOptions}
                                styles={customStyles}
                                menuPosition="fixed"
                                placeholder="ESG parameter"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label className="formlable">
                                Select site location<span className="mark-form">*</span>
                              </label>
                              <Select
                                id="inputState"
                                required
                                className=""
                                value={
                                  sitelocation1
                                    ? { label: sitelocation1, value: sitelocation1 }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  setSiteLocation1(selectedOption.value);
                                  checkAppName1(selectedOption.value);
                                }}
                                options={
                                  activesitelocation &&
                                  activesitelocation.map((status) => ({
                                    label: status.name,
                                    value: status.name,
                                  }))
                                }
                                placeholder="Site"
                                styles={customStyles}
                                menuPosition="fixed"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="next__previous-buttons">
                        <div className="d-flex">
                          <Button
                            className="next-button"
                            onClick={handleNext}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                            disabled={!matrics || !sitelocation1}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 2 */}
                {activeStep === 1 && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Choose data connector </h3>
                      <p>
                        Simplify data collection using import options such as excel sheets, XML
                        files and API integration.
                      </p>
                    </div>

                    <hr />
                    <div className="steps__container">
                      <div>
                        <label className="form-label">
                          Choose connector <span className="mark-form">*</span>
                        </label>
                        <ul className="list dropzone__list">
                          {getappnames && getappnames.configuredAppNames.length === 0 ? (
                            <div></div>
                          ) : (
                            <div style={cardStyles.heading} className="text-conn">
                              Used connector
                            </div>
                          )}

                          {getappnames &&
                            getappnames.configuredAppNames.map((appname) => {
                              console.log(appname);
                              if (appname === "Excel") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                    style={{ "pointer-events": "none" }}
                                  >
                                    <img src="assets/img/2.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "PDF") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                    style={{ "pointer-events": "none" }}
                                  >
                                    <img src="assets/img/pdf.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "Dynamics") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                    style={{ "pointer-events": "none" }}
                                  >
                                    <img src="assets/img/teams.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                            })}
                          {getappnames && getappnames.availableToConfigureAppNames.length === 0 ? (
                            <div></div>
                          ) : (
                            <div style={cardStyles.heading} className="text-conn">
                              Available connector
                            </div>
                          )}

                          {getappnames &&
                            getappnames.availableToConfigureAppNames.map((appname) => {
                              if (appname === "Excel") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                  >
                                    <img src="assets/img/2.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "PDF") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                  >
                                    <img src="assets/img/pdf.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "Dynamics") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                  >
                                    <img src="assets/img/teams.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "SalesForce") {
                                return (
                                  <li
                                    className={selectedExcel === appname ? "active items" : "items"}
                                    value={appname}
                                    onClick={() => handleClick(appname)}
                                  >
                                    <img src="assets/img/salesforce.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                            })}
                          {/* Cascading Connectors */}

                          {getappnames &&
                            getappnames.cascadesConnectors.map((appname) => {
                              if (appname.name === "Azure Table Storage") {
                                return (
                                  <li
                                    className={
                                      selectedExcel === appname.name ? "active items" : "items"
                                    }
                                    value={appname.name}
                                    onClick={() => handleClick(appname.name)}
                                  >
                                    <img src="assets/img/azure.png" />
                                    <h6>{appname.name}</h6>
                                  </li>
                                );
                              }
                              if (appname.name === "API") {
                                return (
                                  <li
                                    className={
                                      selectedExcel === appname.name ? "active items" : "items"
                                    }
                                    value={appname.name}
                                    onClick={() => handleClick(appname.name)}
                                  >
                                    <img src="assets/img/api.png" />
                                    <h6>{appname.name}</h6>
                                  </li>
                                );
                              }
                            })}

                          {getappnames && getappnames.comingSoonAppNames.length === 0 ? (
                            <div></div>
                          ) : (
                            <div style={cardStyles.heading} className="text-conn">
                              Coming soon connector
                            </div>
                          )}

                          {getappnames &&
                            getappnames.comingSoonAppNames.map((appname) => {
                              if (appname === "Meter") {
                                return (
                                  <li className="items">
                                    <img src="assets/img/meterno.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "SAP") {
                                return (
                                  <li className="items">
                                    <img src="assets/img/sap.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "Oracle") {
                                return (
                                  <li className="items">
                                    <img src="assets/img/oracle.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "Dynamics") {
                                return (
                                  <li className="items">
                                    <img src="assets/img/teams.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                              if (appname === "SalesForce") {
                                return (
                                  <li className="items">
                                    <img src="assets/img/salesforce.png" />
                                    <h6>{appname}</h6>
                                  </li>
                                );
                              }
                            })}
                        </ul>
                      </div>
                      <div className="next__previous-buttons">
                        <div className="d-flex">
                          <Button
                            onClick={handleBack}
                            variant="outlined"
                            size="medium"
                            className="outline-btn"
                            sx={{
                              color: "#000",
                              marginRight: "15px",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="next-button"
                            onClick={handleNext}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                            disabled={!selectedExcel}
                          >
                            Next
                          </Button>
                          {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 3 */}
                {activeStep === 2 && (selectedExcel === "Excel" || selectedExcel === "PDF") && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Create connection</h3>
                      <p>
                        Upload the selected format file with the data for the selected ESG parameter
                      </p>
                    </div>
                    <hr />
                    <div className="steps__container">
                      {/* <h6 className='form-label'>Import File</h6> */}
                      <div style={{ color: "#1E4B7A" }}>
                        {importfile ? <p>{importfile} Uploaded successfully</p> : null}
                      </div>
                      <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone__section">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone">
                                <label for="files" class="dropzone-container">
                                  <div className="file-icon">
                                    <img class="icon" src="assets/img/import.png" alt=""></img>
                                  </div>
                                  <div className="dropzone-title">
                                    <span className="browse">Browse</span> or drag and drop
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="next__previous-buttons">
                        <div className="d-flex">
                          <Button
                            onClick={handleBack}
                            variant="outlined"
                            size="medium"
                            className="outline-btn"
                            sx={{
                              color: "#000",
                              marginRight: "15px",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="next-button"
                            onClick={handleNext}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                            disabled={!importfile}
                          >
                            Next
                          </Button>
                          {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px',height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 3 */}
                {activeStep === 2 &&
                  (selectedExcel === "Dynamics" ||
                    selectedExcel === "Azure Table Storage" ||
                    selectedExcel === "API") && (
                    <div>
                      <div className="steps__containt-hader">
                        <h3 className="title">Create connection</h3>
                        <p>
                          Upload the selected format file with the data for the selected ESG
                          parameter
                        </p>
                      </div>
                      <hr />
                      <div className="steps__container">
                        <div style={{ color: "#1E4B7A" }}>
                          {importfile ? <p>{importfile} Connection Created successfully</p> : null}
                        </div>
                        <div className="abc">
                          <div className="form-group col-md-6">
                            <label className="formlable" htmlFor="inputState">
                              Connection type <span className="mark-form">*</span>
                            </label>
                            <select
                              id="inputState"
                              className="Ecodropdown"
                              required
                              onChange={handleConnectionTypeChange}
                              value={connectionType}
                            >
                              <option value="">Select authorization type</option>
                              {/* <option value="BasicAuth">Basic Auth</option> */}
                              <option value="OAuth">OAuth</option>
                              <option value="APIkey">API Key</option>
                              {/* <option value="None">None</option> */}
                            </select>
                          </div>

                          {connectionType === "APIkey" && (
                            <div className="form-group col-md-6">
                              {selectedExcel === "API" && (
                                <div>
                                  <label className="formlable" htmlFor="inputCity">
                                    Query<span className="mark-form"></span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setQuery(e.target.value);
                                    }}
                                    className="form-control"
                                    id="query"
                                    placeholder=""
                                  />
                                </div>
                              )}
                              {selectedExcel === "Azure Table Storage" && (
                                <div>
                                  <label className="formlable" htmlFor="inputCity">
                                    Storage Account Name<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setAccoutName(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="storageAccountName"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Table Name<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setTableName(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="tableName"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Query<span className="mark-form"></span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setQuery(e.target.value);
                                    }}
                                    className="form-control"
                                    id="query"
                                    placeholder=""
                                  />
                                </div>
                              )}
                              <label className="formlable" htmlFor="inputCity">
                                Key <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setApiKey(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Value<span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setApiValue(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Resource URL <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                id="inputCity"
                                onChange={(e) => {
                                  setResource(e.target.value);
                                }}
                                placeholder=""
                              />

                              {selectedExcel === "API" && (
                                <div>
                                  <button
                                    type="submit"
                                    onClick={checkApiConnection}
                                    className="btn btn-primary signin__btn"
                                  >
                                    Connect
                                  </button>
                                </div>
                              )}
                              {selectedExcel === "Azure Table Storage" && (
                                <div>
                                  <button type="submit" className="btn btn-primary signin__btn">
                                    Connect
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                          {connectionType === "OAuth" && (
                            <div className="form-group col-md-6">
                              {selectedExcel === "API" && (
                                <div>
                                  <label className="formlable" htmlFor="inputCity">
                                    Query<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setQuery(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="query"
                                    placeholder=""
                                  />
                                </div>
                              )}
                              {selectedExcel === "Azure Table Storage" && (
                                <div>
                                  <label className="formlable" htmlFor="inputCity">
                                    Storage Account Name<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setAccoutName(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="storageAccountName"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Table Name<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setTableName(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="tableName"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Query<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setQuery(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="query"
                                    placeholder=""
                                  />
                                </div>
                              )}

                              <label className="formlable" htmlFor="inputCity">
                                Access Token URL<span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setAccessTokenUrl(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Client Id<span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setClientID(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Client Secret <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setClientSecret(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Scope <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setScope(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Tenant Id <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setTenantID(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="tenantId"
                                placeholder=""
                              />

                              <label className="formlable" htmlFor="inputCity">
                                Resource URL <span className="mark-form">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setResource(e.target.value);
                                }}
                                className="form-control"
                                required
                                id="inputCity"
                                placeholder=""
                              />

                              {selectedExcel === "API" && (
                                <div>
                                  <button
                                    type="submit"
                                    onClick={checkApiConnection}
                                    className="btn btn-primary signin__btn"
                                  >
                                    Connect
                                  </button>
                                </div>
                              )}

                              {selectedExcel === "Azure Table Storage" && (
                                <div>
                                  <button
                                    type="submit"
                                    onClick={checkApiConnection}
                                    className="btn btn-primary signin__btn"
                                  >
                                    Connect
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="next__previous-buttons">
                          <div className="d-flex">
                            <Button
                              onClick={handleBack}
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                width: "120px",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              className="next-button"
                              onClick={handleNext}
                              variant="contained"
                              size="medium"
                              sx={{
                                color: "#fff",
                                backgroundColor: "#407775",
                                width: "120px",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                              disabled={!importfile}
                            >
                              Next
                            </Button>
                            {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px',height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {activeStep === 2 && selectedExcel === "SalesForce" && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Create connection</h3>
                      <p>
                        Upload the selected format file with the data for the selected ESG parameter
                      </p>
                    </div>
                    <hr />
                    <div className="steps__container">
                      <div style={{ color: "#1E4B7A" }}>
                        {importfile ? <p>{importfile} Connection Created successfully</p> : null}
                      </div>
                      <div className="abc">
                        <div className="form-group col-md-6">
                          <label className="formlable" htmlFor="inputCity">
                            Access Token URL<span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setAccessTokenUrl(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="inputCity"
                            placeholder=""
                          />

                          <label className="formlable" htmlFor="inputCity">
                            Client Id<span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setClientID(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="inputCity"
                            placeholder=""
                          />

                          <label className="formlable" htmlFor="inputCity">
                            Client Secret <span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setClientSecret(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="inputCity"
                            placeholder=""
                          />

                          <label className="formlable" htmlFor="inputCity">
                            Resource <span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSalesResource(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="inputCity"
                            placeholder=""
                          />

                          <label className="formlable" htmlFor="inputCity">
                            Query <span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setQuery(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="tenantId"
                            placeholder=""
                          />

                          <label className="formlable" htmlFor="inputCity">
                            Resource URL <span className="mark-form">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setResource(e.target.value);
                            }}
                            className="form-control"
                            required
                            id="inputCity"
                            placeholder=""
                          />

                          {selectedExcel === "SalesForce" && (
                            <div>
                              <button
                                type="submit"
                                onClick={checkApiConnection1}
                                className="btn btn-primary signin__btn"
                              >
                                Connect
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="next__previous-buttons">
                        <div className="d-flex">
                          <Button
                            onClick={handleBack}
                            variant="outlined"
                            size="medium"
                            className="outline-btn"
                            sx={{
                              color: "#000",
                              marginRight: "15px",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="next-button"
                            onClick={handleNext}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                            disabled={!importfile}
                          >
                            Next
                          </Button>
                          {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px',height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 4 */}
                {activeStep === 3 && (
                  <div className="table__section">
                    <div className="data__table">
                      <div className="steps__containt-hader">
                        <h3 className="title">Map the fields</h3>
                        <p>
                          Map the fields from the data source to the attributes of the standard
                          Ecoprism data requirement.
                        </p>
                      </div>
                      <hr />
                      <div className="steps__container">
                        <div className="table-responsive">
                          <table className="table custom-table ">
                            <thead>
                              <tr>
                                <th scope="col">Data requirement</th>
                                <th scope="col">Field in data source</th>
                              </tr>
                            </thead>
                            <tbody>
                              {excelfields.map((keyName, i) => (
                                <tr>
                                  <td>
                                    <div class="form-group col-md-12">
                                      <select
                                        id={"dropdown" + i}
                                        class="Ecodropdown"
                                        onChange={(e) => excelH(e.target.value, i)}
                                        name="excelHeaderss[]"
                                        required={keyName.value === true ? "required" : undefined}
                                        title={keyName.key}
                                      >
                                        <option value={keyName.value === true ? "" : "None"}>
                                          None
                                        </option>
                                        {headerfields.map((header) => (
                                          <option value={header}>{header}</option>
                                        ))}
                                      </select>
                                      <input
                                        type="hidden"
                                        id={"excel" + i}
                                        value={keyName.value === true ? "" : "None"}
                                        name="excelHeaders[]"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {keyName.key}{" "}
                                    <span style={{ color: "red" }}>
                                      {keyName.value === true ? "*" : ""}
                                    </span>
                                    <input type="hidden" value={keyName.key} name="destination[]" />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <h5>Result</h5>
                        <p>co2e : {result.co2e} {result.co2eUnit}</p>
                        <p> co2e Calculation Method : {result.co2eCalculationMethod}</p> */}
                    </div>
                    <div className="next__previous-buttons">
                      <div className="d-flex">
                        <Button
                          onClick={handleBack}
                          variant="outlined"
                          size="medium"
                          className="outline-btn"
                          sx={{
                            color: "#000",
                            marginRight: "15px",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          className="next-button"
                          onClick={handleNext}
                          variant="contained"
                          size="medium"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#407775",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                          }}
                        >
                          Next
                        </Button>
                        {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', width: '120px', position: 'relative', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                      </div>
                      <div class="ab"></div>
                    </div>
                  </div>
                )}

                {/* Step 5 */}
                {activeStep === 4 && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Review data connection</h3>
                      <p>
                        Review your data connection ensuring seamless integration and optimal
                        performance
                      </p>
                    </div>
                    <hr />
                    <div className="steps__container">
                      <div class="col-md-3"></div>
                      <div class="finish">
                        <p>ESG parameter : {matrics}</p>
                        <p>Site : {sitelocation1}</p>
                        <p>Data connector : {selectedExcel}</p>
                        <p>Owner : {username}</p>
                      </div>
                      <div class="col-md-3"></div>
                      <div className="next__previous-buttons">
                        <div className="d-flex">
                          <Button
                            onClick={handleBack}
                            variant="outlined"
                            size="medium"
                            className="outline-btn"
                            sx={{
                              color: "#000",
                              marginRight: "15px",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="next-button"
                            onClick={handleSubmit}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                          >
                            Save
                          </Button>
                          {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft :'20px', position: 'relative', width: '120px', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 6 */}
                {activeStep === 5 && (
                  <div>
                    <div className="steps__containt-hader">
                      <h3 className="title">Data connection</h3>
                      <p>
                        The connection has been established and is now accessible in the
                        configuration list
                      </p>
                    </div>
                    <hr />
                    <div className="steps__container">
                      <div class="col-md-3"></div>
                      <div class="finish1">
                        <p>Connection created successfully</p>
                        {activeStep === steps.length && (
                          <Paper className="finish" square elevation={0} sx={{ p: 3 }}>
                            {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
                            <Button
                              sx={{
                                color: "#fff",
                                backgroundColor: "#407775",
                                width: "120px",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                              onClick={handleReset}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              <Link to="/allconfiguration" className="btn btn-primary config-btn">
                                {" "}
                                All Configurations{" "}
                              </Link>
                            </Button>
                          </Paper>
                        )}
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
