import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AIPopup from "./aiPopup.js";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "react-bootstrap";

export default function RightContainer({ selectedContent, questions = [], tabData }) {
  const [answers, setAnswers] = useState({});
  const [answers1, setAnswers1] = useState({});
  // const [stepData4, setStepData4] = useState({});
  const [savedStatus, setSavedStatus] = useState({});
  const [aiModalOpen, setAiModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [stepData, setStepData] = useState([]);
  const [expandedTabs, setExpandedTabs] = useState({}); // State to track expanded tabs
  const [selectedOptions, setSelectedOptions] = useState({});
  const selectedSubSubItem = tabData
    .flatMap((item) => item.subItems)
    .flatMap((subItem) => subItem.subSubItems)
    .find((subSubItem) => subSubItem.label === selectedContent);

  const isExpanded = expandedTabs[selectedContent];

  const toggleExpand = () => {
    setExpandedTabs((prevExpandedTabs) => ({
      ...prevExpandedTabs,
      [selectedContent]: !prevExpandedTabs[selectedContent],
    }));
  };

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  const openModal = (question, answer) => {
    setSelectedQuestion(question);
    setSelectedAnswer(answer);
    setAiModalOpen(true);
  };

  const closeModal = () => {
    setAiModalOpen(false);
  };

  /// Reset selected option when selectedSubSubItem changes
  useEffect(() => {
    setSelectedOptions({});
    // Retrieve selected MDR option from localStorage
    const savedOptions = localStorage.getItem("selectedOptions");
    if (savedOptions) {
      setSelectedOptions(JSON.parse(savedOptions));
    }
  }, [selectedSubSubItem]);

  const handleOptionChange = (item, value) => {
    console.log(item);
    console.log(value);
    const updatedOptions = {
      ...selectedOptions,
      [item]: value,
    };
    setSelectedOptions(updatedOptions);
    // Save selected MDR option to localStorage
    localStorage.setItem("selectedOptions", JSON.stringify(updatedOptions));
  };

  // Function to update the answer for a question
  const handleAnswerChange = (question, answer, id) => {
    setAnswers1((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [id]: answer,
      };
      localStorage.setItem("answers1", JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });
  };

  useEffect(() => {
    setSelectedOptions({});
    const savedAnswers = localStorage.getItem("answers");
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
    const savedAnswers1 = localStorage.getItem("answers1");
    if (savedAnswers1) {
      setAnswers1(JSON.parse(savedAnswers1));
    }
    // Retrieve saved stepData from localStorage
    const savedStepData = localStorage.getItem("step4");
    if (savedStepData) {
      setStepData(JSON.parse(savedStepData));
    }
  }, []);

  const handleTableSave = (title, question, answer, module, fun) => {
    console.log(answer);
    //4 Column
    if ("4Column" === fun || "5Column" === fun || "3Column" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        headerArray.forEach((headerRow) => {
          if (headerRow.props.children === undefined) {
            tableArray += "<th></th>";
          } else {
            tableArray += "<th>" + headerRow.props.children + "</th>";
          }
        });
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("4ColumnD" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        headerArray.forEach((headerRow) => {
          tableArray += "<th>" + headerRow.props.children + "</th>";
        });
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";
        tableArray += "<tr>";
        childrenArray.props.children.forEach((child1) => {
          const ifArray = child1.props.children.props;
          const elseArray = child1.props.children;

          if (ifArray !== undefined) {
            tableArray += "<td>" + ifArray.value + "</td>";
          } else {
            tableArray += "<th>" + elseArray + "</th>";
          }
        });
        tableArray += "</tr>";

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("2ColumnD" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead><tr>";
        tableArray +=
          '<th colspan="' + headerArray.props.colSpan + '">' + headerArray.props.children + "</th>";
        tableArray += "</tr></thead>";

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("Scope123" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray +=
          '<tr><th rowSpan="2"></th><th colSpan="4">Retrospective</th><th colSpan="4">Milestones and target years</th></tr><tr><th>Base year</th><th>Comparative</th><th>N</th><th>% N / N-1</th><th>2025</th><th>2030</th><th>2050</th><th>Annual % target / Base year</th></tr>';
        tableArray += "</thead>";

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";

          const ifArray = child.props.children.props;
          const elseArray = child.props.children;
          if (ifArray !== undefined) {
            tableArray += '<td colspan="' + ifArray.colSpan + '">' + ifArray.children + "</td>";
          } else {
            elseArray.forEach((child1) => {
              const ifArray = child1.props.children.props;
              const elseArray = child1.props.children;

              if (ifArray !== undefined) {
                tableArray += "<td>" + ifArray.value + "</td>";
              } else {
                tableArray += "<th>" + elseArray + "</th>";
              }
            });
          }
          tableArray += "</tr>";
        });
        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("2ColumnWH" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";
        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("TableWaste" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray +=
          '<thead><tr><th rowspan="2">Waste Category</th><th rowspan="2">Weight of total Waste Generated</th><th colspan="3">Weight of waste diverted from disposal</th><th colspan="3">Weight of waste diverted to disposal</th><th colspan="2">Non recycled waste</th></tr><tr><th>For reuse</th><th>For recycling</th><th>Other recovery</th><th>To incineration</th><th>To landfill</th><th>To other disposal operations</th><th>Weight of non-recycled waste</th><th>% of non-recycled waste</th></tr></thead>';

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("6Column" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        tableArray +=
          '<th colspan="6">S1-7 – Characteristics of non-employee workers in the undertaking’s own workforce</th>';
        tableArray += "</tr>";
        tableArray += "<tr>";
        tableArray += "<th>Informations on employess by gender</th>";
        tableArray += "<th>Male</th>";
        tableArray += "<th>Female</th>";
        tableArray += "<th>other</th>";
        tableArray += "<th>Not disclosed</th>";
        tableArray += "<th>Total</th>";
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("6Column1" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        tableArray +=
          '<th colspan="6">Table: S1-6 – Characteristics of the Undertaking’s Employees-Information by country</th>';
        tableArray += "</tr>";
        tableArray += "<tr>";
        tableArray += "<th>Informations on employess by gender</th>";
        tableArray += "<th>Country-A</th>";
        tableArray += "<th>Country-B</th>";
        tableArray += "<th>Region-A</th>";
        tableArray += "<th>Region-B</th>";
        tableArray += "<th>Total</th>";
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children.props;
            const elseArray = child1.props.children;

            if (ifArray !== undefined) {
              tableArray += "<td>" + ifArray.value + "</td>";
            } else {
              tableArray += "<th>" + elseArray + "</th>";
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("E54B" === fun || "E54S" === fun || "E54R" === fun) {
      if (answer && answer.props && answer.props.children) {
        const childrenArray = answer.props.children[1].props.children;
        let tableArray = "<table>";
        if (fun === "E54B") {
          tableArray +=
            '<thead><tr><th rowspan="2" colspan="2">Resource and material inflows</th><th colspan="3" style={{ textAlign: "center" }}>Weight of Material</th></tr><tr><th>Biological</th><th>Non-bio</th><th>% Biological</th></tr>';
        }
        if (fun === "E54S") {
          tableArray +=
            '<thead><tr><th rowspan="2" colspan="2">Resource and material inflows</th><th colspan="3" style={{ textAlign: "center" }}>Weight of Material</th></tr><tr><th>Sustainably Sourced</th><th>Non-sustainably sourced</th><th>% Sustainably sourced</th></tr>';
        }
        if (fun === "E54R") {
          tableArray +=
            '<thead><tr><th rowspan="2" colspan="2">Resource and material inflows</th><th colspan="3" style={{ textAlign: "center" }}>Weight of Material</th></tr><tr><th>Recycled/reused</th><th>Virgin</th><th>% Recycled/reused</th></tr>';
        }

        tableArray += "<tbody>";
        childrenArray.forEach((child) => {
          tableArray += "<tr>";
          child.props.children.forEach((child1) => {
            const ifArray = child1.props.children;
            const otherArray = child1.props;
            let hasData = false;
            // Check if ifArray has children props with data
            if (ifArray !== undefined) {
              hasData = true;
              if (ifArray.props && ifArray.props.value !== undefined) {
                tableArray += "<td>" + ifArray.props.value + "</td>";
              } else {
                if (otherArray.rowSpan && otherArray.rowSpan !== undefined) {
                  tableArray +=
                    "<th rowSpan=" +
                    otherArray.rowSpan +
                    ">" +
                    otherArray.children +
                    "</thzzzcxdg>";
                } else if (otherArray.colSpan && otherArray.colSpan !== undefined) {
                  tableArray +=
                    "<th colSpan=" + otherArray.colSpan + ">" + otherArray.children + "</th>";
                } else {
                  tableArray += "<th>" + ifArray + "</th>";
                }
              }
            } else {
            }
          });
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("S1-12" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children[1].props.children.props.children;
        let tableArray = "<table>";

        tableArray += "<thead><tr>";
        tableArray += "<th>Employment of persons with disabilitie</th>";
        tableArray += "<th>Male</th>";
        tableArray += "<th>Female</th>";
        tableArray += "</tr></thead>";
        tableArray += "<tbody>";
        tableArray += "<tr>";
        childrenArray.forEach((child) => {
          const ifArray = child.props.children.props;
          const elseArray = child.props.children;

          if (ifArray !== undefined) {
            tableArray += "<td>" + ifArray.value + "</td>";
          } else {
            tableArray += "<th>" + elseArray + "</th>";
          }
        });
        tableArray += "</tr>";

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("3Column97" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children[1].props.children.props.children;
        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        headerArray.forEach((headerRow) => {
          if (headerRow.props.children === undefined) {
            tableArray += "<th></th>";
          } else {
            tableArray += "<th>" + headerRow.props.children + "</th>";
          }
        });
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";

        tableArray += "<tr>";
        childrenArray.forEach((child1) => {
          const ifArray = child1.props.children.props;
          const elseArray = child1.props.children;

          if (ifArray !== undefined) {
            tableArray += "<td>" + ifArray.value + "</td>";
          } else {
            tableArray += "<th>" + elseArray + "</th>";
          }
        });
        tableArray += "</tr>";

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
    if ("E1-7" === fun) {
      if (answer && answer.props && answer.props.children) {
        const headerArray = answer.props.children.props.children[0].props.children.props.children;
        const childrenArray = answer.props.children.props.children[1].props.children;

        let tableArray = "<table>";

        tableArray += "<thead>";
        tableArray += "<tr>";
        headerArray.forEach((headerRow) => {
          if (headerRow.props.children === undefined) {
            tableArray += "<th></th>";
          } else {
            tableArray += "<th>" + headerRow.props.children + "</th>";
          }
        });
        tableArray += "</tr>";
        tableArray += "</thead>";

        tableArray += "<tbody>";
        let loopCount = 0;
        childrenArray.forEach((child) => {
          loopCount++;
          tableArray += "<tr>";
          // Initialize a counter variable
          if (loopCount <= 9) {
            child.props.children.forEach((child1) => {
              const ifArray = child1.props.children.props;
              const elseArray = child1.props.children;

              if (ifArray !== undefined) {
                tableArray += "<td>" + ifArray.value + "</td>";
              } else {
                tableArray += "<th>" + elseArray + "</th>";
              }
            });
          } else {
            child.props.children.forEach((child1) => {
              const ifArray = child1.props.children.props;
              const elseArray = child1.props.children;
              const elseArraycolSpan = child1.props.colSpan;

              if (ifArray !== undefined) {
                tableArray += '<td colSpan="' + elseArraycolSpan + '">' + ifArray.value + "</td>";
              } else {
                if (elseArraycolSpan !== undefined) {
                  tableArray += '<th colSpan="' + elseArraycolSpan + '">' + elseArray + "</th>";
                } else {
                  tableArray += "<th>" + elseArray + "</th>";
                }
              }
            });
          }
          tableArray += "</tr>";
        });

        tableArray += "</tbody>";
        tableArray += "</table>";
        setSavedStatus((prevSavedStatus) => ({
          ...prevSavedStatus,
          [question]: true,
        }));
        const newData = {
          title: title,
          question: question,
          answer: tableArray,
          module: module,
        };

        setStepData((prevData) => {
          // If prevData is undefined or null, initialize it as an empty array
          if (!prevData) {
            return [newData];
          }
          // If prevData is an array, append newData to it
          localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
          return [...prevData, newData];
        });
      }
    }
  };

  const handleSave = (title, question, answer, module, id) => {
    console.log(answer);
    if (!answer && answer !== 0) {
      console.log("Log 1");
      return false;
    }
    setAnswers((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [id]: answer,
      };
      // Save updated answers to localStorage
      localStorage.setItem("answers", JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });
    // Implement logic to save the answers
    setSavedStatus((prevSavedStatus) => ({
      ...prevSavedStatus,
      [question]: true,
    }));
    const newData = {
      title: title,
      question: question,
      answer: answer,
      module: module,
    };

    setStepData((prevData) => {
      // If prevData is undefined or null, initialize it as an empty array
      if (!prevData) {
        return [newData];
      }
      // If prevData is an array, append newData to it
      localStorage.setItem("step4", JSON.stringify([...prevData, newData]));
      return [...prevData, newData];
    });
  };

  const handleUseGeneratedText = (generatedText) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [selectedQuestion]: generatedText,
    }));
    closeModal();
  };

  const handleSubmit = () => {
    // Implement logic to submit the answers
    console.log("Answers submitted:", answers);
  };

  const filteredQuestions = questions.filter(
    (questionObj) =>
      !questionObj.condition || questionObj.condition === selectedOptions[selectedSubSubItem?.id]
  );

  return (
    <div className="right">
      <AIPopup
        aiModalOpen={aiModalOpen}
        onClose={closeModal}
        question={selectedQuestion}
        answer={selectedAnswer}
        onUseText={handleUseGeneratedText}
      />
      <div className="questions-header">
        <div className="topic-title">
          <Typography variant="h6">{selectedContent}</Typography>

          <div className="checkbox-iconbox">
            {selectedSubSubItem?.mandatory ? ( // Check if mandatory is true
              <img
                className="accordian-icon"
                src="./assets/img/reports/Mandatory.svg"
                alt="Mandatory icon"
              />
            ) : null}
            {selectedSubSubItem?.tooltip && ( // Check if selectedSubSubItem exists and has a tooltip
              <Tooltip title={selectedSubSubItem.tooltip}>
                <IconButton style={{ height: "0", width: "0", marginLeft: "8px" }}>
                  <img
                    className="accordian-icon"
                    src="./assets/img/reports/info.svg"
                    alt="Info Icon"
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {selectedSubSubItem?.obj && ( // Check if obj is present
          <div className="objective-box">
            {typeof selectedSubSubItem.obj === "string" ? ( // Check if obj is a string
              <Typography
                className="objective"
                variant="body1"
                style={{ whiteSpace: "pre-line" }} // Preserve line breaks
              >
                {isExpanded
                  ? selectedSubSubItem.obj
                  : selectedSubSubItem.obj.length > 75
                  ? selectedSubSubItem.obj.slice(0, 75) + "..."
                  : selectedSubSubItem.obj}
                {selectedSubSubItem.obj.length > 75 && ( // Check if objective length exceeds 100 characters
                  <span onClick={toggleExpand}>{isExpanded ? " Read Less" : " Read More"}</span>
                )}
              </Typography>
            ) : (
              // If obj is not a string, assume it's a JSX element
              <div>
                {selectedSubSubItem.obj}
                {/* Check if JSX element has more than 100 characters */}
                {selectedSubSubItem.obj.props.children.length > 100 && (
                  <span onClick={toggleExpand}>{isExpanded ? "Read Less" : "Read More"}</span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mdr-container">
        {selectedSubSubItem && selectedSubSubItem.mdr && (
          <div className="mdr-content d-flex align-items-center justify-content-between">
            <p className="mb-0">{selectedSubSubItem.mdr}</p>
            <div className="flex flex-row" style={{ gap: "20px" }}>
              <div className="flex align-items-center">
                <RadioButton
                  inputId={`${selectedSubSubItem.id}-yes`}
                  name={`${selectedSubSubItem.id}-mdrOption`}
                  value="yes"
                  onChange={(e) => handleOptionChange(selectedSubSubItem.id, e.value)}
                  checked={selectedOptions[selectedSubSubItem.id] === "yes"}
                />
                <label htmlFor={`${selectedSubSubItem.id}-yes`} className="ml-2 mb-0">
                  Yes
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId={`${selectedSubSubItem.id}-no`}
                  name={`${selectedSubSubItem.id}-mdrOption`}
                  value="no"
                  onChange={(e) => handleOptionChange(selectedSubSubItem.id, e.value)}
                  checked={selectedOptions[selectedSubSubItem.id] === "no"}
                />
                <label htmlFor={`${selectedSubSubItem.id}-no`} className="ml-2 mb-0">
                  No
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="questions-container">
        {questions && (
          <div className="input-questions-box">
            {filteredQuestions.map((questionObj, index) =>
              questionObj.table ? (
                <div className="table-container" key={index}>
                  <div className="table-heading">
                    <Typography variant="body1">
                      <span className="bold-part">{questionObj.boldPart}</span>
                      {questionObj.question}
                    </Typography>
                    <div className="table-icons">
                      {questionObj.tooltip && ( // Conditionally render Tooltip
                        <Tooltip title={questionObj.tooltip}>
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/info.svg"
                              alt="Info Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {questionObj.voluntary && ( // Conditionally render Voluntary icon
                        <Tooltip title="Voluntary">
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/voluntary.svg"
                              alt="Voluntary Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {questionObj.phasedIn && ( // Conditionally render Tooltip
                        <Tooltip title={questionObj.phasedIn}>
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/phased-in.svg"
                              alt="Phased-in Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {questionObj.euReg && ( // Conditionally render Tooltip
                        <Tooltip title={questionObj.euReg}>
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/eu-regulation.png"
                              alt="EU Regulation Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  {questionObj.table}
                  <div className="answer-icon">
                    <Tooltip title="Save your answer">
                      <IconButton
                        onClick={() =>
                          handleTableSave(
                            selectedContent,
                            questionObj.question,
                            questionObj.table,
                            questionObj.module,
                            questionObj.fun
                          )
                        }
                        size="small"
                        color="primary"
                        style={{ color: "#707070", float: "right", marginTop: "10px" }}
                      >
                        {savedStatus[questionObj.question] ? (
                          <img
                            className="accordian-icon"
                            src="./assets/img/reports/tick-mark-green.png"
                            alt="Answer Saved"
                          />
                        ) : (
                          <img
                            className="accordian-icon"
                            src="./assets/img/reports/tick-mark-grey.png"
                            alt="Save"
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="question-tab" key={index}>
                  <div className="question-box">
                    <Typography variant="body1">
                      <span className="bold-part">{questionObj.boldPart}</span>{" "}
                      {questionObj.question}
                    </Typography>
                    <div className="questions-icons">
                      {questionObj.tooltip && ( // Conditionally render Tooltip
                        <CustomWidthTooltip title={questionObj.tooltip}>
                          <IconButton sx={{ padding: "0", m: 0 }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/info.svg"
                              alt="Info Icon"
                            />
                          </IconButton>
                        </CustomWidthTooltip>
                      )}
                      {questionObj.voluntary && ( // Conditionally render Voluntary icon
                        <Tooltip title="Voluntary">
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/voluntary.svg"
                              alt="Voluntary Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {questionObj.phasedIn && ( // Conditionally render Tooltip
                        <Tooltip title={questionObj.phasedIn}>
                          <IconButton sx={{ padding: "0" }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/phased-in.svg"
                              alt="Phased-in Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {questionObj.euReg && ( // Conditionally render Tooltip
                        <Tooltip title={questionObj.euReg}>
                          <IconButton sx={{ padding: "0", m: 0 }} className="input-info">
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/eu-regulation.png"
                              alt="EU Regulation Icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="answer-box">
                    <div style={{ position: "relative" }}>
                      <textarea
                        placeholder="Enter your answer"
                        value={answers1[questionObj.id] || ""}
                        onChange={(e) =>
                          handleAnswerChange(questionObj.question, e.target.value, questionObj.id)
                        }
                      />
                      <div className="question-iconbox">
                        <div
                          className={`ai-button ${!answers[questionObj.question] && "disabled"}`}
                          onClick={() =>
                            answers[questionObj.id] &&
                            openModal(questionObj.question, answers[questionObj.id])
                          }
                        >
                          <img
                            className="accordian-icon"
                            src="./assets/img/reports/ai.svg"
                            alt="AI Icon"
                          />
                          <span style={{ marginLeft: "5px" }}>Generate with AI</span>
                        </div>

                        <div className="save-button">
                          <Tooltip title="Save your answer">
                            <IconButton
                              onClick={() =>
                                handleSave(
                                  selectedContent,
                                  questionObj.question,
                                  answers1[questionObj.id],
                                  questionObj.module,
                                  questionObj.id
                                )
                              }
                              size="small"
                              color="primary"
                              style={{ color: "#707070" }}
                            >
                              {answers[questionObj.id] ? (
                                <img
                                  className="accordian-icon"
                                  src="./assets/img/reports/tick-mark-green.png"
                                  alt="Answer Saved"
                                />
                              ) : (
                                <img
                                  className="accordian-icon"
                                  src="./assets/img/reports/tick-mark-grey.png"
                                  alt="Save"
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
