import React from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { useEffect, useState } from "react";
import { authProvider } from '../authProvider';
import MenuIcon from "@mui/icons-material//Menu";
const drawerWidth = 240;
export default function AADHeader({ toggleSidebar, isSidebarOpen }) {
  let navigate = useNavigate (); 
  const location = useLocation();
  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };
  
  const [isOpen, setIsOpen] = useState(false);
  React.useEffect(() => {
    
  }, []);
  const [time, setTime] = React.useState(0);
  const currentCallback = useCurrentCallback(() => {
    const date = new Date();
    setTime(date.toISOString());
  });

  // const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [organization, setOrganization] = useState("");
  const openProfile = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [companyid, setCompanyId] = useState("");
  const [loading, setLoading] = React.useState(false);
  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/organization-name',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setOrganization("");
        }
        else if(responseJson.status == 400){
          setOrganization("");
        }
        else{
          localStorage.setItem('organization', responseJson.data.name);
          setOrganization(responseJson.data.name);
         
        }
      }
      else{
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCompanyId() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/getcompanyid',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setCompanyId("");
        }
        else if(responseJson.status == 400){
          setCompanyId("");
        }
        else{
          localStorage.setItem('companyid', responseJson.data);
          setCompanyId(responseJson.data);
        }
      }
      else{
        setCompanyId("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  const username = localStorage.getItem('userName');
  const organizationValue = localStorage.getItem('organization');
  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/organization-name',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setOrganization("");
        }
        else if(responseJson.status == 400){
          setOrganization("");
        }
        else{
          localStorage.setItem('organization', responseJson.data.name);
          setOrganization(responseJson.data.name);
         
        }
      }
      else{
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }
 const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };
  

return (
  <React.Fragment>
    {location.pathname !== "/login" && (
      <nav
        className={`navbar navbar-expand-lg navbar-light custome__header ${
          isSidebarOpen ? "header-expanded" : "header-collapsed"
        }`}
      >
        <div className="container header-container">
        <div className="navbar-nav-left">
              <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                <MenuIcon className="menu-icon" />
              </button>
              <a className="navbar-brand p-0 organizational-value" href="home">
                {organizationValue}
              </a>
            </div>
           <div className="navbar-nav-right">
              <ul className="navbar-nav mb-2 mb-lg-0 flex-row">
                <li className="nav-item dropdown">
                  <a className="nav-link" href="/home">
                    <img className="icon" src="assets/img/Home.png" alt="Home" />
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#">
                    <img className="icon" src="assets/img/Notifications.png" alt="Notifications" />
                  </a>
                </li>
                <li className="nav-item dropdown login-username">
                  <a
                    className="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {username}!
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    onClick={handleDropdownClick}
                    aria-expanded={isOpen}
                  >
                    <img className="icon" src="assets/img/User.png" alt="User" />
                  </a>
                  {isOpen && (
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li>
                        <a className="dropdown-item" href="/comingsoon">
                          Profile
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/comingsoon">
                          Settings
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li> 
                   <AzureAD provider={authProvider} forceLogin={true}>
                     {
                       ({login, logout, authenticationState, error, accountInfo}) => {
                         switch (authenticationState) {
                           case AuthenticationState.Authenticated:
                             return (
                               <a className="dropdown-item" href="#" onClick={logout}>
                                 Logout
                               </a>
                               
                             );
                           case AuthenticationState.Unauthenticated:
                             return (
                               
                                 <a className="dropdown-item" href="#" onClick={logout}>
                                   Logout
                                 </a>
                                 
                               
                             );
                           case AuthenticationState.InProgress:
                             return (
                               <a className="dropdown-item" href="#" onClick={logout}>
                                 Logout
                               </a>
                               
                             );
                         }
                       }
                     }
                   </AzureAD>
                 </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
        </div>
      </nav>
    )}
   {location.pathname === "/login" && (
        <nav className="navbar navbar-expand-lg custome__header">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src="assets/img/logo.png" alt="" className="img-logo" />
            </a>
          </div>
        </nav>
      )}
  </React.Fragment>
);
}