import Typography from "@mui/material/Typography";

const materialityTopics = [
  {
    label: "Environment",
    accordionPanels: [
      {
        label: "Climate Change",
        id:"e1",
        checkboxes: [
          {
            id: "env-e1-1",
            label: "Climate change adaptation",
            selected: false,
          },
          {
            id: "env-e1-2",
            label: "Climate change mitigation",
            selected: false,
          },
          {
            id: "env-e1-3",
            label: "Energy",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Pollution",
        id:"e2",
        checkboxes: [
          {
            id: "env-e2-1",
            label: "Pollution of air",
            selected: false,
          },
          {
            id: "env-e2-2",
            label: "Pollution of water",
            selected: false,
          },
          {
            id: "env-e2-3",
            label: "Pollution of soil",
            selected: false,
          },
          {
            id: "env-e2-4",
            label: "Pollution of living organisms and food resources",
            selected: false,
          },
          {
            id: "env-e2-5",
            label: "Substances of concern",
            selected: false,
          },
          {
            id: "env-e2-6",
            label: "Substances of very high concern",
            selected: false,
          },
          {
            id: "env-e2-7",
            label: "Microplastics",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Water And Marine Resources",
        id:"e3",
        checkboxes: [
          {
            id: "env-e3-1",
            label: "Water",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Water consumption</li>
                    <li>Water withdrawals</li>
                    <li>Water discharges</li>
                    <li>Water discharges in the oceans</li>
                    <li>Extraction and use of marine resources</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e3-2",
            label: "Marine Resources",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Water consumption</li>
                    <li>Water withdrawals</li>
                    <li>Water discharges</li>
                    <li>Water discharges in the oceans</li>
                    <li>Extraction and use of marine resources</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Biodiversity And Ecosystems",
        id:"e4",
        checkboxes: [
          {
            id: "env-e4-1",
            label: "Direct Impact drivers for diversity loss",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Climate Change</li>
                    <li>Land-use change fresh water-use change and sea-use change</li>
                    <li>Direct exploitation</li>
                    <li>Invasive alien species</li>
                    <li>Pollution</li>
                    <li>Others</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e4-2",
            label: "Impacts on the state of species",
            tooltip: (
              <Typography>
                <p>
                  <strong>Examples:</strong>
                </p>
                <em>
                  <ul>
                    <li>Species population size</li>
                    <li>Species global extinction risk</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e4-3",
            label: "Impacts on the extent and condition of ecosystems",
            tooltip: (
              <Typography>
                <p>
                  <strong>Examples:</strong>
                </p>
                <em>
                  <ul>
                    <li>Land degradation</li>
                    <li>Desertification</li>
                    <li>Soil sealing</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e4-4",
            label: "Impacts on the extent and condition of ecosystems",
            selected: false,
          },

          // Add more checkboxes as needed
        ],
      },
      {
        label: "Circular Economy",
        id:"e5",
        checkboxes: [
          {
            id: "env-e5-1",
            label: "Resources inflows, including resource use",
            selected: false,
          },
          {
            id: "env-e5-2",
            label: "Resource outflows related to products and services",
            selected: false,
          },
          {
            id: "env-e5-3",
            label: "Waste",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },

      // Add more accordion panels as needed
    ],
  },
  {
    label: "Social",
    accordionPanels: [
      {
        label: "Own workforce",
        id:"s1",
        checkboxes: [
          {
            id: "soc-s1-1",
            label: "Working conditions",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Secure employment</li>
                    <li>Working time</li>
                    <li>Adequate wages</li>
                    <li>Social dialogue</li>
                    <li>
                      Freedom of association the existence of works councils and the information
                      consultation and participation rights of workers
                    </li>
                    <li>
                      Collective bargaining including rate of workers covered by collective
                      agreements
                    </li>
                    <li>Work-life balance</li>
                    <li>Health and safety</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s1-2",
            label: "Equal treatment and opportunities for all",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Gender equality and equal pay for work of equal value</li>
                    <li>Training and skills development </li>
                    <li>Employment and inclusion of persons with disabilities </li>
                    <li>Measures against violence and harassment in the workplace </li>
                    <li>Diversity</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s1-3",
            label: "Other work-related rights",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Child labour</li>
                    <li>Forced labour</li>
                    <li>Adequate housing</li>
                    <li>Privacy</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Workers in the value chain",
        id:"s2",
        checkboxes: [
          {
            id: "soc-s2-1",
            label: "Working conditions",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Secure employment</li>
                    <li>Working time</li>
                    <li>Adequate wages</li>
                    <li>Social dialogue</li>
                    <li>Freedom of association including the existence of work councils</li>
                    <li>Collective bargaining</li>
                    <li>Work-life balance</li>
                    <li>Health and safety</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s2-2",
            label: "Equal treatment and opportunities for all",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Gender equality and equal pay for work of equal value</li>
                    <li>Training and skills development </li>
                    <li>The employment and inclusion of persons with disabilities</li>
                    <li>Measures against violence and harassment in the workplace </li>
                    <li>Diversity</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s2-3",
            label: "Other work-related rights",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Child labour</li>
                    <li>Forced labour</li>
                    <li>Adequate housing</li>
                    <li>Water and sanitation</li>
                    <li>Privacy</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Affected communities",
        id:"s3",
        checkboxes: [
          {
            id: "soc-s3-1",
            label: "Communities’ economic social and cultural rights ",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Adequate housing</li>
                    <li>Adequate food</li>
                    <li>Water and sanitation</li>
                    <li>Land-related impacts</li>
                    <li>Security-related impacts</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s3-2",
            label: "Communities’ civil and political rights",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Freedom of expression</li>
                    <li>Freedom of assembly</li>
                    <li>Impacts on human rights defenders</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s3-3",
            label: "Rights of indigenous peoples",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Free prior and informed consent</li>
                    <li>Self-determination</li>
                    <li>Cultural rights</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "Consumers and end-users",
        id:"s4",
        checkboxes: [
          {
            id: "soc-s4-1",
            label: "Information-related impacts for consumers and/or endusers",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Privacy</li>
                    <li>Freedom of expression</li>

                    <li> Access to (quality) information</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s4-2",
            label: "Personal safety of consumers and/or endusers",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Health and safety</li>
                    <li>Security of a person</li>

                    <li>Protection of children</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s4-3",
            label: "Social inclusion of consumers and/or endusers",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered:</strong>
                </p>
                <em>
                  <ul>
                    <li>Non-discrimination</li>
                    <li>Access to products and services</li>

                    <li>Responsible marketing practices</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },

      // Add more accordion panels as needed
    ],
  },
  {
    label: "Governance",
    accordionPanels: [
      {
        label: "Business Conduct",
        id:"g1",
        checkboxes: [
          {
            id: "gov-g1-1",
            label: "Corporate culture",
            selected: false,
          },
          {
            id: "gov-g1-2",
            label: "Protection of whistleblowers ",
            selected: false,
          },
          {
            id: "gov-g1-3",
            label: "Animal welfare",
            selected: false,
          },
          {
            id: "gov-g1-4",
            label: "Political engagement",
            selected: false,
          },
          {
            id: "gov-g1-5",
            label: "Management of relationships with suppliers including payment practices",
            selected: false,
          },
          {
            id: "gov-g1-6",
            label: "Corruption and bribery",
            tooltip: (
              <Typography>
                <p>
                  <strong>Following are topics covered</strong>
                </p>
                <em>
                  <ul>
                    <li>Prevention and detection including training Incidents</li>
                  </ul>
                </em>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      // Add more accordion panels as needed
    ],
  },
  // Add more tab data as needed
];

// Sample report record
const sampleReport = {
  reportName: "Sample Report",
  framework: "Sample Framework",
  status: "In Progress",
  completionTimeline: "2023-09-30",
  reportingPeriod: "2023-01-01 - 2023-12-31",
  creator: "John Doe",
  checkboxes: [
    { label: "Checkbox 1", selected: true },
    { label: "Checkbox 2", selected: false },
  ],
};

materialityTopics[0].accordionPanels[0].reports = [sampleReport]; // Add the sample record to the first accordion panel

export default materialityTopics;
