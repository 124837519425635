import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './main';
import reportWebVitals from './reportWebVitals';
import './fonts/nourishe/NourisheInline.ttf';
import { AzureAD } from 'react-aad-msal';
import App1 from './App';
import { authProvider } from './authProvider';
import { Auth0Provider } from '@auth0/auth0-react';
//console.log(__dirname)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="dev-bzvpxk64kwf4dxfs.us.auth0.com"
    clientId="7cbSk1OzSn3wLKHfN6UlmpdT95NuFo7r"
    authorizationParams={{
      redirect_uri: window.location.origin
      
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
