import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import { TabView, TabPanel } from "primereact/tabview";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./table.css";

const SCFScope = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Track the active tab (0: Scope 1&2, 1: Scope 3)
  const [formData, setFormData] = useState({
    scope1And2Calculation: "",
    scope3Calculation: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Function to validate the form based on the active tab
  const validateForm = () => {
    const newErrors = {};
    if (activeIndex === 0 && !formData.scope1And2Calculation) {
      newErrors.scope1And2Calculation = "Please select if you calculate scope 1&2 emissions.";
    } else if (activeIndex === 1 && !formData.scope3Calculation) {
      newErrors.scope3Calculation = "Please select if you calculate scope 3 emissions.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleNext = () => {
    const newErrors = validateForm();

    // Handle validation for Scope 1&2
    if (activeIndex === 0 && Object.keys(newErrors).length === 0) {
      setActiveIndex(1); // Go to Scope 3 tab if no errors for Scope 1&2
    }
    // Handle validation for Scope 3
    else if (activeIndex === 1 && Object.keys(newErrors).length === 0) {
      navigate("/supplier-carbon-footprint"); // Redirect to the next page
    }
  };

  const handleSave = async () => {
    setLoading(true);
    // Perform the save operation
    // ...
    setLoading(false);
  };

  const handleRadioChange = (e, field) => {
    const value = e.value;
    setFormData({ ...formData, [field]: value });
    // Clear the error for the field once selected
    setErrors({ ...errors, [field]: "" });
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#home">
                <img
                  src="assets/img/logo.png"
                  alt="Company Logo"
                  height="30"
                  className="d-inline-block align-top"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img className="icon mr-3" src="assets/img/User.png" alt="User" />
                      Mathew
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="container survey-form scf p-4 p-lg-5">
          <div className="row mb-4">
            <div className="col">
              <div className="form-header">
                <h1 className="title" style={{ color: "#407775!important" }}>
                  Supplier Carbon Footprint​ Survey
                </h1>
                <h2>Energy And Emissions</h2>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row tabs">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                  <TabPanel header="Scope 1&2">
                    <div className="row gy-4">
                      <div className="col col-md-12">
                        <label className="formlable" htmlFor="scope1And2Calculation">
                          Do you calculate your company's scope 1 and 2 emissions?
                        </label>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="scope1And2Yes"
                              name="scope1And2Calculation"
                              value="Yes"
                              checked={formData.scope1And2Calculation === "Yes"}
                              onChange={(e) => handleRadioChange(e, "scope1And2Calculation")}
                            />
                            <label htmlFor="scope1And2Yes" className="ml-2 mb-0 formlable">
                              Yes
                            </label>
                            <RadioButton
                              inputId="scope1And2No"
                              name="scope1And2Calculation"
                              value="No"
                              checked={formData.scope1And2Calculation === "No"}
                              onChange={(e) => handleRadioChange(e, "scope1And2Calculation")}
                              className="ml-4"
                            />
                            <label htmlFor="scope1And2No" className="ml-2 mb-0 formlable">
                              No
                            </label>
                          </div>
                        </div>
                        {errors.scope1And2Calculation && (
                          <p className="text-danger">{errors.scope1And2Calculation}</p>
                        )}
                      </div>
                    </div>
                    {formData.scope1And2Calculation === "Yes" && (
                      <div className="scope1">
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12NumbersType">
                                Are your emission numbers actual or estimated?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope12NumbersActual"
                                    name="scope12NumbersType"
                                    value="Actual"
                                    checked={formData.scope12NumbersType === "Actual"}
                                    onChange={(e) => handleRadioChange(e, "scope12NumbersType")}
                                  />
                                  <label
                                    htmlFor="scope12NumbersActual"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Actual
                                  </label>
                                  <RadioButton
                                    inputId="scope12NumbersEstimated"
                                    name="scope12NumbersType"
                                    value="Estimated"
                                    checked={formData.scope12NumbersType === "Estimated"}
                                    onChange={(e) => handleRadioChange(e, "scope12NumbersType")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope12NumbersEstimated"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Estimated
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12Verification">
                                Is your scope 1 and 2 emission numbers externally verified?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope12VerificationYes"
                                    name="scope12Verification"
                                    value="Yes"
                                    checked={formData.scope12Verification === "Yes"}
                                    onChange={(e) => handleRadioChange(e, "scope12Verification")}
                                  />
                                  <label
                                    htmlFor="scope12VerificationYes"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Yes
                                  </label>
                                  <RadioButton
                                    inputId="scope12VerificationNo"
                                    name="scope12Verification"
                                    value="No"
                                    checked={formData.scope12Verification === "No"}
                                    onChange={(e) => handleRadioChange(e, "scope12Verification")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope12VerificationNo"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope1Emissions">
                                Scope 1 emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="scope1Emissions"
                                value={formData.scope1Emissions}
                                className={`form-control ${
                                  errors.scope1Emissions ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Scope 1 emissions value"
                              />

                              {errors.scope1Emissions && (
                                <div className="invalid-feedback">{errors.scope1Emissions}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope2Emissions">
                                Scope 2 emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="scope2Emissions"
                                value={formData.scope2Emissions}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 2 emissions value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope1Intensity">
                                Scope 1 emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="scope1Intensity"
                                value={formData.scope1Intensity}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 1 emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit1">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit1"
                                    value={formData.unit1}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency1">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency1"
                                    value={formData.currency1}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope2Intensity">
                                Scope 2 emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="scope2Intensity"
                                value={formData.scope2Intensity}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 2 emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit2">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit2"
                                    value={formData.unit2}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency2">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency2"
                                    value={formData.currency2}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12Percentage">
                                What % of total organizational emissions are covered in this
                                estimation?
                              </label>
                              <div className="row">
                                <div className="col-md-5">
                                  <input
                                    type="text"
                                    id="scope12Percentage"
                                    value={formData.scope12Percentage}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Enter % of total organizational emissions"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel header="Scope 3">
                    <div className="row gy-4">
                      <div className="col col-md-12">
                        <label className="formlable" htmlFor="scope3Calculation">
                          Do you calculate your company's scope 3 upstream and waste emissions?
                        </label>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="scope3Yes"
                              name="scope3Calculation"
                              value="Yes"
                              checked={formData.scope3Calculation === "Yes"}
                              onChange={(e) => handleRadioChange(e, "scope3Calculation")}
                            />
                            <label htmlFor="scope3Yes" className="ml-2 mb-0 formlable">
                              Yes
                            </label>
                            <RadioButton
                              inputId="scope3No"
                              name="scope3Calculation"
                              value="No"
                              checked={formData.scope3Calculation === "No"}
                              onChange={(e) => handleRadioChange(e, "scope3Calculation")}
                              className="ml-4"
                            />
                            <label htmlFor="scope3No" className="ml-2 mb-0 formlable">
                              No
                            </label>
                          </div>
                        </div>
                        {errors.scope3Calculation && (
                          <p className="text-danger">{errors.scope3Calculation}</p>
                        )}
                      </div>
                    </div>
                    {formData.scope3Calculation === "Yes" && (
                      <div className="scope3">
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3NumbersType">
                                Are your emission numbers actual or estimated?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope3NumbersActual"
                                    name="scope3NumbersType"
                                    value="Actual"
                                    checked={formData.scope3NumbersType === "Actual"}
                                    onChange={(e) => handleRadioChange(e, "scope3NumbersType")}
                                  />
                                  <label
                                    htmlFor="scope3NumbersActual"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Actual
                                  </label>
                                  <RadioButton
                                    inputId="scope3NumbersEstimated"
                                    name="scope3NumbersType"
                                    value="Estimated"
                                    checked={formData.scope3NumbersType === "Estimated"}
                                    onChange={(e) => handleRadioChange(e, "scope3NumbersType")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope3NumbersEstimated"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Estimated
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3Verification">
                                Is your scope 3 upstream and waste emission numbers externally
                                verified?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope3VerificationYes"
                                    name="scope3Verification"
                                    value="Yes"
                                    checked={formData.scope3Verification === "Yes"}
                                    onChange={(e) => handleRadioChange(e, "scope3Verification")}
                                  />
                                  <label
                                    htmlFor="scope3VerificationYes"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Yes
                                  </label>
                                  <RadioButton
                                    inputId="scope3VerificationNo"
                                    name="scope3Verification"
                                    value="No"
                                    checked={formData.scope3Verification === "No"}
                                    onChange={(e) => handleRadioChange(e, "scope3Verification")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope3VerificationNo"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="upstreamEmissions">
                                Scope 3 upstream emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="upstreamEmissions"
                                value={formData.upstreamEmissions}
                                className={`form-control ${
                                  errors.upstreamEmissions ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Scope 3 upstream emissions value"
                              />

                              {errors.upstreamEmissions && (
                                <div className="invalid-feedback">{errors.upstreamEmissions}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="wasteEmissions">
                                Scope 3 waste emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="wasteEmissions"
                                value={formData.wasteEmissions}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 3 waste emissions value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="upstreamIntensity">
                                Upstream emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="upstreamIntensity"
                                value={formData.upstreamIntensity}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 3 upstream emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit3">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit3"
                                    value={formData.unit3}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency3">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency3"
                                    value={formData.currency3}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="waterIntensity">
                                Waste emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="waterIntensity"
                                value={formData.waterIntensity}
                                // onChange={handleInputChange}
                                class="form-control"
                                placeholder="Enter Scope 3 waste emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit4">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit4"
                                    value={formData.unit4}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency4">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency4"
                                    value={formData.currency4}
                                    // onChange={handleInputChange}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3Percentage">
                                What % of total organizational emissions are covered in this
                                estimation?
                              </label>
                              <div className="row">
                                <div className="col-md-5">
                                  <input
                                    type="text"
                                    id="scope3Percentage"
                                    value={formData.scope3Percentage}
                                    onChange={handleInputChange}
                                    className={`form-control ${
                                      errors.scope3Percentage ? "is-invalid" : ""
                                    }`}
                                    placeholder="Enter % of total organizational emissions"
                                  />
                                  {errors.scope3Percentage && (
                                    <div className="invalid-feedback">
                                      {errors.scope3Percentage}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                </TabView>
              </div>
            </form>
            <div className="forms-buttons-container">
              <Button
                className="outline-btn"
                variant="contained"
                color="primary"
                startIcon={
                  <ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />
                }
                onClick={() => navigate("/energy-emissions")}
              >
                Back
              </Button>
              <Button
                className="next-button"
                variant="contained"
                color="primary"
                endIcon={
                  <ArrowForwardIosIcon sx={{ fontSize: "12px!important", marginLeft: "0" }} />
                }
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCFScope;
