import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const FormsApps = () => {
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex justify-content-between">
            <div className="steps__containt-hader">
              <h1 className="title">Forms & Apps</h1>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Typography color="text.primary">Forms & Apps</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="forms-apps-ct">
            <h2 className="platform-h2 mt-2">Internal Stakeholders</h2>
            <div className="row">
              <div className="col-md-3" onClick={() => navigate("/employee-commute-data")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-01.svg"
                    alt="Employee commute survey"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/emp-commute.svg"
                    alt="Employee commute survey"
                    className="hover-icon"
                  />
                  <h3>Employee commute survey</h3>
                </div>
              </div>
              <div className="col-md-3" onClick={() => navigate("/internal-page-2")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-02.svg"
                    alt="Business travel"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/business-travel.svg"
                    alt="Business travel"
                    className="hover-icon"
                  />
                  <h3>Business travel</h3>
                </div>
              </div>
            </div>
            <h2 className="platform-h2 mt-4">External Stakeholders</h2>
            <div className="row">
              <div className="col-md-3" onClick={() => navigate("/supplier-carbon-footprint")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-03.svg"
                    alt="Supplier Carbon footprint"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/supplier-carbon-footprint.svg"
                    alt="Supplier Carbon footprint"
                    className="hover-icon"
                  />
                  <h3>Supplier Carbon footprint</h3>
                </div>
              </div>
              <div className="col-md-3" onClick={() => navigate("/external-page-2")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-04.svg"
                    alt="End-of-life management"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/end-of-life.svg"
                    alt="End-of-life management"
                    className="hover-icon"
                  />
                  <h3>End-of-life management</h3>
                </div>
              </div>
              <div className="col-md-3" onClick={() => navigate("/external-page-3")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-05.svg"
                    alt="Logistics"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/logistics.png"
                    alt="Logistics"
                    className="hover-icon"
                  />
                  <h3>Logistics</h3>
                </div>
              </div>
              <div className="col-md-3" onClick={() => navigate("/external-page-4")}>
                <div className="forms-box">
                  <img
                    src="./assets/img/formsapps/icon-06.svg"
                    alt="Packaging composition"
                    className="default-icon"
                  />
                  <img
                    src="./assets/img/formsapps/hover/packaging-composition.png"
                    alt="Packaging composition"
                    className="hover-icon"
                  />
                  <h3>Packaging composition</h3>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="forms-buttons-container">
            <Button
              className="outline-btn"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />}
              onClick={() => navigate("/previous-page")}
            >
              Back
            </Button>
            <Button
              className="next-button"
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon sx={{ fontSize: "12px!important", marginLeft: "0" }} />}
              onClick={() => navigate("/next-page")}
            >
              Next
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FormsApps;
