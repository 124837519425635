import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import _ from "lodash";
import FormData from "form-data";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import { ChangeEvent } from "react";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import containerTheme from "./theme/containerTheme.js";
import toast, { Toaster } from "react-hot-toast";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { create } from "@mui/material/styles/createTransitions.js";
import AADHeader from "./components/AADHeader.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Icon1 = "./assets/img/Assets/configration_icon/White/1.png";
const Icon2 = "./assets/img/Assets/configration_icon/White/2.png";
const Icon3 = "./assets/img/Assets/configration_icon/White/3.png";
const Icon4 = "./assets/img/Assets/configration_icon/White/4.png";
const Icon5 = "./assets/img/Assets/configration_icon/White/5.png";
const customIcons = [Icon1, Icon2, Icon3, Icon4, Icon5];

const iconDotInactive = "./assets/img/Assets/configration_icon/Teal Green/Ellipse 61.png";
const iconBackgroundInactive = "./assets/img/Assets/configration_icon/Teal Green/Ellipse 59.png";
const steps = [
  {
    label: "Select data type",
    description: ``,
    active: 1,
  },
  {
    label: "Choose data connector",
    description: "",
    active: 2,
  },
  {
    label: "Create connection",
    description: "",
    active: 3,
  },
  {
    label: "Map fields",
    description: "",
    active: 4,
  },
  {
    label: "Review and finish",
    description: "",
    active: 5,
  },
];

export default function VerticalLinearStepper(props) {
  // const classes = useStyles(props);

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [count, setCount] = useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [myloading, setMyLoading] = React.useState(false);
  const [apiDone, setApiDone] = React.useState(false);
  const [result, setResult] = React.useState({});
  const [selectedExcel, setSelectedExcel] = useState("");
  const [showTable, setShowTable] = React.useState(false);
  const [businessunit, setBusinessUnit] = React.useState(false);
  const [modules, setModules] = React.useState(false);
  const [mymodule, setMyModule] = useState("");
  const [importfile, setImportFile] = React.useState("");
  const [excelfields, setFields] = React.useState(false);
  const [headerfields, setHeaderFields] = React.useState(false);
  const [pattern, setPattern] = useState("");
  const [sitelocation, setSiteLocation] = useState("");
  const [activesitelocation, setActiveSiteLocation] = useState("");
  const [sitelocation1, setSiteLocation1] = useState("");
  const [leftheader, setLeftHeader] = useState("");
  const [rightheader, setRightHeader] = useState("");
  const [matrics, setMatrics] = useState("");
  const [mymatrics, setMyMatrics] = useState("");
  const [getappnames, setAppNames] = useState("");
  const [apikey, setApiKey] = useState("");
  const [apivalue, setApiValue] = useState("");
  const [resource, setResource] = useState("");
  const [accesstokenurl, setAccessTokenUrl] = useState("");
  const [clientid, setClientID] = useState("");
  const [clientsecret, setClientSecret] = useState("");
  const [scope, setScope] = useState("");
  const [tenantid, setTenantID] = useState("");
  const [query, setQuery] = useState("");
  const [accountname, setAccoutName] = useState("");
  const [tablename, setTableName] = useState("");
  const [apiclientid, setApiClientId] = useState("");
  const [apisubclientid, setApiSubClientId] = useState("");
  const [hash, setHash] = useState("");

  const cardStyles = {
    heading: {
      marginLeft: "15px",
      marginBottom: "10px",
    },
  };

  const handleNext = () => {
    setSelectedDiv(null);
    setCount((prevCount) => prevCount + 1);

    if (activeStep === 3) {
      const inputArray = [];
      const farray = [];
      var excelHeaderss = document.getElementsByName("excelHeaderss[]");
      console.log("excelHeaderss: ", excelHeaderss);
      for (var i = 0; i < excelHeaderss.length; i++) {
        var excelHeaderss1 = excelHeaderss[i];
        var isRequired = excelHeaderss1.required;
        var title = excelHeaderss1.title;
        var id = excelHeaderss1.id;
        var value = excelHeaderss1.value;
        if (isRequired) {
          inputArray.push({ name: title, required: true, id: id });
          //console.log("inputArray: ", inputArray);
          if (value !== "") {
          } else {
            farray.push(title);
            //console.log("farray: ", farray);
          }
        } else {
        }
      }
      if (farray.length > 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Fields are required " + farray.join(", "),
        });
        return false;
      }
    }
    if (activeStep === 0) {
      console.log("activeStep: ", activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setMatrics(matrics);
    setMyModule(mymodule);
    console.log("activeStep: ", activeStep);

    if (activeStep === 1) {
      fetchFields();
      console.log("fetchfields: ", excelfields);
    }
    if (activeStep === 3) {
      var inps = document.getElementsByName("destination[]");
      console.log("inps: ", inps);
      let destination = [];
      for (var i = 0; i < inps.length; i++) {
        var inp = inps[i];
        destination.push(inp.value);
      }
      setRightHeader(destination);
      console.log("rightheader: ", rightheader);
      var inps1 = document.getElementsByName("excelHeaders[]");
      let excelHeaders1 = [];
      for (var i = 0; i < inps1.length; i++) {
        var inp1 = inps1[i];
        excelHeaders1.push(inp1.value);
      }
      setLeftHeader(excelHeaders1);
      console.log("leftheader: ", leftheader);
    }
  };

  const handleBackConfig = () => {
    setShowTable(false);
  };

  const handleBack = () => {
    setSelectedDiv(null);
    setCount((prevCount) => prevCount - 1);
    const currentstep = activeStep;
    if (currentstep === 2) {
      console.log(currentstep);
      setImportFile("");
    }

    if (currentstep === 3) {
      console.log(currentstep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [personName, setPersonName] = React.useState([]);

  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleESGChange = (value, setMyModule) => {
    if (value == "") {
      setMyMatrics("");
      setMatrics("");
      setMyModule("");
    } else {
      setMyModule(value);
      fetchESGMatrics(value);
    }

    console.log(value);
  };

  async function checkAppName1(site) {
    console.log(site);
    if (matrics !== "" && site !== "") {
      var CHeaders = new Headers();
      CHeaders.append("username", username);
      CHeaders.append("esg", matrics);
      CHeaders.append("site", site);

      var Options = {
        method: "GET",
        headers: CHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/get-allappname",
          Options
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success == false) {
          } else if (responseJson.status == 400) {
          } else {
            var data = responseJson.data;
            console.log("look here: ");
            console.log(data);
            setAppNames(data);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function checkAppName2(matrics) {
    console.log(matrics);
    if (matrics !== "" && sitelocation1 !== "") {
      var CHeaders = new Headers();
      CHeaders.append("username", username);
      CHeaders.append("esg", matrics);
      CHeaders.append("site", sitelocation1);

      var Options = {
        method: "GET",
        headers: CHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/get-allappname",
          Options
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success == false) {
          } else if (responseJson.status == 400) {
          } else {
            var data = responseJson.data;
            console.log(data);
            setAppNames(data);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchSiteLocation() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitelocation",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setSiteLocation("");
        } else if (responseJson.status == 400) {
          setSiteLocation("");
        } else {
          setSiteLocation(responseJson);
          setActiveSiteLocation(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setSiteLocation("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchESGMatrics(value) {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      myHeaderss.append("param", value);
      var requestOptionss = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/ESG", requestOptionss);
      const data = await response.json();
      setMyMatrics(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchOrganization() {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      var requestOptionss = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/organization-name",
        requestOptionss
      );
      const data = await response.json();
      setApiClientId("Ecoprism");
      setApiSubClientId(data.data.name);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSiteLocation();
    fetchOrganization();
    console.log("activeStep:", activeStep);
  }, [activeStep]);

  async function fetchModules() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/list-modules");
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setModules("");
        } else if (responseJson.status == 400) {
          setModules("");
        } else {
          setModules(responseJson.data);
        }
      } else {
        setModules("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchModules();
  }, []);

  async function fetchFields() {
    try {
      var myH = new Headers();
      myH.append("username", username);
      myH.append("modulename", mymodule);
      myH.append("pattern", selectedExcel);
      myH.append("ESGMetric", matrics);
      var requestO = {
        method: "GET",
        headers: myH,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/get-fields", requestO);
      const data = await response.json();
      const inputList = data.data;
      console.log("data: ", inputList);
      setFields(inputList);
      console.log("excelfields: ", excelfields);
      function combine(collections) {
        const entries = [...Object.entries(collections)];
        const length = entries[0]?.[1]?.length ?? 0;
        const result = [];
        for (const [name, array] of entries) {
          if (array.length !== length) throw new Error("Non-uniform array lengths");
          for (let i = 0; i < length; i += 1) {
            const item = (result[i] ??= {});
            item[name] = array[i];
          }
        }
        return result;
      }
      const values = Object.values(inputList);
      const keys = Object.keys(inputList);
      const merge = () => {
        const merged = combine({
          value: values,
          key: keys,
        });
        console.log("merged: ", merged);
        setFields(merged);
      };
      merge();
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function uploadFileName(filename) {
    setMyLoading(true);
    const res = await fetch("https://ecoprismapi.azurewebsites.net/GetPdfParameter", {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        filename: filename,
        username: username,
      },
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      if (responseJson.success == false) {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.data,
        });
      } else if (responseJson.status == 400) {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseJson.title,
        });
      } else {
        console.log(responseJson.data);
        function combine(collections) {
          const entries = [...Object.entries(collections)];
          const length = entries[0]?.[1]?.length ?? 0;
          const result = [];
          for (const [name, array] of entries) {
            if (array.length !== length) throw new Error("Non-uniform array lengths");
            for (let i = 0; i < length; i += 1) {
              const item = (result[i] ??= {});
              item[name] = array[i];
            }
          }
          return result;
        }
        const businessUnitValues = Object.values(responseJson.data);
        const businessUnitKeys = Object.keys(responseJson.data);
        const merge = () => {
          const merged = combine({
            businessunitvalue: businessUnitValues,
            businessunit: businessUnitKeys,
          });
          console.log(merged);
          const businessunitvalues = merged.map((item) => item.businessunit);
          const commaSeparatedArray = businessunitvalues.join(",");
          const dataArray = commaSeparatedArray.split(",");
          setHeaderFields(dataArray);
          setMyLoading(false);
          Swal.fire({
            icon: "success",
            html: filename + " uploaded successfully",
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          console.log(dataArray);
        };

        merge();
        setImportFile(filename);
      }
    } else {
      setMyLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pdf Not uploaded",
      });
    }
  }

  //PDF Code
  async function uploadImage(containerName, file) {
    console.log(file);
    let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
      method: "GET",
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      const connectionString = responseJson.data;
      const blobServiceClient = new BlobServiceClient(connectionString);
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(file.name);
      const blockBlobClient = blobClient.getBlockBlobClient();
      const result = await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: (ev) => console.log(ev),
      });
      if (result) {
        console.log(file.name);
        uploadFileName(file.name);
      }

      //console.log(`Upload of file '${file.name}' completed`);
    } else {
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setMyLoading(true);
    //const type = localStorage.getItem('type');
    const type = JSON.parse(localStorage.getItem("type"));
    const name = acceptedFiles[0].name;
    const extension = name.split(".").pop();
    let sendObj = {
      startDate: "",
      endDate: "",
      energyCons: 0,
    };
    if (Array.isArray(type) && type.includes("Excel")) {
      if (extension === "xls" || extension === "xlsx") {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
            //const workbookHeaders = XLSX.readFile(bstr, { sheetRows: 1 });
            console.log(data);
            if (data.length === 0) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Excel File is not proper or header is not present",
              });
              return false;
              // Perform actions for an empty array
            }
            setImportFile(acceptedFiles[0].name);
            setMyLoading(false);
            Swal.fire({
              icon: "success",
              html: acceptedFiles[0].name + " uploaded successfully",
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
            });
            const columnsArray = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames], {
              header: 1,
            })[0];
            console.log(columnsArray);
            setHeaderFields(columnsArray);
            var result = columnsArray
              .map(function (val) {
                return val;
              })
              .join(",");
            var item = "";
            var results = {};

            if (result !== null) {
              result = result.split(",");

              for (var i = 0; i < result.length; i++) {
                item = result[i].trim();

                results[item] = item;
              }
            }
            /* Update state */
            const jsonData = JSON.parse(JSON.stringify(data));
            const newdata = [];
            _.forEach(jsonData, (jd) => {
              sendObj.energyCons = jd.EnergyConsumption;
              sendObj.startDate = jd.StartDate;
              sendObj.endDate = jd.EndDate;
              newdata.push({
                email: "sudies@ecoprism.com",
                energy: sendObj.energyCons,
                region: "DK",
                reportPeriod: 2,
                endTimeEpoch: sendObj.endDate,
                unit: "kWh",
              });
              //console.log(jd, 'hsdajshd')
            });
            setMyLoading(false);
          };
          reader.readAsBinaryString(file);
        });
      } else {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File format is not valid",
        });
        setImportFile();
        return false;
      }
    }
    if (Array.isArray(type) && type.includes("PDF")) {
      if (extension === "pdf") {
        uploadImage("ecoprismfilecontainer", acceptedFiles[0]);
      } else {
        setMyLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File format is not valid",
        });
        setImportFile();
        return false;
      }
    }
  }, []);

  const processExcel = async (newdata) => {
    try {
      const requestOptions = newdata;
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/upload-file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestOptions),
      });
      const data = await response.json();
      setMyLoading(false);
      setApiDone(true);
      setResult(data);
      setHeaderFields(data);
      setActiveStep(2);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadExcel = async (newdata) => {
    console.log(newdata);
    try {
      const requestOptions = newdata;
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/file`, {
        method: "POST",
        body: requestOptions,
      });
      const data = await response.json();
      setMyLoading(false);
      setApiDone(true);
      setResult(data);
      setHeaderFields(data);
      setActiveStep(2);
    } catch (error) {
      console.log(error);
    }
  };

  const excelH = async (e, i) => {
    document.getElementById("excel" + i).value = e;
    console.log("excel: " + e);
  };

  const [selectedDiv, setSelectedDiv] = useState(null);

  useEffect(() => {
    // If there are selected Excel files, but no selected div, select the first Excel file
    if (selectedExcel.length > 0 && selectedDiv === null) {
      setSelectedDiv(selectedExcel[0]);
    }
  }, [selectedExcel, selectedDiv]);

  const handleDivClick = (divName) => {
    console.log(selectedDiv);
    if (selectedDiv != selectedExcel[0]) {
      setSelectedDiv(null);
    } else {
      setSelectedDiv(divName);
      console.log(divName);
    }
  };

  /* For the connectors multiple selection */
  const handleClick = (item) => {
    const updatedSelection = [...selectedExcel];

    // Toggle the selection
    if (updatedSelection.includes(item)) {
      updatedSelection.splice(updatedSelection.indexOf(item), 1);
    } else {
      updatedSelection.push(item);
    }

    localStorage.setItem("type", JSON.stringify(updatedSelection));
    setSelectedExcel(updatedSelection);
  };

  useEffect(() => {
    console.log("Updated selectedExcel:", selectedExcel);
  }, [selectedExcel]);

  const handleAddDefaultValueClick = () => {
    Swal.fire({
      title: `<div class="custom-title-class">
                  <h2>Add Default Value</h2>
                  <button aria-label="Close" class="swal2-close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>`,
      html: `
          <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
            <div style="flex-basis: 48%;">
              <label style="display: block; font-size: 16px; color: #407775; text-align: left">Data Requirement</label>
              <p style="font-size: 14px; color: #6C757D;  text-align: left; margin: 0;">Energy source 1</p>
            </div>
            <div style="flex-basis: 48%;">
              <label style="display: block; font-size: 16px; color: #407775; text-align: left">Default Value</label>
              <input id="swal-input1" type="text" placeholder="Non-renewable" style="width: 100%; font-size: 14px;  border: 1px solid #40777526; border-radius: 5px; background-color: #f5f5f5;" />
            </div>
          </div>`,
      width: "817",
      height: "415",
      // Add custom CSS classes to control positioning
      showCloseButton: true, // Ensure close button visibility
      showCancelButton: true, // Ensure cancel button visibility
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      className: {
        popup: "my-custom-popup", // Apply to the entire popup
        title: "my-custom-title", // Apply to the title section
      },
      // Add custom CSS to style the popup and title
      customClass: {
        popup: {
          "swal2-popup": "top-0", // Align popup to top
          "swal2-title": "text-center", // Center title content
        },
        title: {
          "swal2-title": "custom-title-styles", // Add your desired title styles
        },
        cancelButton: "my-custom-cancel-button", // Style the cancel button
        confirmButton: "my-custom-confirm-button", // Style the confirm button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", "Your default value has been saved.", "success");
      }
    });
  };

  let handleSubmit = async (e) => {
    setMyLoading(true);
    e.preventDefault();
    console.log("Left Header" + leftheader);
    console.log("Right Header" + rightheader);
    var newArray = leftheader.map((e, i) => e + ":" + rightheader[i]);
    console.log(newArray);
    const inputArray = newArray;

    const outputObject = inputArray.reduce((result, item) => {
      const [key, value] = item.split(":");
      result[key] = value;
      return result;
    }, {});

    console.log(outputObject);

    var str = JSON.stringify(newArray);
    var final1 = str
      .replace(/{|},|}/g, "")
      .replace(/\[|]|"/g, "")
      .replace(/,/g, ",");
    console.log(final1);
    var result = newArray.reduce(function (map, obj) {
      console.log(obj);
      map[obj] = obj;
      return map;
    }, {});
    console.log(result);
    console.log(
      Object.entries(newArray).map(
        ([key, value]) => `My key is ${key} and my value is ${JSON.stringify(value)}`
      )
    );
    if (selectedExcel === "API" || selectedExcel === "Azure Table Storage") {
      if (connectionType === "APIKey") {
        try {
          let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorAPI", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              clientid: apiclientid,
              subclientid: apisubclientid,
            },
            body: JSON.stringify({
              ConnectorName: "API",
              authMethod: "APIKey",
              authDetails: {
                Key: apikey,
                Value: apivalue,
              },
              resourceurl: resource,
            }),
          });
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              console.log(responseJson);
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setHash(responseJson.connectionId);
              let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
                method: "POST",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  username: username,
                },
                body: JSON.stringify({
                  siteLocationId: sitelocation1,
                  fileFormat: selectedExcel,
                  esgmetric: matrics,
                  fieldMapping: outputObject,
                  reportFrequency: "",
                  entityUrl: "",
                  clientId: apiclientid,
                  subClientId: apisubclientid,
                  connectionHash: responseJson.connectionId,
                }),
              });
              if (res.status === 200) {
                const responseJson = await res.json();
                if (responseJson.success == false) {
                  console.log(responseJson);
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.data,
                  });
                } else if (responseJson.status == 400) {
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.title,
                  });
                } else {
                  setMyLoading(false);
                  setActiveStep(5);
                }
              } else {
                setMyLoading(false);
                console.log("Error");
              }
            }
          } else {
            setMyLoading(false);
            console.log("Error");
          }
        } catch (err) {
          setMyLoading(false);
          console.log(err);
        }
      } else {
        try {
          let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorAPI", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              clientid: apiclientid,
              subclientid: apisubclientid,
            },
            body: JSON.stringify({
              ConnectorName: selectedExcel,
              authMethod: "OAuth",
              authDetails: {
                type: "OAuth",
                AccessTokenUrl: accesstokenurl,
                ClientId: clientid,
                ClientSecret: clientsecret,
                Scope: scope,
                TenantId: tenantid,
              },
              storageAccountName: accountname,
              ResourceUrl: resource,
              tableName: tablename,
              query: query,
            }),
          });
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              console.log(responseJson);
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setHash(responseJson.connectionId);
              let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
                method: "POST",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  username: username,
                },
                body: JSON.stringify({
                  siteLocationId: sitelocation1,
                  fileFormat: selectedExcel,
                  esgmetric: matrics,
                  fieldMapping: outputObject,
                  reportFrequency: "",
                  entityUrl: "",
                  clientId: apiclientid,
                  subClientId: apisubclientid,
                  connectionHash: responseJson.connectionId,
                }),
              });
              if (res.status === 200) {
                const responseJson = await res.json();
                if (responseJson.success == false) {
                  console.log(responseJson);
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.data,
                  });
                } else if (responseJson.status == 400) {
                  setMyLoading(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: responseJson.title,
                  });
                } else {
                  setMyLoading(false);
                  setActiveStep(5);
                }
              } else {
                setMyLoading(false);
                console.log("Error");
              }
            }
          } else {
            setMyLoading(false);
            console.log("Error");
          }
        } catch (err) {
          setMyLoading(false);
          console.log(err);
        }
      }
    } else {
      try {
        let res = await fetch("https://ecoprismapi.azurewebsites.net/SaveConnectorConfig", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
          },
          body: JSON.stringify({
            siteLocationId: sitelocation1,
            fileFormat: selectedExcel,
            esgmetric: matrics,
            fieldMapping: outputObject,
            reportFrequency: "",
            entityUrl: "",
            clientId: apiclientid,
            subClientId: apisubclientid,
            connectionHash: hash,
          }),
        });
        if (res.status === 200) {
          const responseJson = await res.json();
          if (responseJson.success == false) {
            console.log(responseJson);
            setMyLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseJson.data,
            });
          } else if (responseJson.status == 400) {
            setMyLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseJson.title,
            });
          } else {
            setMyLoading(false);
            setActiveStep(5);
          }
        } else {
          setMyLoading(false);
          console.log("Error");
        }
      } catch (err) {
        setMyLoading(false);
        console.log(err);
      }
    }
  };
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const handleCancel = () => {
    setActiveStep(0); // Reset the active step to 0
  };

  // const delayedCode = setTimeout(() => {
  //   console.log('Code executed after 5 seconds');
  // }, 5000);

  const [connectionType, setConnectionType] = useState("");

  const handleConnectionTypeChange = (e) => {
    setConnectionType(e.target.value);
  };

  const checkApiConnection = async (data) => {
    setMyLoading(true);
    console.log("API Call222");
    console.log(data);
    try {
      let res;
      console.log(selectedExcel);
      if (connectionType === "APIkey") {
        if (apikey === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter API Key",
          });
        }
        if (apivalue === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter Value Here",
          });
        }
        if (resource === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter Resource Url",
          });
        }
        console.log("API Call");
        res = await fetch("https://ecoprismapi.azurewebsites.net/checkApiConnection", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
            key: apikey,
            value: apivalue,
            resource: resource,
          },
          body: JSON.stringify({
            ConnectorName: selectedExcel,
            authMethod: "APIKey",
            authDetails: {
              Key: apikey,
              Value: apivalue,
            },
            ResourceUrl: resource,
          }),
        });
        console.log(res);
        // if (res.status === 200) {
        //   const responseJson = await res.json();
        //   if(responseJson === true){
        //     if (responseJson === false) {
        //       setMyLoading(false);
        //       Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: "Error"
        //       });
        //     }
        //     else if (responseJson.status == 400) {
        //       setMyLoading(false);
        //       Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: responseJson.title
        //       });
        //     }
        //     else {
        //     }
        //   }
        // }
        // else{
        //   setMyLoading(false);
        // }
      } else {
        console.log("OAuth Call");
        if (clientid === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter client id",
          });
        }
        if (clientsecret === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter client secret",
          });
        }
        if (scope === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter scope",
          });
        }
        if (accesstokenurl === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter access token url",
          });
        }
        if (tenantid === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter tenant id",
          });
        }
        if (resource === "") {
          setMyLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter resource urls",
          });
        }
        res = await fetch("https://ecoprismapi.azurewebsites.net/checkApiConnection", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
          },
          body: JSON.stringify({
            ConnectorName: selectedExcel,
            authMethod: "OAuth",
            authDetails: {
              type: "OAuth",
              AccessTokenUrl: accesstokenurl,
              ClientId: clientid,
              ClientSecret: clientsecret,
              Scope: scope,
              TenantId: tenantid,
            },
            storageAccountName: accountname,
            ResourceUrl: resource,
            tableName: tablename,
            query: query,
          }),
        });
      }

      if (res.status === 200) {
        console.log("API Call");
        const responseJson = await res.json();
        if (responseJson === true) {
          let res1;
          if (connectionType === "APIkey") {
            setImportFile("API");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "APIKey",
                authDetails: {
                  Key: apikey,
                  Value: apivalue,
                },
                ResourceUrl: resource,
              }),
            });
          } else {
            setImportFile("Azure Table Storage");
            res1 = await fetch("https://ecoprismapi.azurewebsites.net/getapifields", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
                key: apikey,
                value: apivalue,
                resource: resource,
              },
              body: JSON.stringify({
                ConnectorName: selectedExcel,
                authMethod: "OAuth",
                authDetails: {
                  type: "OAuth",
                  AccessTokenUrl: accesstokenurl,
                  ClientId: clientid,
                  ClientSecret: clientsecret,
                  Scope: scope,
                  TenantId: tenantid,
                },
                storageAccountName: accountname,
                ResourceUrl: resource,
                tableName: tablename,
                query: query,
              }),
            });
          }
          if (res1.status === 200) {
            const responseJson1 = await res1.json();
            if (responseJson1.success == false) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson1.status == 400) {
              setMyLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setMyLoading(false);
              Swal.fire({
                icon: "success",
                html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Connection Created successfully</p>',
                iconHtml:
                  '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              });
              setHeaderFields(responseJson1);
            }
          }
        } else {
          setMyLoading(false);
          setImportFile("");
        }
      } else {
        setMyLoading(false);
        console.log("Error");
      }
    } catch (err) {
      setMyLoading(false);
      console.log(err);
    }
  };

  const connectorData = [
    { type: "Excel", name: "Abc excel", fieldCount: 8 },
    { type: "API", name: "Xyz API", fieldCount: 10 },
  ];
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <React.Fragment>
      <div id="wrapper">
        <div>
          {myloading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
        <div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
        {renderHeader()}
        <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          id="page-content-wrapper"
          className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
        >
          <div className="steps__containt-body">
            {loading && <div class="loading">Loading&#8230;</div>}
            <div className="steps__containt-left">
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        <Badge
                          className="badgeRoot badgeContent"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <>
                              <img
                                src={
                                  step.active <= count
                                    ? "./assets/img/Assets/configration_icon/White/Outer Circle.png"
                                    : iconDotInactive
                                }
                                alt="Outer Circle"
                                className="outerCircle"
                              />
                              <img
                                src={
                                  step.active <= count
                                    ? "./assets/img/Assets/configration_icon/White/Inner circle.png"
                                    : iconBackgroundInactive
                                }
                                alt="Inner Circle"
                                className={`${
                                  step.active <= count ? "innerCircle" : "innerCircleInactive"
                                }`}
                              />
                              <img
                                src={customIcons[index]}
                                alt={step.active <= count ? "Custom Icon" : "Inactive Icon"}
                                className="customIcon"
                              />
                            </>
                          }
                        >
                          <Avatar
                            alt={`Step ${index + 1}`}
                            className={`'avatar' ${step.active ? "activeIcon" : "inactiveIcon"}`}
                          />
                        </Badge>
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <div></div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="revized__containt-right">
              {/* Step 1 */}
              {activeStep === 0 && (
                <div>
                  <div className="revized__containt-header">
                    <h3 className="title">Select data type</h3>
                    <p>Select the data parameter to set up the new connection.</p>
                  </div>
                  <div className="revized__container">
                    <form>
                      <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                          <div className="mb-3">
                            <label className="formlable">
                              Select ESG parameter <span className="mark-form">*</span>
                            </label>
                            <select
                              className="Ecodropdown"
                              required
                              value={matrics}
                              onChange={(e) => {
                                setMatrics(e.target.value);
                                checkAppName2(e.target.value);
                              }}
                            >
                              <option value="">ESG parameter</option>
                              {Object.entries(modules).map(([group, options]) => (
                                <optgroup key={group} label={group}>
                                  {options.map((option) => {
                                    // Adding an if condition using an if statement
                                    if ("Purchased Electricity" === option) {
                                      return <option key={option}>{option}</option>;
                                    } else {
                                      return <option key={option}>{option}</option>;
                                    }
                                  })}
                                </optgroup>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="formlable">
                              Select site location<span className="mark-form">*</span>
                            </label>
                            <select
                              id="inputState"
                              required
                              class="Ecodropdown"
                              value={sitelocation1}
                              onChange={(e) => {
                                setSiteLocation1(e.target.value);
                                checkAppName1(e.target.value);
                              }}
                            >
                              <option value="">Site</option>
                              {activesitelocation &&
                                activesitelocation.map((status) => (
                                  <option key={status.name} value={status.name}>
                                    {status.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="next__previous-buttons">
                      <div className="d-flex">
                        <Button
                          className="next-button"
                          onClick={handleNext}
                          variant="contained"
                          size="medium"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#407775",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                          disabled={!matrics || !sitelocation1}
                        >
                          Next
                          <p style={{ paddingTop: "17px" }}>&gt;</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Step 2 */}
              {activeStep === 1 && (
                <div>
                  <div className="revized__containt-header">
                    <h3 className="title">Choose data connector </h3>
                    <p>
                      Simplify data collection using import options such as excel sheets, XML files
                      and API integration.
                    </p>
                  </div>
                  <div className="revized__container">
                    <div>
                      <label className="form-label">
                        Choose connector <span className="mark-form">*</span>
                      </label>
                      <ul className="list dropzone__list">
                        {getappnames && getappnames.configuredAppNames.length === 0 ? (
                          <div></div>
                        ) : (
                          <div style={cardStyles.heading} className="text-conn">
                            Used connector
                          </div>
                        )}

                        {getappnames &&
                          getappnames.configuredAppNames.map((appname) => {
                            if (appname === "Excel") {
                              return (
                                <li
                                  key={appname}
                                  className={selectedExcel === appname ? "active items" : "items"}
                                  value={appname}
                                  onClick={() => handleClick(appname)}
                                  style={{ position: "relative" }}
                                >
                                  <img src="assets/img/excel.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "PDF") {
                              return (
                                <li
                                  key={appname}
                                  className={selectedExcel === appname ? "active items" : "items"}
                                  value={appname}
                                  onClick={() => handleClick(appname)}
                                  style={{ "pointer-events": "none" }}
                                >
                                  <img src="assets/img/pdf.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "Dynamics") {
                              return (
                                <li
                                  key={appname}
                                  className={selectedExcel === appname ? "active items" : "items"}
                                  value={appname}
                                  onClick={() => handleClick(appname)}
                                  style={{ "pointer-events": "none" }}
                                >
                                  <img src="assets/img/dynamics.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                          })}

                        {getappnames && getappnames.availableToConfigureAppNames.length === 0 ? (
                          <div></div>
                        ) : (
                          <div style={cardStyles.heading} className="text-conn">
                            Available connector
                          </div>
                        )}

                        {getappnames &&
                          getappnames.availableToConfigureAppNames.map((appname) => {
                            if (appname === "Excel") {
                              return (
                                <li
                                  key={appname}
                                  className={
                                    selectedExcel.includes(appname) ? "active items" : "items"
                                  }
                                  value={appname}
                                  htmlFor={appname}
                                  onClick={() => handleClick(appname)}
                                >
                                  <label class="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedExcel.includes(appname)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <img src="assets/img/excel.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "PDF") {
                              return (
                                <li
                                  className={
                                    selectedExcel.includes(appname) ? "active items" : "items"
                                  }
                                  value={appname}
                                  onClick={() => handleClick(appname)}
                                >
                                  <label class="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedExcel.includes(appname)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <img src="assets/img/pdf.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "Dynamics") {
                              return (
                                <li
                                  className={
                                    selectedExcel.includes(appname) ? "active items" : "items"
                                  }
                                  value={appname}
                                  onClick={() => handleClick(appname)}
                                >
                                  <label class="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedExcel.includes(appname)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <img src="assets/img/dynamics.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                          })}
                        {/* Cascading Connectors */}
                        {getappnames &&
                          getappnames.cascadesConnectors.map((appname) => {
                            if (appname.name === "Azure Table Storage") {
                              return (
                                <li
                                  className={
                                    selectedExcel.includes(appname.name) ? "active items" : "items"
                                  }
                                  value={appname.name}
                                  onClick={() => handleClick(appname.name)}
                                >
                                  <label class="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedExcel.includes(appname.name)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <img src="assets/img/azuretablestorage.png" />
                                  <h6>{appname.name}</h6>
                                </li>
                              );
                            }
                            if (appname.name === "API") {
                              return (
                                <li
                                  className={
                                    selectedExcel.includes(appname.name) ? "active items" : "items"
                                  }
                                  value={appname.name}
                                  onClick={() => {
                                    handleClick(appname.name);
                                  }}
                                >
                                  <label class="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedExcel.includes(appname.name)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  {/*<input
                                      type="checkbox"
                                      className="checkbox"
                                      checked={selectedExcel.includes(appname.name)}/>*/}
                                  <img src="assets/img/api.png" />
                                  <h6>{appname.name}</h6>
                                </li>
                              );
                            }
                          })}

                        {getappnames && getappnames.comingSoonAppNames.length === 0 ? (
                          <div></div>
                        ) : (
                          <div style={cardStyles.heading} className="text-conn">
                            Coming soon connector
                          </div>
                        )}

                        {getappnames &&
                          getappnames.comingSoonAppNames.map((appname) => {
                            if (appname === "Meter") {
                              return (
                                <li className="items">
                                  <img src="assets/img/meterno.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "SAP") {
                              return (
                                <li className="items">
                                  <img src="assets/img/sap.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "Oracle") {
                              return (
                                <li className="items">
                                  <img src="assets/img/oracle.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "Dynamics") {
                              return (
                                <li className="items">
                                  <img src="assets/img/dynamics.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                            if (appname === "SalesForce") {
                              return (
                                <li className="items">
                                  <img src="assets/img/salesforce.png" />
                                  <h6>{appname}</h6>
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </div>
                    <div className="next__previous-buttons">
                      <div className="d-flex">
                        <Button
                          onClick={handleBack}
                          variant="outlined"
                          size="medium"
                          className="outline-btn"
                          sx={{
                            color: "#407775",
                            marginRight: "15px",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <p style={{ paddingTop: "17px", color: "#407775" }}>&lt;</p>
                          Back
                        </Button>
                        <Button
                          className="next-button"
                          onClick={handleNext}
                          variant="contained"
                          size="medium"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#407775",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                          }}
                          disabled={!selectedExcel}
                        >
                          Next
                          <p style={{ paddingTop: "17px" }}>&gt;</p>
                        </Button>
                        {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Step 3 */}
              {activeStep === 2 &&
                (selectedExcel.includes("Excel") || selectedExcel.includes("PDF")) && (
                  <div>
                    <div className="revized__containt-header">
                      <h3 className="title">Create connection</h3>
                      <p>
                        Simply data collection using import options such as excel sheets, XML files
                        and API integration.
                      </p>
                    </div>
                    <div className="revized__side__container">
                      <div className="child-div-10">
                        {getappnames &&
                          getappnames.availableToConfigureAppNames
                            .filter((appname) => selectedExcel.includes(appname))
                            .sort((a, b) => selectedExcel.indexOf(a) - selectedExcel.indexOf(b))
                            .map((appname) => (
                              <div
                                key={appname}
                                className={`clickable-div ${
                                  selectedDiv === appname ? "selected" : ""
                                }`}
                                onClick={() => handleDivClick(appname)}
                              >
                                <img
                                  src={`assets/img/${appname.toLowerCase()}.png`}
                                  alt={appname}
                                />
                                <label>{appname}</label>
                              </div>
                            ))}

                        {getappnames &&
                          getappnames.cascadesConnectors
                            .filter((appname) => selectedExcel.includes(appname.name))
                            .sort(
                              (a, b) =>
                                selectedExcel.indexOf(a.name) - selectedExcel.indexOf(b.name)
                            )
                            .map((appname) => (
                              <div
                                key={appname.name}
                                className={`clickable-div ${
                                  selectedDiv === appname.name ? "selected" : ""
                                }`}
                                onClick={() => handleDivClick(appname.name)}
                              >
                                <img
                                  src={`assets/img/${appname.name
                                    .replace(/\s/g, "")
                                    .toLowerCase()}.png`}
                                  alt={appname.name}
                                />
                                <label>{appname.name}</label>
                              </div>
                            ))}
                      </div>
                      <div className="child-div-90">
                        {selectedDiv === "Excel" || selectedDiv === "PDF" ? (
                          !importfile ? (
                            <div style={{ color: "#1E4B7A" }}>
                              <p>Connect To Data Source</p>
                              <div
                                className="form-group col-md-12"
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  maxWidth: "770px",
                                }}
                              >
                                <label class="formlable" for="formGroupExampleInput">
                                  Connector Name 1
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  required
                                  id="formGroupExampleInput"
                                  placeholder="Enter the required connector name"
                                />
                              </div>
                              <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone__section">
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <div className="dropzone">
                                        <label for="files" class="dropzone-container">
                                          <div className="file-icon">
                                            <img
                                              class="icon"
                                              src="assets/img/import.png"
                                              alt=""
                                            ></img>
                                          </div>
                                          <div className="dropzone-title">
                                            <span className="browse">Browse</span> or drag and drop
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div className="next__previous-buttons">
                                <div className="d-flex">
                                  <Button
                                    onClick={handleBack}
                                    variant="outlined"
                                    size="medium"
                                    className="outline-btn"
                                    sx={{
                                      color: "#407775",
                                      marginRight: "15px",
                                      width: "120px",
                                      height: "41px",
                                      borderRadius: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p style={{ paddingTop: "17px", color: "#407775" }}>&lt;</p>
                                    Back
                                  </Button>
                                  <Button
                                    className="next-button"
                                    onClick={handleNext}
                                    variant="contained"
                                    size="medium"
                                    sx={{
                                      color: "#fff",
                                      backgroundColor: "#407775",
                                      width: "120px",
                                      height: "41px",
                                      borderRadius: "10px",
                                    }}
                                    disabled={!importfile}
                                  >
                                    Next
                                    <p style={{ paddingTop: "17px" }}>&gt;</p>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                maxWidth: "770px",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  background: "#F5F8F8",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "300px",
                                  width: "100%",
                                  maxWidth: "770px",
                                  borderRadius: "10px",
                                }}
                              >
                                <img
                                  src="assets/img/successicon.png"
                                  alt="Label 2"
                                  style={{
                                    width: "85px",
                                    height: "85px",
                                  }}
                                />
                                <label
                                  style={{ fontWeight: "bold", size: "medium", color: "#407775" }}
                                >
                                  Success
                                </label>
                                <p>{importfile} Excel Connector Successfully added.</p>
                              </div>
                              <p
                                style={{
                                  textAlign: "right",
                                  marginTop: "14px",
                                  fontSize: "20px",
                                  marginRight: "30px",
                                  fontWeight: "normal",
                                  size: "medium",
                                  color: "#407775",
                                }}
                              >
                                + Add Another Excel
                              </p>
                              <div className="next__previous-buttons">
                                <div className="d-flex">
                                  <Button
                                    onClick={handleBack}
                                    variant="outlined"
                                    size="medium"
                                    className="outline-btn"
                                    sx={{
                                      color: "#407775",
                                      marginRight: "15px",
                                      width: "120px",
                                      height: "41px",
                                      borderRadius: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p style={{ paddingTop: "17px", color: "#407775" }}>&lt;</p>
                                    Back
                                  </Button>
                                  <Button
                                    className="next-button"
                                    onClick={handleNext}
                                    variant="contained"
                                    size="medium"
                                    sx={{
                                      color: "#fff",
                                      backgroundColor: "#407775",
                                      width: "120px",
                                      height: "41px",
                                      borderRadius: "10px",
                                    }}
                                    disabled={!importfile}
                                  >
                                    Next
                                    <p style={{ paddingTop: "17px" }}>&gt;</p>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="revized_steps__container">
                            <div style={{ color: "#1E4B7A" }}>
                              {importfile ? (
                                <p>{importfile} Connection Created successfully</p>
                              ) : null}
                            </div>
                            <div className="abc">
                              <div className="form-group col-md-6">
                                <label className="formlable" htmlFor="inputState">
                                  Connection type<span className="mark-form">*</span>
                                </label>
                                <select
                                  id="inputState"
                                  className="Ecodropdown"
                                  required
                                  onChange={handleConnectionTypeChange}
                                  value={connectionType}
                                >
                                  <option value="">Select authorization type</option>
                                  {/* <option value="BasicAuth">Basic Auth</option> */}
                                  <option value="OAuth">OAuth</option>
                                  <option value="APIkey">API Key</option>
                                  {/* <option value="None">None</option> */}
                                </select>
                              </div>

                              {connectionType === "APIkey" && (
                                <div className="form-group col-md-6">
                                  {selectedExcel.includes("API") && (
                                    <div>
                                      <div>
                                        <label className="formlable" htmlFor="inputCity">
                                          Connector Name<span className="mark-form">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setQuery(e.target.value);
                                          }}
                                          className="form-control"
                                          id="query"
                                          placeholder=""
                                        />
                                      </div>
                                      <div>
                                        <label className="formlable" htmlFor="inputCity">
                                          Query<span className="mark-form"></span>
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setQuery(e.target.value);
                                          }}
                                          className="form-control"
                                          id="query"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedExcel.includes("Azure Table Storage") && (
                                    <div>
                                      <label className="formlable" htmlFor="inputCity">
                                        Storage Account Name<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setAccoutName(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="storageAccountName"
                                        placeholder=""
                                      />

                                      <label className="formlable" htmlFor="inputCity">
                                        Table Name<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setTableName(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="tableName"
                                        placeholder=""
                                      />

                                      <label className="formlable" htmlFor="inputCity">
                                        Query<span className="mark-form"></span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setQuery(e.target.value);
                                        }}
                                        className="form-control"
                                        id="query"
                                        placeholder=""
                                      />
                                    </div>
                                  )}
                                  <label className="formlable" htmlFor="inputCity">
                                    Key <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setApiKey(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Value<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setApiValue(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Resource URL <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    onChange={(e) => {
                                      setResource(e.target.value);
                                    }}
                                    placeholder=""
                                  />

                                  {selectedExcel.includes("API") && (
                                    <div>
                                      <p
                                        type="submit"
                                        onClick={checkApiConnection}
                                        style={{
                                          textAlign: "end",
                                          fontWeight: "bold",
                                          size: "medium",
                                          color: "#407775",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <img
                                          src="assets/img/validate.png"
                                          alt="Success Icon"
                                          style={{
                                            marginRight: "auto",
                                            maxWidth: "17px",
                                            paddingBottom: "3px",
                                          }}
                                        />
                                        Validate Connection
                                      </p>
                                    </div>
                                  )}
                                  {selectedExcel.includes("Azure Table Storage") && (
                                    <div>
                                      <button type="submit" className="btn btn-primary signin__btn">
                                        Connect
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {connectionType === "OAuth" && (
                                <div className="form-group col-md-6">
                                  {selectedExcel.includes("API") && (
                                    <div>
                                      <label className="formlable" htmlFor="inputCity">
                                        Query<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setQuery(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="query"
                                        placeholder=""
                                      />
                                    </div>
                                  )}
                                  {selectedExcel.includes("Azure Table Storage") && (
                                    <div>
                                      <label className="formlable" htmlFor="inputCity">
                                        Storage Account Name<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setAccoutName(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="storageAccountName"
                                        placeholder=""
                                      />

                                      <label className="formlable" htmlFor="inputCity">
                                        Table Name<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setTableName(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="tableName"
                                        placeholder=""
                                      />

                                      <label className="formlable" htmlFor="inputCity">
                                        Query<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setQuery(e.target.value);
                                        }}
                                        className="form-control"
                                        required
                                        id="query"
                                        placeholder=""
                                      />
                                    </div>
                                  )}

                                  <label className="formlable" htmlFor="inputCity">
                                    Access Token URL<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setAccessTokenUrl(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Client Id<span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setClientID(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Client Secret <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setClientSecret(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Scope <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setScope(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Tenant Id <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setTenantID(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="tenantId"
                                    placeholder=""
                                  />

                                  <label className="formlable" htmlFor="inputCity">
                                    Resource URL <span className="mark-form">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setResource(e.target.value);
                                    }}
                                    className="form-control"
                                    required
                                    id="inputCity"
                                    placeholder=""
                                  />

                                  {/*{selectedExcel === 'API' && (
                                <div>
                                  <button type="submit" onClick={checkApiConnection} className="btn btn-primary signin__btn">Connect</button>
                                </div>
                              )}

                              {selectedExcel === 'Azure Table Storage' && (
                                <div>
                                  <button type="submit" onClick={checkApiConnection} className="btn btn-primary signin__btn">Connect</button>
                                </div>
                              )}*/}
                                </div>
                              )}
                            </div>

                            <div className="next__previous-buttons">
                              <div className="d-flex">
                                <Button
                                  onClick={handleBack}
                                  variant="outlined"
                                  size="medium"
                                  className="outline-btn"
                                  sx={{
                                    color: "#000",
                                    marginRight: "15px",
                                    width: "120px",
                                    height: "41px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  Back
                                </Button>
                                <Button
                                  className="next-button"
                                  onClick={handleNext}
                                  variant="contained"
                                  size="medium"
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "#407775",
                                    width: "120px",
                                    height: "41px",
                                    borderRadius: "10px",
                                  }}
                                  disabled={!importfile}
                                >
                                  Next
                                </Button>
                                {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px',height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {/* Step 3 */}
              {activeStep === 2 &&
                (selectedExcel.includes("Dynamics") ||
                  selectedExcel.includes("Azure Table Storage") ||
                  selectedExcel.includes("API")) &&
                !selectedExcel.includes("Excel") &&
                !selectedExcel.includes("PDF") && (
                  <div>
                    <div className="revized__containt-header">
                      <h3 className="title">Create connection</h3>
                      <p>
                        Simply data collection using import options such as excel sheets, XML files
                        and API integration.
                      </p>
                    </div>
                    <div className="revized__side__container">
                      <div className="child-div-10">
                        {getappnames &&
                          getappnames.availableToConfigureAppNames.map(
                            (appname) =>
                              selectedExcel.includes(appname) && (
                                <div
                                  key={appname.name}
                                  className={`clickable-div ${
                                    selectedDiv === appname.name ? "selected" : ""
                                  }`}
                                  onClick={() => handleDivClick(appname.name)}
                                >
                                  <img
                                    src={`assets/img/${appname.toLowerCase()}.png`}
                                    alt={appname}
                                  />
                                  <label>{appname}</label>
                                </div>
                              )
                          )}

                        {getappnames &&
                          getappnames.cascadesConnectors.map(
                            (appname) =>
                              selectedExcel.includes(appname.name) && (
                                <div
                                  key={appname.name}
                                  className={`clickable-div ${
                                    selectedDiv === appname.name ? "selected" : ""
                                  }`}
                                  onClick={() => handleDivClick(appname.name)}
                                >
                                  <img
                                    src={`assets/img/${appname.name
                                      .replace(/\s/g, "")
                                      .toLowerCase()}.png`}
                                    alt={appname.name}
                                  />
                                  <label>{appname.name}</label>
                                </div>
                              )
                          )}
                      </div>
                      <div className="child-div-90">
                        <div className="revized_steps__container">
                          <div style={{ color: "#1E4B7A" }}>
                            {importfile ? (
                              <p>{importfile} Connection Created successfully</p>
                            ) : null}
                          </div>
                          <div className="abc">
                            <div className="form-group col-md-6">
                              <label className="formlable" htmlFor="inputState">
                                Connection type<span className="mark-form">*</span>
                              </label>
                              <select
                                id="inputState"
                                className="Ecodropdown"
                                required
                                onChange={handleConnectionTypeChange}
                                value={connectionType}
                              >
                                <option value="">Select authorization type</option>
                                {/* <option value="BasicAuth">Basic Auth</option> */}
                                <option value="OAuth">OAuth</option>
                                <option value="APIkey">API Key</option>
                                {/* <option value="None">None</option> */}
                              </select>
                            </div>

                            {connectionType === "APIkey" && (
                              <div className="form-group col-md-6">
                                {selectedExcel.includes("API") && (
                                  <div>
                                    <div>
                                      <label className="formlable" htmlFor="inputCity">
                                        Connector Name<span className="mark-form">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setQuery(e.target.value);
                                        }}
                                        className="form-control"
                                        id="query"
                                        placeholder=""
                                      />
                                    </div>
                                    <div>
                                      <label className="formlable" htmlFor="inputCity">
                                        Query<span className="mark-form"></span>
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setQuery(e.target.value);
                                        }}
                                        className="form-control"
                                        id="query"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                )}
                                {selectedExcel.includes("Azure Table Storage") && (
                                  <div>
                                    <label className="formlable" htmlFor="inputCity">
                                      Storage Account Name<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setAccoutName(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="storageAccountName"
                                      placeholder=""
                                    />

                                    <label className="formlable" htmlFor="inputCity">
                                      Table Name<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setTableName(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="tableName"
                                      placeholder=""
                                    />

                                    <label className="formlable" htmlFor="inputCity">
                                      Query<span className="mark-form"></span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setQuery(e.target.value);
                                      }}
                                      className="form-control"
                                      id="query"
                                      placeholder=""
                                    />
                                  </div>
                                )}
                                <label className="formlable" htmlFor="inputCity">
                                  Key <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setApiKey(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Value<span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setApiValue(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Resource URL <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  onChange={(e) => {
                                    setResource(e.target.value);
                                  }}
                                  placeholder=""
                                />

                                {selectedExcel.includes("API") && (
                                  <div>
                                    <p
                                      type="submit"
                                      onClick={checkApiConnection}
                                      style={{
                                        textAlign: "end",
                                        fontWeight: "bold",
                                        size: "medium",
                                        color: "#407775",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <img
                                        src="assets/img/validate.png"
                                        alt="Success Icon"
                                        style={{
                                          marginRight: "auto",
                                          maxWidth: "17px",
                                          paddingBottom: "3px",
                                        }}
                                      />
                                      Validate Connection
                                    </p>
                                  </div>
                                )}
                                {selectedExcel.includes("Azure Table Storage") && (
                                  <div>
                                    <button type="submit" className="btn btn-primary signin__btn">
                                      Connect
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}

                            {connectionType === "OAuth" && (
                              <div className="form-group col-md-6">
                                {selectedExcel.includes("API") && (
                                  <div>
                                    <label className="formlable" htmlFor="inputCity">
                                      Query<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setQuery(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="query"
                                      placeholder=""
                                    />
                                  </div>
                                )}
                                {selectedExcel.includes("Azure Table Storage") && (
                                  <div>
                                    <label className="formlable" htmlFor="inputCity">
                                      Storage Account Name<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setAccoutName(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="storageAccountName"
                                      placeholder=""
                                    />

                                    <label className="formlable" htmlFor="inputCity">
                                      Table Name<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setTableName(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="tableName"
                                      placeholder=""
                                    />

                                    <label className="formlable" htmlFor="inputCity">
                                      Query<span className="mark-form">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        setQuery(e.target.value);
                                      }}
                                      className="form-control"
                                      required
                                      id="query"
                                      placeholder=""
                                    />
                                  </div>
                                )}

                                <label className="formlable" htmlFor="inputCity">
                                  Access Token URL<span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setAccessTokenUrl(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Client Id<span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setClientID(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Client Secret <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setClientSecret(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Scope <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setScope(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Tenant Id <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setTenantID(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="tenantId"
                                  placeholder=""
                                />

                                <label className="formlable" htmlFor="inputCity">
                                  Resource URL <span className="mark-form">*</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setResource(e.target.value);
                                  }}
                                  className="form-control"
                                  required
                                  id="inputCity"
                                  placeholder=""
                                />

                                {/*{selectedExcel === 'API' && (
                                  <div>
                                    <button type="submit" onClick={checkApiConnection} className="btn btn-primary signin__btn">Connect</button>
                                  </div>
                                )}

                                {selectedExcel === 'Azure Table Storage' && (
                                  <div>
                                    <button type="submit" onClick={checkApiConnection} className="btn btn-primary signin__btn">Connect</button>
                                  </div>
                                )}*/}
                              </div>
                            )}
                          </div>
                          <div className="next__previous-buttons">
                            <div className="d-flex">
                              <Button
                                onClick={handleBack}
                                variant="outlined"
                                size="medium"
                                className="outline-btn"
                                sx={{
                                  color: "#000",
                                  marginRight: "15px",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                className="next-button"
                                onClick={handleNext}
                                variant="contained"
                                size="medium"
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#407775",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                                disabled={!importfile}
                              >
                                Next
                              </Button>
                              {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', position: 'relative', width: '120px',height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* Step 4 */}
              {activeStep === 3 && (
                <div className="table__section">
                  <div className="data__table">
                    <div className="steps__containt-hader">
                      <h3 className="title">Map the fields</h3>
                      <p>
                        Map the fields from the data source to the attributes of the standard
                        Ecoprism data requirement.
                      </p>
                    </div>
                    <hr />
                    <div className="steps__container">
                      <div className="table-responsive">
                        <table className="table custom-table ">
                          <thead>
                            <tr>
                              <th scope="col">Data requirement</th>
                              <th scope="col">Connector</th>
                              <th scope="col">Field in data source</th>
                            </tr>
                          </thead>
                          <tbody>
                            {excelfields.map((keyName, i) => (
                              <tr>
                                <td style={{ paddingLeft: "25px" }}>
                                  {keyName.key}{" "}
                                  <span style={{ color: "red" }}>
                                    {keyName.value === true ? "*" : ""}
                                  </span>
                                  <input type="hidden" value={keyName.key} name="destination[]" />
                                </td>
                                <td>
                                  <div
                                    className="form-group col-md-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <select
                                      id={"dropdown" + i}
                                      class="Ecodropdown"
                                      onChange={(e) => excelH(e.target.value, i)}
                                      name="excelHeaderss[]"
                                      required={keyName.value === true ? "required" : undefined}
                                      title={keyName.key}
                                      style={{ paddingLeft: "20px" }}
                                    >
                                      <option value={keyName.value === true ? "" : "None"}>
                                        None
                                      </option>
                                      {headerfields.map((header) => (
                                        <option value={header}>{header}</option>
                                      ))}
                                    </select>
                                    <input
                                      type="hidden"
                                      id={"excel" + i}
                                      value={keyName.value === true ? "" : "None"}
                                      name="excelHeaders[]"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="form-group col-md-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <select
                                      id={"dropdown" + i}
                                      class="Ecodropdown"
                                      onChange={(e) => excelH(e.target.value, i)}
                                      name="excelHeaderss[]"
                                      required={keyName.value === true ? "required" : undefined}
                                      title={keyName.key}
                                      style={{ paddingLeft: "20px" }}
                                    >
                                      <option value={keyName.value === true ? "" : "None"}>
                                        Field Name
                                      </option>
                                      {headerfields.map((header) => (
                                        <option value={header}>{header}</option>
                                      ))}
                                    </select>
                                    <input
                                      type="hidden"
                                      id={"excel" + i}
                                      value={keyName.value === true ? "" : "None"}
                                      name="excelHeaders[]"
                                    />
                                  </div>
                                  <p
                                    className="addDefaultValue-p"
                                    onClick={handleAddDefaultValueClick}
                                  >
                                    + Add Default Value
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <h5>Result</h5>
                        <p>co2e : {result.co2e} {result.co2eUnit}</p>
                        <p> co2e Calculation Method : {result.co2eCalculationMethod}</p> */}
                  </div>
                  <div className="next__previous-buttons">
                    <div className="d-flex">
                      <Button
                        onClick={handleBack}
                        variant="outlined"
                        size="medium"
                        className="outline-btn"
                        sx={{
                          color: "#000",
                          marginRight: "15px",
                          width: "120px",
                          height: "41px",
                          borderRadius: "10px",
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        className="next-button"
                        onClick={handleNext}
                        variant="contained"
                        size="medium"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#407775",
                          width: "120px",
                          height: "41px",
                          borderRadius: "10px",
                        }}
                      >
                        Next
                      </Button>
                      {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft : '20px', width: '120px', position: 'relative', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                    </div>
                    <div class="ab"></div>
                  </div>
                </div>
              )}

              {/* Step 5 */}
              {activeStep === 4 && (
                <div>
                  <div className="revized__containt-header">
                    <h3 className="title">Review data connection</h3>
                    <p>
                      Review your data connection ensuring seamless integration and optimal
                      performance
                    </p>
                  </div>
                  <div className="revized_second_steps__container">
                    <div class="finish">
                      <p>
                        <strong>ESG parameter:</strong> {matrics}
                      </p>
                      <p>
                        <strong>Site:</strong> {sitelocation1}
                      </p>
                      <p>
                        <strong>Data connector:</strong> {selectedExcel}
                      </p>
                      <p>
                        <strong>Owner:</strong> {username}
                      </p>
                      <p>
                        <strong>Data Frequency:</strong>
                      </p>
                      <p>
                        <select className="Ecodropdown" style={{ width: "25%" }}>
                          <option value="OAuth">Weekly</option>
                          <option value="APIkey">Monthly</option>
                        </select>
                      </p>
                    </div>
                    <div className="steps__container">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th scope="col">Data Connector</th>
                              <th scope="col">Connector Name</th>
                              <th scope="col">No of Field</th>
                            </tr>
                          </thead>
                          <tbody>
                            {connectorData.map((connector, index) => (
                              <tr key={index}>
                                <td style={{ paddingLeft: "25px" }}>{connector.type}</td>
                                <td>
                                  <div
                                    className="form-group col-md-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    {connector.name}
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>{connector.fieldCount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="next__previous-buttons">
                      <div className="d-flex">
                        <Button
                          onClick={handleBack}
                          variant="outlined"
                          size="medium"
                          className="outline-btn"
                          sx={{
                            color: "#000",
                            marginRight: "15px",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          className="next-button"
                          onClick={handleSubmit}
                          variant="contained"
                          size="medium"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#407775",
                            width: "120px",
                            height: "41px",
                            borderRadius: "10px",
                          }}
                        >
                          Save
                        </Button>
                        {/* <Button onClick={handleCancel} variant="outlined" size="medium" className="outline-btn" sx={{ 'color' : '#000', marginLeft :'20px', position: 'relative', width: '120px', height: '41px', borderRadius: '10px' }}>Cancel</Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Step 6 */}
              {activeStep === 5 && (
                <div>
                  <div className="steps__containt-hader">
                    <h3 className="title">Data connection</h3>
                    <p>
                      The connection has been established and is now accessible in the configuration
                      list
                    </p>
                  </div>
                  <hr />
                  <div className="steps__container">
                    <div class="col-md-3"></div>
                    <div class="finish1">
                      <p>Connection created successfully</p>
                      {activeStep === steps.length && (
                        <Paper className="finish" square elevation={0} sx={{ p: 3 }}>
                          {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
                          <Button
                            sx={{
                              color: "#fff",
                              backgroundColor: "#407775",
                              width: "120px",
                              height: "41px",
                              borderRadius: "10px",
                            }}
                            onClick={handleReset}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            <Link to="/allconfiguration" className="btn btn-primary config-btn">
                              {" "}
                              All Configurations{" "}
                            </Link>
                          </Button>
                        </Paper>
                      )}
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
