import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import containerTheme from './theme/containerTheme.js'
import Sitelocation from "./sitelocation";
import Listofbusinessunits from "./listofbusinessunits";
import Listofentities from "./listofentities";
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import TableFooter from "@mui/material/TableFooter";

// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  const username = localStorage.getItem("userName");
  console.log(username);
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function createData(name, type, country, revenue, employee) {
    return { name, type, country, revenue, employee };
  }
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredRow1, setHoveredRow1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [etities, setEntities] = useState();
  const [activeetities, setActiveEntities] = useState();
  const [deactiveetities, setDeactiveEntities] = useState();
  const [deletename, setDeleteName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [businessunits, setBusinessUnit] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [oldname, setOldName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employees, setEmployees] = useState("");
  const [curcountry, setCurCountry] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [entitytype, setEntityType] = useState("");
  const [legalEntity, setLegalEntity] = useState();

  const showEditModal = (name, revenue, employees, curcountry, entitytype, currency) => {
    setName(name);
    setOldName(name);
    setRevenue(revenue);
    setEmployees(employees);
    setCurCountry(curcountry);
    setEntityType(entitytype);
    setCurrency(currency);
    setEditIsOpen(true);
    console.log(entitytype);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  const showDeleteModal = (name) => {
    setDeleteName(name);
    setDeleteMessage("Are you sure you want to delete this " + name + "?");
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = async (deletename) => {
    setLoading(true);
    console.log(deletename);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/delete-legalentity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          name: deletename,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: deletename + " Legal entity deleted successfully",
          });
          fetchEntities();
          setDisplayConfirmationModal(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in delete legal entity",
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [getcurrency, setGetCurrency] = useState();
  const handleCurrency = (value, setCountry) => {
    setCountry(value);
    getCurrency(value);
    console.log(value);
  };

  const reactive = async (name) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/reactive-legal-entity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Legal entity reactivated successfully",
          });
        }
        fetchEntities();
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in reactivate legal entity",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function getCurrency() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/currencies");
      const data = await response.json();
      console.log(data);
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEntities() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          console.log(responseJson);
          setLoading(false);
          setEntities("");
        } else if (responseJson.status == 400) {
          setLoading(false);
          setEntities("");
        } else {
          setLoading(false);
          setEntities(responseJson);
          setActiveEntities(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveEntities(responseJson.data.filter((item) => item.isActive === false));
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong",
        });
        setEntities("");
      }

      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  async function fetchLegalEntityType() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/fetch-legal-entity-type");
      const data = await response.json();
      setLegalEntity(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchLegalEntityType();
    fetchEntities();

    getCurrency();
  }, []);

  const [countries, setCountries] = useState();

  async function fetchCountries() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  const saveData = async (name, revenue, employees, country) => {
    setLoading(true);
    try {
      const countri1 = [country];
      const countryString = countri1.toString();
      const countri = countryString.replace(/\[|\]/g, "");
      let res = await fetch("https://ecoprismapi.azurewebsites.net/update-legalentity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          old: oldname,
        },
        body: JSON.stringify({
          name: name,
          revenue: revenue,
          revenueCurrency: currency,
          entityType: entitytype,
          numberOfEmployees: employees,
          countries: [countri],
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Legal entity updated successfully",
          });
        }
        fetchEntities();
        setEditIsOpen(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in update legal entity",
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activeTable, setActiveTable] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(10);
  const [inactiveRowsPerPage, setInactiveRowsPerPage] = useState(10);
  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const toggleTable = (isActive) => {
    setActiveTable(isActive);
    setPage(0); // Reset page when switching tables
  };

  const [filters, setFilters] = useState({
    name: "",
    revenue: "",
    employees: "",
    // Add more filter states for other columns
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9 pd-0">
              <div Class="steps__containt-hader">
                <h3 class="title">Internal</h3>
                <div role="presentation">
                  {/* <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Home
              </Link>
              
              <Typography color="text.primary">Legal Entities</Typography>
            </Breadcrumbs> */}
                </div>
              </div>
            </div>
            <div class="col-md-3 pd-0">
              <div Class="steps__containt-hader btalignleft">
                <a href="/company" className="add-cta">
                  + Add legal entity
                </a>
              </div>
            </div>
          </div>
          <div>
            <div class="col-md-12 flex pd-0">
              <div class="col-md-6 pd-0">
                <button
                  className={activeTable ? "active-button" : "inactive-button"}
                  onClick={() => toggleTable(true)}
                >
                  Active
                </button>
                <button
                  className={!activeTable ? "active-button" : "inactive-button"}
                  onClick={() => toggleTable(false)}
                >
                  Inactive
                </button>
              </div>
              <div class="col-md-6 btalignleft pd-0">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
            {activeTable ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Legal entity</TableCell>
                      <TableCell align="left">Entity type</TableCell>
                      <TableCell align="left">Country</TableCell>
                      <TableCell align="left">Revenue</TableCell>
                      <TableCell align="left">Employees</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Legal Entity"
                          value={filters.legalEntity || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              legalEntity: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Entity Type"
                          value={filters.entityType || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              entityType: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Country"
                          value={filters.country || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              country: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Revenue"
                          value={filters.revenue || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              revenue: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Employees"
                          value={filters.employees || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              employees: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeetities &&
                      activeetities
                        .slice(activePage * activeRowsPerPage, (activePage + 1) * activeRowsPerPage)
                        .filter((row) => {
                          // Apply global filter
                          if (globalFilter !== "") {
                            const lowerGlobalFilter = globalFilter.toLowerCase();
                            return (
                              row.name.toLowerCase().includes(lowerGlobalFilter) ||
                              row.entityType.toLowerCase().includes(lowerGlobalFilter) ||
                              (row.countries &&
                                row.countries.some((country) =>
                                  country.toLowerCase().includes(lowerGlobalFilter)
                                )) ||
                              row.revenue.toString().includes(lowerGlobalFilter) ||
                              row.numberOfEmployees.toString().includes(lowerGlobalFilter)
                            );
                          }
                          return (
                            (filters.legalEntity
                              ? row.name.toLowerCase().includes(filters.legalEntity.toLowerCase())
                              : true) &&
                            (filters.entityType
                              ? row.entityType
                                  .toLowerCase()
                                  .includes(filters.entityType.toLowerCase())
                              : true) &&
                            (filters.country
                              ? row.countries &&
                                row.countries.some((country) =>
                                  country.toLowerCase().includes(filters.country.toLowerCase())
                                )
                              : true) &&
                            (filters.revenue
                              ? row.revenue
                                  .toString()
                                  .toLowerCase()
                                  .includes(filters.revenue.toLowerCase())
                              : true) &&
                            (filters.employees
                              ? row.numberOfEmployees
                                  .toString()
                                  .toLowerCase()
                                  .includes(filters.employees.toLowerCase())
                              : true)
                          );
                        })
                        .map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.entityType} </TableCell>
                            <TableCell align="left">
                              {row.countries && row.countries.join(", ")}
                            </TableCell>
                            <TableCell align="left">
                              {row.revenueCurrency} {row.revenue.toLocaleString()}
                            </TableCell>
                            <TableCell align="left">
                              {row.numberOfEmployees.toLocaleString()}
                            </TableCell>
                            <TableCell align="left">
                              <EditIcon
                                style={{ cursor: "pointer", color: "#407775" }}
                                onClick={() =>
                                  showEditModal(
                                    row.name,
                                    row.revenue,
                                    row.numberOfEmployees,
                                    row.countries && row.countries.map((country) => country),
                                    row.entityType,
                                    row.revenueCurrency
                                  )
                                }
                                onMouseEnter={() => setHoveredRow(row.name)}
                                onMouseLeave={() => setHoveredRow(null)}
                              />
                              {hoveredRow === row.name && <span className="hover-text">Edit</span>}
                              <DeleteIcon
                                style={{ cursor: "pointer", color: "#407775" }}
                                onClick={() => showDeleteModal(row.name)}
                                onMouseEnter={() => setHoveredRow1(row.name)}
                                onMouseLeave={() => setHoveredRow1(null)}
                              />
                              {hoveredRow1 === row.name && (
                                <span className="hover-text">Delete</span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    component="div"
                    count={activeetities ? activeetities.length : 0}
                    rowsPerPage={activeRowsPerPage}
                    page={activePage}
                    onPageChange={handleActivePageChange} // Use the active page change handler here
                    onRowsPerPageChange={(event) => {
                      setActiveRowsPerPage(parseInt(event.target.value, 10));
                      setActivePage(0);
                    }}
                  />
                </TableFooter>
              </TableContainer>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Legal entity</TableCell>
                      <TableCell align="left">Entity type</TableCell>
                      <TableCell align="left">Country</TableCell>
                      <TableCell align="left">Revenue</TableCell>
                      <TableCell align="left">Employees</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Legal Entity"
                          value={filters.legalEntity || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              legalEntity: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Entity Type"
                          value={filters.entityType || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              entityType: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Country"
                          value={filters.country || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              country: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Revenue"
                          value={filters.revenue || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              revenue: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Filter by Employees"
                          value={filters.employees || ""}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              employees: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deactiveetities &&
                      deactiveetities
                        .slice(activePage * activeRowsPerPage, (activePage + 1) * activeRowsPerPage)
                        .filter((row) => {
                          if (globalFilter !== "") {
                            const lowerGlobalFilter = globalFilter.toLowerCase();
                            return (
                              row.name.toLowerCase().includes(lowerGlobalFilter) ||
                              row.entityType.toLowerCase().includes(lowerGlobalFilter) ||
                              (row.countries &&
                                row.countries.some((country) =>
                                  country.toLowerCase().includes(lowerGlobalFilter)
                                )) ||
                              row.revenue.toString().includes(lowerGlobalFilter) ||
                              row.numberOfEmployees.toString().includes(lowerGlobalFilter)
                            );
                          }
                          return (
                            (filters.legalEntity
                              ? row.name.toLowerCase().includes(filters.legalEntity.toLowerCase())
                              : true) &&
                            (filters.entityType
                              ? row.entityType
                                  .toLowerCase()
                                  .includes(filters.entityType.toLowerCase())
                              : true) &&
                            (filters.country
                              ? row.countries &&
                                row.countries.some((country) =>
                                  country.toLowerCase().includes(filters.country.toLowerCase())
                                )
                              : true) &&
                            (filters.revenue
                              ? row.revenue
                                  .toString()
                                  .toLowerCase()
                                  .includes(filters.revenue.toLowerCase())
                              : true) &&
                            (filters.employees
                              ? row.numberOfEmployees
                                  .toString()
                                  .toLowerCase()
                                  .includes(filters.employees.toLowerCase())
                              : true)
                          );
                        })
                        .map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.entityType} </TableCell>
                            <TableCell align="left">
                              {row.countries && row.countries.join(", ")}
                            </TableCell>
                            <TableCell align="left">
                              {row.revenueCurrency} {row.revenue.toLocaleString()}
                            </TableCell>
                            <TableCell align="left">
                              {row.numberOfEmployees.toLocaleString()}
                            </TableCell>
                            <TableCell align="left">
                              <Button class="btn btn-danger" onClick={() => reactive(row.name)}>
                                Reactivate
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    component="div"
                    count={deactiveetities ? deactiveetities.length : 0}
                    rowsPerPage={activeRowsPerPage}
                    page={activePage}
                    onPageChange={handleActivePageChange} // Use the active page change handler here
                    onRowsPerPageChange={(event) => {
                      setActiveRowsPerPage(parseInt(event.target.value, 10));
                      setActivePage(0);
                    }}
                  />
                </TableFooter>
              </TableContainer>
            )}
          </div>

          {/* <LoadingSpinner /> */}
          <div class="container pd-0">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <DeleteConfirmation
              confirmModal={submitDelete}
              showModal={displayConfirmationModal}
              hideModal={hideConfirmationModal}
              message={deleteMessage}
              deletename={deletename}
            />
            <Modal show={editIsOpen} onHide={hideEditModal}>
              <Modal.Header closeButton>
                <div Class="steps__containt-hader">
                  <h3 class="title">Edit Legal Entity</h3>
                </div>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputEmail4">
                        Name of entity <span className="mark-form">*</span>
                      </label>
                      <input
                        type="hidden"
                        onChange={(e) => setOldName(e.target.value)}
                        value={oldname}
                      />
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        class="form-control"
                        placeholder="Enter entity name"
                        readOnly
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputState">
                        Country <span className="mark-form">*</span>
                      </label>
                      <select
                        id="inputState"
                        class="Ecodropdown"
                        value={curcountry}
                        onChange={(e) => setCurCountry(e.target.value)}
                      >
                        <option selected value="">
                          Select country <span className="mark-form">*</span>
                        </option>
                        {countries &&
                          countries.map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputCity">
                        Revenue <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        value={revenue}
                        onChange={(e) => setRevenue(e.target.value)}
                        class="form-control"
                        id="inputCity"
                        placeholder="Enter Company revenue"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputCity">
                        Revenue currency <span className="mark-form">*</span>
                      </label>
                      <select
                        id="inputState"
                        class="Ecodropdown"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option selected value="">
                          Select currency
                        </option>
                        {getcurrency &&
                          getcurrency.map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputState">
                        Entity type <span className="mark-form">*</span>
                      </label>
                      <select
                        id="inputState"
                        class="Ecodropdown"
                        value={entitytype}
                        onChange={(e) => setEntityType(e.target.value)}
                      >
                        <option selected value="">
                          Select entity type
                        </option>
                        {legalEntity &&
                          legalEntity.map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputZip">
                        No. of employees <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        value={employees}
                        onChange={(e) => setEmployees(e.target.value)}
                        class="form-control"
                        id="inputZip"
                        placeholder="no. of employees"
                      />
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  class="btn btn-primary"
                  onClick={() => saveData(name, revenue, employees, curcountry)}
                  disabled={loading}
                >
                  Update
                </Button>
                <Button class="btn btn-danger" onClick={hideEditModal}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Inactive" {...a11yProps(1)} />
     
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Legal entity</TableCell>
            <TableCell align="left">Entity type</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Revenue</TableCell>
            <TableCell align="left">Employees</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeetities && activeetities.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.entityType} </TableCell>
              <TableCell align="left">{row.countries && row.countries.map((country) => (country))}</TableCell>
              <TableCell align="left">{row.revenueCurrency} {row.revenue.toLocaleString()}</TableCell>
              <TableCell align="left">{row.numberOfEmployees.toLocaleString()}</TableCell>
              <TableCell align="left">
                <EditIcon style={{ cursor: "pointer",color:"#407775" }} onClick={() => showEditModal(row.name,row.revenue,row.numberOfEmployees,row.countries && row.countries.map((country) => (country)),row.entityType,row.revenueCurrency)} 
                 onMouseEnter={() => setHoveredRow(row.name)}
                 onMouseLeave={() => setHoveredRow(null)}
               /> 
                {hoveredRow === row.name && <span className="hover-text">Edit</span>}
                
                <DeleteIcon style={{ cursor: "pointer",color:"#407775" }} onClick={() => showDeleteModal(row.name)} 
                onMouseEnter={() => setHoveredRow1(row.name)}
                onMouseLeave={() => setHoveredRow1(null)}
               />
               {hoveredRow1 === row.name && <span className="hover-text">Delete</span>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Legal entity</TableCell>
            <TableCell align="left">Entity type</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Revenue</TableCell>
            <TableCell align="left">Employees</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deactiveetities && deactiveetities.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.entityType} </TableCell>
              <TableCell align="left">{row.countries && row.countries.map((country) => (country))}</TableCell>
              <TableCell align="left">{row.revenueCurrency} {row.revenue.toLocaleString()}</TableCell>
              <TableCell align="left">{row.numberOfEmployees.toLocaleString()}</TableCell>
              <TableCell align="left">
              <Button class="btn btn-danger" onClick={() => reactive(row.name)}>
                    Reactivate
                  </Button>
             
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </CustomTabPanel>
    
    </Box> */}
          </div>
        </div>
      </div>
    </div>
  );
}
