import logo from './logo.svg';
import './App.css';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { useAuth0 } from '@auth0/auth0-react';

function AppLandingPage() {
  let navigate = useNavigate (); 
  const login = () => {
    navigate('/login')
  }

  const [organization, setOrganization] = useState("");
  const [companyid, setCompanyId] = useState("");
  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/organization-name',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setOrganization("");
        }
        else if(responseJson.status == 400){
          setOrganization("");
        }
        else{
          localStorage.setItem('organization', responseJson.data.name);
          setOrganization(responseJson.data.name);
         
        }
      }
      else{
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCompanyId() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/getcompanyid',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setCompanyId("");
        }
        else if(responseJson.status == 400){
          setCompanyId("");
        }
        else{
          localStorage.setItem('companyid', responseJson.data);
          setCompanyId(responseJson.data);
        }
      }
      else{
        setCompanyId("");
      }
    } catch (error) {
      console.error(error);
    }
  }
/*login with auth0*/
 const { user,isAuthenticated, isLoading, error, loginWithRedirect,getIdTokenClaims } = useAuth0();
  useEffect(() => {
  const handleRedirect = async () => {
   if (!isAuthenticated && !isLoading && !error) {
  try {
  // await loginWithRedirect();
  } catch (error) {
  console.error('Error during login:', error);
  }
  }

  if (isAuthenticated) {
  console.log(user);
        console.log(isAuthenticated);
         localStorage.setItem('userName', user.email);
         localStorage.setItem('loginType', 'Auth0');
         const idToken = await getIdTokenClaims();
         console.log('JWT Token:', idToken.__raw);
         localStorage.setItem('JWT',  idToken.__raw);
         // Do whatever you need to with the JWT token here
        getOrganization();
       getCompanyId();
        navigate('/home');
     }
    };

     handleRedirect();
  }, [isAuthenticated, isLoading, error, loginWithRedirect,getIdTokenClaims]);

  
  return (
          <div className='home__page-wrapper'>

            {/* ======= Header ======= */}
            <header id="header" className="fixed-top ">
              <div className="container">
                {/* <h1 className="logo me-auto"><a href="index.html">Arsha</a></h1> */}
                {/* Uncomment below if you prefer to use an image logo */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid logo-img"/></a>                
                  </div>
                  <div className='col-lg-6'>                    
                    {/* <a href="#login" className="btn-login" onClick={login}>Login/Signup</a>              */}
                  </div>
                </div>
              </div>
            </header>{/* End Header */}
            {/* ======= Hero Section ======= */}
            <section className="home__page-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className='home__page-left-section'>
                      <h1 className='title'>Sustainability <span className='text-yellow'>Simplified </span></h1>
                      {/* <h2 className='sub__title'>Solutions Simplified</h2> */}
                      <div className="col-lg-9 p-0">
                         <p>ecoPRISM enables the seamless aggregation, processing, and reporting of ESG data, while also generating actionable insights to help transform your ESG performance for a better tomorrow.</p>
                         </div>
                    </div>
                    <a href="#about" className="btn-get-started">Get Started</a>
              {/* <h5 className='warrior__text'> Committed to creating a sustainable future? <a href="#" className="a-login" onClick={login}>Log In</a></h5> */}
                   <h5 className='warrior__text'> Committed to creating a sustainable future? <a href="#" className="a-login" onClick={login}>Log In</a></h5>
                  </div>
                  <div className="col-lg-5">
                    <img className='landing-img' src="./assets/img/hero-img.png">

                    </img>
                    </div>
                </div>
              </div>
            </section>{/* End Hero */}
           
            
          </div>
        );
      }
   

export default AppLandingPage;