import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./table.css";

const SCFSurveyForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      productOrService: null,
      productName: "",
      productCategory: "",
      quantity: null,
      unit: "",
      value: null,
      currency: null,
      isAdded: true,
    },
  ]);

  const [formData, setFormData] = useState({
    country: "",
  });

  const [errors, setErrors] = useState({});

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productOrService: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCategory: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    value: { value: null, matchMode: FilterMatchMode.CONTAINS },
    currency: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // Initialize your data with empty strings instead of null
      const sampleData = [
        {
          productOrService: "",
          productName: "",
          productCategory: "",
          quantity: "",
          unit: "",
          value: "",
          currency: "",
          isAdded: false,
        },
        // Add more rows as needed
      ];
      setData(sampleData);
    };

    fetchData();
  }, []);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
    setGlobalFilterValue(value);
  };

  const cities = [
    { label: "country 1", value: "country 1" },
    { label: "country 2", value: "country 2" },
    { label: "country 3", value: "country 3" },
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!formData.country) newErrors.country = "Country is required";
    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    navigate("/energy-emissions");
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <img src="assets/img/search.png" className="search-i" alt="search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const productOrServiceOptions = [
    { label: "Product", value: "Product" },
    { label: "Service", value: "Service" },
  ];

  const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "INR", value: "INR" },
  ];

  const onProductOrServiceChange = (e, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].productOrService = e.value;
    setData(updatedData);
  };

  const onCurrencyChange = (e, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].currency = e.value;
    setData(updatedData);
  };

  const textInputBodyTemplate = (rowData, field, { rowIndex }) => {
    if (field === "quantity" || field === "value") {
      return (
        <InputNumber
          value={rowData[field]}
          onValueChange={(e) => {
            const updatedData = [...data];
            updatedData[rowIndex][field] = e.value;
            setData(updatedData);
          }}
          placeholder={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
          mode="decimal"
          useGrouping={false} // Disables grouping (e.g., thousands separator)
        />
      );
    }

    let placeholderText = "";
    switch (field) {
      case "productName":
        placeholderText = "Enter Product/Service Name";
        break;
      case "productCategory":
        placeholderText = "Enter Product Category";
        break;
      case "unit":
        placeholderText = "Enter Unit";
        break;
      default:
        placeholderText = "";
    }

    return (
      <InputText
        value={rowData[field]}
        onChange={(e) => {
          const updatedData = [...data];
          updatedData[rowIndex][field] = e.target.value;
          setData(updatedData);
        }}
        placeholder={placeholderText} // Added placeholder here
      />
    );
  };
  const productOrServiceBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <Dropdown
        value={rowData.productOrService}
        options={productOrServiceOptions}
        onChange={(e) => onProductOrServiceChange(e, rowIndex)}
        placeholder="Select" // Set placeholder here
      />
    );
  };

  const currencyBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <Dropdown
        value={rowData.currency}
        options={currencyOptions}
        onChange={(e) => onCurrencyChange(e, rowIndex)}
        placeholder="Select" // Set placeholder here
      />
    );
  };

  const addRow = () => {
    const newRow = {
      productOrService: "",
      productName: "",
      productCategory: "",
      quantity: "",
      unit: "",
      value: "",
      currency: "",
      isAdded: true,
    };
    setData((prevData) => [...prevData, newRow]);
  };

  const deleteRow = (rowIndex) => {
    const updatedData = [...data];
    updatedData.splice(rowIndex, 1);
    setData(updatedData);
  };

  const deleteIconTemplate = (rowData, { rowIndex }) => {
    return (
      <Tooltip title="Delete" arrow>
        <img
          src="./assets/img/reports/delete.png"
          alt="Delete Icon"
          className="delete-icon actions-icon"
          onClick={() => deleteRow(rowIndex)}
        />
      </Tooltip>
    );
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#home">
                <img
                  src="assets/img/logo.png"
                  alt="Company Logo"
                  height="30"
                  className="d-inline-block align-top"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img class="icon mr-3" src="assets/img/User.png" alt="User" />
                      Mathew
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="container survey-form scf p-4 p-lg-5">
          <div className="row mb-4">
            <div className="col">
              <div className="form-header">
                <h1 className="title" style={{ color: "407775!important" }}>
                  Supplier Carbon Footprint​ Survey
                </h1>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="supplierName">
                      Supplier Name
                    </label>
                    <input
                      type="text"
                      id="supplierName"
                      value={formData.supplierName}
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="supplierLocation">
                      Supplier Location
                    </label>
                    <Dropdown
                      id="country"
                      name="country"
                      className={`form-control wd-100 ${errors.country ? "is-invalid" : ""}`}
                      value={formData.country}
                      options={cities}
                      onChange={(e) => setFormData({ ...formData, country: e.value })}
                      placeholder="Select country"
                      required
                    />
                    {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                  </div>
                </div>
              </div>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="surveyPeriod">
                      Survey Period
                    </label>
                    <input
                      type="text"
                      id="surveyPeriod"
                      value={formData.surveyPeriod}
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="survey-content">
                  <div className="data-responsive">
                    <DataTable
                      value={data}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[10, 20, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      filters={filters}
                      filterDisplay="row"
                      globalFilterFields={[
                        "productOrService",
                        "productName",
                        "productCategory",
                        "quantity",
                        "unit",
                        "value",
                        "currency",
                      ]}
                      header={header}
                      emptyMessage="No data found."
                    >
                      <Column
                        header=""
                        body={deleteIconTemplate}
                        style={{ width: "3rem", textAlign: "center", padding: "0 20px" }}
                      />
                      <Column
                        field="productOrService"
                        header="Product/Service"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={productOrServiceBodyTemplate}
                        style={{ width: "20%" }}
                      />
                      <Column
                        header="Product/Service Name"
                        field="productName"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) =>
                          textInputBodyTemplate(rowData, "productName", props)
                        }
                        style={{ width: "10%" }}
                      />
                      <Column
                        header="Product Category"
                        field="productCategory"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) =>
                          textInputBodyTemplate(rowData, "productCategory", props)
                        }
                        style={{ width: "15%" }}
                      />
                      <Column
                        header="Quantity"
                        field="quantity"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) => textInputBodyTemplate(rowData, "quantity", props)}
                        style={{ width: "5%" }}
                      />
                      <Column
                        header="Unit"
                        field="unit"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) => textInputBodyTemplate(rowData, "unit", props)}
                        style={{ width: "5%" }}
                      />
                      <Column
                        header="Value"
                        field="value"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) => textInputBodyTemplate(rowData, "value", props)}
                        style={{ width: "5%" }}
                      />
                      <Column
                        header="Currency"
                        field="currency"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={currencyBodyTemplate}
                        style={{ width: "30%" }}
                      />
                    </DataTable>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <Button
                        onClick={addRow}
                        className="next-button"
                        variant="contained"
                        color="primary"
                      >
                        Add Row
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="forms-buttons-container">
              {/* <Button
                className="outline-btn"
                variant="contained"
                color="primary"
                startIcon={
                  <ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />
                }
                onClick={() => navigate("/previous-page")}
                disabled
              >
                Back
              </Button> */}
              <Button
                className="next-button"
                variant="contained"
                color="primary"
                endIcon={
                  <ArrowForwardIosIcon sx={{ fontSize: "12px!important", marginLeft: "0" }} />
                }
                onClick={handleSave}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCFSurveyForm;
