import React from "react";
import Links from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useRef, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "react-select";
import AADHeader from "./components/AADHeader.js";

export default function Home() {
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [sitelocation, setSiteLocation] = useState("");
  const [message, setMessage] = useState("");
  const [roleList, setRoleList] = useState("");
  const [roles, setRoles] = useState("");
  const [allsitelocation, setAllSiteLocation] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  async function fetchData() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/roles");
      const data = await response.json();
      setRoleList(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchsitelocation() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitelocation",
        requestOptions
      );

      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setLoading(false);
          setAllSiteLocation();
        } else if (responseJson.status == 400) {
          setLoading(false);
          setAllSiteLocation();
        } else {
          setLoading(false);
          setAllSiteLocation(responseJson.data);
        }
      } else {
        setLoading(false);
        setAllSiteLocation();
      }
    } catch (error) {
      setAllSiteLocation([]);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchsitelocation();
  }, []);

  const generatePassword = (length = 12) => {
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*()_-+=<>?/{}[]";

    const allCharacters = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters.charAt(randomIndex);
    }

    return password;
  };

  let handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const now = new Date();
    let timestamp = now.toISOString();
    try {
      let rest = await fetch("https://ecoprismapi.azurewebsites.net/resgister-user", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          timestamp: timestamp,
          email: email,
          name: name,
          department: department,
          roles: [roles],
          permissions: [],
        }),
      });
      if (rest.status === 200) {
        const responseJson1 = await rest.json();
        if (responseJson1.success === false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "User already exist",
          });
        } else if (responseJson1.status === 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson1.title,
          });
        } else {
          let token = await fetch("https://ecoprismapi.azurewebsites.net/GetAuth0Token", {
            method: "POST",
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
              grant_type: "client_credentials",
              audience: "https://dev-bzvpxk64kwf4dxfs.us.auth0.com/api/v2/",
            }),
          });
          if (token.status === 200) {
            const responseToken = await token.json();
            const accessToken = responseToken.access_token;
            const newPassword = generatePassword();
            let accessRes = await fetch("https://ecoprismapi.azurewebsites.net/CreateUser", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                token: accessToken,
              },
              body: JSON.stringify({
                email: email,
                given_name: name,
                family_name: name,
                name: name,
                nickname: name,
                connection: "Username-Password-Authentication",
                password: newPassword,
              }),
            });
            if (accessRes.status === 200) {
              const accessresponseJson = await accessRes.json();
            }
            console.log(responseToken);
          }
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: "User created successfully",
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "SOmething went wrong",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      border: "1px solid #ebe9e9",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      textAlign: "left",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      textAlign: "left",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div Class="steps__containt-hader">
            <h3 class="title">Add New User</h3>
            <div role="presentation">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Links underline="hover" color="#707070" className="breadcrumbs-font">
                  Settings
                </Links>
                <Link
                  to="/listofusers"
                  underline="hover"
                  color="#707070"
                  className="breadcrumbs-font"
                >
                  User List
                </Link>
                <Link underline="hover" color="#707070" className="breadcrumbs-font">
                  Add New User
                </Link>
              </Breadcrumbs>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div className="message">{message ? <p>{message}</p> : null}</div>
              <form onSubmit={handleSubmit} class="form-eco">
                <div class="form-row">
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputemail">
                      Email Address<span className="mark-form">*</span>
                    </label>
                    <input
                      type="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Enter email address"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      title="Enter a valid email address"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputfullname">
                      Full Name<span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      required
                      onChange={(e) => {
                        // Check if the entered value contains only alphabets
                        const onlyAlphabets = /^[A-Za-z\s]+$/;
                        if (onlyAlphabets.test(e.target.value) || e.target.value === "") {
                          // If it's valid or empty, update the state
                          setName(e.target.value);
                        }
                      }}
                      className="form-control"
                      placeholder="Enter full name"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputdepartment">
                      Department<span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={department}
                      required
                      onChange={(e) => {
                        // Check if the entered value contains only alphabets
                        const onlyAlphabets = /^[A-Za-z\s]+$/;
                        if (onlyAlphabets.test(e.target.value) || e.target.value === "") {
                          // If it's valid or empty, update the state
                          setDepartment(e.target.value);
                        }
                      }}
                      class="form-control"
                      placeholder="Enter department"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputState">
                      Site location
                    </label>
                    <Select
                      id="inputState"
                      className=""
                      value={sitelocation ? { label: sitelocation, value: sitelocation } : null}
                      onChange={(selectedOption) => setSiteLocation(selectedOption.value)}
                      options={
                        allsitelocation &&
                        allsitelocation.map((status) => ({
                          label: status.name,
                          value: status.name,
                        }))
                      }
                      placeholder="Select Site Location"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputState">
                      Role <span className="mark-form">*</span>
                    </label>
                    <Select
                      id="inputState"
                      className=""
                      required
                      value={roles ? { label: roles, value: roles } : null}
                      onChange={(selectedOption) => setRoles(selectedOption.value)}
                      options={
                        roleList && roleList.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select Role"
                      styles={customStyles}
                      menuPosition="fixed"
                    />
                  </div>
                  <div class="form-group col-md-6"></div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    {/* <Checkbox
                 color="primary"
                 className="checkbox-user"
                  />
                <label class="formlable" for="inputState"><span className="mark-form">**Notify the new user about User Creation</span></label> */}
                  </div>
                  <div class="form-group col-md-6"></div>
                </div>
                <Button
                  type="submit"
                  className="next-button"
                  variant="contained"
                  size="medium"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#407775",
                    width: "120px",
                    height: "41px",
                    texttransform: "capitalize",
                    borderRadius: "10px",
                  }}
                  disabled={loading}
                >
                  Submit
                </Button>
                <Button
                  href="listofusers"
                  variant="outlined"
                  size="medium"
                  className="outline-btn"
                  sx={{
                    color: "#000",
                    marginLeft: "15px",
                    width: "120px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                >
                  Cancel
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
