import React from "react";
import { useNavigate } from 'react-router-dom';
import { authProvider } from './authProvider';
import { useEffect, useState } from "react";
// import Cookies from 'js-cookie';
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  

  
  useEffect(() => {
    const checkAuthState = async () => {
      const isAuthenticated = await authProvider.getAccount();

      if (isAuthenticated) {
        const timeout = setTimeout(() => {
          // Clear Azure AD cookies
          // Cookies.remove('.AspNet.Cookies', { path: '/', domain: window.location.hostname });
          // Cookies.remove('OpenIdConnect.nonce', { path: '/', domain: window.location.hostname });
  
          // Logout and redirect to login page after 15 seconds
          authProvider.logout();
          //navigate("/login", { replace: true });
        },5000);
      }
    };

    checkAuthState();
  }, [navigate]);

  const handleLogin = () => {
    console.log('in')
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePass = (e) => {
    setPassword(e.target.value)
  }

    
  const login = () => {
    navigate('/login')
  }    
 
  return (
    <div id="wrapper " className="access-bg">
         {/* ======= Header ======= */}
         <header id="header" className="fixed-top dark-bg ">
              <div className="container">
                {/* <h1 className="logo me-auto"><a href="index.html">Arsha</a></h1> */}
                {/* Uncomment below if you prefer to use an image logo */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <a href="/" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid logo-img"/></a>                
                  </div>
                  <div className='col-lg-6'>                    
                    {/* <a href="#login" className="btn-login" onClick={login}>Login/Signup</a>              */}
                  </div>
                </div>
              </div>
            </header>{/* End Header */}
            {/* ======= Hero Section ======= */}
     <div className="login__wrapper">
      {/* <LoginHeader heading=""/> */}
        <section className="login__section">
          <div className="row">
          <div class="center-screen">
        <div className="access-denied">
              <img className="denied-img" src="./assets/img/protection.png" alt=""/>
                <p className="access-txt">You are not authorized to access this website.</p>
                 <p className="access-txt-para">It looks like you do not have the necessary permissions to view this content. This may be because you are not associated with any company in our system.</p>
               </div>
              </div>
            </div>
        </section>
      </div>
    </div>
  
  );
}
function App() {
  return (
    <div className="App">
      <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      
    </div>
  );
}
