import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Backdrop, CircularProgress } from "@mui/material";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import AADHeader from "./components/AADHeader.js";
import { useNavigate,useLocation} from "react-router-dom";
import { Card } from "react-bootstrap";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsFullscreen from "highcharts/modules/full-screen";
import HighchartsMap from "highcharts/modules/map"; // Import the map module
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import highchartsMore from "highcharts/highcharts-more";

HighchartsMap(Highcharts);
highchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsFullscreen(Highcharts);
const ECDashboard = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState("");
  const location = useLocation(); // To get query params

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      from: params.get('from'), // For example: ?from=2024-08-01
      to: params.get('to'),     // For example: ?to=2024-08-31
    };
  };

  const { from, to } = getQueryParams(location.search);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    console.log('From:', from);
    console.log('To:', to);
    fetchDashboard();
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    
  }, []);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const pieChartOptions = {
    chart: {
        type: 'pie',
    },
    title: {
        text: 'Survey response',
        align: 'center',
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        enabled: false, // Disable tooltip to avoid additional text
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f}%', // Show only the percentage
                distance: 20, // Distance outside the pie chart
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333',
                },
                connectorShape: 'crookedLine', // Creates a line connecting the label to the pie slice
                crookDistance: '70%', // Controls the bend in the connector line (makes the line more like an arrow)
                connectorPadding: 5,
                connectorWidth: 1.5,
                connectorColor: '#333',
            },
            showInLegend: true,
            borderColor: null,
        },
    },
    series: [{
        name: 'Response',
        colorByPoint: true,
        data: [{
            name: 'Responded',
            y: 43,
            color: '#B4A34A',
        }, {
            name: 'Not responded',
            y: 57,
            color: '#FAB430',
        }],
    }],
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#333',
        },
        symbolRadius: 0, // Makes legend square instead of circle
        symbolHeight: 14,
        symbolWidth: 14,
    },
  };

  const transportModePreferences = dashboardData.transportModePreferences || {};
  const transportCategories = Object.keys(transportModePreferences); // ['Car']
  const transportData = Object.values(transportModePreferences); // [1]
  const transportModeOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Transport mode preferences'
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: transportCategories
    },
    series: [{
      name: 'Preferences',
      data: transportData,
      color: '#B4A34A'
    }],
    legend: {
      enabled: false // This will hide the legend
  }
  };


  const getCountryCode = (countryName) => {
    const mapFeatures = worldMap.features; // Features contain countries and their properties
    for (const feature of mapFeatures) {
      if (feature.properties.name === countryName) {
        return feature.properties['iso-a2'];  // Return the ISO-2 code if found
      }
    }
    return null; // Return null if the country code isn't found
  };
  // Extract countries and their response rates
  const countryResponseRates = dashboardData.countryResponseRates || {};

  // Transform countryResponseRates into the format needed for the Highcharts map
  const countryData = Object.keys(countryResponseRates).map(country => {
    const countryCode = getCountryCode(country); // Get the country code dynamically
    return {
      code: countryCode,  // Use the dynamically found ISO code
      value: countryResponseRates[country],  // Use the response rate as value
      color: '#FFC561',  // Set a default color
    };
  }).filter(country => country.code !== null); // Filter out countries without a valid ISO code
  const countryResponseRateOptions = {
    chart: {
      map: "custom/world",
    },
    credits: {
        enabled: false,
    },
    title: {
        text: 'Country-level response rate',
    },
    mapNavigation: {
        enabled: true,
        enableButtons: false,
    },
    series: [{
        name: 'Countries',
        mapData: worldMap, // Correctly set the map data for the series
        data: countryData,
        joinBy: ['iso-a2', 'code'], // Ensure data joins correctly with the map
        states: {
            hover: {
                color: '#BADA55'
            }
        },
        dataLabels: {
            enabled: true,
            format: '{point.name}',
        },
        colorAxis: {
            min: 0,
            minColor: '#EFEFFF',
            maxColor: '#102D44'
        },
    }]
  };

  const siteResponse = dashboardData.siteResponseRates || {};
  const siteResponseCategories = Object.keys(siteResponse); // ['Car']
  const siteResponseData = Object.values(siteResponse); // [1]
  const siteResponseRateOptions = {
    chart: {
      type: 'bar',
    },
    credits: {
      enabled: false,
   },
    title: {
      text: 'Site-level response rate'
    },
    xAxis: {
      categories: siteResponseCategories
    },
    series: [{
      name: 'Response Rate',
      data: siteResponseData,
      color: '#9AC7BF'
    }],
    legend: {
      enabled: false 
  }
  };

  async function fetchDashboard() {
    try {
      
      let response = await fetch("https://ecoprismapi.azurewebsites.net/employee-dashboard", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          companyid:companyid,
          from:from,
          to:to,
        },
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
        } else if (responseJson.status == 400) {
          
        } else {
          console.log(responseJson);
          setDashboardData(responseJson.data);
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="row mb-4">
            <div className="col-md-8">
              <div className="d-flex flex-column steps__containt-hader">
                <h1 className="title">Employee Commute</h1>
              </div>
            </div>
          </div>

          {/* Tiles Section */}
          <div className="row ec-dashboard">
            <div className="col-md-4 mb-4">
              <Card className="ec-card">
                <Card.Body>
                  <div className="d-flex">
                    <img
                      src="./assets/img/formsapps/total-recipients.svg"
                      alt="Icon"
                      className="mr-3"
                    />
                    <div>
                      <Card.Title>Total recipients</Card.Title>
                      <Card.Text>{dashboardData.totalRecipients}</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="ec-card">
                <Card.Body>
                  <div className="d-flex">
                    <img
                      src="./assets/img/formsapps/response-rate.svg"
                      alt="Icon"
                      className="mr-3"
                    />
                    <div>
                      <Card.Title>Response rate</Card.Title>
                      <Card.Text>{dashboardData.responseRate}%</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-4">
              <Card className="ec-card">
                <Card.Body>
                  <div className="d-flex">
                    <img
                      src="./assets/img/formsapps/survey-conducted.svg"
                      alt="Icon"
                      className="mr-3"
                    />
                    <div>
                      <Card.Title>Survey Conducted</Card.Title>
                      <Card.Text>{dashboardData.totalRecipients}</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          {/* Charts Section */}
          <div className="row ec-dashboard">
            <div className="col-md-6 mb-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={pieChartOptions}
              />
            </div>
            <div className="col-md-6 mb-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={transportModeOptions}
              />
            </div>
            <div className="col-md-6 mb-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={countryResponseRateOptions}
              />
            </div>
            <div className="col-md-6 mb-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={siteResponseRateOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECDashboard;
