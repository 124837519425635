import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    padding: 10,
  },
  pageContainer: {
    flex: 1,
    position: 'relative',
  },
  pageContainer1: {
    flex: 1,
    position: 'absolute',
    zIndex:2
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  logo: {
    width: '100%',
    height: 'auto',
    maxWidth: 150, // Set maximum width
    maxHeight: 100, // Set maximum height
    resizeMode: 'contain', // Ensures the image scales properly within the bounds
    marginBottom: 20,
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noBreak: {
    pageBreakInside: 'avoid',
  },
  section: {
    margin: 5,
    padding: 5,
  },
  margintop: {
    marginTop: 10,
    fontSize: '14px',
  },
  view: {
    fontSize: '13px',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    color: 'grey',
  },
  color: {
    color: '#2494cc',
    fontWeight: 500,
  },
  center: {
    textAlign: 'center',
  },
  text: {
    fontSize: '12px',
    lineHeight: '1.4px',
  },
  indexEntry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #9BD0EA',
    paddingVertical: 4,
  },
  indexTitle: {
    fontSize: 12,
    color: 'black',
  },
  indexPageNumber: {
    fontSize: 12,
    color: 'black',
    marginTop: 20,
  },
  content: {
    marginBottom: 50,
    // Adjust the value as needed to ensure the footer does not overlap
  },
  tableContainer: {
    border: '1px solid #000',
    marginVertical: 10,
    fontSize: '10px',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottom: '1px solid #000',
    backgroundColor: '#f0f0f0',
    fontSize: '10px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #000',
    fontSize: '10px',
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
    borderRight: '1px solid #000',
    padding: 4,
    fontSize: '10px',
  },
  tableCellLast: {
    flex: 1,
    textAlign: 'center',
    padding: 4,
    fontSize: '10px',
  },
  backgroundImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1, // Ensure background is behind content
  },
  
});

// Function to group data by title
const groupByTitle = (data) => {
  return data.reduce((acc, current) => {
    const { title, question, answer, module } = current;
    if (!acc[title]) {
      acc[title] = { title, module, questions: [] };
    }
    acc[title].questions.push({ question, answer });
    return acc;
  }, {});
};

// Function to calculate the number of pages
const calculatePageNumbers = (groupedData) => {
  let currentPage = 2; // Start from 2 because the cover page is page 1
  const indexEntries = [];

  const addModuleToIndex = (module, moduleTitle) => {
    let moduleContent = Object.values(groupedData).filter(group => group.module === module);
    if (moduleContent.length > 0) {
      indexEntries.push({ title: moduleTitle, page: currentPage });
    }
    currentPage += Math.ceil(moduleContent.length / 2); // Assuming 2 groups per page
  };

  addModuleToIndex('General', 'General');
  addModuleToIndex('Environment', 'Environment');
  addModuleToIndex('Social', 'Social');
  addModuleToIndex('Governance', 'Governance');

  return indexEntries;
};

// Function to convert HTML table to image data URL
const convertHtmlTableToImage = async (htmlString) => {
  const container = document.createElement('div');
  container.innerHTML = htmlString;
  document.body.appendChild(container);

  // Apply CSS styles to the table and header
  const table = container.querySelector('table');
  if (table) {
    table.style.borderCollapse = 'collapse';
    table.style.width = '100%';
    table.style.fontSize = '20px';

    const headers = table.querySelectorAll('th');
    headers.forEach((header) => {
      header.style.backgroundColor = '#f0f0f0'; // Change to desired header background color
      header.style.border = '1px solid #000';   // Change to desired border color
      header.style.padding = '4px';
      header.style.fontSize = '20px';
    });

    const cells = table.querySelectorAll('td');
    cells.forEach((cell) => {
      cell.style.border = '1px solid #000'; // Change to desired border color
      cell.style.padding = '4px';
      cell.style.fontSize = '20px';
    });
  }

  const canvas = await html2canvas(container, { useCORS: true });
  const imgData = canvas.toDataURL('image/png');

  document.body.removeChild(container); // Clean up the temporary container
  return imgData;
};

// Create Document Component
const MyDocument = ({ reportData, reportTitle,companyLogo }) => {
  console.log(reportTitle);
  console.log(companyLogo);
  const renderFirstPage = () => (
    <Page size="A4" style={styles.page}>
     <View style={[styles.pageContainer, styles.center]}> {/* Combine styles for centering */}
     <Image src="assets/img/background.png" style={styles.backgroundImage} />
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    {companyLogo && <Image src={companyLogo} style={styles.logo} />} {/* Conditionally render logo */}
    <Text style={styles.heading}>{reportTitle}</Text>
  </View>
</View>
      <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} fixed />
    </Page>
  );

  const [processedData, setProcessedData] = useState(null);

  useEffect(() => {
    const preprocessData = async () => {
      const groupedData = groupByTitle(reportData);

      for (const group of Object.values(groupedData)) {
        for (const question of group.questions) {
          if (question.answer.includes('<table>')) {
            question.answer = await convertHtmlTableToImage(question.answer);
          }
        }
      }

      setProcessedData(groupedData);
    };

    preprocessData();
  }, [reportData]);

  if (!processedData) {
    return null; // Or a loading spinner, etc.
  }

  const indexEntries = calculatePageNumbers(processedData);
  let currentPage = 2; // Start from 2 because the cover page is page 1

  const renderModuleSection = (module, moduleTitle) => {
    let moduleContent = Object.values(processedData)
      .filter(group => group.module === module)
      .map((group, index) => {
        currentPage++;
        return (
          <View key={index} style={styles.section}>
            {index === 0 && (
              <View style={[styles.section, styles.color, styles.center]}>
                <Text>{moduleTitle}</Text>
              </View>
            )}
            <View style={[styles.section, styles.margintop, styles.color]}>
              <Text>{group.title}</Text>
            </View>
            {group.questions.map((q, idx) => (
              <View style={styles.section} key={idx}>
                {q.answer.startsWith('data:image') ? (
                  <Image src={q.answer} />
                ) : (
                  <Text style={styles.text}>{q.answer}</Text>
                )}
              </View>
            ))}
          </View>
        );
      });

    return moduleContent;
  };

  const renderIndexPage = () => (
    <Page size="A4" style={styles.page}>
      <Text style={styles.header} fixed>FY2023-ESRS</Text>
      <View style={[styles.section, styles.color, styles.center]}>
        <Text>Index</Text>
      </View>
      {indexEntries.map((entry, index) => (
        <View key={index} style={styles.indexEntry}>
          <Text style={styles.indexTitle}>{entry.title}</Text>
          <Link src={`#page=${entry.page}`} style={styles.indexPageNumber}>{entry.page}</Link>
        </View>
      ))}
      <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} fixed />
    </Page>
  );

  return (
    <Document title={reportTitle} options={{ toolbar: false, pdfOpenParams: { view: 'FitV' } }}>
      {renderFirstPage()}
      {renderIndexPage()}
      <Page size="A4" style={styles.page}>
        {renderModuleSection('General', 'General')}
        {renderModuleSection('Environment', 'Environment')}
        {renderModuleSection('Social', 'Social')}
        {renderModuleSection('Governance', 'Governance')}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export { MyDocument };
