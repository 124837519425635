import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppLandingPage from "./App";
import Dashboard from "./Dashboard";
import Login from "./login";
import Company from "./company";
import BusinessUnit from "./Businessunit";
import Sitelocation from "./sitelocation";
import Listofentities from "./listofentities";
import Listofbusinessunits from "./listofbusinessunits";
import ListSiteLocations from "./listsitelocations";
import Maincompany from "./maincompany";
import AddUser from "./adduser";
import Listofusers from "./listofusers";
import AllConfiguration from "./allconfiguration";
import ReportHistory from "./reporthistory";
import Report from "./report";
import Configration from "./configuration";
import Home from "./Home";
import ChatPage from "./ChatPage";
import Accessdenied from "./accessdenied";
import Comingsoon from "./comingsoon";
import Insights from "./insights";
import Assessinglowre from "./assessing-low-re";
import Electricitycostevaluation from "./electricity-cost-evaluation";
import Assessingelecusage from "./assessing-elec-usage";
import Reporting from "./reporting";
import GenerateReport from "./GenerateReport";
import DisplayLabels from "./displayLabels";
import Csrd1 from "./csrd1";
import ReportTable from "./ReportTable";
import Table from "./Table";
import FormApp from "./Form";
import Internal from "./internal";
import Standards from "./standards";
import Csrd from "./csrd";
import ReportDashboard from "./ReportDashboard";
import StandardsFrameworks from "./StandardsFrameworks";
import Carousel from "./demo";
import Social from "./social";
import Water from "./water";
import Demotable from "./demotable";
import Demonew from "./demonew";
import Scope2 from "./scope2";
import Scope1 from "./scope1";
import Newconfig from "./newconfig";
import Newallconfig from "./newallconfig";
import EditConfiguration from "./editConfiguration";
import PreviewReport from "./PreviewReport";
import Materiality from "./Materiality";
import ReportingModules from "./ReportingModules";
import FormsApps from "./FormsApps";
import EmployeeCommuteSurvey from "./EmployeeCommuteSurvey";
import StartNewSurvey from "./StartNewSurvey";
import ECDashboard from "./ECDashboard";
import ECSurveyForm from "./ECSurveyForm";
import SCFData from "./SCFData";
import SCFSurvey from "./SCFSurvey";
import SCFSurveyForm from "./SCFSurveyForm";
import SCFEnergyEmissions from "./SCFEnergyEmissions";
import SCFScope from "./SCFScope";
import Repository from "./Repository";
import RepositoryAddLink from "./RepositoryAddLink";
import EditCSRD from "./editCsrd";
import EditMateriality from "./editMateriality";
import EditReportingModule from "./editReportingModule";
import EditGenerateReport from "./editGenerateReport";
import EditPreviewReport from "./editPreviewReport";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLandingPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/allconfiguration",
    element: <AllConfiguration />,
  },
  {
    path: "/configuration",
    element: <Configration />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/reporthistory",
    element: <ReportHistory />,
  },
  {
    path: "/Businessunit",
    element: <BusinessUnit />,
  },
  {
    path: "/sitelocation",
    element: <Sitelocation />,
  },
  {
    path: "/listofentities",
    element: <Listofentities />,
  },
  {
    path: "/listsitelocations",
    element: <ListSiteLocations />,
  },
  {
    path: "/maincompany",
    element: <Maincompany />,
  },
  {
    path: "/adduser",
    element: <AddUser />,
  },
  {
    path: "/listofusers",
    element: <Listofusers />,
  },
  {
    path: "/listofbusinessunits",
    element: <Listofbusinessunits />,
  },
  {
    path: "/accessdenied",
    element: <Accessdenied />,
  },
  {
    path: "/comingsoon",
    element: <Comingsoon />,
  },
  {
    path: "/forms-apps",
    element: <FormsApps />,
  },
  {
    path: "/employee-commute-data",
    element: <EmployeeCommuteSurvey />,
  },
  {
    path: "/start-new-survey",
    element: <StartNewSurvey />,
  },
  {
    path: "employee-commute",
    element: <ECDashboard />,
  },
  {
    path: "employee-commute-survey",
    element: <ECSurveyForm />,
  },
  {
    path: "supplier-carbon-footprint",
    element: <SCFData />,
  },
  {
    path: "supplier-carbon-footprint-survey",
    element: <SCFSurvey />,
  },
  {
    path: "supplier-carbon-footprint-survey-form",
    element: <SCFSurveyForm />,
  },
  {
    path: "energy-emissions",
    element: <SCFEnergyEmissions />,
  },

  {
    path: "energy-emissions-scope",
    element: <SCFScope />,
  },
  {
    path: "repository",
    element: <Repository />,
  },
  {
    path: "add-more",
    element: <RepositoryAddLink />,
  },
  {
    path: "/insights",
    element: <Insights />,
  },
  {
    path: "/assessing-low-re",
    element: <Assessinglowre />,
  },
  {
    path: "/electricity-cost-evaluation",
    element: <Electricitycostevaluation />,
  },
  {
    path: "/assessing-elec-usage",
    element: <Assessingelecusage />,
  },
  {
    path: "/reporting",
    element: <Reporting />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/reporting",
    element: <Reporting />,
  },
  {
    path: "/internal",
    element: <Internal />,
  },
  {
    path: "/standards",
    element: <Standards />,
  },
  {
    path: "/csrd",
    element: <Csrd />,
  },
  {
    path: "/GenerateReport",
    element: <GenerateReport />,
  },
  {
    path: "/displayLabels",
    element: <DisplayLabels />,
  },
  {
    path: "/csrd1",
    element: <Csrd1 />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/ReportingModules",
    element: <ReportingModules />,
  },

  {
    path: "/StandardsFrameworks",
    element: <StandardsFrameworks />,
  },
  {
    path: "/ReportTable",
    element: <ReportTable />,
  },
  {
    path: "/Materiality",
    element: <Materiality />,
  },
  {
    path: "/PreviewReport",
    element: <PreviewReport />,
  },
  {
    path: "/Form",
    element: <FormApp />,
  },
  {
    path: "/Table",
    element: <Table />,
  },
  {
    path: "/demo",
    element: <Carousel />,
  },
  {
    path: "/reportDashboard",
    element: <ReportDashboard />,
  },
  {
    path: "/ChatPage",
    element: <ChatPage />,
  },
  {
    path: "/social",
    element: <Social />,
  },
  {
    path: "/demotable",
    element: <Demotable />,
  },
  {
    path: "/demonew",
    element: <Demonew />,
  },
  {
    path: "/scope2",
    element: <Scope2 />,
  },
  {
    path: "/scope1",
    element: <Scope1 />,
  },
  {
    path: "/editConfiguration",
    element: <EditConfiguration />,
  },
  {
    path: "/newconfig",
    element: <Newconfig />,
  },
  {
    path: "/newallconfig",
    element: <Newallconfig />,
  },
  {
    path: "/editcsrd",
    element: <EditCSRD />,
  },
  {
    path: "/editmateriality",
    element: <EditMateriality />,
  },
  {
    path: "/editreportingmodule",
    element: <EditReportingModule />,
  },
  {
    path: "/editgeneratereport",
    element: <EditGenerateReport />,
  },
  {
    path: "/editpreviewreport",
    element: <EditPreviewReport />,
  },
  {
    path: "/water",
    element: <Water />,
  },
]);

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  return <RouterProvider router={router} />;
}
