import React from "react";
import clsx from "clsx";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appInsights } from "./AppInsights";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Label } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { LineChart, Line, Legend } from "recharts";
import WorldMap from "react-svg-worldmap";
import AADHeader from "./components/AADHeader.js";
import { MultiSelect } from "primereact/multiselect";

export default function Home() {
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [responseConfig, setResponseConfig] = useState({});
  const [legal, setLegal] = useState([]);
  const [business, setBusiness] = useState([]);
  const [site, setSite] = useState([]);
  const [year, setYear] = useState([]);
  const [country, setCountry] = useState([]);
  const [employee, setEmployee] = useState("");
  const [social, setSocial] = useState("");
  const [yearr, setYearR] = useState("");
  const [countries, setCountries] = useState();
  const [businessunit, setBusinessUnit] = useState();
  const [activesite, setActiveSite] = useState();
  const [activeetities, setActiveEntities] = useState();
  const [activeyears, setActiveYears] = useState();
  const [job, setJob] = useState([]);
  const [jobs, setJobs] = useState();

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const handleChatClick = () => {
    navigate("/ChatPage");
  };
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function fetchFilters() {
    
    try {
      const country = JSON.parse(localStorage.getItem('scountry'));
      const year = JSON.parse(localStorage.getItem('syear'));
      const legal = JSON.parse(localStorage.getItem('slegal'));
      const business = JSON.parse(localStorage.getItem('sbusiness'));
      const site = JSON.parse(localStorage.getItem('ssite'));
      let response = await fetch("https://ecoprismapi.azurewebsites.net/social-filters", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          companyId: companyid,
          year: year,
          legalEntity: legal,
          businessUnit: business,
          country: country,
          site: site,
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
          
        } else if (responseJson.status == 400) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
        } else {
          console.log(responseJson);
          setActiveEntities(responseJson.legalEntity);
          setActiveYears(responseJson.year);
          setBusinessUnit(responseJson.businessUnit);
          setCountries(responseJson.country);
          setActiveSite(responseJson.site);
          setJobs(responseJson.jobLevel);
        }
      } else {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  
  

  
 
  async function fetchSocial() {
    let country = JSON.parse(localStorage.getItem('scountry'));
    if (!country || country.length === 0) {
      country = [];
    }
    let year = JSON.parse(localStorage.getItem('syear'));
    if (!year || year.length === 0) {
      year = [];
    }
    let legal = JSON.parse(localStorage.getItem('slegal'));
    if (!legal || legal.length === 0) {
      legal = [];
    }
    let business = JSON.parse(localStorage.getItem('sbusiness'));
    if (!business || business.length === 0) {
      business = [];
    }
    let site = JSON.parse(localStorage.getItem('ssite'));
    if (!site || site.length === 0) {
      site = [];
    }
    let sjob = JSON.parse(localStorage.getItem('sjob'));
    if (!sjob || sjob.length === 0) {
      sjob = [];
    }
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("username", username);
      myHeaders.append("companyid", companyid);
      myHeaders.append("country", encodeURIComponent(country));
      myHeaders.append("site", site);
      myHeaders.append("year", year);
      myHeaders.append("legal", legal);
      myHeaders.append("business", business);
      myHeaders.append("employee", sjob);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      console.log(requestOptions);
      console.log(country);
      const response = await fetch("https://ecoprismapi.azurewebsites.net/social", requestOptions);
      const responseJson = await response.json();
      if (responseJson.success === false || responseJson.status === 400) {
      } else {
        setSocial(responseJson.data.value);
        //console.log(responseJson.data.value);
        const parts = responseJson.data.value.genderDistribution.split("; ");
        const result = parts.map((part) => part.split(":").pop()).join(":");
        setYearR(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchSocial();
    fetchFilters();
    let country = JSON.parse(localStorage.getItem('scountry'));
    if (!country || country.length === 0) {
      country = [];
    }
    else{
      console.log(country);
      country = setCountry(country);
    }

    let year = JSON.parse(localStorage.getItem('syear'));
    if (!year || year.length === 0) {
      year = [];
    }
    else{
      year = setYear(year);
    }

    let legal = JSON.parse(localStorage.getItem('slegal'));
    if (!legal || legal.length === 0) {
      legal = [];
    }
    else{
      legal = setLegal(legal);
    }

    let business = JSON.parse(localStorage.getItem('sbusiness'));
    if (!business || business.length === 0) {
      business = [];
    }
    else{
      business = setBusiness(business);
    }

    let site = JSON.parse(localStorage.getItem('ssite'));
    if (!site || site.length === 0) {
      site = [];
    }
    else{
      site = setSite(site);
    }

    let sjob = JSON.parse(localStorage.getItem('sjob'));
    if (!sjob || sjob.length === 0) {
      sjob = [];
    }
    else{
      sjob = setJob(sjob);
    }

    
  }, []);

  const data_age = [
    { name: "<30", value: social?.employeeAgeDistribution?.below30 || 0 },
    { name: "30-50", value: social?.employeeAgeDistribution?.between30And50 || 0 },
    { name: ">50", value: social?.employeeAgeDistribution?.above50 || 0 },
  ];

  // Filter out data with value 0
  const filteredData = data_age.filter((entry) => entry.value !== 0);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const value = filteredData[index].value;
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </text>
    );
  };
  const CustomLegend_Pie = () => (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
      {filteredData.map((entry, index) => (
        <div
          key={entry.name}
          style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
        >
          <span style={{ width: "12px", height: "12px", backgroundColor: COLORS[index] }}></span>
          {entry.name}
        </div>
      ))}
    </div>
  );

  const dataLineChart_Employeeturnover =
    social &&
    social.quarterlyEmployeeTurnAroundTrend.map((item) => ({
      name: item.quarter,
      uv: item.turnover,
    }));
  console.log(dataLineChart_Employeeturnover);
  const dataLineChart_Fatalities =
    social &&
    social.annualFatalitiesTrend.map((item) => ({ name: item.year, uv: item.fatalities }));

  // Code for Bar Chart Employees by employment type and gender Start
  const dataBarChart_Stack =
    social &&
    social.employeesByEmploymentTypeAndGender.map((item) => ({
      category: item.employmentType,
      Male: item.maleCount,
      Female: item.femaleCount,
      Others: item.othersCount,
      NotReported: 0,
    }));
  const keys = ["Male", "Female", "Others", "NotReported"]; // List of genders
  const COLORS_Stack = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]; // Colors for different genders
  // Code for Bar Chart Employees by employment type and gender End

  // Code for Bar Chart Employees by region and employment type Start
  const dataBarChart_Stack_region =
    social &&
    social.employeesByRegionAndEmploymentType.map((item) => ({
      category: item.Region,
      Nonguaranteedhr: item["non-guaranteed-hours"],
      Fulltime: item["full-time"],
      Parttimeemployees: item["part-time"],
      Onsite: item["on-site"],
    }));
  const keys_region = [
    "Nonguaranteedhrs",
    "Fulltime",
    "Parttime",
    "Onsite",
  ];
  const COLORS_Stack_region = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#407775"];
  // Code for Bar Chart Employees by region and employment type End
  const maxValue_employeeturnover = Math.max(
    ...(social && social?.quarterlyEmployeeTurnAroundTrend?.map((item) => item.turnover))
  );
  console.log(maxValue_employeeturnover);
  const yAxisDomain_employeeturnover = [0, Math.ceil(maxValue_employeeturnover / 5) * 5];
  const maxValue = Math.max(
    ...(social && social.annualFatalitiesTrend.map((item) => item.fatalities))
  );
  const yAxisDomain = [0, Math.ceil(maxValue / 5) * 5];
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`${label}`}</p>
          <ul>
            {payload.map((point, index) => (
              <li key={index} style={{ color: point.color }}>
                {`${point.name}: ${point.value} (${point.payload.uv}% )`}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const data_map =
    social &&
    social.employeesByCountry.map((item) => ({ country: item.country, value: item.employeeCount }));

  const handleLegalChange = (selectedLegal) => {
    setYear(selectedLegal);
    localStorage.setItem('slegal', JSON.stringify(selectedLegal));
    fetchSocial();
    fetchFilters();
  };
  
  const handleBusinessChange = (selectedBusiness) => {
    setBusiness(selectedBusiness);
    localStorage.setItem('sbusiness', JSON.stringify(selectedBusiness));
    fetchSocial();
    fetchFilters();
  };

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    localStorage.setItem('scountry', JSON.stringify(selectedCountry));
    fetchSocial();
    fetchFilters();
  };
  
  const handleSiteChange = (selectedSite) => {
    setSite(selectedSite);
    localStorage.setItem('ssite', JSON.stringify(selectedSite));
    fetchSocial();
    fetchFilters();
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    localStorage.setItem('syear', JSON.stringify(selectedYear));
    fetchSocial();
    fetchFilters();
  };

  

  const handleJobChange = (selectedLegal) => {
    setJob(selectedLegal);
    localStorage.setItem('sjob', JSON.stringify(selectedLegal));
    fetchSocial();
    fetchFilters();
  };

  const activeyear = activeyears && activeyears.map((status) => status);
  const activelegal = activeetities && activeetities.map((status) => status);
  const activebusiness = businessunit && businessunit.map((status) => status);
  const activesites = activesite && activesite.map((status) => status);
  const activecountry = countries && countries.map((status) => status);
  const activejob = jobs && jobs.map((status) => status);
  
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        {/* Rest of your existing JSX content */}
        <div className="row mt-25 pd-lr-50">
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Legal entity{" "}
            </label>
            <MultiSelect
              value={legal}
              onChange={(e) => {
                setLegal(e.target.value);
                handleLegalChange(e.target.value);
              }}
              options={activelegal}
              placeholder="Select Legal"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
            />
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Business unit{" "}
            </label>
            <MultiSelect
              value={business}
              onChange={(e) => {
                setBusiness(e.target.value);
                handleBusinessChange(e.target.value);
              }}
              options={activebusiness}
              placeholder="Select Country"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
            />
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Country
            </label>
            <MultiSelect
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
                handleCountryChange(e.target.value);
              }}
              options={activecountry}
              placeholder="Select Country"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
            />
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Site location
            </label>
            <MultiSelect
              value={site}
              onChange={(e) => handleSiteChange(e.target.value)}
              options={activesites}
              placeholder="Select Site"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
              style={{ "--placeholder-font-size": "16px" }}
            />
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Year
            </label>
            <MultiSelect
              value={year}
              onChange={(e) => handleYearChange(e.target.value)}
              options={activeyear}
              placeholder="Select Year"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
              style={{ "--placeholder-font-size": "16px" }}
            />
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Emp category{" "}
            </label>
            <MultiSelect
              value={job}
              onChange={(e) => handleJobChange(e.target.value)}
              options={activejob}
              placeholder="Select Emp Category"
              maxSelectedLabels={1}
              className="wd-85"
              showClear={true} // Add this line to show the clear icon
              showSelectAll={true} // Show the "Select All" checkbox
              selectAllLabel="Select All" // Label for the "Select All" checkbox
              style={{ "--placeholder-font-size": "16px" }}
            />
            
          </div>
        </div>

        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">{social.totalEmployees}</div>
              <div className="employee-box-header">Total employees</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">{yearr}</div>
              <div className="employee-box-header">Gender distribution (Male:Female:Other)</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">{social.employeeTurnoverRate}%</div>
              <div className="employee-box-header">Employee turnover rate</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">
                {social.employeesWithDisabilitiesPercentage}%
              </div>
              <div className="employee-box-header">Employees with disabilities</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">
                {social.employeesEntitledToFamilyLeavesPercentage}%
              </div>
              <div className="employee-box-header">Employees entitled to family related leaves</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="employee-box">
              <div className="employee-box-content">{social.averageTrainingHoursPerEmployee}</div>
              <div className="employee-box-header">Average hrs of skill training per employee</div>
            </div>
          </div>
        </div>
        <div className="row pd-lr-50">
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>Employee by countries</h5>
              {social ? (
                <WorldMap
                  color="green"
                  title=""
                  value-suffix="employees"
                  size="md"
                  data={data_map}
                  width={600} // Set the desired width
                  height={500} // Set the desired height
                />
              ) : (
                <p></p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>Employee age distribution</h5>
              <ResponsiveContainer width="100%" height={300}>
                {social ? (
                  <PieChart width={400} height={400}>
                    <Pie
                      data={filteredData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {filteredData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend content={<CustomLegend_Pie />} align="center" verticalAlign="bottom" />
                  </PieChart>
                ) : (
                  <p></p>
                )}
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-6">
            <div className="charts">
              <div class="table-responsive-social">
                <table class="custom-table-social">
                  <tr>
                    <th>Employees Wages</th>
                  </tr>
                  <tr>
                    <td>Male-female pay gap</td>
                    <td>{social?.maleFemalePayGap || 0}%</td>
                  </tr>
                  <tr>
                    <td>Annual compensation ratio</td>
                    <td>{social?.annualCompensationRatio || 0}%</td>
                  </tr>
                  <tr>
                    <td>Employees with inadequate wages</td>
                    <td>{social?.employeesWithInadequateWages}%</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="charts">
              <div class="table-responsive-social">
                <table class="custom-table-social">
                  <tr>
                    <th>Health and safety </th>
                  </tr>
                  <tr>
                    <td>Work related fatalities</td>
                    <td>{social?.workRelatedFacilities || 0}</td>
                  </tr>
                  <tr>
                    <td>Work related accidents</td>
                    <td>{social?.workRelatedAccidents || 0}</td>
                  </tr>
                  <tr>
                    <td>
                      No. of work days lost to work related ill-health, accidents and fatalities
                    </td>
                    <td>{social?.numberOfWorkDaysLost || 0}</td>
                  </tr>
                  <tr>
                    <td>Severe human rights incidents</td>
                    <td>{social?.severeHumanRightIncidents || 0}</td>
                  </tr>
                  <tr>
                    <td>Harassment incidents</td>
                    <td>{social?.workRelatedFacilities || 0}</td>
                  </tr>
                  <tr>
                    <td>Total fines paid for violating social and human rights</td>
                    <td>EUR {social?.totalFinesPaidForViolatingHumanAndSocialRights || 0}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>Employee age distribution</h5>
              <ResponsiveContainer width="100%" height={400}>
                {social ? (
                  <LineChart
                    width={500}
                    height={400}
                    data={dataLineChart_Employeeturnover}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                    <YAxis
                      ticks={[...Array(Math.ceil(maxValue_employeeturnover / 5) + 1).keys()].map(
                        (tick) => tick * 5
                      )}
                      domain={yAxisDomain_employeeturnover}
                      tickFormatter={(value) => `${value}%`}
                      label={{
                        value: "Employee turnover",
                        angle: -90,
                        position: "insideLeft",
                        offset: -5,
                        fontSize: 12,
                      }}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip />

                    {/* <Line type="linear" dataKey="uv" stroke="#407775" dot={false} /> */}
                    <Line
                      type="linear"
                      dataKey="uv"
                      stroke="#407775"
                      dot={false}
                      label={{
                        position: "top",
                        content: ({ x, y, value }) => (
                          <text
                            x={x}
                            y={y}
                            dy={-5}
                            fill="#407775"
                            fontSize={10}
                            textAnchor="middle"
                          >
                            {`${value}%`}
                          </text>
                        ),
                      }}
                    />
                  </LineChart>
                ) : (
                  <p></p>
                )}
              </ResponsiveContainer>
            </div>
          </div>

          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>Employee age distribution</h5>
              <ResponsiveContainer width="100%" height={400}>
                {social ? (
                  <LineChart
                    width={500}
                    height={400}
                    data={dataLineChart_Fatalities}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                    <YAxis
                      ticks={[...Array(Math.ceil(maxValue / 5) + 1).keys()].map((tick) => tick * 5)}
                      domain={yAxisDomain}
                      label={{
                        value: "Fatalities",
                        angle: -90,
                        position: "insideLeft",
                        offset: -5,
                        fontSize: 12,
                      }}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip />

                    <Line type="linear" dataKey="uv" stroke="#407775" dot={false} />
                    <Line
                      type="linear"
                      dataKey="uv"
                      stroke="#407775"
                      dot={false}
                      label={{
                        position: "top",
                        content: ({ x, y, value }) => (
                          <text
                            x={x}
                            y={y}
                            dy={-5}
                            fill="#407775"
                            fontSize={10}
                            textAnchor="middle"
                          >
                            {value}
                          </text>
                        ),
                      }}
                    />
                  </LineChart>
                ) : (
                  <p></p>
                )}
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>
                Employees by employment type and gender
              </h5>
              <div style={{ marginBottom: "20px" }}>
                <ResponsiveContainer width="100%" height={400}>
                  {social ? (
                    <BarChart
                      width={500}
                      height={400}
                      data={dataBarChart_Stack}
                      margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="category"
                        angle={-20}
                        textAnchor="end"
                        interval={0}
                        tick={{ fontSize: 10 }} // Adjust the font size for the X-axis labels
                      />
                      <YAxis
                        label={{
                          value: "Employees",
                          angle: -90,
                          position: "insideLeft",
                          offset: -10,
                          fontSize: 12, // Adjust the font size for the Y-axis label
                        }}
                      />
                      <Tooltip />

                      {keys.map((key, index) => (
                        <Bar
                          key={key}
                          dataKey={key}
                          stackId="gender"
                          fill={COLORS_Stack[index]}
                          barSize={30}
                        />
                        // Adjust 'barSize' prop to set the width of bars
                      ))}
                      <Legend
                        layout="horizontal" // Display legend items horizontally
                        align="center" // Align the legend to the center
                        verticalAlign="top" // Position the legend above the bars
                        wrapperStyle={{ fontSize: 12, marginBottom: "10px" }}
                      />
                    </BarChart>
                  ) : (
                    <p></p>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "15px" }}>
                Employees by region and employment type
              </h5>

              <div style={{ marginBottom: "20px" }}>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    width={500}
                    height={400}
                    data={dataBarChart_Stack_region}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }} // Adjust bottom margin to accommodate rotated labels
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="category"
                      angle={-20}
                      textAnchor="end"
                      interval={0}
                      tick={{ fontSize: 10 }} // Adjust the font size for the X-axis labels
                    />
                    <YAxis
                      label={{
                        value: "Employees",
                        angle: -90,
                        position: "insideLeft",
                        offset: -10,
                        fontSize: 12, // Adjust the font size for the Y-axis label
                      }}
                    />
                    <Tooltip />

                    {keys_region.map((key, index) => (
                      <Bar
                        key={key}
                        dataKey={key}
                        stackId="gender"
                        fill={COLORS_Stack_region[index]}
                        barSize={30} // Adjust 'barSize' prop to set the width of bars
                      />
                    ))}
                    <Legend
                      layout="horizontal" // Display legend items horizontally
                      align="center" // Align the legend to the center
                      verticalAlign="top" // Position the legend above the bars
                      wrapperStyle={{ fontSize: 12, marginBottom: "10px" }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
