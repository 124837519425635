/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faChevronLeft, faChevronRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
export const Pagination = ({ activePage, count, rowsPerPage, totalPages, setActivePage }) => {
    const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
    const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;
  
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  return (
    <>
      <ul className="pagination m-0">
        <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
          <a
            className="page-link waves-effect"
            aria-label="First"
            onClick={() => setActivePage(1)}
            style={{ cursor: activePage === 1 ? 'not-allowed' : 'pointer' }}
          >
            <span>
              <i className="fas fa-angle-double-left"></i>
            </span>
          </a>
        </li>
        <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
          <a
            className="page-link waves-effect"
            aria-label="Previous"
            onClick={() => activePage > 1 && setActivePage(activePage - 1)}
            style={{ cursor: activePage === 1 ? 'not-allowed' : 'pointer' }}
          >
            <span>
              <i className="fas fa-chevron-left"></i>
            </span>
          </a>
        </li>
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${number === activePage ? 'active' : ''}`}>
            <a
              className="page-link waves-effect"
              onClick={() => setActivePage(number)}
              style={{ cursor: 'pointer' }}
            >
              {number}
            </a>
          </li>
        ))}
        <li className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}>
          <a
            className="page-link waves-effect"
            aria-label="Next"
            onClick={() => activePage < totalPages && setActivePage(activePage + 1)}
            style={{ cursor: activePage === totalPages ? 'not-allowed' : 'pointer' }}
          >
            <span>
              <i className="fas fa-chevron-right"></i>
            </span>
          </a>
        </li>
        <li className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}>
          <a
            className="page-link waves-effect"
            aria-label="Last"
            onClick={() => setActivePage(totalPages)}
            style={{ cursor: activePage === totalPages ? 'not-allowed' : 'pointer' }}
          >
            <span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          </a>
        </li>
      </ul>
    </>
  )
}
