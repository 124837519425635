import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import CustomTabPanel from "./CustomTabPanel";
import { Link } from "react-router-dom";
import materialityTopics from "./materialityTopics";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import CryptoJS from 'crypto-js';

const tabsData = materialityTopics;

export default function MaterialityTabs() {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const companyid = localStorage.getItem("companyid");
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [value, setValue] = useState(0);
  // const [tabData, setTabData] = useState(() =>
  //   tabsData.map((tab) => ({
  //     accordionPanels: tab.accordionPanels.map((panel) => ({
  //       checkboxes: panel.checkboxes.map(() => ({
  //         selected: false,
  //       })),
  //     })),
  //   }))
  // );

  
  const initializeTabData = () => {
    const storedCheckboxes = JSON.parse(localStorage.getItem("step2")) || [];
    setSelectedCheckboxes(storedCheckboxes);
    return tabsData.map((tab, tabIndex) => ({
      accordionPanels: tab.accordionPanels.map((panel, panelIndex) => ({
        checkboxes: panel.checkboxes.map((checkbox, checkboxIndex) => {
          const isSelected = storedCheckboxes.some(
            (item) =>
              item.tabIndex === tabIndex &&
              item.panelIndex === panelIndex &&
              item.checkboxIndex === checkboxIndex
          );
          return { selected: isSelected };
        }),
      })),
    }));
  };
  const [tabData, setTabData] = useState(initializeTabData);
  const [openTooltip, setOpenTooltip] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState("100%"); // Default to 100%

  const [selectedTooltip, setSelectedTooltip] = useState(""); // Selected tooltip content

  const csrdDivRef = useRef(null); // Add a ref for the csrd1.js div

  useEffect(() => {
    const updatePopoverWidth = () => {
      if (csrdDivRef.current) {
        // Get the width of the csrd1.js div
        const csrdWidth = csrdDivRef.current.clientWidth;
        // Update the width of the Popover
        setPopoverWidth(csrdWidth + "px");
      }
    };

    // Call the function on mount
    updatePopoverWidth();

    window.addEventListener("resize", updatePopoverWidth);

    return () => {
      window.removeEventListener("resize", updatePopoverWidth);
    };
    initializeTabData();
  }, []);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    setExpandedPanels((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panelIndex]
        : prevExpanded.filter((index) => index !== panelIndex)
    );
  };

  const handleChange = (event, newValue) => {
    // Close all accordions when a tab is clicked
    setExpandedPanels([]);
    setValue(newValue);
  };

  

  const handleCheckboxChange = (tabIndex, panelIndex, checkboxIndex, checked, panelId) => {
    setTabData((prevTabData) => {
      const newTabData = [...prevTabData];
      if (
        newTabData[tabIndex]?.accordionPanels[panelIndex]?.checkboxes[checkboxIndex] !== undefined
      ) {
        newTabData[tabIndex].accordionPanels[panelIndex].checkboxes[checkboxIndex].selected =
          checked;
      }
      return newTabData;
    });

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const updatedCheckboxes = [...prevSelectedCheckboxes];
      const selectedCheckbox = {
        tabIndex: tabIndex,
        panelIndex: panelIndex,
        checkboxIndex: checkboxIndex,
        label:
          checked && tabsData[tabIndex]?.accordionPanels[panelIndex]?.checkboxes[checkboxIndex]
            ? tabsData[tabIndex].accordionPanels[panelIndex].checkboxes[checkboxIndex].label || ""
            : "",
        panelId: panelId,
      };

      if (checked) {
        updatedCheckboxes.push(selectedCheckbox);
      } else {
        const indexToRemove = updatedCheckboxes.findIndex(
          (checkbox) =>
            checkbox.tabIndex === tabIndex &&
            checkbox.panelIndex === panelIndex &&
            checkbox.checkboxIndex === checkboxIndex
        );
        if (indexToRemove !== -1) {
          updatedCheckboxes.splice(indexToRemove, 1);
        }
      }
      localStorage.setItem("step2", JSON.stringify(updatedCheckboxes));
      return updatedCheckboxes;
    });
  };

  const renderAccordionPanel = (panels, tabIndex) => {
    const leftPanels = [];
    const rightPanels = [];

    panels.forEach((panel, panelIndex) => {
      const accordionPanel = (
        <Accordion
          expanded={expandedPanels.includes(panelIndex)}
          onChange={handleAccordionChange(panelIndex)}
          key={panelIndex}
        >
          <AccordionSummary className="acc-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>{panel.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {panel.checkboxes.map((checkbox, checkboxIndex) => (
                <div key={checkboxIndex} className="csrd-checkbox">
                  <div className="checkbox-iconbox materiality-label">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            tabData[tabIndex]?.accordionPanels[panelIndex]?.checkboxes[
                              checkboxIndex
                            ]?.selected || false
                          }
                          onChange={(event) =>
                            handleCheckboxChange(
                              tabIndex,
                              panelIndex,
                              checkboxIndex,
                              event.target.checked,
                              panel.id
                            )
                          }
                        />
                      }
                      label={<div className="checkbox-label">{checkbox.label}</div>}
                    />
                    {checkbox.tooltip && (
                      <Tooltip title={checkbox.tooltip} className="custom-tooltip">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton>
                            <img
                              className="accordian-icon"
                              src="./assets/img/reports/info.svg"
                              alt="Info Icon"
                            />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      );

      if (panelIndex % 2 === 0) {
        leftPanels.push(accordionPanel);
      } else {
        rightPanels.push(accordionPanel);
      }
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {leftPanels}
        </Grid>
        <Grid item xs={6}>
          {rightPanels}
        </Grid>
      </Grid>
    );
  };

  return (
    <div ref={csrdDivRef}>
      <Tabs
        className="main-acc-tab"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabsData.map((tab, tabIndex) => (
          <Tab
            key={tabIndex}
            label={
              <div className="tab-label-container  materiality-labels">
                {/* Add icons for specific tabs */}
                {tabIndex === 0 && (
                  <img
                    src="./assets/img/reports/Environment.svg"
                    alt="Environment Icon"
                    className="tab-icon"
                  />
                )}
                {tabIndex === 1 && (
                  <img
                    src="./assets/img/reports/Social.svg"
                    alt="Social Icon"
                    className="tab-icon"
                  />
                )}
                {tabIndex === 2 && (
                  <img
                    src="./assets/img/reports/governance.svg"
                    alt="Governance Icon"
                    className="tab-icon"
                  />
                )}
                <span
                  className="tab-title"
                  style={{ color: tabIndex === value ? "#407775" : "#ADA9A9" }}
                >
                  {tab.label}
                </span>
                <span className="tab-number">
                  <span style={{ fontSize: "12px" }}>
                    {tabData[tabIndex]
                      ? tabData[tabIndex].accordionPanels.flatMap((panel) =>
                          panel.checkboxes.filter((checkbox) => checkbox.selected)
                        ).length
                      : ""}
                  </span>
                </span>
              </div>
            }
          />
        ))}
      </Tabs>

      <div className="csrd-tabs materiality-tabs">
        {tabsData.map((tab, tabIndex) => (
          <CustomTabPanel key={tabIndex} value={value} index={tabIndex}>
            {renderAccordionPanel(tab.accordionPanels, tabIndex)}
          </CustomTabPanel>
        ))}
      </div>
    </div>
  );
}
