import React, { useState, useRef, useEffect } from "react";
import SideNavigation from "./components/SideNavigation.js";
import LoginHeader from "./components/LoginHeader.js";
import "./chatPage.css";
import Swal from "sweetalert2";
import AADHeader from "./components/AADHeader.js";
const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const chatBottom = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [selectedScope, setSelectedScope] = useState("");
  const [scopeMetrics, setScopeMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const welcomeMessage = "Hi there! I'm ecobot. How can I assist you today?";
    if (messages.length === 0) {
      setMessages([{ text: welcomeMessage, sender: "ecobot", time: getTime() }]);
    }
  }, [messages]);

  const getTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleScopeSelection = (scope) => {
    setSelectedScope(scope);
    const scopeOptions = {
      Scope1: [
        "Fuel consumption (Stationary)",
        "Fuel consumption (Mobile)",
        "Fugitive Emission",
        "Process Emission",
      ],
      Scope2: [
        "Purchased Electricity",
        "Purchased Heating",
        "Purchased Steam",
        "Purchased Cooling",
      ],
      Scope3: ["Business Travel", "Employee commuting"],
      Social: ["Employee Wellbeing"],
      // Add more scopes and their respective ESG metrics as needed
    };
    setScopeMetrics(scopeOptions[scope]);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: `Please Select a scope:`, sender: "ecobot", time: getTime() },
      { text: ` ${scope}`, sender: "you", time: getTime() },
      { text: `Please choose an ESG metric for ${scope}:`, sender: "ecobot", time: getTime() },
    ]);
  };

  const handleMetricSelection = (metric) => {
    setSelectedMetric(metric);
    setIsButtonDisabled(false);
    if (selectedScope !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: ` ${metric} `, sender: "you", time: getTime() },
      ]);
    }
    // Handle the selected ESG metric logic here if needed
  };
  const handleSubmit = async (e) => {
    console.log(selectedMetric);
    const username = localStorage.getItem("userName");
    e.preventDefault();

    if (inputValue.trim() !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputValue, sender: "you", time: getTime() },
      ]);
      setInputValue("");
      let res = await fetch("https://ecoprismapi.azurewebsites.net/chat-response", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          esg: selectedMetric,
          prompt: inputValue,
        },
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success === false) {
          console.log(responseJson);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.message,
          });
        } else if (responseJson.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.message,
          });
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: responseJson.content, sender: "ecobot", time: getTime() },
          ]);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error",
        });
      }
    }
  };
  const scrollToBottom = () => {
    chatBottom.current.scrollIntoView({ behavior: "smooth" });
  };
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader  toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="chat-container">
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.sender === "you" ? "user-message" : "ecobot-message"
                }`}
              >
                <div className={`message-text ${message.sender}`}>
                  <div>
                    {/* Prefix added based on the sender */}
                    {message.sender === "you" && <span className="fn-bold">You: </span>}
                    {message.sender === "ecobot" && <span className="fn-bold">Ecobot: </span>}
                    {message.text}
                  </div>
                  <div className="message-time">{message.time}</div>
                </div>
              </div>
            ))}
            <div ref={chatBottom}></div>
          </div>
          {isSidebarOpen && selectedScope === "" && (
            <div className="chat-form scope-options">
              <div className="message ecobot">
                <span className="fn-bold">Ecobot:</span> Please Select a scope:{" "}
              </div>
              <div className="message-time">{getTime()}</div>
              <button
                onClick={() => handleScopeSelection("Scope1")}
                className="scope-option-button"
              >
                Scope1
              </button>
              <button
                onClick={() => handleScopeSelection("Scope2")}
                className="scope-option-button"
              >
                Scope2
              </button>
              <button
                onClick={() => handleScopeSelection("Scope3")}
                className="scope-option-button"
              >
                Scope3
              </button>
              <button
                onClick={() => handleScopeSelection("Social")}
                className="scope-option-button"
              >
                Social
              </button>
            </div>
          )}
          {isSidebarOpen && selectedScope !== "" && selectedMetric === "" && (
            <div className="chat-form">
              <div>Please choose an ESG metric for {selectedScope}:</div>
              {scopeMetrics.map((metric, index) => (
                <button
                  key={index}
                  onClick={() => handleMetricSelection(metric)}
                  className="option-button"
                >
                  {metric}
                </button>
              ))}
            </div>
          )}
          <form onSubmit={handleSubmit} className="chat-form">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type a message..."
              className="input-box"
            />
            <button type="submit" className="send-button" disabled={isButtonDisabled}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
