import React from "react";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Links from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Company from "./company";
import { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { appInsights } from "./AppInsights";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";
import AADHeader from "./components/AADHeader.js";
export default function Home() {
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = useState();
  const [legalEntity, setLegalEntity] = useState();
  const [getcurrency, setGetCurrency] = useState();
  const [entityname, setEntityName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [country, setCountry] = useState("");
  const [employees, setEmployees] = useState("");
  const [message, setMessage] = useState("");
  const [currency, setCurrency] = useState("");
  const [entitytype, setEntityType] = useState("");
  const [industrialsector, setIndustrialSector] = useState("");
  const [getsector, setSector] = useState("");
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function fetchData() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchLegalEntityType() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/fetch-legal-entity-type");
      const data = await response.json();
      setLegalEntity(data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCurrency = (value, setCountry) => {
    setCountry(value);
    getCurrency(value);
    console.log(value);
  };

  async function getCurrency() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/currencies", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
      });
      const data = await response.json();
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function getIndustrialSector() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/industiralsector", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
      });
      const data = await response.json();
      setSector(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchData();
    fetchLegalEntityType();
    getCurrency();
    getIndustrialSector();
  }, []);

  let handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (revenue === "" || revenue === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;font-family:Poppins;">Please enter revenue</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (revenue <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;font-family:Poppins;">Revenue must be greater than zero</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (employees === "" || employees === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;font-family:Poppins;">Please enter employees</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (employees <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;font-family:Poppins;">Employee must be greater than zero</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      let rest = await fetch("https://ecoprismapi.azurewebsites.net/check-legal-entity", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: entityname,
        },
      });
      if (rest.status === 200) {
        const responseJson1 = await rest.json();
        if (responseJson1.data == true) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;font-family:Poppins;">Legal entity already exist</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson1.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;font-family:Poppins;">${responseJson1.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          const countri = [country];
          let res = await fetch("https://ecoprismapi.azurewebsites.net/create-legalentity", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
            },
            body: JSON.stringify({
              name: entityname,
              revenue: revenue,
              countries: countri,
              entityType: entitytype,
              revenueCurrency: currency,
              parentAssociation: "test320",
              numberOfEmployees: employees,
              industrialSector: industrialsector,
            }),
          });
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              setLoading(false);
              console.log(responseJson);
              Swal.fire({
                icon: "error",
                html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;font-family:Poppins;">${responseJson.data}</p>`,
                iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
              });
            } else if (responseJson.status == 400) {
              setLoading(false);
              Swal.fire({
                icon: "error",
                html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;font-family:Poppins;">${responseJson.title}</p>`,
                iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
              });
            } else {
              setEntityName("");
              setRevenue("");
              setCountry("");
              setEmployees("");
              setCurrency("");
              setEntityType("");
              setIndustrialSector("");
              setLoading(false);
              Swal.fire({
                icon: "success",
                html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">New Legal entity has been successfully created</p>',
                iconHtml:
                  '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              });
            }
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;font-family:Poppins;">Error In Creating Legal Entity</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          }
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;font-family:Poppins;">Something went wrong</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      border: "1px solid #ebe9e9",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      border: "1px solid #40777526",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        border: "1px solid #40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",
      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };

  const [revenueError, setRevenueError] = useState("");
  const [employeesError, setEmployeesError] = useState("");
  const validateRevenue = (value) => {
    const pattern = /^[0-9]+([,\.][0-9]+)?$/;
    if (!pattern.test(value)) {
      setRevenueError("Please enter a valid number for revenue.");
    } else {
      setRevenueError("");
    }
  };

  const validateEmployees = (value) => {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(value)) {
      setEmployeesError("Please enter a valid number for employees.");
    } else {
      setEmployeesError("");
    }
  };

  const handleRevenueChange = (e) => {
    const value = e.target.value;
    setRevenue(value);
    validateRevenue(value);
  };

  const handleEmployeesChange = (e) => {
    const value = e.target.value;
    setEmployees(value);
    validateEmployees(value);
  };

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div Class="steps__containt-hader">
            <h3 class="title">Add Legal Entity</h3>
            <div role="presentation">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Links underline="hover" color="#707070" className="breadcrumbs-font">
                  Company
                </Links>
                <Link to="/listofentities" underline="hover" className="breadcrumbs-font">
                  Legal Entities
                </Link>
                <Typography color="inherit">Add Legal Entity</Typography>
              </Breadcrumbs>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div className="message">{message ? <p>{message}</p> : null}</div>
              <form onSubmit={handleSubmit} class="form-eco">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputEmail4">
                      Name of entity <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={entityname}
                      required
                      onChange={(e) => setEntityName(e.target.value)}
                      class="form-control"
                      placeholder="Enter entity name"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputState">
                      Entity type <span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      value={
                        legalEntity && legalEntity.find((option) => option.value === entitytype)
                      } // Ensure correct value is selected
                      styles={customStyles}
                      onChange={(selectedOption) => setEntityType(selectedOption.value)}
                      options={
                        legalEntity &&
                        legalEntity.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select entity type"
                      menuPosition="fixed"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputState">
                      Country <span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      styles={customStyles}
                      value={country && { label: country, value: country }} // Set the selected value
                      onChange={(selectedOption) => setCountry(selectedOption.value)}
                      options={
                        countries && countries.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select country"
                      menuPosition="fixed"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputCity">
                      Revenue <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={revenue}
                      pattern="[0-9]+([,\.][0-9]+)?"
                      required
                      onChange={handleRevenueChange}
                      className={`form-control ${revenueError ? "is-invalid" : ""}`}
                      id="inputCity"
                      placeholder="Enter company revenue"
                    />
                    {revenueError && <div className="invalid-feedback">{revenueError}</div>}
                  </div>
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputCity">
                      Revenue currency <span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      styles={customStyles}
                      value={currency && { label: currency, value: currency }} // Set the selected value
                      onChange={(selectedOption) => setCurrency(selectedOption.value)}
                      options={
                        getcurrency &&
                        getcurrency.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select currency"
                      menuPosition="fixed"
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputZip">
                      No. of employees<span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={employees}
                      pattern="[0-9]+"
                      required
                      onChange={handleEmployeesChange}
                      className={`form-control ${employeesError ? "is-invalid" : ""}`}
                      id="inputZip"
                      placeholder="Enter no. of employees"
                    />
                    {employeesError && <div className="invalid-feedback">{employeesError}</div>}
                  </div>
                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputZip">
                      Industrial Sector<span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      styles={customStyles}
                      value={
                        industrialsector && { label: industrialsector, value: industrialsector }
                      } // Set the selected value
                      onChange={(selectedOption) => setIndustrialSector(selectedOption.value)}
                      options={
                        getsector && getsector.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select Sector"
                      menuPosition="fixed"
                    />
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={loading}
                  className="next-button"
                  variant="contained"
                  size="medium"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#407775",
                    width: "120px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                >
                  Submit
                </Button>
                <Button
                  href="listofentities"
                  variant="outlined"
                  className="outline-btn"
                  size="medium"
                  sx={{
                    color: "#000",
                    marginLeft: "15px",
                    width: "120px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                >
                  Cancel
                </Button>
              </form>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
