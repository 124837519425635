import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, deletename, message }) => {
  return (
    <Modal show={showModal} onHide={hideModal} className="custom-modal-width-delete">
      <Modal.Header>
        {/* <Modal.Title style={{ fontSize: '16px' }}>Deactivation Confirmation</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div
          className=""
          style={{ wordWrap: "break-word", padding: "22px", fontSize: "15px", textAlign: "center" }}
        >
          <img
            src="assets/img/deactivate.png"
            style={{ width: "55px", height: "55px", marginBottom: "10px" }}
          />
          <h4 style={{ color: "#CC2229", fontSize: "20px" }}>Deactivation Confirmation</h4>
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer className="delete-modal-buttons">
        <Button className="btn-cancel outline-btn" variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" className="btn-deactive" onClick={() => confirmModal(deletename)}>
          Deactivate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
