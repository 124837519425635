import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import containerTheme from './theme/containerTheme.js'
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import { FaCalendarAlt } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import classnames from "classnames";
import AADHeader from "./components/AADHeader.js";

// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  const username = localStorage.getItem("userName");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const slParam = urlSearchParams.get("sl");
  const esg = urlSearchParams.get("esg");
  var myHeaders = new Headers();
  myHeaders.append("username", username);
  myHeaders.append("targetREShare", "");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [renewable, setRenewable] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [companyaverage, setCompanyAverage] = React.useState("");
  const [targetcompany, setTargtCompany] = React.useState("");
  const [currentcompany, setCurrentCompany] = React.useState("");
  const [postre, setPostre] = React.useState("");
  const [posttotal, setPostTotal] = React.useState("");
  const [postreduction, setPostReduction] = React.useState("");

  async function fetchRenewable() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/GetRenewableEnergyInsights",
        requestOptions
      );
      const data = await response.json();
      if (data.success == false) {
        setLoading(false);
      } else if (data.status == 400) {
        setLoading(false);
      } else {
        setLoading(false);
        setCompanyAverage(data.data.currentCompanyAverageREPercentage.toFixed(2));
        setTargtCompany(data.data.targetCompanyREPercentage.toFixed(2));
        setValue(data.data.targetCompanyREPercentage.toFixed(2));
        setCurrentCompany(data.data.currentCompanyEmissions.toFixed(2));
        setPostre(data.data.postREScenarioInPercentage.toFixed(2));
        setPostTotal(data.data.postScenarioTotalEmissions.toFixed(2));
        setPostReduction(data.data.postScenarioReductionInEmissionsInPercentage.toFixed(2));
        setRenewable(data.data.siteRenewableEnergyInsights);

        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    fetchRenewable();
  }, []);
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      setIsLoading(true);
      var myHeader = new Headers();
      myHeader.append("username", username);
      myHeader.append("target", value);

      var requestOptions1 = {
        method: "GET",
        headers: myHeader,
        redirect: "follow",
      };
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/GetRenewableEnergyInsightsTarget",
        requestOptions1
      );
      const data = await response.json();
      if (data.success == false) {
        setLoading(false);
      } else if (data.status == 400) {
        setLoading(false);
      } else {
        setLoading(false);
        setCompanyAverage(data.data.currentCompanyAverageREPercentage.toFixed(2));
        setTargtCompany(data.data.targetCompanyREPercentage.toFixed(2));
        setValue(data.data.targetCompanyREPercentage.toFixed(2));
        setCurrentCompany(data.data.currentCompanyEmissions.toFixed(2));
        setPostre(data.data.postREScenarioInPercentage.toFixed(2));
        setPostTotal(data.data.postScenarioTotalEmissions.toFixed(2));
        setPostReduction(data.data.postScenarioReductionInEmissionsInPercentage.toFixed(2));
        setRenewable(data.data.siteRenewableEnergyInsights);

        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader  toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9">
              <div Class="steps__containt-hader">
                <h3 class="title">Insights</h3>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/insights"> */}
                    <Typography color="text.primary">Insights</Typography>
                    {/* </Link> */}

                    <Typography color="text.primary">Assessing Low RE % Sites</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="container">{message}</div>
          <div class="Col-md-12 flex-pd">
            <div Class="col-md-4 borderright pd-right">
              <h2 className="text-inputinsights font-16">INPUT PARAMETER</h2>
              <form onSubmit="" class="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="formlable" for="formGroupExampleInput">
                      Average company RE share (%)
                    </label>
                    <input
                      type="text"
                      readOnly
                      class="form-control wd-85"
                      required
                      id="formGroupExampleInput"
                      value={companyaverage}
                      placeholder="14.82%"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="formlable" htmlFor="formGroupExampleInput">
                      Target RE share (%)
                    </label>
                    <div className={`editable ${isEditing ? "editing" : ""}`}>
                      <div className="input-container">
                        {isEditing ? (
                          <>
                            <input
                              type="text"
                              className="form-control wd-85"
                              required
                              id="formGroupExampleInput"
                              value={value}
                              placeholder=""
                              onChange={handleInputChange}
                            />
                            <img
                              src="assets/img/saveicon.png" // Replace with your save icon image URL
                              alt="Save"
                              className="icon-insight save-icon"
                              onClick={handleSaveClick}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              type="text"
                              className="form-control wd-85"
                              required
                              id="formGroupExampleInput"
                              value={value}
                              placeholder=""
                              readOnly
                            />
                            <img
                              src="assets/img/editicon.png" // Replace with your edit icon image URL
                              alt="Edit"
                              className="icon-insight edit-icon"
                              onClick={handleEditClick}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="formlable" for="formGroupExampleInput2">
                      Current emissions (tCO<sub>2</sub>e){" "}
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control wd-85"
                      pattern="[0-9]+"
                      value={
                        !isNaN(currentcompany) && currentcompany !== ""
                          ? parseFloat(currentcompany)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""
                      }
                      required
                      id="formGroupExampleInput2"
                      placeholder=""
                    />
                  </div>
                </div>
              </form>
            </div>
            <div Class="col-md-8 pd-left">
              <h2 className="text-inputanalysis font-16">ANALYSIS SUMMARY</h2>
              <form onSubmit="" class="">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable text-inputanalysis" for="formGroupExampleInput">
                      Post scenario RE share (%)
                    </label>
                    <input
                      type="text"
                      readOnly
                      class="form-control bg-inputanalysis wd-85"
                      value={postre}
                      required
                      id="formGroupExampleInput"
                      placeholder="15.82%"
                    />
                  </div>
                  <div class="form-group col-md-6"></div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable text-inputanalysis" for="formGroupExampleInput2">
                      % Reduction in emissions
                    </label>
                    <input
                      type="text"
                      readOnly
                      class="form-control bg-inputanalysis wd-85"
                      value={postreduction}
                      pattern="[0-9]+"
                      required
                      id="formGroupExampleInput2"
                      placeholder="1.16%"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="formlable text-inputanalysis" for="formGroupExampleInput">
                      Post scenario emissions (tCO<sub>2</sub>e){" "}
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control bg-inputanalysis wd-85"
                      value={
                        !isNaN(posttotal) && posttotal !== ""
                          ? parseFloat(posttotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""
                      }
                      required
                      id="formGroupExampleInput"
                      placeholder=""
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Site </TableCell>
                    <TableCell align="left">RE share (%)</TableCell>
                    <TableCell align="left">RE electricity consumption (MWh) </TableCell>
                    <TableCell align="left">Total electricity consumption (MWh)</TableCell>
                    <TableCell align="left">Target RE share (%)</TableCell>
                    <TableCell align="left">Target electricity consumption(MWh)</TableCell>
                    <TableCell align="left">Target Non-RE electricity consumption (MWh)</TableCell>
                    <TableCell align="left">
                      Target emissions (tCO<sub>2</sub>e)
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {renewable &&
                    renewable.map((row) => (
                      <TableRow
                        key={row.siteLocationName}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="left"
                          className={classnames({
                            "text-danger":
                              parseFloat(targetcompany) > row.reShareInPercentage.toFixed(2), // Add this class if RE percentage is less than targetcompany
                            "text-success":
                              parseFloat(targetcompany) < row.reShareInPercentage.toFixed(2), // Add this class if RE percentage is greater than targetcompany
                          })}
                        >
                          {row.siteLocationName}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classnames({
                            "text-danger":
                              parseFloat(targetcompany) > row.reShareInPercentage.toFixed(2), // Add this class if RE percentage is less than targetcompany
                            "text-success":
                              parseFloat(targetcompany) <= row.reShareInPercentage.toFixed(2), // Add this class if RE percentage is greater than targetcompany
                          })}
                        >
                          {row.reShareInPercentage.toFixed(2)}
                        </TableCell>
                        <TableCell align="left">
                          {parseFloat(row.totalREConsumption)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {parseFloat(row.totalElectricityConsumption)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {row.targetREShareInPercentage.toFixed(2)}
                        </TableCell>
                        <TableCell align="left">
                          {parseFloat(row.targetREConsumption)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {parseFloat(row.targetNonREConsumption)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {parseFloat(row.targetEmissions)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
