import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import CustomTabPanel from "./CustomTabPanel";
import csrdData from "./csrdData";
import Tooltip from "@mui/material/Tooltip";

const tabsData = csrdData;

export default function Csrd1() {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    JSON.parse(localStorage.getItem("step3"))
  );

  const step2 = JSON.parse(localStorage.getItem("step2"));
  const step3 = JSON.parse(localStorage.getItem("step3"));
  const uniquePanelIds = [];

  step2.forEach((item) => {
    uniquePanelIds.push(item.panelId);
  });
  uniquePanelIds.push("general1");
  console.log(uniquePanelIds);

  const [expandedPanel, setExpandedPanel] = useState(null);
  const [value, setValue] = useState(0);
  const [tabData, setTabData] = useState(
    tabsData.map((tab) => ({
      accordionPanels: tab.accordionPanels.map((panel) => ({
        ...panel,
        checkboxes: panel.checkboxes.map((checkbox) => ({
          ...checkbox,
          selected: step2.some((selected) => selected.panelId === panel.id),
        })),
      })),
    }))
  );

  const csrdDivRef = useRef(null); // Add a ref for the csrd1.js div
  console.log(selectedCheckboxes);

  console.log(tabData);
  useEffect(() => {
    const updatedTabData = tabData.map((tab, tabIndex) => ({
      ...tab,
      accordionPanels: tab.accordionPanels.map((panel, panelIndex) => ({
        ...panel,
        checkboxes: panel.checkboxes.map((checkbox, checkboxIndex) => ({
          ...checkbox,
          selected: selectedCheckboxes.some(
            (checked) =>
              checked.tabIndex === tabIndex &&
              checked.panelIndex === panelIndex &&
              checked.checkboxIndex === checkboxIndex
          ),
        })),
      })),
    }));

    setTabData(updatedTabData);
  }, []);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panelIndex : null);
  };

  const handleChange = (event, newValue) => {
    // Close all accordions when a tab is clicked
    setExpandedPanel(null);
    setValue(newValue);
  };

  const renderAccordionPanel = (panels, tabIndex) =>
    panels.map((panel, panelIndex) => (
      <Accordion
        expanded={expandedPanel === panelIndex}
        onChange={handleAccordionChange(panelIndex)}
        key={panelIndex}
      >
        <AccordionSummary className="acc-header" expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            control={
              <Checkbox
                checked={tabData[tabIndex].accordionPanels[panelIndex].checkboxes.every(
                  (checkbox) => checkbox.selected
                )}
                onChange={(event) =>
                  handleCheckboxChange(tabIndex, panelIndex, -1, event.target.checked)
                }
              />
            }
          />

          <Typography>{panel.label}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: expandedPanel === panelIndex ? "0" : "16px",
            fontWeight: "400", // Apply padding conditionally
          }}
        >
          <div>
            {panel.checkboxes.map((checkbox, checkboxIndex) => (
              <div key={checkboxIndex} className="csrd-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        tabData[tabIndex].accordionPanels[panelIndex].checkboxes[checkboxIndex]
                          .selected
                      }
                      onChange={(event) =>
                        handleCheckboxChange(
                          tabIndex,
                          panelIndex,
                          checkboxIndex,
                          event.target.checked
                        )
                      }
                    />
                  }
                  label={<div>{checkbox.label}</div>}
                />
                <div className="checkbox-iconbox">
                  {checkbox.mandatory && ( // Render the mandatory icon only if mandatory property is true
                    <Tooltip title="Mandatory" arrow>
                      <IconButton>
                        <img
                          className="accordian-icon"
                          src="./assets/img/reports/Mandatory.svg"
                          alt="Mandatory icon"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {checkbox.tooltip && (
                    <Tooltip title={checkbox.tooltip} arrow>
                      <IconButton>
                        <img
                          className="accordian-icon"
                          src="./assets/img/reports/info.svg"
                          alt="Info Icon"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    ));

  const handleCheckboxChange = (tabIndex, panelIndex, checkboxIndex, checked) => {
    const updatedTabData = [...tabData];
    // If the checkbox being changed is the one in the AccordionSummary
    if (checkboxIndex === -1) {
      // Update the selected state of all checkboxes under this accordion panel
      updatedTabData[tabIndex].accordionPanels[panelIndex].checkboxes = updatedTabData[
        tabIndex
      ].accordionPanels[panelIndex].checkboxes.map((checkbox) => ({
        ...checkbox,
        selected: checked,
      }));

      // Only update the accordion state if the "Autoselect" checkbox is checked
      if (checked) {
        setExpandedPanel(panelIndex);
      }
    } else {
      // Update the selected state of the individual checkbox
      updatedTabData[tabIndex].accordionPanels[panelIndex].checkboxes[checkboxIndex].selected =
        checked;
    }
    setTabData(updatedTabData);

    let updatedSelectedCheckboxes = [...selectedCheckboxes];

    // If the checkbox being changed is the one in the AccordionSummary
    if (checkboxIndex === -1) {
      if (checked) {
        // Add all checkboxes under this accordion panel to the selected checkboxes
        updatedSelectedCheckboxes.push(
          ...tabsData[tabIndex].accordionPanels[panelIndex].checkboxes.map((checkbox, index) => ({
            tabIndex,
            panelIndex,
            checkboxIndex: index,
            label: checkbox.label,
          }))
        );
      } else {
        // Remove all checkboxes under this accordion panel from the selected checkboxes
        updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
          (checkbox) => !(checkbox.tabIndex === tabIndex && checkbox.panelIndex === panelIndex)
        );
      }
    } else {
      const checkboxLabel =
        tabsData[tabIndex].accordionPanels[panelIndex].checkboxes[checkboxIndex].label;

      if (checked) {
        updatedSelectedCheckboxes.push({
          tabIndex,
          panelIndex,
          checkboxIndex,
          label: checkboxLabel,
        });
      } else {
        const indexToRemove = updatedSelectedCheckboxes.findIndex(
          (item) =>
            item.tabIndex === tabIndex &&
            item.panelIndex === panelIndex &&
            item.checkboxIndex === checkboxIndex
        );
        if (indexToRemove !== -1) {
          updatedSelectedCheckboxes.splice(indexToRemove, 1);
        }
      }
    }

    setSelectedCheckboxes(updatedSelectedCheckboxes);
    console.log(updatedSelectedCheckboxes);
    localStorage.setItem("step3", JSON.stringify(updatedSelectedCheckboxes));
    localStorage.setItem("csrd", JSON.stringify(updatedSelectedCheckboxes));
  };

  return (
    <div ref={csrdDivRef}>
      <Tabs
        className="main-acc-tab"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabsData.map((tab, tabIndex) => (
          <Tab
            key={tabIndex}
            label={
              <div className="tab-label-container">
                {/* Add icons for specific tabs */}
                {tabIndex === 1 && (
                  <img
                    src="./assets/img/reports/Environment.svg"
                    alt="Environment Icon"
                    className="tab-icon"
                  />
                )}
                {tabIndex === 2 && (
                  <img
                    src="./assets/img/reports/Social.svg"
                    alt="Social Icon"
                    className="tab-icon"
                  />
                )}
                {tabIndex === 3 && (
                  <img
                    src="./assets/img/reports/governance.svg"
                    alt="Governance Icon"
                    className="tab-icon"
                  />
                )}
                <span
                  className="tab-title"
                  style={{ color: tabIndex === value ? "#407775" : "#ADA9A9" }}
                >
                  {tab.label}
                </span>
                <span className="tab-number">
                  <span style={{ fontSize: "12px" }}>
                    {tabData[tabIndex]
                      ? tabData[tabIndex].accordionPanels.flatMap((panel) =>
                          panel.checkboxes.filter((checkbox) => checkbox.selected)
                        ).length +
                        "/" +
                        tab.accordionPanels.flatMap((panel) => panel.checkboxes).length
                      : ""}
                  </span>
                </span>
              </div>
            }
          />
        ))}
      </Tabs>

      <div className="csrd-tabs">
        {tabsData.map((tab, tabIndex) => (
          <CustomTabPanel key={tabIndex} value={value} index={tabIndex}>
            <div>{tab.content}</div>
            {renderAccordionPanel(tab.accordionPanels, tabIndex)}
          </CustomTabPanel>
        ))}
      </div>
    </div>
  );
}
