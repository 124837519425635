import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import BusinessUnit from "./Businessunit";
import { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import AADHeader from "./components/AADHeader.js";
// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  const username = localStorage.getItem("userName");

  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedOptions, setSelectedOptions] = useState();
  const [listEntities, setListEntities] = useState();
  const [getcurrency, setGetCurrency] = useState();
  // Array of all options
  const optionList = [];

  {
    listEntities &&
      listEntities.data.map((item) => optionList.push({ label: item.name, value: item.name }));
  }

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  async function fetchLegalEntities() {
    try {
      const response = await fetch("http://20.66.103.34:8000/list-entities", requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setListEntities("");
        } else if (responseJson.status == 400) {
          setListEntities("");
        } else {
          responseJson.data.map((item) => optionList.push({ label: item.name, value: item.name }));
          setListEntities(responseJson);
        }
      } else {
        setListEntities("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function getCurrency() {
    try {
      const response = await fetch("http://20.66.103.34:8000/currencies");
      const data = await response.json();
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchLegalEntities();
    getCurrency();
    console.log("Use Effect 1");
  }, []);

  let handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      let rest = await fetch("http://20.66.103.34:8000/check-business", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
      });
      if (rest.status === 200) {
        const responseJson1 = await rest.json();
        if (responseJson1.data == true) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Business unit already exist",
          });
        } else if (responseJson1.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson1.title,
          });
        } else {
          // var le=[];
          // {selectedOptions.map((opt, index) => (
          //   le.push(opt.value)
          // ))}
          let res = await fetch("http://20.66.103.34:8000/create-businessunit", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
            },
            body: JSON.stringify({
              name: name,
              revenue: revenue,
              revenueCurrency: currency,
              numberOfEmployees: employees,
              associatedLegalEntities: [""],
            }),
          });
          //let resJson = await res.json();
          if (res.status === 200) {
            const responseJson = await res.json();
            if (responseJson.success == false) {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.data,
              });
            } else if (responseJson.status == 400) {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseJson.title,
              });
            } else {
              setName("");
              setRevenue("");
              setEmployees("");
              // setSelectedOptions("");
              setCurrency("");
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: " New Business Unit has been successfully created",
              });
              // toast.success('Business Unit created successfully');
            }
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error In Creating Business Unit",
            });
          }
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [name, setName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employees, setEmployees] = useState("");
  const [message, setMessage] = useState("");
  const [currency, setCurrency] = useState("");

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="center-screen">
            <div className="access-denied">
              <img className="denied-img" src="./assets/img/coming-soon.png" alt="" />
              {/* <h1>Access Denied !</h1> */}
              <p className="access-txt">This page is under development.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
