import React from "react";
import { useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from 'sweetalert2';

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [organization, setOrganization] = useState("");
  const [companyid, setCompanyId] = useState("");
  const [tenants, setTenants] = useState([]);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    //loginWithRedirect();
    getAllTenants();
  }, []);

  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/organization-name',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setOrganization("");
        }
        else if(responseJson.status == 400){
          setOrganization("");
        }
        else{
          localStorage.setItem('organization', responseJson.data.name);
          setOrganization(responseJson.data.name);
         
        }
      }
      else{
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCompanyId() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/getcompanyid',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setCompanyId("");
        }
        else if(responseJson.status == 400){
          setCompanyId("");
        }
        else{
          localStorage.setItem('companyid', responseJson.data);
          setCompanyId(responseJson.data);
        }
      }
      else{
        setCompanyId("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getAllTenants = async () => {
    try {
      const myHeaders = new Headers();
      const username = localStorage.getItem('userName');
      myHeaders.append("username", username);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/alltenants', requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        console.log(responseJson);
        setTenants(responseJson);
      } else {
        setTenants("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const checkIsAdmin = async (username) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("email", username);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/checkisadmin', requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        console.log(responseJson);
        return responseJson.data;
      } else {
        setTenants("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleLogin = () => {
    console.log('in')
    
  }

  function deleteCookies() {
    var Cookies = document.cookie.split(';');

    // set 1 Jan, 1970 expiry for every cookies
    for (var i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePass = (e) => {
    setPassword(e.target.value)
  }
  return (
    <div id="wrapper">
     <div className="login__wrapper">
      
        <section className="login__section">
          <div className="row">
            <div className="col-lg-12">
              <div className="login__inner">
                <div className="login__left">
                  <img className="login__left-img" src="./assets/img/login.png" alt=""/>
                </div>
                <div className="login__right">
                  <h2 className="login__title">Sign in</h2>
                    <button className="btn google__btn" onClick={loginWithRedirect}>
                      Login with Ecoprism Account
                    </button>
                    <div className="or__text">OR</div>
                    <AzureAD provider={authProvider}>
                      {
                          ({login, logout, authenticationState, error, accountInfo}) => {
                            console.log(authenticationState);
                            const allowedTenants = tenants;
                            switch (authenticationState) {
                              case AuthenticationState.Authenticated:
                                const user=accountInfo.account.userName;
                                const tenantid= accountInfo.account.idToken.tid;
                                console.log(accountInfo);
                                console.log(tenantid);
                                checkIsAdmin(user).then(isAdmin => {
                                  console.log(isAdmin);
                                  if (isAdmin === true) {
                                    localStorage.setItem('userName', accountInfo.account.userName);
                                    localStorage.setItem('loginType', 'AAD');
                                    return (
                                      navigate("/home")
                                    );
                                  } else if (tenants.includes(tenantid)){
                                    localStorage.setItem('userName', accountInfo.account.userName);
                                    localStorage.setItem('loginType', 'AAD');
                                    return (
                                      navigate("/home")
                                    );
                                  }
                                  else {
                                    return (
                                      navigate("/accessdenied")
                                    );
                                  }
                                });
                                 
                              case AuthenticationState.Unauthenticated:
                                return (
                                    <button className="btn google__btn" onClick={login}>
                                      <img src="assets/img/microsoft_icon.png" alt=""/>
                                        Sign in with Microsoft
                                    </button>
                                );
                              case AuthenticationState.InProgress:
                                return (
                                    <button className="btn google__btn" onClick={login}>
                                      <img src="assets/img/microsoft_icon.png" alt=""/>
                                      Sign in with Microsoft
                                    </button>
                                );
                            }
                          }
                      }
                    </AzureAD>
                    
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  
  );
}
function App() {
  return (
    <div className="App">
      <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      
    </div>
  );
}
