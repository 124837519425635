import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import SideNavigation from "./components/SideNavigation.js";
import LoginHeader from "./components/LoginHeader.js";
import LeftContainer from "./LeftContainer.js";
import RightContainer from "./RightContainer.js";
import getQuestionData from "./getQuestionData.js";
import tabData from "./tabData.js"; // Import tabData
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import InputPopup from "./InputPopup.js";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BlobServiceClient } from "@azure/storage-blob";
import { useNavigate } from "react-router-dom";
import AADHeader from "./components/AADHeader.js";

const steps = ["Start", "Materiality", "Module", "Input", "Report"];

export default function GenerateReport() {
  const companyid = localStorage.getItem("companyid");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedContent, setSelectedContent] = useState(""); // State to store selected content
  const [selectedLabel, setSelectedLabel] = useState(""); // Define selectedLabel state
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]); // State to store selected checkboxes
  const questionData = getQuestionData();
  const step1 = localStorage.getItem("step1");
  const stept3 = localStorage.getItem("step3");
  const reportName = JSON.parse(step1);
  const hash = localStorage.getItem("hash");
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  async function uploadJsonFile(containerName, folderName, fileName, jsonData) {
    try {
      // Fetch SAS token from your API
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;

        // Create BlobServiceClient using the SAS token
        const blobServiceClient = new BlobServiceClient(connectionString);

        // Get or create container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        //await containerClient.createIfNotExists();

        // Get BlobClient for the file
        const blobName = `${folderName}/${fileName}.json`; // Construct blob name with folder path
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        console.log(blobClient);
        // Convert JSON data to ArrayBuffer
        const arrayBuffer = new TextEncoder().encode(JSON.stringify(jsonData));
        // Upload the file content
        const uploadResponse = await blockBlobClient.uploadBrowserData(arrayBuffer.buffer, {
          blobHTTPHeaders: { blobContentType: "application/json" }, // Set content type as JSON
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20, // Number of concurrent requests
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`JSON file "${fileName}" uploaded successfully.`);
          console.log(`Blob URL: ${blobClient.url}`);
          localStorage.setItem("bloburl", blobClient.url);
        }
        // Perform any additional operations after successful upload
        // uploadFileName(fileName); // Assuming this is some function you want to call after upload
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading JSON file:", error);
    }
  }

  const handleNext = () => {
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const step4 = JSON.parse(localStorage.getItem("step4"));
    
    const organizationValue = localStorage.getItem("organization");
    const selectedOptions = localStorage.getItem("selectedOptions");
    let mdrp = [];
    if(selectedOptions !== null || selectedOptions !== ''){
      mdrp =JSON.parse(selectedOptions);
    }
    else{
      mdrp =[];
    }
    const username = localStorage.getItem("userName");
    const uniqueData = [];
    const uniqueQuestion = {};

    step4.forEach((item) => {
      if (!step4[item.question]) {
        step4[item.question] = true;
        uniqueData.push(item);
      }
    });
    console.log(uniqueData);
    localStorage.setItem("step4", JSON.stringify(uniqueData));
    const answers = localStorage.getItem("answers");
    const answers1 = localStorage.getItem("answers1");
    const tableanswers = localStorage.getItem("tableAnswers");
    const selectedOptions1 = localStorage.getItem("selectedOptions");
    const parseData = JSON.parse(step1);
    const logo = localStorage.getItem("logo");
    const rdata = {
      name: parseData.name,
      timeline: parseData.timeline,
      legalEntity: parseData.legalEntity,
      startDate: parseData.startDate,
      endDate: parseData.endDate,
      materiality: JSON.parse(step2),
      reportingModule: JSON.parse(step3),
      generateReport: step4,
      mdrp:mdrp,
      answers:JSON.parse(answers),
      answers1:JSON.parse(answers1),
      tableAnswers:JSON.parse(tableanswers),
      selectedOptions:JSON.parse(selectedOptions1),
      logo: logo,
    };
    uploadJsonFile("ecoprismfilecontainer", companyid, hash, rdata);

    navigate("/editPreviewReport");
  };

  useEffect(() => {
    // Retrieve and parse the selected data from URL parameters
    const searchParams = new URLSearchParams(window.location.search);
    const selectedDataString = searchParams.get("selectedData");
    if (selectedDataString) {
      const selectedData = JSON.parse(decodeURIComponent(selectedDataString));
      // Extract and store the selected checkboxes
      const checkboxes = selectedData
        .flatMap((tab) =>
          tab.selectedCheckboxes.map((checkbox) => ({
            id: checkbox.id,
            label: checkbox.label,
            tooltip: checkbox.tooltip,
          }))
        )
        .filter((checkbox) => checkbox.id !== undefined);
      setSelectedCheckboxes(checkboxes);
    }
    setIsModalOpen(true);
  }, []);

  // Function to update selected content when a tab is clicked
  const handleSelect = (content, label) => {
    setSelectedContent(content);
    setSelectedLabel(label); // Store the label name in state
  };
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <InputPopup isModalOpen={isModalOpen} onClose={closeModal} />
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd bg-grey">
          {/* Title and Breadcrumbs */}
          <div className="flex justify-content-between">
            <div className="steps__containt-hader">
              <h3 className="title">{reportName.name}</h3>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Link underline="hover" color="inherit" href="/standards">
                    Standards & Frameworks
                  </Link>
                  <Link underline="hover" color="inherit" href={`/editcsrd?file=${hash}`}>
                    CSRD
                  </Link>
                  <Typography color="text.primary">{reportName.name}</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="stepper-box">
              <Stepper activeStep={3} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-name">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <div className="button-box d-flex justify-content-end">
            <Button
              onClick={() => navigate("/ReportingModules")}
              variant="outlined"
              className="module-btn"
              size="medium"
              sx={{ marginRight: "15px" }}
            >
              <WestIcon fontSize="small" sx={{ marginRight: "4px" }} />
              Module
            </Button>
            <Button
              onClick={() => navigate("/reportDashboard")}
              variant="outlined"
              className="module-btn"
              size="medium"
              sx={{ marginRight: "15px" }}
            >
              <img src="/assets/img/reports/Status.svg" style={{ marginRight: "4px" }} />
              Status
            </Button>
            <Button
              onClick={() => navigate("/PreviewReport")}
              variant="outlined"
              className="module-btn"
              size="medium"
            >
              Preview Report
              <EastIcon fontSize="small" sx={{ marginLeft: "4px" }} />
            </Button>
          </div>

          <div className="input-page-container">
            <div className="left-container" style={{ flex: "33%" }}>
              <LeftContainer onSelect={handleSelect} selectedCheckboxes={selectedCheckboxes} />
            </div>
            <div className="right-container" style={{ flex: "67%" }}>
              {/* <RightContainer
                selectedContent={selectedContent}
                questions={questionData[selectedContent]}
                selectedLabel={selectedLabel} // Pass the selected label as a prop
              /> */}
              <RightContainer
                selectedContent={selectedContent}
                questions={questionData[selectedContent]}
                selectedLabel={selectedLabel}
                tabData={tabData}
              />
            </div>
          </div>
          <div className="buttons-container">
            <Button
              onClick={() => navigate("/editReportingModule")}
              variant="outlined"
              className="outline-btn"
              size="medium"
              type="button"
            >
              <ChevronLeftIcon fontSize="small" /> Previous
            </Button>
            <Button className="next-button" onClick={handleNext} variant="contained" size="medium">
              Next
              <ChevronRightIcon fontSize="small" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
