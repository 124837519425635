import React from "react";
import clsx from "clsx";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appInsights } from "./AppInsights";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
import LatLng from "./country-codes-lat-long.json";
import { MultiSelect } from "primereact/multiselect";
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Label } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { LineChart, Line, Legend } from "recharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsFullscreen from "highcharts/modules/full-screen";
import worldMap from "highcharts/modules/map";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { InputSwitch } from "primereact/inputswitch";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsMap from "highcharts/modules/map"; // Import the map module
import proj4 from "proj4";
import mapDataWorld from "@highcharts/map-collection/custom/world.geo.json";
import AADHeader from "./components/AADHeader.js";
// Initialize the exporting module
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsFullscreen(Highcharts);
HighchartsMap(Highcharts);
highchartsMore(Highcharts);
Highcharts.setOptions({
  colors: [
    "#407775",
    "#fab430",
    "#c8e1e0",
    "#b8d9d8",
    "#b8d9d8",
    "#b8d9d8",
    "#b8d9d8",
    "#fcd283",
    "#fde4b4",
    "#fbc051",
    "#e09405",
    "#fef6e6",
  ], // Define your custom colors here
});
export default function Home() {
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emissionbased, setEmissionBased] = React.useState(false);
  const [news, setNews] = useState(false);
  const [responseConfig, setResponseConfig] = useState({});
  const [legal, setLegal] = useState([]);
  const [business, setBusiness] = useState([]);
  const [site, setSite] = useState([]);
  const [year, setYear] = useState([]);
  const [allSupplier, setAllSupplier] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [country, setCountry] = useState([]);
  const [employee, setEmployee] = useState("");
  const [scope, setScope] = useState("");
  const [yearr, setYearR] = useState("");
  const [countries, setCountries] = useState();
  const [businessunit, setBusinessUnit] = useState();
  const [activesite, setActiveSite] = useState();
  const [activeetities, setActiveEntities] = useState();
  const [checked, setChecked] = useState(true);
  const [viewMode, setViewMode] = useState("marketBased");
  const [scope1Emission, setScope1Emission] = useState("");
  const [energyConsumption, setEnergyConsumption] = useState("");
  const [emissionIntensity, setEmissionIntensity] = useState("");
  const [activeyears, setActiveYears] = useState();
  
  if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
  }
  // Function to handle toggle change
  const handleToggleChange = (value) => {
    console.log(value);
    if (value === true) {
      setViewMode("locationBased");
      setChecked(value);
    } else {
      setViewMode("marketBased");
      setChecked(value);
    }
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const handleChatClick = () => {
    navigate("/ChatPage");
  };
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  async function fetchFilters() {
    
    try {
      const country = JSON.parse(localStorage.getItem('country1'));
      const year = JSON.parse(localStorage.getItem('year1'));
      const legal = JSON.parse(localStorage.getItem('legal1'));
      const business = JSON.parse(localStorage.getItem('business1'));
      const site = JSON.parse(localStorage.getItem('site1'));
      let response = await fetch("https://ecoprismapi.azurewebsites.net/scope1-filters", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          companyId: companyid,
          year: year,
          legalEntity: legal,
          businessUnit: business,
          country: country,
          site: site,
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
        } else if (responseJson.status == 400) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
        } else {
          setActiveEntities(responseJson.legalEntity);
          setActiveYears(responseJson.year);
          setBusinessUnit(responseJson.businessUnit);
          setCountries(responseJson.country);
          setActiveSite(responseJson.site);
        }
      } else {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  
  async function fetchScope1() {
    try {
      setLoading(true);
      const companyid = localStorage.getItem("companyid");
      var myHeaders = new Headers();
      myHeaders.append("username", username);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const country = JSON.parse(localStorage.getItem('country1'));
      const year = JSON.parse(localStorage.getItem('year1'));
      const legal = JSON.parse(localStorage.getItem('legal1'));
      const business = JSON.parse(localStorage.getItem('business1'));
      const site = JSON.parse(localStorage.getItem('site1'));
      let response = await fetch("https://ecoprismapi.azurewebsites.net/scope1", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          companyId: companyid,
          year: year,
          legalEntity: legal,
          businessUnit: business,
          country: country,
          site: site,
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success === false || responseJson.status === 400) {
          setLoading(false);
        } else {
          setScope(responseJson);
          setScope1Emission(responseJson.totalEmissions);
          setEnergyConsumption(responseJson.totalElectricityConsumptionInMwh);
          setEmissionIntensity(responseJson.emissionIntensity);
          console.log(responseJson);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    const country = JSON.parse(localStorage.getItem('country1'));
    const year = JSON.parse(localStorage.getItem('year1'));
    const legal = JSON.parse(localStorage.getItem('legal1'));
    const business = JSON.parse(localStorage.getItem('business1'));
    const site = JSON.parse(localStorage.getItem('site1'));

    if(country === '' || country === undefined || country === null)
    {
      localStorage.setItem('country1', JSON.stringify([])); 
    }
    if(year === '' || year === undefined || year === null)
    {
      localStorage.setItem('year1', JSON.stringify([])); 
    }
    if(legal === '' || legal === undefined || legal === null)
    {
      localStorage.setItem('legal1', JSON.stringify([])); 
    }
    if(business === '' || business === undefined || business === null)
    {
      localStorage.setItem('business1', JSON.stringify([])); 
    }
    if(site === '' || site === undefined || site === null)
      {
        localStorage.setItem('site1', JSON.stringify([])); 
      }
    fetchScope1();
    fetchFilters();
    
  }, []);

  const handleLegalChange = (selectedLegal) => {
    setLegal(selectedLegal);
    localStorage.setItem('legal1', JSON.stringify(selectedLegal));
    fetchScope1();
    fetchFilters();
  };

  const handleBusinessChange = (selectedBusiness) => {
    setBusiness(selectedBusiness);
    localStorage.setItem('business1', JSON.stringify(selectedBusiness));
    fetchScope1();
    fetchFilters();
  };

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    localStorage.setItem('country1', JSON.stringify(selectedCountry));
    fetchScope1();
    fetchFilters();
  };

  const handleSiteChange = (selectedSite) => {
    setSite(selectedSite);
    localStorage.setItem('site1', JSON.stringify(selectedSite));
    fetchScope1();
    fetchFilters();
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    localStorage.setItem('year1', JSON.stringify(selectedYear));
    fetchScope1();
    fetchFilters();
  };

  // Consuption by supplier start
  const consumptionBySupplier = scope && scope.consumptionBySupplier;

  const seriesData = consumptionBySupplier
    ? Object.entries(consumptionBySupplier).map(([supplierName, electricityConsumption]) => ({
        name: supplierName,
        y: parseFloat(electricityConsumption),
      }))
    : [];
  const seriesData_location = consumptionBySupplier
    ? Object.entries(consumptionBySupplier).map(([supplierName, electricityConsumption]) => ({
        name: supplierName,
        y: parseFloat(electricityConsumption),
      }))
    : [];
  const options_donut = {
    chart: {
      type: "pie",
      height: "90%",
    },
    title: {
      text: "Top Electricity suppliers",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: ({point.percentage:.0f}%)",
          style: {
            fontWeight: "500", // Set font weight to normal
          },
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      formatter: function () {
        const pointY = new Intl.NumberFormat().format(Math.round(this.y));
        return `${this.series.name}: ${pointY} (${this.percentage.toFixed(0)}%)`;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        colorByPoint: true,
        data: seriesData, // Assuming seriesData is defined elsewhere
      },
    ],
  };

  const pieChartOptions = {
    chart: {
        type: 'pie',
    },
    title: {
      text: "Emissions by categories",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        enabled: true, // Enable tooltip
        pointFormat: '{series.name}: <b>{point.y} tCO2e</b> ({point.percentage:.1f}%)', // Show emission and percentage in the tooltip
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f}%', // Show only the percentage on the chart
                distance: 20, // Distance outside the pie chart
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#333',
                },
                connectorShape: 'crookedLine', // Creates a line connecting the label to the pie slice
                crookDistance: '70%', // Controls the bend in the connector line (makes the line more like an arrow)
                connectorPadding: 5,
                connectorWidth: 1.5,
                connectorColor: '#333',
            },
            showInLegend: true,
            borderColor: null,
        },
    },
    series: [{
        name: 'Emission (tCO2e)', // Series name for tooltip context
        colorByPoint: true,
        data: [{
            name: 'Stationary',
            y: 3365, // Emission value for Stationary
            color: '#407775',
        }, {
            name: 'Mobile',
            y: 5503, // Emission value for Mobile
            color: '#FAB430',
        }],
    }],
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#333',
        },
        symbolRadius: 50, // Makes legend square instead of circle
        symbolHeight: 14,
        symbolWidth: 14,
    },
};


  // Emission by categories
  const energyMix = Object.keys(scope && scope.emissionMixBySite || {});
  console.log(energyMix);
  const energyMixCategories = energyMix.map((site) => site);
  const energyMixemission = energyMix.map((site) => scope && scope.emissionMixBySite[site].totalEmissions);
  const siteResponseRateOptions = {
    chart: {
        type: 'bar',
    },
    credits: {
        enabled: false,
    },
    title: {
      text: "Emissions by site",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    xAxis: {
        categories: energyMixCategories
    },
    yAxis: {
        min: 0,
        title: {
            text: ''
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y}', // Display the value with 'tCO2e' at the end of each bar
                style: {
                    fontWeight: '400',
                    color: '#333',
                },
            }
        }
    },
    series: [{
        name: 'Emission (tCO2e)',
        data: energyMixemission,
        color: '#9AC7BF'
    }],
    legend: {
        enabled: false 
    }
};
 // Emission by sitelocation
  const calculatePercentage = (total, value) => {
    return (value / total) * 100;
  };

  //RE share by month start
  const marketCategories = [];
  const marketColumnSeries = [];
  const marketLineSeries = [];

  scope &&
    scope.energyConsumptionAndREShareByMonth.forEach((data) => {
      marketCategories.push(data.month.substring(0, 3) + " " + data.year.toString().substring(2));

      marketColumnSeries.push(data.energyInMWH);
      marketLineSeries.push(data.totalEmissions);
    });

  // Creating the desired format
  const Data_RESM = {
    categories: marketCategories, // Get last 5 months
    columnSeries: marketColumnSeries, // Get last 5 months' energy consumption
    lineSeries: marketLineSeries, // Get last 5 months' renewable energy share
  };

  

  

  const options_dualAxis = {
    chart: {
      type: "column",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) and Emissions by Month",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      margin: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_RESM.categories,
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
    },
    yAxis: [
      {
        title: {
          text: "Energy consumption (MWh)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "Total emissions (tCO2e)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        opposite: true,
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
          tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_RESM.columnSeries,
        yAxis: 0,
      },
      {
        name: "Total emissions (tCO2e)",
        data: Data_RESM.lineSeries,
        type: "line",
        yAxis: 1,
      },
    ],
  };

  
  //RE share by month end

  // emission by business unit start
  const energyConsumptionByBusinessUnit = scope && scope.emissionsAndConsumptionByBusinessUnit;
  const ecbbucategories = [];
  const ecbbuelectricityconsuption = [];
  const ecbbumarketbased = [];
  const ecbbulocationbased = [];

  if (energyConsumptionByBusinessUnit) {
    Object.entries(energyConsumptionByBusinessUnit).forEach(([name, value]) => {
      ecbbucategories.push(name); // Pushing the name
      ecbbuelectricityconsuption.push(value.energyInMWH);
      ecbbumarketbased.push(value.totalEmissions);
    });
  }

  const Data_emission_BU = {
    categories: ecbbucategories,
    columnSeries: ecbbuelectricityconsuption,
    lineSeries: ecbbumarketbased,
  };

  const Data_emission_BU_location = {
    categories: ecbbucategories,
    columnSeries: ecbbuelectricityconsuption,
    lineSeries: ecbbumarketbased,
  };

  const dualAxis_emissionBU = {
    chart: {
      type: "column",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) and Emissions by BU (tCO2e)",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      margin: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_emission_BU.categories,
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
    },
    yAxis: [
      {
        title: {
          text: "Energy consumption (MWh)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "Total emissions (tCO2e)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        opposite: true,
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
          tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_emission_BU.columnSeries,
        yAxis: 0,
      },
      {
        name: "Total emissions (tCO2e)",
        data: Data_emission_BU.lineSeries,
        type: "line",
        yAxis: 1,
      },
    ],
  };

  

  // emission by business unit end

  // Emission by site type start
  const emissionSiteData = scope && scope.energyBySiteType;
  const data_emissionsite = {
    categories: Object.keys(emissionSiteData),
    values: Object.values(emissionSiteData).map((emission) => emission.energyInMWH),
  };

  const data_emissionsite_location = {
    categories: Object.keys(emissionSiteData),
    values: Object.values(emissionSiteData).map((emission) => emission.locationBasedEmissions),
  };

  //Site type data
  const optionsPieChart = {
    chart: {
      type: "pie",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) by Site Type",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Set this to create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%", // Removed decimals from percentage
          style: {
            fontWeight: "400", // Set font weight to normal
          },
        },
        showInLegend: true,
        size: "80%",
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: data_emissionsite.categories.map((site, index) => ({
          name: site,
          y: Math.round(data_emissionsite.values[index]), // Round the values to the nearest integer
        })),
        tooltip: {
          style: {
            fontSize: "12px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">' +
              this.series.name +
              "</span>: " +
              new Intl.NumberFormat().format(this.y) +
              " (" +
              this.percentage.toFixed(0) +
              "%)<br/>"
            );
          },
        },
      },
    ],
  };

  

  // Emission by site type end
  // Dummy map data for Great Britain (UK)
  //Market Based
  const mapData =
    scope &&
    scope.emissionsAndConsumptionByCountry
      .map((country) => {
        // Check if the country is "United States"
        const usLatLng = LatLng.find((item) => item.country === country.country);
        if (usLatLng) {
          // Check if latLng for "United States" is found
          const formattedZ = parseFloat(country.totalEmissions.toFixed(2));
          const formattedY = parseFloat(country.energyInMWH.toFixed(2));
          return {
            z: formattedZ, // Use locationBasedEmissions as the value for bubble size
            Y: formattedY, // Use locationBasedEmissions as the value for bubble size
            keyword: country.country, // Country name
            name: country.country,
            lat: usLatLng.latitude, // Latitude
            lon: usLatLng.longitude, // Longitude
          };
        }
        return null;
      })
      .filter((data) => data !== null); // Remove null values from the array
  const mapOptions = {
    chart: {
      map: "custom/world",
      height: "50%",
    },
    title: {
      text: "Total energy consumption (MWh) and emissions(tCO2e) footprint",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      headerFormat: "",
      formatter: function () {
        return (
          "<b>" +
          this.point.keyword +
          "</b><br>Emission (tCO2e): " +
          new Intl.NumberFormat().format(Math.round(this.point.z)) +
          "<br>Energy Consumption (MWh): " +
          new Intl.NumberFormat().format(Math.round(this.point.Y))
        );
      },
    },
    series: [
      {
        // Use the gb-all map with no data as a basemap
        name: "",
        mapData: mapDataWorld,
        borderColor: "#A0A0A0",
        nullColor: "rgba(200, 200, 200, 0.3)",
        showInLegend: false,
      },
      {
        // Specify points using lat/lon
        // Specify points using lat/lon
        type: "mapbubble",
        name: "",
        color: "#fab430",
        data: mapData,
        cursor: "pointer",
        showInLegend: false,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
        point: {
          events: {
            click: function () {
              console.log(this.keyword);
            },
          },
        },
      },
    ],
  };

  //Location Based
  //Location Based
  const mapDataL =
    scope &&
    scope.emissionsAndConsumptionByCountry
      .map((country) => {
        // Check if the country is found in LatLng data
        const latLng = LatLng.find((item) => item.country === country.country);
        if (latLng) {
          // Check if latLng for the country is found
          return {
            z: parseFloat(country.totalEmissions.toFixed(2)), // Use locationBasedEmissions as the value for bubble size
            keyword: country.country, // Country name
            name: country.country,
            lat: latLng.latitude, // Latitude
            lon: latLng.longitude, // Longitude
            electricityConsumptionGJ: country.energyInMWH, // Add electricityConsumptionGJ
          };
        }
        return null;
      })
      .filter((data) => data !== null); // Remove null values from the array

  const mapOptionsLocation = {
    chart: {
      map: "custom/world",
      height: "50%",
    },
    title: {
      text: "Total energy consumption (MWh) and emissions(tCO2e) footprint",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: "left",
        verticalAlign: "top",
        symbolSize: 18,
        theme: {
          "stroke-width": 1,
          r: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      headerFormat: "",
      formatter: function () {
        return (
          "<b>" +
          this.point.keyword +
          "</b><br>Emission (tCO2e): " +
          new Intl.NumberFormat().format(Math.round(this.point.z)) +
          "<br>Energy Consumption (MWh): " +
          new Intl.NumberFormat().format(Math.round(this.point.electricityConsumptionGJ))
        );
      },
    },
    series: [
      {
        // Use the gb-all map with no data as a basemap
        name: "Basemap",
        mapData: mapDataWorld,
        borderColor: "#A0A0A0",
        nullColor: "rgba(200, 200, 200, 0.3)",
        showInLegend: false,
      },
      {
        // Specify points using lat/lon
        type: "mapbubble",
        name: "",
        color: "#fab430",
        data: mapDataL,
        cursor: "pointer",
        showInLegend: false,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
        point: {
          events: {
            click: function () {
              console.log(this.keyword);
            },
          },
        },
      },
    ],
  };

  const yearss = activeyears && activeyears.map((status) => status);
  const activelegal = activeetities && activeetities.map((status) => status);
  const activebusiness = businessunit && businessunit.map((status) => status);
  const activesites = activesite && activesite.map((status) => status);
  const activecountry = countries && countries.map((status) => status);
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };


  const locationBasedEmissions = scope.locationBasedEmissions != null
  ? Number(scope.locationBasedEmissions.toFixed(0)).toLocaleString()
  : '';

  const marketBasedEmissions = scope.marketBasedEmissions != null
  ? Number(scope.marketBasedEmissions.toFixed(0)).toLocaleString()
  : '';

  const totalElectricityConsumptionGJ = scope.totalElectricityConsumptionGJ != null
  ? Number(scope.totalElectricityConsumptionGJ.toFixed(0)).toLocaleString()
  : '';

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        {/* Rest of your existing JSX content */}
        <div class="container">
          <div className="row mt-25 pd-lr-50">
            <div className="col-md-6">
              <div Class="steps__containt-hader">
                <h3 class="title">Scope 1 Emission</h3>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end"></div>
          </div>

          <div className="row mt-25 pd-lr-50">
            <div className="col">
              <label className="formlable-search" htmlFor="inputState">
                Legal entity{" "}
              </label>
              <MultiSelect
                value={legal}
                onChange={(e) => {
                  setLegal(e.target.value);
                  handleLegalChange(e.target.value);
                }}
                options={activelegal}
                placeholder="Select Legal"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col">
              <label className="formlable-search" htmlFor="inputState">
                Business unit{" "}
              </label>
              <MultiSelect
                value={business}
                onChange={(e) => {
                  setBusiness(e.target.value);
                  handleBusinessChange(e.target.value);
                }}
                options={activebusiness}
                placeholder="Select Country"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col">
              <label className="formlable-search" htmlFor="inputState">
                Country
              </label>
              <MultiSelect
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  handleCountryChange(e.target.value);
                }}
                options={activecountry}
                placeholder="Select Country"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col">
              <label className="formlable-search" htmlFor="inputState">
                Site location
              </label>
              <MultiSelect
                value={site}
                onChange={(e) => {
                  setSite(e.target.value);
                  handleSiteChange(e.target.value);
                }}
                options={activesites}
                placeholder="Select Site"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col">
              <label className="formlable-search" htmlFor="inputState">
                Year
              </label>
              <MultiSelect
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                  handleYearChange(e.target.value);
                }}
                options={yearss}
                placeholder="Select Year"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
          </div>

          {scope ? (
            <div>
              <div id="marketBased">
                <div className="row mt-25 mb-25 pd-lr-50">
                  <div className="col-md-4">
                    <div className="employee-box">
                      <div className="employee-box-header-scope2">
                        Scope 1 emission <span className="dashboard-value">(tCO2e)</span>
                      </div>
                      <div className="employee-box-content">
                        {scope1Emission}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="employee-box">
                      <div className="employee-box-header-scope2">
                        Total energy consumption <span className="dashboard-value">(MWh)</span>
                      </div>
                      <div className="employee-box-content">
                        {energyConsumption}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="employee-box">
                      <div className="employee-box-header-scope2">
                        Scope 1 emission intensity
                        <span className="dashboard-value">(tCO2e/mn EUR)</span>
                      </div>
                      <div className="employee-box-content">
                        {emissionIntensity}
                        
                      </div>
                    </div>
                  </div>
                </div>

                {/* Top Electricity Supplier */}
                <div className="row mt-25 mb-25 pd-lr-50">
                  <div className="col-md-8">
                    <div className="charts">
                      <div style={{ marginBottom: "20px" }}>
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact
                            constructorType={"mapChart"}
                            highcharts={Highcharts}
                            options={mapOptions}
                          />
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts">
                      <div style={{ marginBottom: "20px" }}>
                        <ResponsiveContainer width="100%" height="90%">
                          <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Enery Consuption */}
                <div className="row pd-lr-50">
                  <div className="col-md-8">
                    <div className="charts">
                      <ResponsiveContainer width="100%" height="70%">
                        <HighchartsReact highcharts={Highcharts} options={options_dualAxis} />
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts" style={{ overflowX: "auto" }}>
                      <ResponsiveContainer width="100%" height="90%">
                        <div>
                          <HighchartsReact highcharts={Highcharts} options={siteResponseRateOptions} />
                        </div>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>

                {/* Energy Emission */}
                <div className="row mt-25 mb-25 pd-lr-50">
                  <div className="col-md-6">
                    <div className="charts">
                      <ResponsiveContainer width="100%" height="70%">
                        <HighchartsReact highcharts={Highcharts} options={optionsPieChart} />
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="charts">
                      <ResponsiveContainer width="100%" height="70%">
                        <HighchartsReact highcharts={Highcharts} options={dualAxis_emissionBU} />
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
}
