import React from "react";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { appInsights } from "./AppInsights";
import Swal from "sweetalert2";
import AADHeader from "./components/AADHeader.js";

export default function Home() {
  const username = localStorage.getItem("userName")
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [countries, setCountries] = useState();
  const [loading, setLoading] = React.useState(false);

  async function fetchData() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchData();
  }, []);

  const generatePassword = (length = 12) => {
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*()_-+=<>?/{}[]";

    const allCharacters = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters.charAt(randomIndex);
    }

    return password;
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const countri = [country];
      let res = await fetch("https://ecoprismapi.azurewebsites.net/create-company", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'username':username
        },
        body: JSON.stringify({
          name: entityname,
          revenue: revenue,
          countries: countri,
          numberOfEmployees: employees,
          adminEmail: adminemail,
          adminName: adminname,
          authenticationType: authtype,
        }),
      });
      if (res.status === 200) {
        const responseJson1 = await res.json();
        if (responseJson1.success === false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "User already exist",
          });
        } else if (responseJson1.status === 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson1.title,
          });
        } else {
          let token = await fetch("https://ecoprismapi.azurewebsites.net/GetAuth0Token", {
            method: "POST",
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
              grant_type: "client_credentials",
              audience: "https://dev-bzvpxk64kwf4dxfs.us.auth0.com/api/v2/",
            }),
          });
          if (token.status === 200) {
            const responseToken = await token.json();
            const accessToken = responseToken.access_token;
            const newPassword = generatePassword();
            let accessRes = await fetch("https://ecoprismapi.azurewebsites.net/CreateUser", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                token: accessToken,
              },
              body: JSON.stringify({
                email: adminemail,
                given_name: entityname,
                family_name: entityname,
                name: entityname,
                nickname: entityname,
                connection: "Username-Password-Authentication",
                password: newPassword,
              }),
            });
            if (accessRes.status === 200) {
              const accessresponseJson = await accessRes.json();
            }
          }
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: "Company created successfully",
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "SOmething went wrong",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [entityname, setEntityName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [country, setCountry] = useState("");
  const [employees, setEmployees] = useState("");
  const [adminname, setAdminName] = useState("");
  const [adminemail, setAdminEmail] = useState("");
  const [authtype, setauthenticationType] = useState("");
  const [message, setMessage] = useState("");
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container">
          <div class="col-md-12">
            <div Class="steps__containt-hader">
              <h3 class="title">Add New Company</h3>
            </div>
            {message && <Alert variant="success">{message}</Alert>}
            <form onSubmit={handleSubmit}>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="formlable" for="inputcompany">
                    Company Name
                  </label>

                  <input
                    type="text"
                    value={entityname}
                    onChange={(e) => setEntityName(e.target.value)}
                    class="form-control"
                    placeholder="Enter entity name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="formlable" for="inputState">
                    Country
                  </label>
                  <select
                    id="inputState"
                    class="form-control"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option selected value="">
                      Select Country
                    </option>
                    {countries &&
                      countries.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="formlable" for="inputCity">
                    Admin Email
                  </label>
                  <input
                    type="text"
                    value={adminemail}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    class="form-control"
                    id="inputCity"
                    placeholder="Enter Company revenue"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="formlable" for="inputZip">
                    Admin Name
                  </label>
                  <input
                    type="text"
                    value={adminname}
                    onChange={(e) => setAdminName(e.target.value)}
                    class="form-control"
                    id="inputZip"
                    placeholder="no. of employees"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="formlable" for="inputCity">
                    Revenue
                  </label>
                  <input
                    type="text"
                    value={revenue}
                    onChange={(e) => setRevenue(e.target.value)}
                    class="form-control"
                    id="inputCity"
                    placeholder="Enter Company revenue"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="formlable" for="inputZip">
                    No. of Employees
                  </label>
                  <input
                    type="text"
                    value={employees}
                    onChange={(e) => setEmployees(e.target.value)}
                    class="form-control"
                    id="inputZip"
                    placeholder="no. of employees"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="formlable" for="inputState">
                    Authentication Type
                  </label>
                  <select
                    id="inputState"
                    class="form-control"
                    value={authtype}
                    onChange={(e) => setauthenticationType(e.target.value)}
                  >
                    <option selected value="">
                      Select Authentication Type
                    </option>
                    <option value="Auth0">Auth0</option>
                    <option value="AAD">AAD(Microsoft)</option>
                  </select>
                </div>
                <div class="form-group col-md-6"></div>
              </div>
              <button type="submit" class="btn btn-primary signin__btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
