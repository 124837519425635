import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tooltip } from "@mui/material";
import "./table.css";

const Repository = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); // State to hold table data
  const [filters, setFilters] = useState({
    fileName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [searchTerm, setSearchTerm] = useState(""); // State for the link search term

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch or initialize your data here
    const fetchData = async () => {
      // Sample data, replace this with your API call or data source
      const sampleData = [
        {
          fileName: "File 1",
          category: "Policy",
          userName: "Vasini",
          date: "11-07-2024 16:54:00",
        },
        {
          fileName: "File 2",
          category: "Policy",
          userName: "Vishakha",
          date: "11-07-2024 16:54:00",
        },
        // Add more data as needed
      ];
      setData(sampleData);
    };

    fetchData();
  }, []);

  const renderHeader1 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const deleteRow = (rowIndex) => {
    const updatedData = [...data];
    updatedData.splice(rowIndex, 1);
    setData(updatedData);
  };

  const deleteIconTemplate = (rowData, { rowIndex }) => {
    return (
      <Tooltip title="Delete" arrow>
        <img
          src="./assets/img/reports/delete-repo.svg"
          alt="Delete Icon"
          className="delete-icon actions-icon"
          onClick={() => deleteRow(rowIndex)}
        />
      </Tooltip>
    );
  };

  // Date Template to show Date and Time in two lines
  const dateTemplate = (rowData) => {
    const [date, time] = rowData.date.split(" ");
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  };

  // LinkCard component to display each link with edit/delete actions
  const LinkCard = ({ linkData, deleteLink }) => {
    return (
      <div className="link-card">
        <div className="link-header">
          <span className="category">{linkData.category}</span>
          <h3 className="link-title">{linkData.linkTitle}</h3>
          <a href={linkData.url} target="_blank" rel="noopener noreferrer" className="link-url">
            {linkData.url}
          </a>
        </div>
        <div className="action-icons">
          <Tooltip title="Edit" arrow>
            <img
              src="./assets/img/reports/edit.png"
              alt="Edit Icon"
              className="edit-icon actions-icon"
            />
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <img
              src="./assets/img/reports/delete-repo.svg"
              alt="Delete Icon"
              className="delete-icon actions-icon"
              onClick={() => deleteLink(linkData.linkTitle)} // Invoke delete function
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  // Sample link data for the links section
  const [linkData, setLinkData] = useState([
    { category: "Policy", linkTitle: "Link name 1", url: "https://finance.ec.europa.eu/index_en" },
    {
      category: "Guideline",
      linkTitle: "Link name 2",
      url: "https://finance.ec.europa.eu/index_en",
    },
    {
      category: "Guideline",
      linkTitle: "Link name 3",
      url: "https://finance.ec.europa.eu/index_en",
    },
    {
      category: "Guideline",
      linkTitle: "Link name 4",
      url: "https://finance.ec.europa.eu/index_en",
    },
  ]);

  // Filtered links based on search term
  const filteredLinks = linkData.filter(
    (link) =>
      link.linkTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      link.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      link.url.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteLink = (linkTitle) => {
    const updatedLinks = linkData.filter((link) => link.linkTitle !== linkTitle);
    setLinkData(updatedLinks); // Assuming you're using a `setLinkData` state updater
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader1()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="row mb-4">
            <div className="col-md-8">
              <div className="d-flex flex-column steps__containt-hader">
                <h1 className="title">Repository</h1>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" disabled>
                    Company
                  </Link>
                  <Typography color="text.primary">Repository​</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <div className="steps__containt-hader">
                <a className="add-cta" href="/add-more">
                  + Add More
                </a>
              </div>
            </div>
          </div>
          <div className="repository-wrapper">
            <div className="row gx-5">
              <div className="col-md-8">
                <h2 className="h2-title mb-4">Files</h2>
                <div className="data-responsive">
                  <DataTable
                    value={data}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 20, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters}
                    filterDisplay="row"
                    globalFilterFields={["fileName", "category", "userName", "date"]}
                    emptyMessage="No data found."
                  >
                    <Column
                      header="FileName"
                      field="fileName"
                      sortable
                      filter
                      filterPlaceholder="Filter"
                    />
                    <Column
                      header="Category"
                      field="category"
                      sortable
                      filter
                      filterPlaceholder="Filter"
                    />
                    <Column
                      header="User Name"
                      field="userName"
                      sortable
                      filter
                      filterPlaceholder="Filter"
                    />
                    <Column
                      header="Date"
                      field="date"
                      body={dateTemplate}
                      sortable
                      filter
                      filterPlaceholder="Filter"
                    />
                    <Column
                      header=""
                      body={deleteIconTemplate}
                      style={{ width: "3rem", textAlign: "center" }}
                    />
                  </DataTable>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="h2-title mb-0">Links</h2>

                  <span className="p-input-icon-left">
                    <img src="assets/img/search.png" className="search-i" alt="search" />
                    <InputText
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search"
                      className="wd-100"
                    />
                  </span>
                </div>

                <div className="links-wrapper">
                  {filteredLinks.map((link) => (
                    <LinkCard key={link.linkTitle} linkData={link} deleteLink={deleteLink} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Repository;
