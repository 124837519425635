import { useState, useMemo } from 'react';
import { sortRows, filterRows, paginateRows } from './helpers';
import { Pagination } from './Pagination';
import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LoginHeader from "./components/LoginHeader.js";
import Link from "@mui/material/Link";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from './theme/containerTheme.js'
import { useEffect,useLayoutEffect,useRef } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "./Spinner.css";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card,Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Select from "react-select";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableFooter from '@mui/material/TableFooter';
import { FaSearch } from 'react-icons/fa';
import { appInsights } from './AppInsights';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import DataTables from "datatables.net";


export const Table = ({ columns, rows }) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [globalSearch, setGlobalSearch] = useState('');
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' });
  const rowsPerPage = 10;

  const filteredRows = useMemo(() => {
    let result = filterRows(rows, filters);

    if (globalSearch) {
      result = result.filter((row) =>
        columns.some((column) => {
          const cellValue = row[column.accessor];
          return cellValue && cellValue.toString().toLowerCase().includes(globalSearch.toLowerCase());
        })
      );
    }

    return result;
  }, [rows, filters, globalSearch, columns]);

  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort]);
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);

  const count = filteredRows.length;
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleSearch = (value, accessor) => {
    setActivePage(1);

    if (accessor === 'global') {
      setGlobalSearch(value);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }));
    }
  };

  const handleSort = (accessor) => {
    setActivePage(1);
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }));
  };

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' });
    setActivePage(1);
    setFilters({});
    setGlobalSearch('');
  };

  return (
    <>
      <table className="table" style={{ width: '100%' }}>
        <thead>
          <tr>
            {columns.map((column) => {
              const sortIcon = () => {
                if (column.accessor === sort.orderBy) {
                  if (sort.order === 'asc') {
                    return '⬆️';
                  }
                  return '⬇️';
                } else {
                  return '️↕️';
                }
              }
              return (
                <th key={column.accessor}>
                  <span>{column.label}</span>
                  <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button>
                </th>
              );
            })}
          </tr>
          <tr>
            {columns.map((column) => {
              return (
                <th key={`${column.accessor}-search`}>
                  <input
                  className='search-input'
                    type="search"
                    placeholder={`Filter`}
                    value={column.accessor === 'global' ? globalSearch : filters[column.accessor]}
                    onChange={(event) => handleSearch(event.target.value, column.accessor)}
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {calculatedRows.map((row) => {
            return (
              <tr key={row.id}>
                {columns.map((column) => {
                  if (column.format) {
                    return <td key={column.accessor}>{column.format(row[column.accessor])}</td>;
                  }
                  return <td key={column.accessor}>{row[column.accessor]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {count > 0 ? (
        <Pagination
          activePage={activePage}
          count={count}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          setActivePage={setActivePage}
        />
      ) : (
        <p>No data found</p>
      )}

      <div>
        <p>
          <button onClick={clearAll}>Clear all</button>
        </p>
      </div>
    </>
  );
};
