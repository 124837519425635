import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import MaterialityPopup from "./MaterialityPopup.js";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MaterialityTabs from "./MaterialityTabs.js";
import { useNavigate } from "react-router-dom";
import { BlobServiceClient } from "@azure/storage-blob";
import csrdData from "./csrdData";
import AADHeader from "./components/AADHeader.js";

const steps = ["Start", "Materiality", "Module", "Input", "Report"];

export default function Materiality() {
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedReports, setSavedReports] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [formData, setFormData] = useState({
    selectedCheckboxes: [],
  });
  const [selectedCheckboxes1, setSelectedCheckboxes1] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (selectedCheckboxes) => {
    setSelectedCheckboxes(selectedCheckboxes);
  };

  async function uploadJsonFile(containerName, folderName, fileName, jsonData) {
    try {
      // Fetch SAS token from your API
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;

        // Create BlobServiceClient using the SAS token
        const blobServiceClient = new BlobServiceClient(connectionString);

        // Get or create container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        //await containerClient.createIfNotExists();

        // Get BlobClient for the file
        const blobName = `${folderName}/${fileName}.json`; // Construct blob name with folder path
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        console.log(blobClient);
        // Convert JSON data to ArrayBuffer
        const arrayBuffer = new TextEncoder().encode(JSON.stringify(jsonData));
        // Upload the file content
        const uploadResponse = await blockBlobClient.uploadBrowserData(arrayBuffer.buffer, {
          blobHTTPHeaders: { blobContentType: "application/json" }, // Set content type as JSON
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20, // Number of concurrent requests
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`JSON file "${fileName}" uploaded successfully.`);
          console.log(`Blob URL: ${blobClient.url}`);
        }
        // Perform any additional operations after successful upload
        // uploadFileName(fileName); // Assuming this is some function you want to call after upload
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading JSON file:", error);
    }
  }

  const handleSave = () => {
    const step1 = localStorage.getItem("step1");
    let step2;
    const step2Storage = localStorage.getItem("step2");
    if (!step2Storage) {
      step2 = [""];
    } else {
      step2 = JSON.parse(step2Storage);
    }
    const newEntry = {
      tabIndex: 3,
      panelIndex: 3,
      checkboxIndex: 3,
      label: "General",
      panelId: "general1",
    };
    const updatedStep2 = [...step2, newEntry];
    localStorage.setItem("step2", JSON.stringify(updatedStep2));
    // Step 3 Changes Start
    let updatedSelectedCheckboxes1 = [...selectedCheckboxes1];
    console.log(updatedStep2);
    csrdData.flatMap((tab, tabIndex) =>
      tab.accordionPanels.map((panel, panelIndex) => {
        if (panel) {
          panel.checkboxes.map((checkbox, checkboxIndex) => {
            // Check if the panelId matches
            const isSelected = updatedStep2.some((selected) => selected.panelId === panel.id);
            
            if (isSelected) {
              // Mark the checkbox as selected
              checkbox.selected = true;
              // Log indices and perform any action if checkbox is selected
              updatedSelectedCheckboxes1.push({
                tabIndex,
                panelIndex,
                checkboxIndex,
                label: checkbox.label,
                panelId: panel.id,
              });
              console.log(updatedSelectedCheckboxes1);
              localStorage.setItem("step3", JSON.stringify(updatedSelectedCheckboxes1));
            } else {
              // Optionally, you can handle the case where the checkbox is not selected
              checkbox.selected = false;
            }
          });
        }
      })
    );
    // Step 3 Changes End
    const step21 = localStorage.getItem("step2");
    const hash = localStorage.getItem("hash");
    const data = JSON.parse(step1);
    const rdata = {
      name: data.name,
      timeline: data.timeline,
      legalEntity: data.legalEntity,
      startDate: data.startDate,
      endDate: data.endDate,
      materiality: updatedStep2,
    };
    uploadJsonFile("ecoprismfilecontainer", companyid, hash, rdata);
    navigate("/ReportingModules");
  };

  useEffect(() => {
    // Open the modal on page load
    setIsModalOpen(true);
  }, []);
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex justify-content-between">
            <div className="steps__containt-hader">
              <h3 className="title">Select Materiality Topics</h3>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Link underline="hover" color="inherit" href="/standards">
                    Standards & Frameworks
                  </Link>
                  <Link underline="hover" color="inherit" href="/csrd">
                    CSRD
                  </Link>
                  <Typography color="text.primary">New Report</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="stepper-box">
              <Stepper activeStep={1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-name">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <div>
            <div className="button-box d-flex justify-content-end">
              <Button
                onClick={() => navigate("/csrd")}
                variant="outlined"
                className="module-btn"
                size="medium"
                sx={{ marginRight: "15px" }}
              >
                <WestIcon fontSize="small" sx={{ marginRight: "4px" }} />
                Start
              </Button>
              <Button
                onClick={() => navigate("/reportDashboard")}
                variant="outlined"
                className="module-btn"
                size="medium"
                sx={{ marginRight: "15px" }}
              >
                <img src="/assets/img/reports/Status.svg" style={{ marginRight: "4px" }} />
                Status
              </Button>
              <Button
                onClick={() => navigate("/ReportingModules")}
                variant="outlined"
                className="module-btn"
                size="medium"
              >
                Module <EastIcon fontSize="small" sx={{ marginLeft: "4px" }} />
              </Button>
            </div>
            <MaterialityTabs />
            <div className="row justify-content-end pt-3">
              <div className="col-auto">
                <Button
                  variant="outlined"
                  className="outline-btn"
                  size="medium"
                  type="button"
                  onClick={() => navigate("/csrd")}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-auto">
                <Button
                  onClick={handleSave}
                  className="next-button"
                  variant="contained"
                  size="medium"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaterialityPopup isModalOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}
