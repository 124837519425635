import React from "react";
import { useLocation } from "react-router-dom";

function DisplayLabels() {
  const { state } = useLocation();

  // Check if the state prop is null
  if (!state) {
    return <div>No selected checkboxes.</div>;
  }

  const selectedCheckboxes = state.selectedCheckboxes;

  return (
    <div>
      <h2>Selected Checkbox Labels:</h2>
      {selectedCheckboxes.map((label, index) => (
        <p key={index}>{label}</p>
      ))}
    </div>
  );
}

export default DisplayLabels;
