import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function MaterialityPopup({ isModalOpen, onClose }) {
  return (
    <Modal className="reports-modal materiality-modal" show={isModalOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="module-title">Now you can select the material topics!</h5>
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>Material topic:</strong> ESRS puts forward the concept of double materiality
                which is union of sustainability matters from a perspective of impact materiality
                and financial materiality.
              </p>
            </div>
            <div className="col-md-6">
              <img src="./assets/img/reports/group-5992.png" width="100%" alt="" />
            </div>
          </div>
          <div className="materiality-bullets">
            <div className="materiality-point">
              <h5>1.</h5>
              <p>
                Conduct materiality assessment to evaluate your organizational impacts and risk to
                choose the material topics that are relevant to your organization.
              </p>
            </div>
            <div className="materiality-point">
              <h5>2.</h5>
              <p>
                The material topics are structured as per the ESRS standards and they come along
                with their sub-topics across three overarching themes of Environment, Social, and
                Governance.
              </p>
            </div>
            <div className="materiality-point">
              <h5>3.</h5>
              <p>
                From the list of material topics provided carefully choose the topics that are
                essential to your specific sustainability objectives and goals.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/csrd" variant="outlined" className="outline-btn" size="medium" type="button">
          Cancel
        </Button>
        <Button variant="primary" className="next-button" onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MaterialityPopup;
