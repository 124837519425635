import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Links from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import containerTheme from './theme/containerTheme.js'
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Paginator } from "primereact/paginator";
import "./table.css";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";
// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  const username = localStorage.getItem("userName");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const slParam = urlSearchParams.get("sl");
  const esg = urlSearchParams.get("esg");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [reporthistory, setReportHistory] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [supplier, setSupplier] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  // const [message, setMessage] = React.useState(false);
  const [startdate, setStartDate] = React.useState("");
  const [enddate, setEndDate] = React.useState("");
  const [sup, setSup] = React.useState("");
  const [own, setOwn] = React.useState("");
  const [electricitycost, setElectricityCost] = React.useState("");
  const [allusers, setAllUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [electricitysaving, setElectricitySaving] = React.useState("");
  const [electricityunitcost, setElectricityUnitCost] = React.useState("");
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [page, setPage] = useState(0);
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  const [inact, setInact] = useState("");
  const [act, setAct] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    roles: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    roles: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };
  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters1 };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };
  const header = renderHeader();
  const header1 = renderHeader1();

  async function fetchUsers() {
    setLoading(true);
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/all-registered-users",
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setAllUsers(data.data); // Set the retrieved user data to the state
        setActiveCounter(data.data.length);
        setInActiveCounter(0);
      } else {
        setMessage("Failed to fetch users");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage("Failed to fetch users");
      console.error(error);
    }
  }

  useEffect(() => {
    fetchUsers();
    setActive("block");
    setInactive("none");
    setInact("inactive-buttonred");
    setAct("active-buttongreen");
  }, []);

  const toggleTable = (name) => {
    // Initialize DataTable instance for the selected tab
    if (name === "active") {
      setActive("block");
      setInactive("none");
      setInact("inactive-buttonred");
      setAct("active-buttongreen");
    } else if (name === "inactive") {
      setInact("active-buttonred");
      setAct("inactive-buttongreen");
      setActive("none");
      setInactive("block");
    }
    setPage(0); // Reset page when switching tables
  };

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input type="text" value={value} readOnly placeholder="Start date" className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  );
  const CustomDatePickerInput1 = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input type="text" value={value} readOnly placeholder="End date" className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  );
  const renderHeader2 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader2()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9">
              <div Class="steps__containt-hader">
                <h3 class="title">Users List</h3>
                <div role="presentation">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                  >
                    <Links underline="hover" color="inherit">
                      Settings
                    </Links>
                    <Typography color="text.primary">User Management</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div Class="steps__containt-hader btalignleft">
                <Link to="/adduser" className="add-cta btalignleft">
                  + Add New User
                </Link>
              </div>
            </div>
          </div>
          <div class="container">{message}</div>
          <div class="col-md-12">
            <DataTable
              value={allusers}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              dataKey="id"
              filters={filters}
              filterDisplay="row"
              globalFilterFields={["name", "email", "roles"]}
              header={header}
              emptyMessage="No data found."
            >
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    <EditIcon
                      title="Edit"
                      style={{ cursor: "pointer", color: "#407775", width: "20px", height: "20px" }}
                    />

                    <img
                      className="action-icon"
                      src="assets/img/deactivate.png"
                      alt="Deactive"
                      style={{ cursor: "pointer", color: "#407775" }}
                    />
                  </div>
                )}
                style={{ minWidth: "5rem" }}
              />

              <Column
                sortable
                field="name"
                header="Name"
                filter
                filterPlaceholder="Filter"
                style={{ minWidth: "5rem" }}
              />
              <Column
                sortable
                header="Email"
                field="email"
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Filter"
              />
              <Column
                sortable
                header="Roles"
                field="roles"
                style={{ minWidth: "5rem" }}
                filter
                filterPlaceholder="Filter"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
