import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function InputPopup({ isModalOpen, onClose }) {
  return (
    <Modal className="reports-modal input-modal" show={isModalOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="module-title">
            Throughout the input screen, you will notice different icons to help ease the process.
            Below are detailed explanations for each icon.
          </h5>
          <div className="row first-row">
            <div className="col-md-4">
              <div class="card mandatory">
                <div class="card-header">
                  <h5>Mandatory</h5>
                  <div>
                    <img src="./assets/img/reports/Mandatory.svg" alt="" />
                  </div>
                </div>

                <div class="card-body">
                  <ul>
                    <li>
                      These are non-negotiable requirements set by ESRS standards that your company
                      must report to ensure compliance.
                    </li>
                    <li>
                      Make sure you disclose the necessary reporting requirements established by
                      regulatory bodies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card voluntary">
                <div class="card-header">
                  <h5>Voluntary</h5>
                  <div>
                    <img src="./assets/img/reports/voluntary.svg" alt="" />
                  </div>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      These disclosures focus on encouraging companies to provide extra information
                      beyond what is legally required.
                    </li>
                    <li>
                      You can choose these voluntary disclosures to emphasize your transparency and
                      accountability in the reporting.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card phased-in">
                <div class="card-header">
                  <h5>Phased in</h5>
                  <div>
                    <img src="./assets/img/reports/phased-in.svg" alt="" />
                  </div>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      Disclosure requirements phased in by regulations, allowing you to be skipped
                      initially but requires you to disclose over time.
                    </li>
                    <li>
                      The proposed timeline for the disclosure is provided along with their
                      effective dates.
                    </li>
                    <li>
                      It's essential for your organization to prepare for their eventual
                      implementation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row first-row">
            <div className="col-md-4">
              <div class="card eu-reg">
                <div class="card-header">
                  <h5>Alignment with other EU Regulations</h5>
                  <div style={{ padding: "0" }}>
                    <img
                      src="./assets/img/reports/eu-reg.png"
                      style={{ width: "50px", marginTop: "-3px", marginLeft: "-5px" }}
                      alt=""
                    />
                  </div>
                </div>

                <div class="card-body">
                  <ul>
                    <li>
                      These are data points that are aligned with other EU regulations (such as
                      SFDR, Pillar 3, Benchmark Regulation, EU Climate Law). If this data point is
                      material to your company, you must report on that to ensure compliance.
                    </li>
                    <li>
                      If it is not material, you can explicitly mention that "it is not material"
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card info">
                <div class="card-header">
                  <h5>Information (i) icon</h5>
                  <div>
                    <img src="./assets/img/reports/info.svg" alt="" />
                  </div>
                </div>

                <div class="card-body">
                  <ul>
                    <li>
                      Beside each disclosure title, you'll find this (i) icon, indicating the
                      disclosure requirement and offering a clearer understanding of the necessary
                      materials and provides a brief overview of the specific disclosure as per ESRS
                      standards.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card ai">
                <div class="card-header">
                  <h5>Generate with AI</h5>
                  <div>
                    <img src="./assets/img/reports/ai.svg" alt="" />
                  </div>
                </div>

                <div class="card-body">
                  <ul>
                    <li>
                      These are non-negotiable requirements set by ESRS standards that your company
                      must report to ensure compliance.
                    </li>
                    <li>
                      Make sure you disclose the necessary reporting requirements established by
                      regulatory bodies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          href="/ReportingModules"
          variant="outlined"
          className="outline-btn"
          size="medium"
          type="button"
        >
          Cancel
        </Button>
        <Button variant="primary" className="next-button" onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InputPopup;
