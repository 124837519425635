import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import ReportsCarousel from "./ReportsCarousel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ReportTable from "./ReportTable.js";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";

const Standards = () => {
  localStorage.removeItem("step1");
  localStorage.removeItem("step2");
  localStorage.removeItem("step3");
  localStorage.removeItem("step4");
  localStorage.removeItem("answers");
  localStorage.removeItem("answers1");
  localStorage.removeItem("tableAnswers");
  localStorage.removeItem("hash");
  localStorage.removeItem("selectedOptions");
  localStorage.removeItem("mdrp");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex justify-content-between">
            <div class="steps__containt-hader">
              <h3 class="title">Standards & Frameworks</h3>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Typography color="text.primary">Standards & Frameworks</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="standards-ct">
            <div class="col-md-12 flex pd-0">
              <h2>Start a new report with built-in frameworks</h2>
            </div>
            <ReportsCarousel />
            <div class="col-md-12 flex pd-0 pt-20">
              <h2 style={{ marginTop: "30px" }}>Existing Reports</h2>
            </div>
            <ReportTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Standards;
