import React from "react";
import clsx from "clsx";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect , useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appInsights } from "./AppInsights";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
import LatLng from "./country-codes-lat-long.json";
import { MultiSelect } from "primereact/multiselect";
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Label } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { LineChart, Line, Legend } from "recharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsFullscreen from "highcharts/modules/full-screen";
import worldMap from "highcharts/modules/map";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { InputSwitch } from "primereact/inputswitch";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsMap from "highcharts/modules/map"; // Import the map module
import proj4 from "proj4";
import mapDataWorld from "@highcharts/map-collection/custom/world.geo.json";
import SolidGauge from "highcharts/modules/solid-gauge";
import AADHeader from "./components/AADHeader.js";
import { useCallback } from "react";
import debounce from "lodash/debounce";
// Initialize the exporting module
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsFullscreen(Highcharts);
HighchartsMap(Highcharts);
highchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts.setOptions({
  colors: [
    "#407775",
    "#fab430",
    "#c8e1e0",
    "#b8d9d8",
    "#b8d9d8",
    "#b8d9d8",
    "#b8d9d8",
    "#fcd283",
    "#fde4b4",
    "#fbc051",
    "#e09405",
    "#fef6e6",
  ], // Define your custom colors here
});
export default function Home() {
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emissionbased, setEmissionBased] = React.useState(false);
  const [news, setNews] = useState(false);
  const [responseConfig, setResponseConfig] = useState({});
  const [legal, setLegal] = useState([]);
  const [business, setBusiness] = useState([]);
  const [site, setSite] = useState([]);
  const [year, setYear] = useState([]);
  const [allSupplier, setAllSupplier] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [country, setCountry] = useState([]);
  const [employee, setEmployee] = useState("");
  const [scope, setScope] = useState("");
  const [yearr, setYearR] = useState("");
  const [countries, setCountries] = useState();
  const [businessunit, setBusinessUnit] = useState();
  const [activesite, setActiveSite] = useState();
  const [activeetities, setActiveEntities] = useState();
  const [activeyears, setActiveYears] = useState();
  const [checked, setChecked] = useState(true);
  const [viewMode, setViewMode] = useState("locationBased");
  
  if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
  }
  // Function to handle toggle change
  const handleToggleChange = (value) => {
    console.log(value);
    if (value === true) {
      setViewMode("locationBased");
      setChecked(value);
    } else {
      setViewMode("marketBased");
      setChecked(value);
    }
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const handleChatClick = () => {
    navigate("/ChatPage");
  };
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  async function fetchFilters() {
    
    try {
      const country = JSON.parse(localStorage.getItem('country'));
      const year = JSON.parse(localStorage.getItem('year'));
      const legal = JSON.parse(localStorage.getItem('legal'));
      const business = JSON.parse(localStorage.getItem('business'));
      const site = JSON.parse(localStorage.getItem('site'));
      const supplier = JSON.parse(localStorage.getItem('supplier'));
      let response = await fetch("https://ecoprismapi.azurewebsites.net/scope2-filters", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          companyId: companyid,
          year: year,
          legalEntity: legal,
          businessUnit: business,
          country: country,
          site: site,
          supplier: supplier,
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
          setAllSupplier("");
        } else if (responseJson.status == 400) {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
          setAllSupplier("");
        } else {
          setActiveEntities(responseJson.legalEntity);
          setActiveYears(responseJson.year);
          setBusinessUnit(responseJson.businessUnit);
          setCountries(responseJson.country);
          setActiveSite(responseJson.site);
          setAllSupplier(responseJson.supplier);
        }
      } else {
          setActiveEntities("");
          setActiveYears("");
          setBusinessUnit("");
          setCountries("");
          setActiveSite("");
          setAllSupplier("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchScope2() {
    try {
      setLoading(true);
      const companyid = localStorage.getItem("companyid");
      const country = JSON.parse(localStorage.getItem('country'));
      const year = JSON.parse(localStorage.getItem('year'));
      const legal = JSON.parse(localStorage.getItem('legal'));
      const business = JSON.parse(localStorage.getItem('business'));
      const site = JSON.parse(localStorage.getItem('site'));
      const supplier = JSON.parse(localStorage.getItem('supplier'));
      var myHeaders = new Headers();
      myHeaders.append("username", username);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      let response = await fetch("https://ecoprismapi.azurewebsites.net/scope2", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          companyId: companyid,
          year: year,
          legalEntity: legal,
          businessUnit: business,
          country: country,
          site: site,
          supplier: supplier,
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success === false || responseJson.status === 400) {
          setLoading(false);
        } else {
          setScope(responseJson);
          console.log(responseJson);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchFilters();
    fetchScope2();
  }, []);

  

  

  const handleLegalChange = (selectedLegal) => {
    setLegal(selectedLegal);
    localStorage.setItem('legal', JSON.stringify(selectedLegal));
    fetchScope2();
    fetchFilters();
  };

  const handleBusinessChange = (selectedBusiness) => {
    setBusiness(selectedBusiness);
    localStorage.setItem('business', JSON.stringify(selectedBusiness));
    fetchScope2();
    fetchFilters();
  };

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    localStorage.setItem('country', JSON.stringify(selectedCountry));
    fetchScope2();
    fetchFilters();
  };

  const handleSiteChange = (selectedSite) => {
    setSite(selectedSite);
    localStorage.setItem('site', JSON.stringify(selectedSite));
    fetchScope2();
    fetchFilters();
  };

  const handleSupplierChange = (selectedSupplier) => {
    setSupplier(selectedSupplier);
    localStorage.setItem('supplier', JSON.stringify(selectedSupplier));
    fetchScope2();
    fetchFilters();
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    localStorage.setItem('year', JSON.stringify(selectedYear));
    fetchScope2();
    fetchFilters();
  };

  // Consuption by supplier start
  const consumptionBySupplier = scope && scope.consumptionBySupplier;

  const seriesData = consumptionBySupplier
    ? Object.entries(consumptionBySupplier).map(([supplierName, electricityConsumption]) => ({
        name: supplierName,
        y: parseFloat(electricityConsumption),
      }))
    : [];
  const seriesData_location = consumptionBySupplier
    ? Object.entries(consumptionBySupplier).map(([supplierName, electricityConsumption]) => ({
        name: supplierName,
        y: parseFloat(electricityConsumption),
      }))
    : [];
  const options_donut = {
    chart: {
      type: "pie",
      height: "90%",
    },
    title: {
      text: "Top Electricity suppliers",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: ({point.percentage:.0f}%)",
          style: {
            fontWeight: "500", // Set font weight to normal
          },
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      formatter: function () {
        const pointY = new Intl.NumberFormat().format(Math.round(this.y));
        return `${this.series.name}: ${pointY} (${this.percentage.toFixed(0)}%)`;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        colorByPoint: true,
        data: seriesData, // Assuming seriesData is defined elsewhere
      },
    ],
  };

  const options_donut_location = {
    chart: {
      type: "pie",
      height: "90%",
    },
    title: {
      text: "Top Electricity suppliers",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: ({point.percentage:.0f}%)",
          style: {
            fontWeight: "500", // Set font weight to normal
          },
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      formatter: function () {
        const pointY = new Intl.NumberFormat().format(Math.round(this.y));
        return `${this.series.name}: ${pointY} (${this.percentage.toFixed(0)}%)`;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        colorByPoint: true,
        data: seriesData_location, // Assuming seriesData_location is defined elsewhere
      },
    ],
  };

  // Consuption by supplier end

  const calculatePercentage = (total, value) => {
    return (value / total) * 100;
  };

  const sites = Object.keys(scope && scope.energyMixBySite);
  const categories = sites.map((site) => site);
  const nonRenewableData = sites.map((site) => scope && scope.energyMixBySite[site].nonRenewable);
  const renewableData = sites.map((site) => scope && scope.energyMixBySite[site].renewable);

  const data_emission = {
    categories: categories,
    series: [
      { name: "RE", data: renewableData },
      { name: "Non-RE", data: nonRenewableData },
    ],
  };

  // Calculate total for each category
  const totals = data_emission.categories.map((category, index) => {
    return data_emission.series.reduce((sum, series) => sum + series.data[index], 0);
  });

  // Calculate percentages based on totals
  const percentageData = data_emission.series.map((series) => ({
    name: series.name,
    data: series.data.map((value, index) => calculatePercentage(totals[index], value)),
  }));

  const options_stackedBar = {
    chart: {
      type: "bar",
      height: "100%",
    },
    title: {
      text: "Energy mix by site location",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    xAxis: {
      categories: data_emission.categories,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      tickPositions: [0, 25, 50, 75, 100],
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal", // Display legend items horizontally
      itemStyle: {
        // Style the legend items if needed
        color: "#333333",
        fontWeight: "normal",
      },
      itemMarginTop: 2, // Adjust top margin between legend items
      itemMarginBottom: 2, // Adjust bottom margin between legend items
      padding: 10, // Add padding around the legend
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 0,
      shadow: false,
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      headerFormat: "{point.x}<br/>",
      pointFormat: "{series.name}: {point.y:.0f}%<br/>",
    },
    plotOptions: {
      bar: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}%",
          style: {
            fontWeight: "400", // Set font weight to normal
          },
        },
        reversed: true, // Toggle to place bars on top
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen", "printChart", "downloadPNG"],
        },
      },
      buttonPosition: {
        align: "left",
        verticalAlign: "top",
        x: 10,
        y: 10,
      },
    },
    series: percentageData,
  };

  const options_stackedBar_location = {
    chart: {
      type: "bar",
      height: "100%",
    },
    title: {
      text: "Energy mix by site location",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    xAxis: {
      categories: data_emission.categories,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      tickPositions: [0, 25, 50, 75, 100],
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal", // Display legend items horizontally
      itemStyle: {
        // Style the legend items if needed
        color: "#333333",
        fontWeight: "normal",
      },
      itemMarginTop: 2, // Adjust top margin between legend items
      itemMarginBottom: 2, // Adjust bottom margin between legend items
      padding: 10, // Add padding around the legend
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 0,
      shadow: false,
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      headerFormat: "{point.x}<br/>",
      pointFormat: "{series.name}: {point.y:.0f}%<br/>",
    },
    plotOptions: {
      bar: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}%",
          style: {
            fontWeight: "400", // Set font weight to normal
          },
        },
        // Define your custom colors here
      },
    },
    series: percentageData,
    colors: ["#407775", "#fab430"],
  };

  //RE share by month start
  const marketCategories = [];
  const marketColumnSeries = [];
  const marketLineSeries = [];

  scope &&
    scope.energyConsumptionAndREShareByMonth.forEach((data) => {
      marketCategories.push(data.month.substring(0, 3) + " " + data.year.toString().substring(2));

      marketColumnSeries.push(data.electricityConsumptionInMwh);
      marketLineSeries.push(data.renewableEnergyShare);
    });

  // Creating the desired format
  const Data_RESM = {
    categories: marketCategories, // Get last 5 months
    columnSeries: marketColumnSeries, // Get last 5 months' energy consumption
    lineSeries: marketLineSeries, // Get last 5 months' renewable energy share
  };

  const Data_RESM_location = {
    categories: marketCategories, // Get last 5 months
    columnSeries: marketColumnSeries, // Get last 5 months' energy consumption
    lineSeries: marketLineSeries, // Get last 5 months' renewable energy share
  };

  // Convert line series values to percentages
  const lineSeriesPercentage = Data_RESM.lineSeries.map((value) => (value / 150) * 100);

  const lineSeriesPercentage_location = Data_RESM_location.lineSeries.map(
    (value) => (value / 150) * 100
  );

  const options_dualAxis = {
    chart: {
      type: "column",
      height: "50%",
      
    },
    title: {
      text: "Energy consumption (MWh) and RE Share by Month",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
       
      },
      margin: 100,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_RESM.categories,
      labels: {
        step: 1,
      },
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "",
        },
        opposite: true,
        labels: {
          format: "{value}%",
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}%", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          if (point.series.type === "line") {
            tooltip += `<span style="color:${point.series.color}">${
              point.series.name
            }</span>: ${Math.round(value)}%<br/>`;
          } else {
            value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
            tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
          }
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_RESM.columnSeries,
        yAxis: 0,
      },
      {
        name: "RE Share",
        data: lineSeriesPercentage,
        type: "line",
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
  };

  const options_dualAxis_location = {
    chart: {
      type: "column",
      height: "50%",
    },
    title: {
      text: "Energy consumption (MWh) and RE Share by Month",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_RESM_location.categories,
      labels: {
        step: 1,
      },
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "",
        },
        opposite: true,
        labels: {
          format: "{value}%",
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}%", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          if (point.series.type === "line") {
            tooltip += `<span style="color:${point.series.color}">${
              point.series.name
            }</span>: ${Math.round(value)}%<br/>`;
          } else {
            value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
            tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
          }
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_RESM_location.columnSeries,
        yAxis: 0,
      },
      {
        name: "RE Share",
        data: lineSeriesPercentage_location,
        type: "line",
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
  };

  //RE share by month end

  // emission by business unit start
  const energyConsumptionByBusinessUnit = scope && scope.emissionsAndConsumptionByBusinessUnit;
  const ecbbucategories = [];
  const ecbbuelectricityconsuption = [];
  const ecbbumarketbased = [];
  const ecbbulocationbased = [];

  if (energyConsumptionByBusinessUnit) {
    Object.entries(energyConsumptionByBusinessUnit).forEach(([name, value]) => {
      ecbbucategories.push(name); // Pushing the name
      ecbbuelectricityconsuption.push(value.electricityConsumptionInMwh);
      ecbbumarketbased.push(value.marketBasedEmissions);
      ecbbulocationbased.push(value.locationBasedEmissions);
    });
  }

  const Data_emission_BU = {
    categories: ecbbucategories,
    columnSeries: ecbbuelectricityconsuption,
    lineSeries: ecbbumarketbased,
  };

  const Data_emission_BU_location = {
    categories: ecbbucategories,
    columnSeries: ecbbuelectricityconsuption,
    lineSeries: ecbbumarketbased,
  };

  const dualAxis_emissionBU = {
    chart: {
      type: "column",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) and Emissions by BU (tCO2e)",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      margin: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_emission_BU.categories,
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
    },
    yAxis: [
      {
        title: {
          text: "Energy consumption (MWh)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "Market based emissions (tCO2e)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        opposite: true,
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
          tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_emission_BU.columnSeries,
        yAxis: 0,
      },
      {
        name: "Market based emissions (tCO2e)",
        data: Data_emission_BU.lineSeries,
        type: "line",
        yAxis: 1,
      },
    ],
  };

  const dualAxis_emissionBU_location = {
    chart: {
      type: "column",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) and Emissions by BU (tCO2e)",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      margin: 20, // Set bottom margin
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Data_emission_BU_location.categories,
      gridLineWidth: 0, // Hide major grid lines on x-axis
      minorGridLineWidth: 0, // Hide minor grid lines on x-axis
      style: {
        fontSize: "11px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
    },
    yAxis: [
      {
        title: {
          text: "Energy consumption (MWh)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
      {
        title: {
          text: "Location based emissions (tCO2e)",
          rotation: -90, // Rotate title to be displayed vertically
          style: {
            fontSize: "11px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
        },
        opposite: true,
        gridLineWidth: 0, // Hide major grid lines on y-axis
        minorGridLineWidth: 0, // Hide minor grid lines on y-axis
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#fab430",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for column data labels
        },
      },
      line: {
        color: "#407775",
        dataLabels: {
          enabled: false,
          format: "{y:.0f}", // No decimals for line data labels
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      shared: true,
      formatter: function () {
        let tooltip = "";
        this.points.forEach((point) => {
          let value = point.y;
          value = new Intl.NumberFormat().format(Math.round(value)); // Comma separated and no decimals
          tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: ${value}<br/>`;
        });
        return tooltip;
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: Data_emission_BU_location.columnSeries,
        yAxis: 0,
      },
      {
        name: "Location based emissions (tCO2e)",
        data: Data_emission_BU_location.lineSeries,
        type: "line",
        yAxis: 1,
      },
    ],
  };

  // emission by business unit end

  // Emission by site type start
  const emissionSiteData = scope && scope.energyBySiteType;
  const data_emissionsite = {
    categories: Object.keys(emissionSiteData),
    values: Object.values(emissionSiteData).map((emission) => emission.electricityConsumptionInMwh),
  };

  const data_emissionsite_location = {
    categories: Object.keys(emissionSiteData),
    values: Object.values(emissionSiteData).map((emission) => emission.electricityConsumptionInMwh),
  };

  //Site type data
  const optionsPieChart = {
    chart: {
      type: "pie",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) by Site Type",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Set this to create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%", // Removed decimals from percentage
          style: {
            fontWeight: "400", // Set font weight to normal
          },
        },
        showInLegend: true,
        size: "80%",
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: data_emissionsite.categories.map((site, index) => ({
          name: site,
          y: Math.round(data_emissionsite.values[index]), // Round the values to the nearest integer
        })),
        tooltip: {
          style: {
            fontSize: "12px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">' +
              this.series.name +
              "</span>: " +
              new Intl.NumberFormat().format(this.y) +
              " (" +
              this.percentage.toFixed(0) +
              "%)<br/>"
            );
          },
        },
      },
    ],
  };

  const optionsPieChartlocation = {
    chart: {
      type: "pie",
      height: "80%",
    },
    title: {
      text: "Energy consumption (MWh) by Site Type",
      align: "left", // Align text to the left
      style: {
        fontSize: "14px", // Set font size
        fontWeight: "400", // Set font weight
        color: "#494949",
      },
      marginBottom: 20, // Set bottom margin
    },
    credits: {
      enabled: false, // Disable the credits link
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Set this to create a donut chart
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%", // Removed decimals from value and percentage
          style: {
            fontWeight: "400", // Set font weight to normal
          },
        },
        showInLegend: true,
        size: "80%",
        colors: ["#407775", "#fab430"], // Define your colors here
      },
    },
    series: [
      {
        name: "Energy consumption (MWh)",
        data: data_emissionsite_location.categories.map((site, index) => ({
          name: site,
          y: Math.round(data_emissionsite_location.values[index]), // Round the values to the nearest integer
        })),
        tooltip: {
          style: {
            fontSize: "12px", // Set font size
            fontWeight: "400", // Set font weight
            color: "#494949",
          },
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">' +
              this.series.name +
              "</span>: " +
              new Intl.NumberFormat().format(this.y) +
              " (" +
              this.percentage.toFixed(0) +
              "%)<br/>"
            );
          },
        },
      },
    ],
  };

  // Emission by site type end
  // Dummy map data for Great Britain (UK)
  //Market Based

  // Prepare map data based on emissions and consumption by country
  const mapData =
    scope && scope.emissionsAndConsumptionByCountry
      ? scope.emissionsAndConsumptionByCountry
          .map((country) => {
            // Find the corresponding latitude and longitude
            const latLng = LatLng.find((item) => item.country === country.country);
            if (latLng) {
              return {
                z: parseFloat(country.electricityConsumptionInMwh.toFixed(2)), // Bubble size based on electricity consumption
                keyword: country.country, // Country name
                name: country.country,
                lat: latLng.latitude, // Latitude
                lon: latLng.longitude, // Longitude
                emissions: country.marketBasedEmissions, // Emissions for color
                electricityConsumptionInMwh: country.electricityConsumptionInMwh, // Add electricity consumption
              };
            }
            return null;
          })
          .filter((data) => data !== null) // Remove null values
      : []; // Initialize as an empty array if no data

  let mapOptions;

  if (mapData.length > 0) {
    // Calculate dynamic min and max electricity consumption values for bubble size
    const minElectricity = Math.min(...mapData.map((d) => d.z));
    const maxElectricity = Math.max(...mapData.map((d) => d.z));

    // Define map options
    mapOptions = {
      chart: {
        map: "custom/world",
        height: "50%", // Increase the height to use more space
      },
      title: {
        text: "Global energy consumption (MWh) and emissions (tCO2e) footprint",
        align: "left", // Align text to the left
        style: {
          fontSize: "14px", // Set font size
          fontWeight: "400", // Set font weight
          color: "#494949",
        },
        marginBottom: 20, // Set bottom margin
      },
      credits: {
        enabled: false,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          align: "left",
          verticalAlign: "top",
          symbolSize: 18,
          theme: {
            "stroke-width": 1,
            r: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px", // Set font size
          fontWeight: "400", // Set font weight
          color: "#494949",
        },
        headerFormat: "",
        formatter: function () {
          return (
            "<b>" +
            this.point.keyword +
            "</b><br>Emission (tCO2e): " +
            new Intl.NumberFormat().format(Math.round(this.point.emissions)) +
            "<br>Electricity Consumption (MWh): " +
            new Intl.NumberFormat().format(Math.round(this.point.electricityConsumptionInMwh))
          );
        },
      },
      colorAxis: {
        min: Math.min(...mapData.map((d) => d.emissions)),
        max: Math.max(...mapData.map((d) => d.emissions)),
        stops: [
          [0, "#407775"], // Low emission
          [1, "#FAB430"], // High emission
        ],
        labels: {
          format: "{value}",
          formatter: function () {
            const value = this.value;
            if (value >= 1000) {
              return (value / 1000).toFixed(1) + "k";
            }
            return value;
          },
        },
        margin: 0, // Remove margin around the color axis
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        title: {
          text: "Emissions (tCO2e)",
          style: {
            fontSize: "10px",
            fontWeight: "400",
            color: "#494949",
          },
        },
        symbolWidth: 400,
        itemStyle: {
          fontWeight: "400",
          fontSize: "10px",
          color: "#494949",
        },
        labelFormatter: function () {
          return '<span style="text-align:center;"></span>';
        },
        margin: 0, // Remove margin around the legend
        padding: 0, // Remove padding around the legend
        itemMarginTop: 0, // Reduce top margin for legend items
        itemMarginBottom: 0, // Reduce bottom margin for legend items
      },
      series: [
        {
          // Use the world map with no data as a basemap
          name: "Basemap",
          mapData: mapDataWorld,
          borderColor: "#A0A0A0",
          nullColor: "rgba(200, 200, 200, 0.3)",
          showInLegend: false,
        },
        {
          // Specify points using lat/lon
          type: "mapbubble",
          name: "",
          data: mapData,
          cursor: "pointer",
          showInLegend: false,
          minSize: "10%", // Adjusted for better visual differentiation
          maxSize: "40%", // Adjusted for better visual differentiation
          colorKey: "emissions", // Color based on emissions
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
          point: {
            events: {
              click: function () {
                console.log(this.keyword);
              },
            },
          },
          marker: {
            lineColor: "#000000",
            lineWidth: 0.5,
          },
        },
      ],
    };
  } else {
    console.error("No data available for the map.");
  }

  // marketbased
  const renewableEPercentage = Number.isFinite(scope.renewableEnergyPercentage)
    ? Number(scope.renewableEnergyPercentage.toFixed(0))
    : 0;
  const optionsSpeedo = {
    chart: {
      type: "solidgauge",
      height: "35%",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false, // Disable exporting module to hide the context menu
    },
    title: null,
    pane: {
      center: ["50%", "90%"],
      size: "200%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      stops: [
        [0.3, "#407775"], // green
        [0.6, "#407775"], // yellow
        [0.9, "#407775"], // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
        text: "",
      },
      labels: {
        enabled: false, // Hide the labels
      },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
          format: '<div style="text-align:center"><span style="font-size:15px">{y}%</span></div>',
        },
      },
    },
    series: [
      {
        name: "Percentage",
        data: [renewableEPercentage],
      },
    ],
  };

  const mapDataL =
    scope && scope.emissionsAndConsumptionByCountry
      ? scope.emissionsAndConsumptionByCountry
          .map((country) => {
            // Find the corresponding latitude and longitude
            const latLng = LatLng.find((item) => item.country === country.country);
            if (latLng) {
              return {
                z: parseFloat(country.electricityConsumptionInMwh.toFixed(2)), // Bubble size based on electricity consumption
                keyword: country.country, // Country name
                name: country.country,
                lat: latLng.latitude, // Latitude
                lon: latLng.longitude, // Longitude
                emissions: country.locationBasedEmissions, // Emissions for color
                electricityConsumptionInMwh: country.electricityConsumptionInMwh, // Add electricity consumption
              };
            }
            return null;
          })
          .filter((data) => data !== null) // Remove null values
      : []; // Initialize as an empty array if no data

  let mapOptionsLocation;

  if (mapDataL.length > 0) {
    // Calculate dynamic min and max electricity consumption values for bubble size
    const minElectricity = Math.min(...mapDataL.map((d) => d.z));
    const maxElectricity = Math.max(...mapDataL.map((d) => d.z));

    // Define map options
    mapOptionsLocation = {
      chart: {
        map: "custom/world",
        height: "50%", // Increase the height to use more space
      },
      title: {
        text: "Global energy consumption (MWh) and emissions(tCO2e) footprint",
        align: "left", // Align text to the left
        style: {
          fontSize: "14px", // Set font size
          fontWeight: "400", // Set font weight
          color: "#494949",
        },
        marginBottom: 10, // Set bottom margin
      },
      credits: {
        enabled: false,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          align: "left",
          verticalAlign: "top",
          symbolSize: 18,
          theme: {
            "stroke-width": 1,
            r: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px", // Set font size
          fontWeight: "400", // Set font weight
          color: "#494949",
        },
        headerFormat: "",
        formatter: function () {
          return (
            "<b>" +
            this.point.keyword +
            "</b><br>Emission (tCO2e): " +
            new Intl.NumberFormat().format(Math.round(this.point.emissions)) +
            "<br>Electricity Consumption (MWh): " +
            new Intl.NumberFormat().format(Math.round(this.point.electricityConsumptionInMwh))
          );
        },
      },
      colorAxis: {
        min: Math.min(...mapDataL.map((d) => d.emissions)),
        max: Math.max(...mapDataL.map((d) => d.emissions)),
        stops: [
          [0, "#407775"], // Low emission
          [1, "#FAB430"], // High emission
        ],
        labels: {
          format: "{value}",
          formatter: function () {
            const value = this.value;
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
      },
      series: [
        {
          // Use the world map with no data as a basemap
          name: "Basemap",
          mapData: mapDataWorld,
          borderColor: "#A0A0A0",
          nullColor: "rgba(200, 200, 200, 0.3)",
          showInLegend: false,
        },
        {
          // Specify points using lat/lon
          type: "mapbubble",
          name: "",
          data: mapDataL,
          cursor: "pointer",
          showInLegend: false,
          minSize: "10%", // Minimum bubble size
          maxSize: "40%", // Maximum bubble size
          colorKey: "emissions", // Color based on emissions
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
          point: {
            events: {
              click: function () {
                console.log(this.keyword);
              },
            },
          },
          marker: {
            lineColor: "#000000",
            lineWidth: 0.5,
          },
        },
      ],
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        title: {
          text: "Emissions (tCO2e)",
          style: {
            fontSize: "10px",
            fontWeight: "400",
            color: "#494949",
          },
        },
        symbolWidth: 400,
        itemStyle: {
          fontWeight: "400",
          fontSize: "10px",
          color: "#494949",
        },
        labelFormatter: function () {
          return '<span style="text-align:center;"></span>';
        },
        margin: 0,
        padding: 0,
        itemMarginTop: 5,
        height: 20,
        itemMarginBottom: 0,
      },
    };
  } else {
    console.error("No data available for the map.");
  }

  const activeyear = activeyears && activeyears.map((status) => status);
  const activelegal = activeetities && activeetities.map((status) => status);
  const activebusiness = businessunit && businessunit.map((status) => status);
  const activesites = activesite && activesite.map((status) => status);
  const activesupplier = allSupplier && allSupplier.map((status) => status);
  const activecountry = countries && countries.map((status) => status);
  //const activeyear = yearss.map((status) => status.name);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        {/* Rest of your existing JSX content */}
        <div class="container">
          <div className="row mt-25 pd-lr-50">
            <div className="col-md-6">
              <div Class="steps__containt-hader">
                <h3 class="title">Scope 2 Emission</h3>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <span style={{ fontSize: "13px", marginRight: "10px" }}>Market Based</span>
              <InputSwitch checked={checked} onChange={(e) => handleToggleChange(e.value)} />
              <span style={{ fontSize: "13px", marginLeft: "10px" }}>Location Based</span>
            </div>
          </div>

          <div className="row mt-25 pd-lr-50">
            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Legal entity{" "}
              </label>
              <MultiSelect
                value={legal}
                onChange={(e) => {
                  setLegal(e.target.value);
                  handleLegalChange(e.target.value);
                }}
                options={activelegal}
                placeholder="Select Legal"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Business unit{" "}
              </label>
              <MultiSelect
                value={business}
                onChange={(e) => {
                  setBusiness(e.target.value);
                  handleBusinessChange(e.target.value);
                }}
                options={activebusiness}
                placeholder="Select Country"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Country
              </label>
              <MultiSelect
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  handleCountryChange(e.target.value);
                }}
                options={activecountry}
                placeholder="Select Country"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Site location
              </label>
              <MultiSelect
                value={site}
                onChange={(e) => {
                  setSite(e.target.value);
                  handleSiteChange(e.target.value);
                }}
                options={activesites}
                placeholder="Select Site"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>
            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Supplier{" "}
              </label>
              <MultiSelect
                value={supplier}
                onChange={(e) => {
                  setSupplier(e.target.value);
                  handleSupplierChange(e.target.value);
                }}
                options={activesupplier}
                placeholder="Select Supplier"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
              />
            </div>

            <div className="col-md-2">
              <label class="formlable-search" for="inputState">
                Year
              </label>
              <MultiSelect
                value={year}
                onChange={(e) => handleYearChange(e.target.value)}
                options={activeyear}
                placeholder="Select Year"
                maxSelectedLabels={1}
                className="wd-85"
                showClear={true} // Add this line to show the clear icon
                showSelectAll={true} // Show the "Select All" checkbox
                selectAllLabel="Select All" // Label for the "Select All" checkbox
                style={{ "--placeholder-font-size": "16px" }}
              />
            </div>
          </div>
          {scope ? (
            <div>
              {viewMode === "marketBased" && (
                <div id="marketBased">
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Scope 2 emission-location based{" "}
                          <span className="dashboard-value">(tCO2e)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(scope.locationBasedEmissions.toFixed(0)).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Scope 2 emission-market based{" "}
                          <span className="dashboard-value">(tCO2e)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(scope.marketBasedEmissions.toFixed(0)).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Total energy consumption <br></br>
                          <span className="dashboard-value">(MWh)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(
                            scope.totalElectricityConsumptionInMwh.toFixed(0)
                          ).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">Renewable energy mix</div>
                        <div className="employee-box-content">
                          <HighchartsReact highcharts={Highcharts} options={optionsSpeedo} />
                          {/* {scope.renewableEnergyPercentage.toFixed(0)}% */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Top Electricity Supplier */}
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-8">
                      <div className="charts">
                        <div style={{ marginBottom: "5px" }}>
                          <ResponsiveContainer width="100%" height="80%">
                            <HighchartsReact
                              constructorType={"mapChart"}
                              highcharts={Highcharts}
                              options={mapOptions}
                            />
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="charts">
                        <div style={{ marginBottom: "20px" }}>
                          <ResponsiveContainer width="100%" height="90%">
                            <HighchartsReact highcharts={Highcharts} options={options_donut} />
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Enery Consuption */}
                  <div className="row pd-lr-50">
                    <div className="col-md-8">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact highcharts={Highcharts} options={options_dualAxis} />
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="charts" style={{ overflowX: "auto" }}>
                        <ResponsiveContainer width="100%" height="90%">
                          <div>
                            <HighchartsReact highcharts={Highcharts} options={options_stackedBar} />
                          </div>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>

                  {/* Energy Emission */}
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-6">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact highcharts={Highcharts} options={optionsPieChart} />
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact highcharts={Highcharts} options={dualAxis_emissionBU} />
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {viewMode === "locationBased" && (
                <div id="locationBased">
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Scope 2 emission-location based{" "}
                          <span className="dashboard-value">(tCO2e)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(scope.locationBasedEmissions.toFixed(0)).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Scope 2 emission-market based{" "}
                          <span className="dashboard-value">(tCO2e)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(scope.marketBasedEmissions.toFixed(0)).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">
                          Total energy consumption <br></br>
                          <span className="dashboard-value">(MWh)</span>
                        </div>
                        <div className="employee-box-content">
                          {Number(
                            scope.totalElectricityConsumptionInMwh.toFixed(0)
                          ).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="employee-box">
                        <div className="employee-box-header-scope2">Renewable energy mix</div>
                        <div className="employee-box-content">
                          <HighchartsReact highcharts={Highcharts} options={optionsSpeedo} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Top Electricity Supplier */}
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-8">
                      <div className="charts">
                        <div style={{ marginBottom: "5px" }}>
                          <ResponsiveContainer width="100%" height="80%">
                            <HighchartsReact
                              constructorType={"mapChart"}
                              highcharts={Highcharts}
                              options={mapOptionsLocation}
                            />
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="charts">
                        <div style={{ marginBottom: "20px" }}>
                          <ResponsiveContainer width="100%" height="90%">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options_donut_location}
                            />
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Enery Consuption */}
                  <div className="row pd-lr-50">
                    <div className="col-md-8">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options_dualAxis_location}
                          />
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="charts" style={{ overflowX: "auto" }}>
                        <ResponsiveContainer width="100%" height="90%">
                          <div>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options_stackedBar_location}
                            />
                          </div>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>

                  {/* Energy Emission */}
                  <div className="row mt-25 mb-25 pd-lr-50">
                    <div className="col-md-6">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsPieChartlocation}
                          />
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="charts">
                        <ResponsiveContainer width="100%" height="70%">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={dualAxis_emissionBU_location}
                          />
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
}
