import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LoginHeader from "./components/LoginHeader.js";
import Link from "@mui/material/Link";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./Spinner.css";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card, Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableFooter from "@mui/material/TableFooter";
import { FaSearch } from "react-icons/fa";
import { appInsights } from "./AppInsights";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import DataTables from "datatables.net";
import "datatables.net-fixedheader";
import { Table } from "./Table1";

export default function Home() {
  //let table = new DataTables('#myTable');
  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedOptions, setSelectedOptions] = useState();
  const [listEntities, setListEntities] = useState();
  const [dboptions, setDBOptions] = useState();

  // Array of all options
  const optionList = [];

  {
    listEntities &&
      listEntities.data.map((item) => optionList.push({ label: item.name, value: item.name }));
  }

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  async function fetchLegalEntities() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setListEntities("");
        } else if (responseJson.status == 400) {
          setListEntities("");
        } else {
          responseJson.data.map((item) => optionList.push({ label: item.name, value: item.name }));
          setListEntities(responseJson);
        }
      } else {
        setListEntities("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchLegalEntities();
  }, []);

  function createData(name, revenue, employee, entity) {
    return { name, revenue, employee, entity };
  }

  const [isLoading, setIsLoading] = useState(false);
  const [deletename, setDeleteName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [businessunits, setBusinessUnit] = useState();
  const [activebusinessunits, setActiveBusinessUnit] = useState();
  const [deactivebusinessunits, setDeactiveBusinessUnit] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employees, setEmployees] = useState("");
  const [message, setMessage] = useState("");
  const [currency, setCurrency] = useState("");
  const [getcurrency, setGetCurrency] = useState();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredRow1, setHoveredRow1] = useState(null);
  const [oldname, setOldName] = useState("");
  const [activeTable, setActiveTable] = useState(true);
  const [inactiveTable, setInactiveTable] = useState(false);
  let tcount = 0;

  const showEditModal = (name, revenue, employees, currency, le) => {
    setName(name);
    setOldName(name);
    setRevenue(revenue);
    setEmployees(employees);
    setCurrency(currency);
    const defaultList = [];
    {
      le && le.map((item) => defaultList.push({ label: item, value: item }));
    }
    setDBOptions(defaultList);
    setSelectedOptions(defaultList);
    console.log(le);
    setEditIsOpen(true);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  const showDeleteModal = (name) => {
    console.log(name);
    setDeleteName(name);
    setDeleteMessage("Are you sure you want to deactivate this " + name + "?");
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = async (deletename) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/delete-businessunit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          oldBusinessUnitName: deletename,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Business unit deactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          fetchBusinessUnits();
          setDisplayConfirmationModal(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops..",
          text: "Error in deactivate business unit",
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const saveData = async (name, revenue, employees, currencySS) => {
    setLoading(true);
    try {
      if (revenue === "" || revenue === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter revenue",
        });
        return false;
      }
      if (revenue <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Revenue must be greater than zero",
        });
        return false;
      }
      if (employees === "" || employees === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter employees",
        });
        return false;
      }
      if (employees <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "employee must be greater than zero",
        });
        return false;
      }
      if (currency === "" || currency === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select currency",
        });
        return false;
      }
      console.log(selectedOptions);
      var le = [];
      {
        selectedOptions.map((opt, index) => le.push(opt.value));
      }
      let res = await fetch("https://ecoprismapi.azurewebsites.net/update-businessunit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          old: oldname,
        },
        body: JSON.stringify({
          name: name,
          revenue: revenue,
          revenueCurrency: currency,
          associatedLegalEntities: [""],
          numberOfEmployees: employees,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">The BU has been successfully updated</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchBusinessUnits();
        setEditIsOpen(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in update business Unit",
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const reactive = async (name) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/reactive-business-unit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.data,
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseJson.title,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Business unit reactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchBusinessUnits();
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error in reactivate business unit",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function fetchBusinessUnits() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-businessunits",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          console.log(responseJson);
          setLoading(false);
          setBusinessUnit("");
        } else if (responseJson.status == 400) {
          setLoading(false);
          setBusinessUnit("");
        } else {
          setLoading(false);
          setBusinessUnit(responseJson);
          console.log(responseJson);
          setActiveBusinessUnit(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveBusinessUnit(responseJson.data.filter((item) => item.isActive === false));
          setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
          setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
        }
      } else {
        setLoading(false);
        setBusinessUnit("");
      }
      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  async function getCurrency() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/currencies");
      const data = await response.json();
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchBusinessUnits();
    getCurrency();
  }, []);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(10);
  const [inactiveRowsPerPage, setInactiveRowsPerPage] = useState(10);
  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const toggleTable = (isActive) => {
    setActiveTable(isActive);
    setPage(0); // Reset page when switching tables
  };

  const allRows = [
    { id: 1, name: "Liz Lemon", age: 36, is_manager: true, start_date: "02-28-1999" },
    { id: 2, name: "Jack Donaghy", age: 40, is_manager: true, start_date: "03-05-1997" },
    { id: 3, name: "Tracy Morgan", age: 39, is_manager: false, start_date: "07-12-2002" },
    { id: 4, name: "Jenna Maroney", age: 40, is_manager: false, start_date: "02-28-1999" },
    { id: 5, name: "Kenneth Parcell", age: Infinity, is_manager: false, start_date: "01-01-1970" },
    { id: 6, name: "Pete Hornberger", age: 42, is_manager: true, start_date: "04-01-2000" },
    { id: 7, name: "Frank Rossitano", age: 36, is_manager: false, start_date: "06-09-2004" },
    { id: 8, name: "Liz Lemon", age: 36, is_manager: true, start_date: "02-28-1999" },
    { id: 9, name: "Jack Donaghy", age: 40, is_manager: true, start_date: "03-05-1997" },
    { id: 10, name: "Tracy Morgan", age: 39, is_manager: false, start_date: "07-12-2002" },
    { id: 11, name: "Jenna Maroney", age: 40, is_manager: false, start_date: "02-28-1999" },
    { id: 12, name: "Kenneth Parcell", age: Infinity, is_manager: false, start_date: "01-01-1970" },
    { id: 13, name: "Pete Hornberger", age: 42, is_manager: true, start_date: "04-01-2000" },
    { id: 14, name: "Frank Rossitano", age: 95, is_manager: false, start_date: "06-09-2004" },
    { id: 15, name: "Liz Lemon", age: 22, is_manager: true, start_date: "02-28-1999" },
    { id: 16, name: "Jack Donaghy", age: 42, is_manager: true, start_date: "03-05-1997" },
    { id: 17, name: "Tracy Morgan", age: 78, is_manager: false, start_date: "07-12-2002" },
    { id: 18, name: "Jenna Maroney", age: 47, is_manager: false, start_date: "02-28-1999" },
    { id: 19, name: "Kenneth Parcell", age: Infinity, is_manager: false, start_date: "01-01-1970" },
    { id: 20, name: "Pete Hornberger", age: 56, is_manager: true, start_date: "04-01-2000" },
    { id: 21, name: "Frank Rossitano", age: 69, is_manager: false, start_date: "06-09-2004" },
  ];

  const [activeRows, setActiveRows] = useState(allRows.filter((row) => row.is_manager));
  const [inactiveRows, setInactiveRows] = useState(allRows.filter((row) => !row.is_manager));
  const [showActive, setShowActive] = useState(true);

  const columns = [
    { accessor: "name", label: "Name" },
    { accessor: "age", label: "Age" },
    { accessor: "is_manager", label: "Manager", format: (value) => (value ? "✔️" : "✖️") },
    { accessor: "start_date", label: "Start Date" },
  ];

  const handleButtonClick = (active) => {
    setShowActive(active);
  };
  const rows = showActive ? activeRows : inactiveRows;
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />{" "}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex">
            <div className="col-md-9 pd-0">
              <div className="steps__containt-hader">
                <h3 className="title">Business Units</h3>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" className="breadcrumbs-font">
                      Company
                    </Link>
                    <Typography color="text.primary">Business Unit</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div className="col-md-3 pd-0">
              <div className="steps__containt-hader btalignleft">
                <a href="/Businessunit" className="add-cta btalignleft">
                  + Add business unit
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-9 pd-0">
              <button
                className={activeTable ? "active-buttongreen" : "inactive-buttongreen"}
                onClick={() => handleButtonClick(true)}
              >
                <span className="record-count">{activeCounter}</span>Active
              </button>
              <button
                className={!activeTable ? "active-buttonred" : "inactive-buttonred"}
                onClick={() => handleButtonClick(false)}
              >
                <span className="record-count">{inactiveCounter}</span> Inactive
              </button>
            </div>
            <div class="col-md-3 btalignleft pd-0">
              <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  className="search-input1"
                  placeholder="Search all columns"
                  onChange={(event) => {
                    const searchValue = event.target.value.toLowerCase();
                    if (showActive) {
                      const filteredRows = allRows.filter((row) => row.is_manager);
                      setActiveRows(
                        filteredRows.filter((row) =>
                          Object.values(row).some(
                            (value) => value && value.toString().toLowerCase().includes(searchValue)
                          )
                        )
                      );
                    } else {
                      const filteredRows = allRows.filter((row) => !row.is_manager);
                      setInactiveRows(
                        filteredRows.filter((row) =>
                          Object.values(row).some(
                            (value) => value && value.toString().toLowerCase().includes(searchValue)
                          )
                        )
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              {showActive ? (
                <Table
                  className="display"
                  style={{ width: "100%" }}
                  rows={activeRows}
                  columns={columns}
                />
              ) : (
                <Table
                  className="display"
                  style={{ width: "100%" }}
                  rows={inactiveRows}
                  columns={columns}
                />
              )}
            </div>
          </div>

          <div className="col-md-12 pd-0">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <DeleteConfirmation
              confirmModal={submitDelete}
              showModal={displayConfirmationModal}
              hideModal={hideConfirmationModal}
              message={deleteMessage}
              deletename={deletename}
            />
            <Modal show={editIsOpen} onHide={hideEditModal} className="custom-modal-width">
              <Modal.Header>
                <div className="steps__containt-hader edit-form">
                  <h3 className="title">Edit Business Unit</h3>
                </div>
              </Modal.Header>

              <Modal.Body>
                <form className="edit-form">
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput">
                      Business unit <span className="mark-form">*</span>
                    </label>
                    <input
                      type="hidden"
                      onChange={(e) => setOldName(e.target.value)}
                      value={oldname}
                    />
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="formGroupExampleInput"
                      placeholder="Enter business unit"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput2">
                      Revenue <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      pattern="[0-9]+([,\.][0-9]+)?"
                      required
                      value={revenue}
                      onChange={(e) => setRevenue(e.target.value)}
                      id="formGroupExampleInput2"
                      placeholder="Revenue"
                    />
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="inputCity">
                      Revenue currency <span className="mark-form">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="Ecodropdown"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option selected value="">
                        Select currency
                      </option>
                      {getcurrency &&
                        getcurrency.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="formlable" for="formGroupExampleInput2">
                      No. of employees <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      pattern="[0-9]+"
                      value={employees}
                      onChange={(e) => setEmployees(e.target.value)}
                      id="formGroupExampleInput2"
                      placeholder="Enter no. of employees"
                    />
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="edit-form">
                  <Button
                    className="btn btn-primary mr10"
                    disabled={loading}
                    onClick={() => saveData(name, revenue, employees, currency)}
                  >
                    Update
                  </Button>
                  <Button className="btn btn-danger mr10" onClick={hideEditModal}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
