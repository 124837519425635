import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OpenAI } from 'openai';

const ChartComponent = () => {
  const [userInput, setUserInput] = useState('');
  const [chartOptions, setChartOptions] = useState(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const generateChartFromGPT = async () => {
    try {
      const openai = new OpenAI({
        apiKey: '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', // Replace with your actual API key
        dangerouslyAllowBrowser: true,
      });

      const gptResponse = await openai.Completion.create({
        engine: 'text-davinci-002',
        prompt: userInput,
        max_tokens: 150,
      });

      // Parse GPT response and generate Highcharts configuration
      const chartConfig = parseGPTResponse(gptResponse.choices[0]?.text);

      setChartOptions(chartConfig);
    } catch (error) {
      console.error('Error with GPTs integration', error);
    }
  };

  const parseGPTResponse = (gptResponse) => {
    // Implement logic to parse GPT response and generate Highcharts configuration
    // Example: Extract chart type, data points, labels, etc.
    const chartConfig = {
      title: {
        text: 'Dynamic Chart from GPT',
      },
      series: [{
        data: [10, 20, 30, 40, 50],
        type: 'bar',
      }],
    };

    return chartConfig;
  };

  useEffect(() => {
    // You might want to trigger chart generation when userInput changes
    generateChartFromGPT();
  }, [userInput]);

  return (
    <div>
      <h1>Chart Generated from GPT</h1>
      <label>
        Enter your chart requirement:
        <input type="text" value={userInput} onChange={handleInputChange} />
      </label>
      <button onClick={generateChartFromGPT}>Generate Chart</button>
      {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
    </div>
  );
};

export default ChartComponent;
