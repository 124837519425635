import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AADHeader from "./components/AADHeader.js";
// const useStyles = makeStyles((theme) => containerTheme(theme));

export default function Home() {
  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [responseConfig, setResponseConfig] = useState({});

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [value, onChange] = useState(new Date());
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9 pd-0">
              <div class="steps__containt-hader">
                <h3 class="title">Insights</h3>
                <div role="presentation"></div>
              </div>
            </div>
            <div class="col-md-3 pd-0"></div>
          </div>

          <div className="col-md-12 flex">
            <div class="col-md-4 pd-0">
              <div className="">
                <div className="inner-img">
                  <a href="/assessing-low-re">
                    <img src="./assets/img/i1.png" className=""></img>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="inner-img">
                  <a href="/comingsoon">
                    <img src="./assets/img/i2.png" className=""></img>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div className="">
                <div className="inner-img">
                  <a href="/comingsoon">
                    <img src="./assets/img/i3.png" className=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
