import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Links from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import { useEffect, useState, useLayoutEffect } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import TableFooter from "@mui/material/TableFooter";
import { FaSearch } from "react-icons/fa";
import { appInsights } from "./AppInsights";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import DataTables from "datatables.net";
import $ from "jquery";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Paginator } from "primereact/paginator";
import "./table.css";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "react-select";
import AADHeader from "./components/AADHeader.js";
export default function Home() {
  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function createData(name, type, country, revenue, employee) {
    return { name, type, country, revenue, employee };
  }
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredRow1, setHoveredRow1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [etities, setEntities] = useState();
  const [activeetities, setActiveEntities] = useState();
  const [deactiveetities, setDeactiveEntities] = useState();
  const [deletename, setDeleteName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [businessunits, setBusinessUnit] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [oldname, setOldName] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employees, setEmployees] = useState("");
  const [curcountry, setCurCountry] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [entitytype, setEntityType] = useState("");
  const [legalEntity, setLegalEntity] = useState();
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  const [industrialSector, setIndustrialSector] = useState("");
  const [getsector, setSector] = useState("");
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [inact, setInact] = useState("");
  const [act, setAct] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    entityType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenueCurrency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    countries: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numberOfEmployees: { value: null, matchMode: FilterMatchMode.CONTAINS },
    industrialSector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    entityType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    countries: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenueCurrency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    numberOfEmployees: { value: null, matchMode: FilterMatchMode.CONTAINS },
    industrialSector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive</span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive</span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters1 };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };

  const header = renderHeader();
  const header1 = renderHeader1();

  const showEditModal = (name, revenue, employees, curcountry, entitytype, currency, sector) => {
    setName(name);
    setOldName(name);
    setRevenue(revenue);
    setEmployees(employees);
    setCurCountry(curcountry);
    setEntityType(entitytype);
    setCurrency(currency);
    setIndustrialSector(sector);
    setEditIsOpen(true);
    console.log(entitytype);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  const showDeleteModal = (name) => {
    setDeleteName(name);
    setDeleteMessage("Are you sure you want to deactivate this " + name + "?");
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = async (deletename) => {
    setLoading(true);
    console.log(deletename);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/delete-legalentity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          name: deletename,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                   <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Legal entity deactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          fetchEntities();
          setDisplayConfirmationModal(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error in deactivate legal entity</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [getcurrency, setGetCurrency] = useState();
  const handleCurrency = (value, setCountry) => {
    setCountry(value);
    getCurrency(value);
  };

  const reactive = async (name) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/reactive-legal-entity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Legal entity reactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchEntities();
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error in reactivate legal entity</p>`,
          text: "Error in reactivate legal entity",
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function getCurrency() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/currencies");
      const data = await response.json();
      setGetCurrency(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function getIndustrialSector() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/industiralsector", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
      });
      const data = await response.json();
      setSector(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchEntities() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          console.log(responseJson);
          setLoading(false);
          setEntities("");
        } else if (responseJson.status == 400) {
          setLoading(false);
          setEntities("");
        } else {
          setLoading(false);
          setEntities(responseJson);
          setActiveEntities(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveEntities(responseJson.data.filter((item) => item.isActive === false));
          setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
          setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Something Went Wrong</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setEntities("");
      }

      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  async function fetchLegalEntityType() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/fetch-legal-entity-type");
      const data = await response.json();
      setLegalEntity(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchLegalEntityType();
    fetchEntities();
    getIndustrialSector();
    getCurrency();
  }, []);

  const [countries, setCountries] = useState();

  async function fetchCountries() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  const saveData = async (name, revenue, employees, country) => {
    setLoading(true);
    try {
      console.log(industrialSector);
      if (industrialSector === "" || industrialSector === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please select industrial sector</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (country === "" || country === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please select country</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (revenue === "" || revenue === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please enter revenue</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (revenue <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Revenue must be greater than zero</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (employees === "" || employees === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please enter employees</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (employees <= 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Employees must be greater than zero</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (!/^\d*$/g.test(employees)) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please enter whole number</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (currency === "" || currency === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please select currency</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',

          text: "Please select currency",
        });
        return false;
      }
      if (entitytype === "" || entitytype === null) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please select entity type</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      const countri1 = [country];
      const countryString = countri1.toString();
      const countri = countryString.replace(/\[|\]/g, "");
      let res = await fetch("https://ecoprismapi.azurewebsites.net/update-legalentity", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          old: oldname,
        },
        body: JSON.stringify({
          name: name,
          revenue: revenue,
          revenueCurrency: currency,
          entityType: entitytype,
          numberOfEmployees: employees,
          countries: [countri],
          industrialSector: industrialSector,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Legal entity updated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchEntities();
        setEditIsOpen(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Error in update legal entity</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activeTable, setActiveTable] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(10);
  const [inactiveRowsPerPage, setInactiveRowsPerPage] = useState(10);
  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [globalFilter, setGlobalFilter] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useLayoutEffect(() => {
    initializeDataTable();
    initializeDataTable1();
    setActive("block");
    setInactive("none");
    setInact("inactive-buttonred");
    setAct("active-buttongreen");
  }, [activeetities]);

  const initializeDataTable = () => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      var dataTable = $("#myTable").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();
          console.log(api);
          console.log("DataTables initialization complete");

          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Handle global search
          $("#globalSearch").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().toLowerCase();
                    return (
                      columnString.includes(enteredValue) ||
                      columnString.replace(/,/g, "").includes(enteredValue)
                    );
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const initializeDataTable1 = () => {
    if (!$.fn.DataTable.isDataTable("#myTable1")) {
      var dataTable1 = $("#myTable1").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();
          console.log(api);
          console.log("DataTables initialization complete");

          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Add a global search input
          $("#customSearchDivinactive").append(
            '<input type="text" id="globalSearch1" class="search-input search-container " placeholder="Search all data" />'
          );

          // Handle global search
          $("#globalSearch1").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters1 th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters1 th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().replace(/,/g, "").toLowerCase();
                    return columnString.includes(enteredValue);
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const toggleTable = (name) => {
    // Initialize DataTable instance for the selected tab
    if (name === "active") {
      setActive("block");
      setInactive("none");
      setInact("inactive-buttonred");
      setAct("active-buttongreen");
    } else if (name === "inactive") {
      setInact("active-buttonred");
      setAct("inactive-buttongreen");
      setActive("none");
      setInactive("block");
    }
    setPage(0); // Reset page when switching tables
  };

  // New Datable start
  const data =
    activeetities &&
    activeetities.map((row, rowIndex) => [
      <div className="actions">
        <div className="mr-20">
          <EditIcon
            style={{ cursor: "pointer", color: "#407775", width: "20px", height: "20px" }}
            onClick={() =>
              showEditModal(
                row.name,
                row.revenue,
                row.numberOfEmployees,
                row.countries && row.countries.map((country) => country),
                row.entityType,
                row.revenueCurrency,
                row.industrialSector
              )
            }
          />
          <img
            className="action-icon"
            src="assets/img/deactivate.png"
            alt="Deactivate"
            style={{ cursor: "pointer", color: "#407775" }}
            onClick={() => showDeleteModal(row.name)}
          />
        </div>
        {" " + row.name}
      </div>,
      row.entityType,
      row.countries && row.countries.join(", "),
      row.revenueCurrency + " " + row.revenue && row.revenue.toLocaleString(),
      row.numberOfEmployees && row.numberOfEmployees.toLocaleString(),
      row.industrialSector,
    ]);

  const ddata =
    deactiveetities &&
    deactiveetities.map((row, rowIndex) => [
      <div className="actions">
        <div className="mr-20">
          <img
            className="action-icon"
            src="assets/img/reactivate.png"
            alt="Reactivate"
            style={{ cursor: "pointer", color: "#407775" }}
            onClick={() => reactive(row.name)}
          />
        </div>
        {" " + row.name}
      </div>,
      row.entityType,
      row.countries && row.countries.join(", "),
      row.revenueCurrency + " " + row.revenue && row.revenue.toLocaleString(),
      row.numberOfEmployees && row.numberOfEmployees.toLocaleString(),
      row.industrialSector,
    ]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      border: "1px solid #ebe9e9",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };

  const [revenueError, setRevenueError] = useState("");
  const [employeesError, setEmployeesError] = useState("");
  const validateRevenue = (value) => {
    const pattern = /^[0-9]+([,\.][0-9]+)?$/;
    if (!pattern.test(value)) {
      setRevenueError("Please enter a valid number for revenue.");
    } else {
      setRevenueError("");
    }
  };

  const validateEmployees = (value) => {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(value)) {
      setEmployeesError("Please enter a valid number for employees.");
    } else {
      setEmployeesError("");
    }
  };

  const handleRevenueChange = (e) => {
    const value = e.target.value;
    setRevenue(value);
    validateRevenue(value);
  };

  const handleEmployeesChange = (e) => {
    const value = e.target.value;
    setEmployees(value);
    validateEmployees(value);
  };
  const renderHeader2 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {renderHeader2()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9 pd-0">
              <div Class="steps__containt-hader">
                <h3 class="title">Legal Entities</h3>
                <div role="presentation">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                  >
                    <Links underline="hover" color="inherit" className="breadcrumbs-font">
                      Company
                    </Links>
                    <Typography color="text.primary">Legal Entities</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3 pd-0">
              <div Class="steps__containt-hader btalignleft">
                <Link to="/company" className="add-cta btalignleft">
                  + Add Legal Entity
                </Link>
              </div>
            </div>
          </div>
          <div>
            {/* <div class="col-md-9 pd-0 mb-10">
              <button id="active" className={act} onClick={() => toggleTable('active')}><span className="record-count">{activeCounter}</span><span>Active</span></button>
              <button id="inactive" className={inact} onClick={() => toggleTable('inactive')}><span className="record-count">{inactiveCounter}</span> <span>Inactive </span> </button>
            </div> */}
            <div className="row" style={{ display: active }}>
              <DataTable
                value={activeetities}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                dataKey="id"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={[
                  "name",
                  "revenue",
                  "numberOfEmployees",
                  "countries",
                  "entityType",
                  "industrialSector",
                  "revenueCurrency",
                ]}
                header={header}
                emptyMessage="No data found."
              >
                <Column
                  header=""
                  body={(rowData) => (
                    <div>
                      <EditIcon
                        title="Edit"
                        style={{
                          cursor: "pointer",
                          color: "#407775",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={() =>
                          showEditModal(
                            rowData.name,
                            rowData.revenue,
                            rowData.numberOfEmployees,
                            rowData.countries && rowData.countries.map((country) => country),
                            rowData.entityType,
                            rowData.revenueCurrency,
                            rowData.industrialSector
                          )
                        }
                      />

                      <img
                        className="action-icon"
                        src="assets/img/deactivate.png"
                        alt="Deactive"
                        style={{ cursor: "pointer", color: "#407775" }}
                        onClick={() => showDeleteModal(rowData.name)}
                      />
                    </div>
                  )}
                  style={{ minWidth: "5rem" }}
                />

                <Column
                  sortable
                  field="name"
                  header="Name"
                  filter
                  filterPlaceholder="Filter"
                  style={{ minWidth: "15rem" }}
                />
                <Column
                  sortable
                  header="Entity Type"
                  field="entityType"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Country"
                  field="countries"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Revenue"
                  field="revenue"
                  sortable
                  body={(rowData) => (
                    <div>
                      <div>{rowData.revenueCurrency + " " + rowData.revenue.toLocaleString()}</div>
                    </div>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                  filterMatchMode="custom" // Set filterMatchMode to custom for custom filtering
                  filterFunction={(value, filter, rowData) => {
                    const filterValue = filter.value.toLowerCase();
                    console.log(filterValue);
                    return (
                      (rowData && rowData.revenueCurrency.toLowerCase().includes(filterValue)) ||
                      rowData.revenue.toLowerCase().includes(filterValue)
                    );
                  }}
                />
                <Column
                  sortable
                  header="Employees"
                  field="numberOfEmployees"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Industrial Sector"
                  field="industrialSector"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
              </DataTable>
            </div>
            <div className="row" style={{ display: inactive }}>
              <DataTable
                value={deactiveetities}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                dataKey="id"
                filters={filters1}
                filterDisplay="row"
                globalFilterFields={[
                  "name",
                  "revenue",
                  "numberOfEmployees",
                  "countries",
                  "entityType",
                  "industrialSector",
                  "revenueCurrency",
                ]}
                header={header1}
                emptyMessage="No data found."
              >
                <Column
                  header=""
                  body={(rowData) => (
                    <div>
                      <img
                        className="action-icon"
                        src="assets/img/reactivate.png"
                        alt="Reactive"
                        style={{ cursor: "pointer", color: "#407775" }}
                        onClick={() => reactive(rowData.name)}
                      />
                    </div>
                  )}
                  style={{ minWidth: "5rem" }}
                />

                <Column
                  sortable
                  field="name"
                  header="Name"
                  filter
                  filterPlaceholder="Filter"
                  style={{ minWidth: "15rem" }}
                />
                <Column
                  sortable
                  header="Entity Type"
                  field="entityType"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Country"
                  field="countries"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Revenue"
                  field="revenue"
                  sortable
                  body={(rowData) => (
                    <div>
                      <div>{rowData.revenueCurrency + " " + rowData.revenue.toLocaleString()}</div>
                    </div>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                  filterMatchMode="custom" // Set filterMatchMode to custom for custom filtering
                  filterFunction={(value, filter, rowData) => {
                    const filterValue = filter.value.toLowerCase();
                    console.log(filterValue);
                    return (
                      (rowData && rowData.revenueCurrency.toLowerCase().includes(filterValue)) ||
                      rowData.revenue.toLowerCase().includes(filterValue)
                    );
                  }}
                />
                <Column
                  sortable
                  header="Employees"
                  field="numberOfEmployees"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Industrial Sector"
                  field="industrialSector"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
              </DataTable>
            </div>
          </div>

          {/* <LoadingSpinner /> */}
          <div class="container pd-0">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <DeleteConfirmation
              confirmModal={submitDelete}
              showModal={displayConfirmationModal}
              hideModal={hideConfirmationModal}
              message={deleteMessage}
              deletename={deletename}
            />
            <Modal
              show={editIsOpen}
              onHide={hideEditModal}
              className="custom-modal-width legal-modal"
            >
              <Modal.Header>
                <div Class="steps__containt-hader edit-form">
                  <h3 class="title">Edit Legal Entity</h3>
                </div>
              </Modal.Header>
              <Modal.Body>
                <form className="edit-form">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputEmail4">
                        Name of entity <span className="mark-form">*</span>
                      </label>
                      <input
                        type="hidden"
                        onChange={(e) => setOldName(e.target.value)}
                        value={oldname}
                        disabled
                      />
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        class="form-control"
                        placeholder="Enter entity name"
                        readOnly
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputState">
                        Country <span className="mark-form">*</span>
                      </label>
                      <Select
                        id="inputState"
                        className=""
                        value={{ label: curcountry, value: curcountry }} // Set the selected value
                        onChange={(selectedOption) => setCurCountry(selectedOption.value)}
                        options={
                          countries && countries.map((status) => ({ label: status, value: status }))
                        }
                        styles={customStyles}
                        placeholder="Select country *"
                        menuPosition="fixed"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputCity">
                        Revenue <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        value={revenue}
                        onChange={handleRevenueChange}
                        className={`form-control ${revenueError ? "is-invalid" : ""}`}
                        id="inputCity"
                        placeholder="Enter company revenue"
                      />
                      {revenueError && <div className="invalid-feedback">{revenueError}</div>}
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputCity">
                        Revenue currency <span className="mark-form">*</span>
                      </label>
                      <Select
                        id="inputState"
                        className=""
                        value={{ label: currency, value: currency }} // Set the selected value
                        onChange={(selectedOption) => setCurrency(selectedOption.value)}
                        options={
                          getcurrency &&
                          getcurrency.map((status) => ({ label: status, value: status }))
                        }
                        placeholder="Select currency"
                        styles={customStyles}
                        menuPosition="fixed"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputState">
                        Entity type <span className="mark-form">*</span>
                      </label>
                      <Select
                        id="inputState"
                        className=""
                        value={{ label: entitytype, value: entitytype }} // Set the selected value
                        onChange={(selectedOption) => setEntityType(selectedOption.value)}
                        options={
                          legalEntity &&
                          legalEntity.map((status) => ({ label: status, value: status }))
                        }
                        placeholder="Select entity type"
                        styles={customStyles}
                        menuPosition="fixed"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputZip">
                        No. of employees <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        value={employees}
                        onChange={handleEmployeesChange}
                        className={`form-control ${employeesError ? "is-invalid" : ""}`}
                        id="inputZip"
                        placeholder="No. of employees"
                      />
                      {employeesError && <div className="invalid-feedback">{employeesError}</div>}
                    </div>
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputZip">
                        Industrial Sector<span className="mark-form">*</span>
                      </label>
                      <Select
                        id="inputState"
                        className=""
                        required
                        value={{ label: industrialSector, value: industrialSector }} // Set the selected value
                        onChange={(selectedOption) => setIndustrialSector(selectedOption.value)}
                        options={
                          getsector && getsector.map((status) => ({ label: status, value: status }))
                        }
                        placeholder="Select Sector"
                        styles={customStyles}
                        menuPosition="fixed"
                      />
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="edit-form modal-buttons">
                  <Button
                    className="btn btn-primary"
                    onClick={() => saveData(name, revenue, employees, curcountry)}
                    disabled={loading}
                  >
                    Update
                  </Button>
                  <Button className="btn btn-cancel" onClick={hideEditModal}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
