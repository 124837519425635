import { Typography } from "@mui/material";

const csrdData = [
  {
    label: "General",
    accordionPanels: [
      {
        label: "ESRS 2",
        id: "general1",
        checkboxes: [
          {
            id: "gen-bp-1",
            label: "BP-1 – General basis for preparation of the sustainability statement",
            tooltip:
              "The undertaking shall disclose the general basis for preparation of its sustainability statement.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-bp-2",
            label: "BP-2 – Disclosures in relation to specific circumstances",
            tooltip:
              "The undertaking shall provide disclosures in relation to specific circumstances.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-gov-1",
            label: "GOV-1 – The role of the administrative, management and supervisory bodies",
            tooltip:
              "The undertaking shall disclose the composition of the administrative, management and supervisory bodies, their roles and responsibilities and access to expertise and skills with regard to sustainability matters. ",

            selected: false,
            mandatory: true,
          },
          {
            id: "gen-gov-2",
            label:
              "GOV-2 – Information provided to and sustainability matters addressed by the undertaking’s administrative, management and supervisory bodies",
            tooltip:
              "The undertaking shall disclose how the administrative, management and supervisory bodies are informed about sustainability matters and how these matters were addressed during the reporting period.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-gov-3",
            label: "GOV-3 – Integration of sustainability-related performance in incentive schemes",
            tooltip:
              "The undertaking shall disclose information about the integration of its sustainabilityrelated performance in incentive schemes.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-gov-4",
            label: "GOV–4 - Statement on due diligence",
            tooltip:
              "The undertaking shall disclose a mapping of the information provided in its sustainability statement about the due diligence process. ",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-gov-5",
            label: "GOV–5 - Risk management and internal controls over sustainability reporting",
            tooltip:
              "The undertaking shall disclose the main features of its risk management and internal control system in relation to the sustainability reporting process.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-sbm-1",
            label: "SBM-1 – Strategy, business model and value chain",
            tooltip:
              "The undertaking shall disclose the elements of its strategy that relate to or impact sustainability matters, its business model and its value chain.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-sbm-2",
            label: "SBM-2 – Interests and views of stakeholders",
            tooltip:
              "The undertaking shall disclose how the interests and views of its stakeholders are taken into account by the undertaking’s strategy and business model.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-sbm-3",
            label:
              "SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip:
              "The undertaking shall disclose its material impacts, risks and opportunities and how they interact with its strategy and business model.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-iro-1",
            label:
              "IRO-1 - Description of the process to identify and assess material impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose its process to identify its impacts, risks and opportunities and to assess which ones are material.",
            selected: false,
            mandatory: true,
          },
          {
            id: "gen-iro-2",
            label:
              "IRO-2 – Disclosure Requirements in ESRS covered by the undertaking’s sustainability statement",
            tooltip:
              "The undertaking shall report on the Disclosure Requirements complied with in its sustainability statements.",
            selected: false,
            mandatory: true,
          },
          // Add more checkboxes as needed
        ],
      },
      // Add more accordion panels as needed
    ],
  },
  {
    label: "Environment",
    accordionPanels: [
      {
        label: "ESRS E1 Climate Change",
        id: "e1",
        checkboxes: [
          {
            id: "env-e1-gov-3",
            label:
              "ESRS 2 GOV-3 Integration of sustainability related performance in incentive schemes",
            selected: false,
          },
          {
            id: "env-e1-1",
            label: "E1-1 – Transition plan for climate change mitigation",
            tooltip:
              "The undertaking shall disclose its transition plan for climate change mitigation.",
            selected: false,
          },
          {
            id: "env-e1-sbm-3",
            label:
              "E1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip:
              "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
            selected: false,
          },
          {
            id: "env-e1-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material climate-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
            selected: false,
          },
          {
            id: "env-e1-2",
            label: "E1-2 – Policies related to climate change mitigation and adaptation",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to climate change mitigation and adaptation.",
            selected: false,
          },
          {
            id: "env-e1-3",
            label: "E1-3 – Actions and resources in relation to climate change policies",
            tooltip:
              "The undertaking shall disclose its climate change mitigation and adaptation actions and the resources allocated for their implementation.",
            selected: false,
          },
          {
            id: "env-e1-4",
            label: "E1-4 – Targets related to climate change mitigation and adaptation",
            tooltip: "The undertaking shall disclose the climate-related targets it has set.",
            selected: false,
          },
          {
            id: "env-e1-5",
            label: "E1-5 – Energy consumption and mix",
            tooltip: "The undertaking shall provide information on its energy consumption and mix.",
            selected: false,
          },
          {
            id: "env-e1-6",
            label: "E1-6 – Gross Scopes 1, 2, 3 and Total GHG emissions",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose in metric tonnes of CO<sub>2</sub> its:
                </p>
                <em>
                  <ol type="a">
                    <li>gross Scope 1 GHG emissions</li>
                    <li>gross Scope 2 GHG emissions</li>
                    <li>gross Scope 3 GHG emissions</li>
                    <li>total GHG emissions</li>
                  </ol>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e1-7",
            label:
              "E1-7 – GHG removals and GHG mitigation projects financed through carbon credits",
            tooltip: (
              <Typography>
                <p>The undertaking shall disclose:</p>
                <em>
                  <ol type="a">
                    <li>
                      GHG removals and storage in metric tonnes of CO<sub>2</sub> resulting from
                      projects it may have developed in its own operations, or contributed to in its
                      upstream and downstream value chain; and
                    </li>
                    <li>
                      the amount of GHG emission reductions or removals from climate change
                      mitigation projects outside its value chain it has financed or intends to
                      finance through any purchase of carbon credits.
                    </li>
                  </ol>
                </em>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e1-8",
            label: "E1-8 – Internal carbon pricing",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose whether it applies internal carbon pricing schemes,
                  and if so, how they support its decision making and incentivise the implementation
                  of climate-related policies and targets.
                </p>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "env-e1-9",
            label:
              "E1-9 – Anticipated financial effects from material physical and transition risks and potential climate-related opportunities",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose its: anticipated financial effects from material
                  physical risks; anticipated financial effects from material transition risks; and
                  potential to benefit from material climate-related opportunities.
                </p>
              </Typography>
            ),
            selected: false,
          },

          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS E2 Pollution",
        id: "e2",
        checkboxes: [
          {
            id: "env-e2-iro1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material pollution-related impacts, risks and opportunities",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e2-1",
            label: "E2-1 – Policies related to pollution",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to pollution prevention and control.",
            selected: false,
          },
          {
            id: "env-e2-2",
            label: "E2-2 – Actions and resources related to pollution",
            tooltip:
              "The undertaking shall disclose its pollution-related actions and the resources allocated to their implementation.",
            selected: false,
          },
          {
            id: "env-e2-3",
            label: "E2-3 – Targets related to pollution",
            tooltip: "The undertaking shall disclose the pollution-related targets it has set.",
            selected: false,
          },
          {
            id: "env-e2-4",
            label: "E2-4 – Pollution of air, water and soil",
            tooltip:
              "The undertaking shall disclose the pollutants that it emits through its own operations, as well as the microplastics it generates or uses.",
            selected: false,
          },
          {
            id: "env-e2-5",
            label: "E2-5 – Substances of concern and substances of very high concern",
            tooltip:
              "The undertaking shall disclose information on the production, use, distribution, commercialisation and import/export of substances of concern and substances of very high concern, on their own, in mixtures or in articles.",
            selected: false,
          },
          {
            id: "env-e2-6",
            label:
              "E2-6 – Anticipated financial effects from pollution-related impacts, risks and opportunities",
            tooltip: "",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS E3 Water and Marine Resources",
        id: "e3",
        checkboxes: [
          {
            id: "env-e3-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material water and marine resources-related impacts, risks and opportunities",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e3-1",
            label: "E3-1 – Policies related to water and marine resources",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e3-2",
            label: "E3-2 – Actions and resources related to water and marine resources",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to water and marine resources",
            selected: false,
          },
          {
            id: "env-e3-3",
            label: "E3-3 – Targets related to water and marine resources",
            tooltip:
              "The undertaking shall disclose the water and marine resources-related targets it has set.",
            selected: false,
          },
          {
            id: "env-e3-4",
            label: "E3-4 – Water consumption",
            tooltip:
              "The undertaking shall disclose information on its water consumption performance related to its material impacts, risks and opportunities.",
            selected: false,
          },
          {
            id: "env-e3-5",
            label:
              "E3-5 – Anticipated financial effects from water and marine resources-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose the anticipated financial effects of material water and marine resources-related risks and opportunities.",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS E4 Biodiversity and Ecosystems",
        id: "e4",
        checkboxes: [
          {
            id: "env-e4-sbm-3",
            label:
              "E4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e4-iro-1",
            label:
              "ESRS 2 IRO-1 Description of processes to identify and assess material biodiversity and ecosystem-related impacts, risks and opportunities ",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e4-1",
            label:
              "E4-1 – Transition plan and consideration of biodiversity and ecosystems in strategy and business model",
            tooltip:
              "The undertaking shall disclose how its biodiversity and ecosystem impacts, dependencies, risks and opportunities originate from and trigger adaptation of its strategy and business model. ",
            selected: false,
          },
          {
            id: "env-e4-2",
            label: "E4-2 – Policies related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall describe its adopted policies to manage its material impacts, risks, dependencies, and opportunities related to biodiversity and ecosystems. ",
            selected: false,
          },
          {
            id: "env-e4-3",
            label: "E4-3 – Actions and resources related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall disclose its biodiversity and ecosystems-related actions and the resources allocated to their implementation.",
            selected: false,
          },
          {
            id: "env-e4-4",
            label: "E4-4 – Targets related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall disclose the biodiversity and ecosystem-related targets it has set. ",
            selected: false,
          },
          {
            id: "env-e4-5",
            label: "E4-5 – Impact metrics related to biodiversity and ecosystems change",
            tooltip:
              "The undertaking shall report metrics related to its material impacts on biodiversity and ecosystems.",
            selected: false,
          },
          {
            id: "env-e4-6",
            label:
              "E4-6 – Anticipated financial effects from biodiversity and ecosystem-related risks and opportunities",
            tooltip:
              "The undertaking shall disclose its anticipated financial effects of material biodiversityand ecosystem-related risks and opportunities. ",
            selected: false,
          },

          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS E5 Resource use and circular economy",
        id: "e5",
        checkboxes: [
          {
            id: "env-e5-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material resource use and circular economy-related impacts, risks and opportunities",
            tooltip: "",
            selected: false,
          },
          {
            id: "env-e5-1",
            label: "E5-1 – Policies related to resource use and circular economy",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to resource use and circular economy.",
            selected: false,
          },
          {
            id: "env-e5-2",
            label: "E5-2 – Actions and resources related to resource use and circular economy",
            tooltip:
              "The undertaking shall disclose its resource use and circular economy actions and the resources allocated to their implementation.",
            selected: false,
          },
          {
            id: "env-e5-3",
            label: "E5-3 – Targets related to resource use and circular economy",
            tooltip:
              "The undertaking shall disclose the resource use and circular economy-related targets it has set.",
            selected: false,
          },
          {
            id: "env-e5-4",
            label: "E5-4 – Resource inflows of Disclosure Requirement",
            tooltip:
              "The undertaking shall disclose information on its resource inflows related to its material impacts, risks and opportunities.",
            selected: false,
          },
          {
            id: "env-e5-5",
            label: "E5-5 –Resource outflows",
            tooltip:
              "The undertaking shall disclose information on its resource outflows, including waste, related to its material impacts, risks and opportunities.",
            selected: false,
          },
          {
            id: "env-e5-6",
            label:
              "E5-6 – Anticipated financial effects from resource use and circular economy-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose the anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts.",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },

      // Add more accordion panels as needed
    ],
  },
  {
    label: "Social",
    accordionPanels: [
      {
        label: "ESRS S1 Own workforce",
        id: "s1",
        checkboxes: [
          {
            id: "soc-s1-sbm-3",
            label:
              "S1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip:
              "The undertaking shall state its policies that address the management of its material impacts on own workforce, as well as associated material risks and opportunities; and provide a summary of the content of the policies and how they are communicated",
            selected: false,
          },
          {
            id: "soc-s1-1",
            label: "S1-1 – Policies related to own workforce",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on its own workforce, as well as associated material risks and opportunities.",
            selected: false,
          },
          {
            id: "soc-s1-2",
            label:
              "S1-2 – Processes for engaging with own workers and workers’ representatives about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with people in its own workforce and workers' representatives about actual and potential impacts on its own workforce.",
            selected: false,
          },
          {
            id: "soc-s1-3",
            label:
              "S1-3 – Processes to remediate negative impacts and channels for own workers to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on people in its own workforce that the undertaking is connected with, as well as channels available to its own workforce to raise concerns and have them addressed.",
            selected: false,
          },
          {
            id: "soc-s1-4",
            label:
              "S1-4 – Taking action on material impacts on own workforce, and approaches to mitigating material risks and pursuing material opportunities related to own workforce, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material negative and positive impacts, and to manage material risks and pursue material opportunities related to its own workforce, and the effectiveness of those actions.",
            selected: false,
          },
          {
            id: "soc-s1-5",
            label:
              "S1-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on its own workforce; and/or </li>
                  <li>advancing positive impacts on its own workforce; and/or </li>
                  <li>managing material risks and opportunities related to its own workforce.</li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s1-6",
            label: "S1-6 – Characteristics of the undertaking’s employees",
            tooltip:
              "The undertaking shall describe key characteristics of employees in its own workforce.",
            selected: false,
          },
          {
            id: "soc-s1-7",
            label:
              "S1-7 – Characteristics of non-employee workers in the undertaking’s own workforce",
            tooltip:
              "The undertaking shall describe key characteristics of non-employees in its own workforce. ",
            selected: false,
          },
          {
            id: "soc-s1-8",
            label: "S1-8 – Collective bargaining coverage and social dialogue",
            tooltip:
              "The undertaking shall disclose information on the extent to which the working conditions and terms of employment of its employees are determined or influenced by collective bargaining agreements and on the extent to which its employees are represented in social dialogue in the European Economic Area (EEA) at the establishment and European level. ",
            selected: false,
          },
          {
            id: "soc-s1-9",
            label: "S1-9 – Diversity metrics",
            tooltip:
              "The undertaking shall disclose the gender distribution at top management and the age distribution amongst its employees.",
            selected: false,
          },
          {
            id: "soc-s1-10",
            label: "S1-10 – Adequate wages",
            tooltip:
              "The undertaking shall disclose whether or not its employees are paid an adequate wage, and if they are not all paid an adequate wage, the countries and percentage of employees concerned.",
            selected: false,
          },
          {
            id: "soc-s1-11",
            label: "S1-11 – Social protection",
            tooltip:
              "The undertaking shall disclose whether its employees are covered by social protection against loss of income due to major life events, and, if not, the countries where this is not the case.",
            selected: false,
          },
          {
            id: "soc-s1-12",
            label: "S1-12– Persons with disabilities",
            tooltip:
              "The undertaking shall disclose the percentage of its own employees with disabilities.",
            selected: false,
          },
          {
            id: "soc-s1-13",
            label: "S1-13 – Training and skills development metrics",
            tooltip:
              "The undertaking shall disclose the extent to which training and skills development is provided to its employees.",
            selected: false,
          },
          {
            id: "soc-s1-14",
            label: "S1-14 – Health and safety metrics",
            tooltip:
              "The undertaking shall disclose information on the extent to which its own workforce is covered by its health and safety management system and the number of incidents associated with work-related injuries, ill health and fatalities of its own workforce. In addition, it shall disclose the number of fatalities as a result of work-related injuries and work-related ill health of other workers working on the undertaking’s sites.",
            selected: false,
          },
          {
            id: "soc-s1-15",
            label: "S1-15 – Work-life balance metrics",
            tooltip:
              "The undertaking shall disclose the extent to which employees are entitled to and make use of family-related leave.",
            selected: false,
          },
          {
            id: "soc-s1-16",
            label: "S1-16 – Compensation metrics (pay gap and total compensation)",
            tooltip:
              "The undertaking shall disclose the percentage gap in pay between its female and male employees and the ratio between the remuneration of its highest paid individual and the median remuneration for its employees.",
            selected: false,
          },
          {
            id: "soc-s1-17",
            label: "S1-17 – Incidents, complaints and severe human rights impacts",
            tooltip:
              "The undertaking shall disclose the number of work-related incidents and/or complaints and severe human rights impacts within its own workforce, and any related material fines, sanctions or compensation for the reporting period.",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS S2 workers in the value chain",
        id: "s2",
        checkboxes: [
          {
            id: "soc-s2-sbm-3",
            label:
              "ESRS 2 SBM-3 Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: "",
            selected: false,
          },
          {
            id: "soc-s2-1",
            label: "S2-1 – Policies related to value chain workers",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on value chain workers, as well as associated material risks and opportunities.",
            selected: false,
          },
          {
            id: "soc-s2-2",
            label: "S2-2 – Processes for engaging with value chain workers about impacts ",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with value chain workers and their representatives about actual and potential impacts on them.",
            selected: false,
          },
          {
            id: "soc-s2-3",
            label:
              "S2-3 – Processes to remediate negative impacts and channels for value chain workers to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on value chain workers that the undertaking is connected with, as well as channels available to value chain workers to raise concerns and have them addressed. ",
            selected: false,
          },
          {
            id: "soc-s2-4",
            label:
              "S2-4 – Taking action on material impacts on value chain workers, and approaches to managing material risks and pursuing materials opportunities related to value chain workers, and effectiveness of those action",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on value chain workers, and to manage material risks and pursue material opportunities related to value chain workers and the effectiveness of those actions.",
            selected: false,
          },
          {
            id: "soc-s2-5",
            label:
              "S2-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li> reducing negative impacts on value chain workers; and/or</li>
                  <li>advancing positive impacts on value chain workers; and/or</li>
                  <li>managing material risks and opportunities related to value chain workers.</li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS S3 Affected communities",
        id: "s3",
        checkboxes: [
          {
            id: "soc-s3-sbm-3",
            label:
              "ESRS 2 SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  When responding to ESRS 2 SBM-3 paragraph 48, the undertaking shall disclose:
                </p>
                <ol type="a">
                  <li>
                    whether and how actual and potential impacts on affected communities as
                    identified in ESRS 2 IRO-1 Description of the processes to identify and assess
                    material impacts, risks and opportunities:
                    <ol type="i">
                      <li>
                        originate from or are connected to the undertaking’s strategy and business
                        models, and
                      </li>
                      <li>
                        inform and contribute to adapting the undertaking’s strategy and business
                        model; and
                      </li>
                    </ol>
                  </li>
                  <li>
                    the relationship between its material risks and opportunities arising from
                    impacts and dependencies on affected communities and its strategy and business
                    model.
                  </li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s3-1",
            label: "S3-1 – Policies related to affected communities",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on affected communities, as well as associated material risks and opportunities. ",
            selected: false,
          },
          {
            id: "soc-s3-2",
            label: "S3-2 – Processes for engaging with affected communities about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with affected communities and their representatives about actual and potential impacts on them. ",
            selected: false,
          },
          {
            id: "soc-s3-3",
            label:
              "S3-3 – Processes to remediate negative impacts and channels for affected communities to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on affected communities that the undertaking is connected with, as well as channels available to affected communities to raise concerns and have them addressed.",
            selected: false,
          },
          {
            id: "soc-s3-4",
            label:
              "S3-4 – Taking action on material impacts on affected communities, and approaches to managing material risks and pursuing material opportunities related to affected communities, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on affected communities, and to manage material risks and pursue material opportunities related to affected communities and the effectiveness of those actions. ",
            selected: false,
          },
          {
            id: "soc-s3-5",
            label:
              "S3-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on affected communities; and/or </li>
                  <li>advancing positive impacts on affected communities; and/or</li>
                  <li>
                    managing material risks and opportunities related to affected communities.
                  </li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },
      {
        label: "ESRS S4 Consumers and end-users",
        id: "s4",
        checkboxes: [
          {
            id: "soc-s4-sbm-3",
            label:
              "S4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  When responding to ESRS 2 SBM-3 paragraph 48, the undertaking shall disclose:
                </p>
                <ol type="a">
                  <li>
                    whether and how actual and potential impacts on consumers and/or end-users as
                    identified in Disclosure Requirements ESRS 2 IRO-1 Description of the processes
                    to identify and assess material impacts, risks and opportunities:
                    <ol type="i">
                      <li>
                        originate from or are connected to the undertaking’s strategy and business
                        model, and
                      </li>
                      <li>
                        inform and contribute to adapting the undertaking’s strategy and business
                        model, and
                      </li>
                    </ol>
                  </li>
                  <li>
                    the relationship between its material risks and opportunities arising from
                    impacts and dependencies on consumers and/or end-users and its strategy and
                    business model.
                  </li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          {
            id: "soc-s4-1",
            label: "S4-1 – Policies related to consumers and end-users",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts of its products and/or services on consumers and end-users, as well as associated material risks and opportunities. ",
            selected: false,
          },
          {
            id: "soc-s4-2",
            label: "S4-2 – Processes for engaging with consumers and endusers about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with consumers and end-users and their representatives about actual and potential impacts on them. ",
            selected: false,
          },
          {
            id: "soc-s4-3",
            label:
              "S4-3 – Processes to remediate negative impacts and channels for consumers and end-users to raise concerns",
            tooltip:
              "The undertaking shall descrsibe the processes it has in place to provide for or cooperate in the remediation of negative impacts on consumers and end-users that the undertaking is connected with, as well as channels available to consumers and endusers to raise concerns and have them addressed. ",
            selected: false,
          },
          {
            id: "soc-s4-4",
            label:
              "S4-4 – Taking action on material impacts on consumers and end- users, and approaches to managing material risks and pursuing material opportunities related to consumers and end-users, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on consumers and end-users, and to manage material risks and pursue material opportunities related to consumers and end-users, and effectiveness of those actions. ",
            selected: false,
          },
          {
            id: "soc-s4-5",
            label:
              "S4-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on consumers and/or end-users; and/or </li>
                  <li> advancing positive impacts on consumers and/or end-users; and/or </li>
                  <li>
                    managing material risks and opportunities related to consumers and/or end-users.
                  </li>
                </ol>
              </Typography>
            ),
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },

      // Add more accordion panels as needed
    ],
  },
  {
    label: "Governance",
    accordionPanels: [
      {
        label: "ESRS G1 Business Conduct",
        id: "g1",
        checkboxes: [
          {
            id: "gov-g1-gov-1",
            label:
              "ESRS 2 GOV-1 – The role of the administrative, supervisory and management bodies",
            tooltip:
              "The undertaking shall provide information on its governance structure and composition.",
            selected: false,
          },
          {
            id: "gov-g1-1",
            label: "G1-1– Corporate culture and bBusiness conduct policies and corporate culture",
            tooltip:
              "The undertaking shall disclose its policies with respect to business conduct matters and how it fosters its corporate culture",
            selected: false,
          },
          {
            id: "gov-g1-2",
            label: "G1-2 – Management of relationships with suppliers",
            tooltip:
              "The undertaking shall provide information about the management of its relationships with its suppliers and its impacts on its supply chain.",
            selected: false,
          },
          {
            id: "gov-g1-3",
            label: "G1-3 – Prevention and detection of corruption and bribery",
            tooltip:
              "The undertaking shall provide information about its system to prevent and detect, investigate, and respond to allegations or incidents relating to corruption and bribery including the related training.",
            selected: false,
          },
          {
            id: "gov-g1-4",
            label: "G1-4 – Confirmed incidents of corruption or bribery",
            tooltip:
              "The undertaking shall provide information on incidents of corruption or bribery during the reporting period.",
            selected: false,
          },
          {
            id: "gov-g1-5",
            label: "G1-5 – Political influence and lobbying activities",
            tooltip:
              "The undertaking shall provide information on the activities and commitments related to exerting its political influence, including its lobbying activities related to its material impacts, risks and opportunities. ",
            selected: false,
          },
          {
            id: "gov-g1-6",
            label: "G1-6 – Payment practices",
            tooltip:
              "The undertaking shall provide information on its payment practices, especially with respect to late payments to small and medium enterprises (SMEs).",
            selected: false,
          },
          // Add more checkboxes as needed
        ],
      },

      // Add more accordion panels as needed
    ],
  },
  // Add more tab data as needed
];

// Sample report record
const sampleReport = {
  reportName: "Sample Report",
  framework: "Sample Framework",
  status: "In Progress",
  completionTimeline: "2023-09-30",
  reportingPeriod: "2023-01-01 - 2023-12-31",
  creator: "John Doe",
  checkboxes: [
    { label: "Checkbox 1", selected: true },
    { label: "Checkbox 2", selected: false },
  ],
};

csrdData[0].accordionPanels[0].reports = [sampleReport]; // Add the sample record to the first accordion panel

export default csrdData;
