import React from "react";
import clsx from "clsx";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appInsights } from "./AppInsights";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Label } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { LineChart, Line, Legend } from "recharts";
import WorldMap from "react-svg-worldmap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsFullscreen from "highcharts/modules/full-screen";
import AADHeader from "./components/AADHeader.js";
// Initialize the exporting module
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsFullscreen(Highcharts);

export default function Home() {
  const username = localStorage.getItem("userName");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [news, setNews] = useState(false);
  const [responseConfig, setResponseConfig] = useState({});

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const handleChatClick = () => {
    navigate("/ChatPage");
  };
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  async function fetchNews() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch("https://ecoprismapi.azurewebsites.net/news", requestOptions);
      const data = await response.json();
      if (data.success === false || data.status === 400) {
        setLoading(false);
      } else {
        setLoading(false);
        setNews(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchNews();
  }, []);

  const data = [
    { name: "Surface water", value: 200 },
    { name: "Ground water", value: 300 },
    { name: "Produced water", value: 300 },
    { name: "Sea water", value: 200 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const CustomLegend_Pie = () => {
    const fontSize = "12px"; // Adjust the font size as needed
    return (
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
        {data.map((entry, index) => (
          <div
            key={entry.name}
            style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
          >
            <span
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: COLORS[index],
                marginRight: "4px",
              }}
            ></span>
            <span style={{ fontSize }}>{entry.name}</span>
          </div>
        ))}
      </div>
    );
  };
  const dataLineChart_Employeeturnover = [
    {
      name: "Q1 FY 2022",
      uv: 27,
    },
    {
      name: "Q2 FY 2022",
      uv: 19,
    },
    {
      name: "Q3 FY 2022",
      uv: 17,
    },
    {
      name: "Q4 FY 2022",
      uv: 18,
    },
    {
      name: "Q5 FY 2023",
      uv: 15,
    },
    // Add more data as needed
  ];
  const dataLineChart_Fatalities = [
    {
      name: "2020",
      uv: 30,
    },
    {
      name: "2021",
      uv: 29,
    },
    {
      name: "2022",
      uv: 18,
    },
    {
      name: "2023",
      uv: 27,
    },

    // Add more data as needed
  ];

  const generateChartOptions = () => {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: ["Water withdrawal", "Water discharge", "Water recycle"],
        gridLineWidth: 0, // Set x-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set x-axis minor grid line width to 0 to hide minor grid lines
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Set y-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set y-axis minor grid line width to 0 to hide minor grid lines
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      series: [
        {
          name: "Surfacewater",
          data: [5, 3, 4],
        },
        {
          name: "Groundwater",
          data: [2, 2, 3],
        },
        {
          name: "Seawater",
          data: [3, 4, 4],
        },
        {
          name: "Producedwater",
          data: [2, 2, 3],
        },
        {
          name: "Third-party water",
          data: [2, 2, 3],
        },
      ],
    };
  };

  const options = generateChartOptions();

  // Stacked line chart
  const generateLineChartOptions = () => {
    return {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May"],
        gridLineWidth: 0, // Set x-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set x-axis minor grid line width to 0 to hide minor grid lines
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Set y-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set y-axis minor grid line width to 0 to hide minor grid lines
      },
      series: [
        {
          name: "Surface water",
          data: [1000, 3000, 2000, 4000, 5000],
        },
        {
          name: "Groundwater",
          data: [1500, 3500, 2500, 4200, 5200],
        },
        {
          name: "Seawater",
          data: [1800, 3200, 2500, 4900, 5100],
        },
        {
          name: "Produced water",
          data: [1400, 3000, 2800, 4500, 5900],
        },
        {
          name: "Total",
          data: [1900, 7000, 6000, 8000, 15000],
        },
      ],
    };
  };

  const options_line = generateLineChartOptions();

  // bar chart1
  const initialData = {
    categories: ["Produced water", "Seawater", "Groundwater", "Surface Water"],
    series: [
      { name: "Produced water", data: [11612] },
      { name: "Seawater", data: [5000] },
      { name: "Groundwater", data: [18952] },
      { name: "Surface Water", data: [47848] },
    ],
  };
  const generateBarRaceChartOptions = (data) => {
    const colors = ["#FF0000", "#00FF00", "#0000FF", "#FFD700"];

    return {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.categories,
        gridLineWidth: 0, // Set x-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set x-axis minor grid line width to 0 to hide minor grid lines
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Set y-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set y-axis minor grid line width to 0 to hide minor grid lines
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: "black",
            style: {
              fontWeight: "bold",
            },
            formatter: function () {
              return this.y;
            },
          },
          showInLegend: false,
        },
      },
      series: [
        {
          data: data.series.map((series, index) => ({
            y: series.data[0],
            color: colors[index],
            dataLabels: {
              format: "{y}",
            },
            showInLegend: false,
          })),
        },
      ],
    };
  };
  const [chartData] = useState(initialData);
  const options_waterwithdrwal = generateBarRaceChartOptions(chartData);

  // bar chart2
  const initialData_waterdischarge = {
    categories: ["Third-party water", "Seawater", "Groundwater", "Surface Water"],
    series: [
      { name: "Third-party water", data: [17121] },
      { name: "Seawater", data: [14506] },
      { name: "Groundwater", data: [14506] },
      { name: "Surface Water", data: [18204] },
    ],
  };

  const generateBarRaceChartOptions_waterdischarge = (data) => {
    const colors_waterdischarge = ["#FF0000", "#00FF00", "#0000FF", "#FFD700"];

    return {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.categories,
        gridLineWidth: 0, // Set x-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set x-axis minor grid line width to 0 to hide minor grid lines
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0, // Set y-axis grid line width to 0 to hide major grid lines
        minorGridLineWidth: 0, // Set y-axis minor grid line width to 0 to hide minor grid lines
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: "black",
            style: {
              fontWeight: "bold",
            },
            formatter: function () {
              return this.y;
            },
          },
          showInLegend: false,
        },
      },
      series: [
        {
          data: data.series.map((series, index) => ({
            y: series.data[0],
            color: colors_waterdischarge[index],
          })),
        },
      ],
    };
  };

  const [chartData1] = useState(initialData_waterdischarge);
  const options_waterdischarge = generateBarRaceChartOptions_waterdischarge(chartData1);

  const keys_region = [
    "Permanentemployees",
    "Temporaryemployees",
    "Nonguaranteedhrsemployees",
    "Fulltimeemployees",
    "Parttimeemployees",
  ]; // List of genders
  const COLORS_Stack_region = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#407775"]; // Colors for different genders
  // Find the maximum value in the 'uv' field of your data
  const maxValue_employeeturnover = Math.max(
    ...dataLineChart_Employeeturnover.map((item) => item.uv)
  );
  // Find the maximum value in the 'uv' field of your data
  // Calculate the y-axis domain based on the maximum value
  const yAxisDomain_employeeturnover = [0, Math.ceil(maxValue_employeeturnover / 5) * 5];
  const maxValue = Math.max(...dataLineChart_Fatalities.map((item) => item.uv));
  // Calculate the y-axis domain based on the maximum value
  const dataBarChart = [
    { category: "Produced Water", value: 11612, color: "#0088FE" },
    { category: "Sea Water", value: 5000, color: "#00C49F" },
    { category: "Ground Water", value: 18532, color: "#FFBB28" },
    { category: "Surface Water", value: 47852, color: "#FF8042" },
    // Add more data if needed...
  ];
  const maxBarValue = Math.max(...dataBarChart.map((item) => item.value));
  const dataBarChart_water = [
    { category: "Third-party Water", value: 17121, color: "#0088FE" },
    { category: "Sea Water", value: 14506, color: "#00C49F" },
    { category: "Ground Water", value: 14506, color: "#FFBB28" },
    { category: "Surface Water", value: 18204, color: "#FF8042" },
    // Add more data if needed...
  ];
  const maxBarValue_water = Math.max(...dataBarChart_water.map((item) => item.value));
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        {/* Rest of your existing JSX content */}
        <div className="row mt-25 pd-lr-50">
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Legal entity{" "}
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select{" "}
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Business unit{" "}
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select{" "}
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>

          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Site location
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select{" "}
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Water category{" "}
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
          <div className="col-md-2">
            <label class="formlable" for="inputState">
              Risk category{" "}
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
          <div className="col-md-1">
            <label class="formlable" for="inputState">
              Year
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
          <div className="col-md-1">
            <label class="formlable" for="inputState">
              Country
            </label>
            <select id="inputState" class="Ecodropdown" required>
              <option selected value="">
                Select{" "}
              </option>
              <option value="">L1</option>
              <option value="">L2</option>
              <option value="">L3</option>
              <option value="">L4</option>
            </select>
          </div>
        </div>

        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-4">
            <div className="employee-box">
              <div className="employee-box-header-water">Water Withdrawal</div>
              <div className="employee-box-content">XXXX m³</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employee-box">
              <div className="employee-box-header-water">Water Discharge</div>
              <div className="employee-box-content">XXXX m³</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employee-box">
              <div className="employee-box-header-water">Water Recycled</div>
              <div className="employee-box-content">7200 m³</div>
            </div>
          </div>
        </div>
        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-4">
            <div className="charts">
              {/* <h5 style={{ textAlign: 'center',fontSize: '15px' }}>Employees by employment type and gender</h5> */}
              <div style={{ marginBottom: "20px" }}>
                <h5 style={{ textAlign: "center", fontSize: "12px" }}>
                  Total water withdrawal by source(m³)
                </h5>

                <ResponsiveContainer width="100%" height="100%">
                  <HighchartsReact highcharts={Highcharts} options={options_waterwithdrwal} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="charts">
              {/* <h5 style={{ textAlign: 'center',fontSize: '15px' }}>Employees by region and employment type</h5> */}
              <div style={{ marginBottom: "20px" }}>
                <h5 style={{ textAlign: "center", fontSize: "12px" }}>
                  Total water discharge by destination (m³)
                </h5>
                <ResponsiveContainer width="100%" height="100%">
                  <HighchartsReact highcharts={Highcharts} options={options_waterdischarge} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "12px", marginBottom: "30px" }}>
                Water risk distribution
              </h5>
              <div class="table-responsive-social">
                <table className="custom-table-social">
                  <tr>
                    <th style={{ backgroundColor: "#407775", color: "white" }}>
                      No. of site by water risk category
                    </th>
                    <th style={{ backgroundColor: "#407775", color: "white" }}>Level</th>
                    <th style={{ backgroundColor: "#407775", color: "white" }}>
                      No. of site by water stress category
                    </th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td style={{ backgroundColor: "green", color: "white" }}>Low</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td style={{ backgroundColor: "orange", color: "white" }}>Medium</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td style={{ backgroundColor: "red", color: "white" }}>High</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td style={{ backgroundColor: "darkred", color: "white" }}>Extremely high</td>
                    <td>1</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row pd-lr-50">
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "12px" }}>
                Monthly trend by water category
              </h5>
              <ResponsiveContainer width="100%" height="100%">
                <HighchartsReact highcharts={Highcharts} options={options_line} />
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-6">
            <div className="charts">
              <h5 style={{ textAlign: "center", fontSize: "12px" }}>
                Water stats for sites with high and extremely high water stress m³
              </h5>
              <ResponsiveContainer width="100%" height="100%">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="row mt-25 mb-25 pd-lr-50">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  );
}
