import React from "react";
import Links from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "react-select";
import AADHeader from "./components/AADHeader.js";

export default function Home() {
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = useState();
  const [sitename, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalcode, setPostalCode] = useState("");
  const [lat, setLatitude] = useState("");
  const [lng, setLongitude] = useState("");
  const [message, setMessage] = useState("");
  const [sitetype, setSiteType] = useState("");
  const [getsitetype, setGetSiteType] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [inputList, setInputList] = useState([{ businessunit: "", businessunitvalue: "" }]);
  const [bunit, setBunit] = useState([{ bunit: "", bunitvalue: "" }]);
  const [businessunit, setBusinessUnit] = useState();
  const [businessunit1, setBusinessUnit1] = useState();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDisabled1, setIsButtonDisabled1] = useState(false);
  const [activebu, setActiveBU] = useState("");
  const [activele, setActiveLE] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery));
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setAddress1(query);
    const results = await geocodeByAddress(query);
    const { lat, lng } = await getLatLng(results[0]);
    setLatitude(lat.toFixed(4));
    setLongitude(lng.toFixed(4));
    let cityValue = "";
    let pincodeValue = "";
    let countryValue = "";
    let stateValue = "";

    results[0].address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        cityValue = component.long_name;
      }
      if (component.types.includes("country")) {
        countryValue = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        stateValue = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        pincodeValue = component.long_name;
      }
    });

    setCity(cityValue);
    setPostalCode(pincodeValue);
    setCountry(countryValue);
    setState(stateValue);
    console.log(addressObject);
  }

  async function fetchData() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Yfe8E1kXXtdJ36h-XDoed0l3TagQFgM&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  async function fetchLegalEntity() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setBusinessUnit("");
        } else if (responseJson.status == 400) {
          setBusinessUnit("");
        } else {
          setBusinessUnit(responseJson);
          setActiveLE(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setBusinessUnit("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchBusinessUnit() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-businessunits",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setBusinessUnit1("");
        } else if (responseJson.status == 400) {
          setBusinessUnit1("");
        } else {
          setBusinessUnit1(responseJson);
          setActiveBU(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setBusinessUnit1("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSiteType() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitetype",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setGetSiteType("");
        } else if (responseJson.status == 400) {
          setGetSiteType("");
        } else {
          setGetSiteType(responseJson);
        }
      } else {
        setGetSiteType("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchBusinessUnit();
    fetchLegalEntity();
    fetchSiteType();
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    // Legal Entity
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    var allocate1 = [];
    var result = list.reduce(function (map, obj) {
      map[obj.businessunit] = obj.businessunitvalue;
      allocate1.push(obj.businessunitvalue);
      return map;
    }, {});
    if (inputList.length === 0) {
      console.log("Zero Value");
    }
    const sumList = allocate1.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue);
    }, 0);
    console.log(sumList);
    if (sumList >= 100) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target;
    // Business Unit
    const list1 = [...bunit];
    list1[index][name] = value;
    setBunit(list1);
    var allocate11 = [];
    var result = list1.reduce(function (map, obj) {
      map[obj.bunit] = obj.bunitvalue;
      allocate11.push(obj.bunitvalue);
      return map;
    }, {});
    if (bunit.length === 0) {
      console.log("Zero Value");
    }
    const sumList1 = allocate11.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue);
    }, 0);
    console.log(sumList1);
    if (sumList1 >= 100) {
      setIsButtonDisabled1(true);
    } else {
      setIsButtonDisabled1(false);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    var allocate1 = [];
    var result = list.reduce(function (map, obj) {
      map[obj.businessunit] = obj.businessunitvalue;
      allocate1.push(obj.businessunitvalue);
      return map;
    }, {});
    if (inputList.length === 0) {
      console.log("Zero Value");
    }
    const sumList = allocate1.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue);
    }, 0);
    console.log(sumList);
    if (sumList >= 100) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleRemoveClick1 = (index) => {
    // Business Unit
    const list1 = [...bunit];
    list1.splice(index, 1);
    setBunit(list1);
    var allocate11 = [];
    var result = list1.reduce(function (map, obj) {
      map[obj.bunit] = obj.bunitvalue;
      allocate11.push(obj.bunitvalue);
      return map;
    }, {});
    if (bunit.length === 0) {
      console.log("Zero Value");
    }
    const sumList1 = allocate11.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue);
    }, 0);
    console.log(sumList1);
    if (sumList1 >= 100) {
      setIsButtonDisabled1(true);
    } else {
      setIsButtonDisabled1(false);
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { businessunit: "", businessunitvalue: "" }]);
  };

  const handleAddClick1 = () => {
    setBunit([...bunit, { bunit: "", bunitvalue: "" }]);
  };

  let handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      let rest = await fetch("https://ecoprismapi.azurewebsites.net/check-sitelocation", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: sitename,
        },
      });
      if (postalcode === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                 <p style="font-size: 16px;">Postal code is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (!validatePostalCode(postalcode)) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                 <p style="font-size: 16px;">Please enter a valid postal code.</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (rest.status === 200) {
        const responseJson1 = await rest.json();
        if (responseJson1.data === true) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Site location already exist</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson1.status === 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;font-family:Poppins;">${responseJson1.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          // Legal entity
          var allocate = [];
          var result = inputList.reduce(function (map, obj) {
            map[obj.businessunit] = obj.businessunitvalue;
            allocate.push(obj.businessunitvalue);
            return map;
          }, {});
          if (inputList.length === 0) {
            console.log("Zero Value");
          }
          const sum = allocate.reduce((accumulator, currentValue) => {
            console.log(currentValue);
            return accumulator + parseFloat(currentValue);
          }, 0);
          console.log(3.75 + 2.25);
          console.log(sum);
          const uniqueBusinessUnits = {};
          const duplicates = {};
          inputList.forEach((item) => {
            const { businessunit } = item;
            if (!uniqueBusinessUnits[businessunit]) {
              uniqueBusinessUnits[businessunit] = true;
            } else {
              duplicates["count"] = (duplicates[businessunit] || 1) + 1;
            }
          });
          if (duplicates.count > 1) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;font-family:Poppins;">Multiple allocation to same legal entity not allowed</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
            return false;
          }

          // Business Unit
          var allocate11 = [];
          var result11 = bunit.reduce(function (map, obj) {
            map[obj.bunit] = obj.bunitvalue;
            allocate11.push(obj.bunitvalue);
            return map;
          }, {});
          if (bunit.length === 0) {
            console.log("Zero Value");
          }
          const sum1 = allocate11.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue);
          }, 0);

          const uniqueBusinessUnits1 = {};
          const duplicates1 = {};
          bunit.forEach((item) => {
            const { bunit } = item;
            if (!uniqueBusinessUnits1[bunit]) {
              uniqueBusinessUnits1[bunit] = true;
            } else {
              duplicates1["count"] = (duplicates1[bunit] || 1) + 1;
            }
          });
          if (duplicates1.count > 1) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;font-family:Poppins;">Multiple allocation to same business unit not allowed</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
            return false;
          }
          if (bunit.length > 0) {
            console.log(bunit);
          }
          if (sum > 100) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;font-family:Poppins;">LE Allocation is greater than 100%</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
            return false;
          } else if (sum < 100) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;font-family:Poppins;">LE Allocation is less than 100%</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
            return false;
          } else if (isNaN(sum)) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;font-family:Poppins;">LE Allocation is less than 100%</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });

            return false;
          } else {
            var result = inputList.reduce(function (map, obj) {
              map[obj.businessunit] = obj.businessunitvalue;
              return map;
            }, {});

            var result1 = bunit.reduce(function (map, obj) {
              map[obj.bunit] = obj.bunitvalue;
              return map;
            }, {});
            const isEmpty = Object.keys(result1).every((key) => key === "");
            if (isEmpty) {
              var result2 = {};
              console.log("Check empty");
            } else {
              // Business Unit
              var allocate1 = [];
              var result1 = bunit.reduce(function (map, obj) {
                map[obj.bunit] = obj.bunitvalue;
                allocate1.push(obj.bunitvalue);
                return map;
              }, {});
              const sum1 = allocate1.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue);
              }, 0);
              const uniqueBusinessUnits1 = {};
              const duplicates1 = {};
              bunit.forEach((item) => {
                const { bunit } = item;
                if (!uniqueBusinessUnits1[bunit]) {
                  uniqueBusinessUnits1[bunit] = true;
                } else {
                  duplicates1["count"] = (duplicates1[bunit] || 1) + 1;
                }
              });
              if (duplicates1.count > 1) {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;font-family:Poppins;">Multiple allocation to same business unit not allowed</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
                return false;
              }
              if (sum1 > 100) {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;font-family:Poppins;">Business unit Allocation is greater than 100%</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
                return false;
              }
              if (sum1 < 100) {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;font-family:Poppins;">Business unit Allocation is less than 100%</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
                return false;
              }
              if (isNaN(sum1)) {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;font-family:Poppins;">Business unit Allocation is less than 100%</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });

                return false;
              }
              var result2 = bunit.reduce(function (map, obj) {
                map[obj.bunit] = obj.bunitvalue;
                return map;
              }, {});
              console.log("not empty");
            }

            //const countri = [country];
            let res = await fetch("https://ecoprismapi.azurewebsites.net/create-sitelocation", {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                username: username,
              },
              body: JSON.stringify({
                name: sitename,
                addressLine1: address1,
                addressLine2: "Address2",
                city: city,
                state: state,
                postalCode: postalcode,
                country: country,
                latitude: lat,
                siteType: sitetype,
                longitude: lng,
                parentAssociation: "",
                legalEntityMapping: result,
                businessUnitMapping: result2,
              }),
            });
            //let resJson = await res.json();
            if (res.status === 200) {
              const responseJson = await res.json();
              if (responseJson.success === false) {
                setLoading(false);
                console.log(responseJson);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
              } else if (responseJson.status === 400) {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                       <p style="font-size: 16px;">${responseJson.title}</p>`,
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
              } else {
                setName("");
                setAddress1("");
                setCountry("");
                setCity("");
                setPostalCode("");
                setState("");
                setLatitude("");
                setLongitude("");
                setSiteType("");
                setInputList([{ businessunit: "", businessunitvalue: "" }]);
                setBunit([{ bunit: "", bunitvalue: "" }]);
                setLoading(false);

                Swal.fire({
                  icon: "success",
                  html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">New Site Location has been successfully created</p>',
                  iconHtml:
                    '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
                });
              }
            } else {
              setLoading(false);
              Swal.fire({
                icon: "error",
                html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;">Error in creating site location</p>`,
                iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
              });
            }
          }
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Something went wrong</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      border: "1px solid #ebe9e9",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      textAlign: "left",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      textAlign: "left",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
      textAlign: "left",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };
  const [postalcodeError, setPostalCodeError] = useState("");
  const isNumeric = (value) => /^[0-9]*$/.test(value);
  // Helper function to check if the value is only numeric
  const isAlphanumericWithNumber = (value) => {
    const hasAlphabet = /[a-zA-Z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    return hasAlphabet && hasNumeric;
  };

  // Function to validate the input based on the scenarios
  const validatePostalCode = (value) => {
    if (isNumeric(value) || isAlphanumericWithNumber(value)) {
      setPostalCodeError("");
      return true;
    } else {
      setPostalCodeError("Please enter a valid postal code.");
      return false;
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    const alphanumeric = /^[a-zA-Z0-9]*$/; // Allow only alphanumeric characters
    if (
      alphanumeric.test(value) ||
      value === "" ||
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      setPostalCode(value);
      validatePostalCode(value);
    }
  };

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div></div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div Class="steps__containt-hader">
            <h3 class="title">Add Site Location</h3>
            <div role="presentation">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Links underline="hover" color="#707070" className="breadcrumbs-font">
                  Company
                </Links>
                <Link
                  to="/listsitelocations"
                  underline="hover"
                  color="#707070"
                  className="breadcrumbs-font"
                >
                  Site Locations
                </Link>

                <Typography color="inherit">Add Site Location</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div className="message">{message ? <p>{message}</p> : null}</div>
              <form onSubmit={handleSubmit} class="form-eco">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputcompany">
                      Name <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      value={sitename}
                      required
                      onChange={(e) => setName(e.target.value)}
                      class="form-control"
                      placeholder="Enter sitelocation name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputState">
                      Site type <span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      value={getsitetype && getsitetype.find((option) => option.value === sitetype)} // Ensure correct value is selected
                      onChange={(selectedOption) => setSiteType(selectedOption.value)}
                      options={
                        getsitetype &&
                        getsitetype.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select site type"
                      styles={customStyles}
                      menuPosition="fixed"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="formlable" for="inputCity">
                      Address <span className="mark-form">*</span>
                    </label>
                    <input
                      ref={autoCompleteRef}
                      type="text"
                      class="form-control"
                      required
                      id="inputCity"
                      placeholder=""
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="formlable" for="inputState">
                      Country <span className="mark-form">*</span>
                    </label>
                    <Select
                      required
                      id="inputState"
                      className=""
                      value={countries && countries.find((option) => option.value === country)} // Ensure correct value is selected
                      onChange={(selectedOption) => setCountry(selectedOption.value)}
                      options={
                        countries && countries.map((status) => ({ label: status, value: status }))
                      }
                      placeholder="Select Country"
                      styles={customStyles}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputCity">
                      State <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="inputCity"
                      placeholder=""
                      value={state}
                      onChange={(e) => {
                        // Check if the entered value contains only alphabets
                        const onlyAlphabets = /^[A-Za-z\s]+$/;
                        if (onlyAlphabets.test(e.target.value) || e.target.value === "") {
                          // If it's valid or empty, update the state
                          setState(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputZip">
                      City <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="inputZip"
                      placeholder=""
                      value={city}
                      onChange={(e) => {
                        // Check if the entered value contains only alphabets
                        const onlyAlphabets = /^[A-Za-z\s]+$/;
                        if (onlyAlphabets.test(e.target.value) || e.target.value === "") {
                          // If it's valid or empty, update the state
                          setCity(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputZip">
                      Zip/Postal Code <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${postalcodeError ? "is-invalid" : ""}`}
                      required
                      id="inputZip"
                      placeholder=""
                      value={postalcode}
                      onChange={handlePostalCodeChange}
                    />
                    {postalcodeError && <div className="invalid-feedback">{postalcodeError}</div>}
                  </div>
                </div>
                <div class="form-row">
                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputZip">
                      Latitude <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="inputZip"
                      placeholder=""
                      value={lat}
                      onChange={(e) => {
                        // Check if the entered value contains only numeric and decimal characters, including minus sign
                        const onlyNumericAndDecimal = /^-?[0-9]*\.?[0-9]*$/;
                        if (
                          onlyNumericAndDecimal.test(e.target.value) ||
                          e.target.value === "" ||
                          e.inputType === "deleteContentBackward"
                        ) {
                          // If it's valid, empty, or a backspace event, update the state
                          setLatitude(e.target.value);
                        }
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="formlable" htmlFor="inputZip">
                      Longitude <span className="mark-form">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="inputZip"
                      placeholder=""
                      value={lng}
                      onChange={(e) => {
                        // Check if the entered value contains only numeric and decimal characters, including minus sign
                        const onlyNumericAndDecimal = /^-?[0-9]*\.?[0-9]*$/;
                        if (
                          onlyNumericAndDecimal.test(e.target.value) ||
                          e.target.value === "" ||
                          e.inputType === "deleteContentBackward"
                        ) {
                          // If it's valid, empty, or a backspace event, update the state
                          setLongitude(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="App">
                  <div Class="steps__containt-hader">
                    <h3 class="title-sl">
                      % Allocation to legal entities <span className="mark-form">*</span>
                    </h3>
                  </div>
                  {inputList.map((x, i) => {
                    return (
                      <div className="box">
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <Select
                              required
                              id="inputState"
                              className="Ecodropdown-new"
                              name="businessunit"
                              placeholder="Select legal entities"
                              value={
                                x.businessunit
                                  ? { label: x.businessunit, value: x.businessunit }
                                  : null
                              }
                              onChange={(selectedOption) =>
                                handleInputChange(
                                  { target: { name: "businessunit", value: selectedOption.value } },
                                  i
                                )
                              }
                              options={
                                Array.isArray(activele)
                                  ? activele.map((status) => ({
                                      label: status.name,
                                      value: status.name,
                                    }))
                                  : []
                              }
                              styles={customStyles}
                              menuPosition="fixed"
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <input
                              className="ml10 form-control"
                              name="businessunitvalue"
                              placeholder="Ex.60%..."
                              value={x.businessunitvalue}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <div className="btn-box">
                              {inputList.length !== 1 && (
                                <button
                                  type="button"
                                  className="mr10 btn btn-danger"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove
                                </button>
                              )}
                              {inputList.length - 1 === i && (
                                <button
                                  type="button"
                                  id="addMore"
                                  onClick={handleAddClick}
                                  class="btn btn-primary"
                                  disabled={isButtonDisabled}
                                >
                                  Add more
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div Class="steps__containt-hader">
                    <h3 class="title-sl">% Allocation to business unit</h3>
                  </div>
                  {bunit.map((x, i) => {
                    return (
                      <div className="box">
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <Select
                              id="inputState"
                              className=""
                              name="businessunit"
                              placeholder="Select business unit"
                              value={x.bunit ? { label: x.bunit, value: x.borderunit } : null}
                              onChange={(selectedOption) =>
                                handleInputChange1(
                                  { target: { name: "bunit", value: selectedOption.value } },
                                  i
                                )
                              }
                              options={
                                Array.isArray(activebu)
                                  ? activebu.map((status) => ({
                                      label: status.name,
                                      value: status.name,
                                    }))
                                  : []
                              }
                              styles={customStyles}
                              menuPosition="fixed"
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <input
                              className="ml10 form-control"
                              name="bunitvalue"
                              placeholder="Ex.60%..."
                              value={x.bunitvalue}
                              onChange={(e) => handleInputChange1(e, i)}
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <div className="btn-box">
                              {bunit.length !== 1 && (
                                <button
                                  type="button"
                                  className="mr10 btn btn-danger"
                                  onClick={() => handleRemoveClick1(i)}
                                >
                                  Remove
                                </button>
                              )}
                              {bunit.length - 1 === i && (
                                <button
                                  type="button"
                                  id="addMore"
                                  onClick={handleAddClick1}
                                  class="btn btn-primary"
                                  disabled={isButtonDisabled1}
                                >
                                  Add more
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  type="submit"
                  disabled={loading}
                  className="next-button"
                  variant="contained"
                  size="medium"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#407775",
                    width: "120px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                >
                  Submit
                </Button>
                <Button
                  href="listsitelocations"
                  variant="outlined"
                  className="outline-btn"
                  size="medium"
                  sx={{
                    color: "#000",
                    marginLeft: "15px",
                    width: "120px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                >
                  Cancel
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
