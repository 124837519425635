import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ModulePopup({ isModalOpen, onClose }) {
  return (
    <Modal className="reports-modal module-modal" show={isModalOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="module-title">Select the relevant modules!</h5>
          <div className="module-bullets">
            <ul>
              <li>
                The disclosures relevant to the material topics chosen by you will be highlighted
                with a materiality icon.
              </li>
            </ul>
          </div>
          <div className="module-bullets">
            <ul>
              <li>
                Mandatory disclosures will be indicated within the materiality chosen by you and
                other mandatory disclosures irrespective of the materiality as per the ESRS
                standard.
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div class="card mandatory">
                <div class="card-header">
                  <h5>Mandatory</h5>
                  <div>
                    <img src="./assets/img/reports/Mandatory.svg" alt="" />
                  </div>
                </div>

                <div class="card-body">
                  <p class="card-text">
                    These are non-negotiable requirements set by ESRS standards that your company
                    must report to ensure compliance. Make sure you disclose the necessary reporting
                    requirements established by regulatory bodies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card disclose">
                <div class="card-header">
                  <h5>Disclose if Material</h5>
                  <div>
                    <img src="./assets/img/reports/disclose.svg" alt="" />
                  </div>
                </div>{" "}
                <div class="card-body">
                  <p class="card-text">
                    Before choosing modules ensure that you conduct a materiality assessment to
                    identify significant ESG impacts and risks relevant to your company's operations
                    and stakeholders. Carefully choose the relevant disclosures based on your
                    materiality assessment and disclose the necessary reporting requirements
                    established by regulatory bodies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          href="/Materiality"
          variant="outlined"
          className="outline-btn"
          size="medium"
          type="button"
        >
          Cancel
        </Button>
        <Button variant="primary" className="next-button" onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModulePopup;
