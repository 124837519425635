import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import data from "./tabData"; // Import the tab data
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
function LeftContainer({ onSelect }) {
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubChange = (event, newSubValue) => {
    setSubValue(newSubValue);
  };

  const selectedSubSubItem = data[value].subItems[subValue]?.subSubItems || [];

  const [expandedMainTab, setExpandedMainTab] = useState(""); // State to track expanded main tab
  const [expandedInnerAccordions, setExpandedInnerAccordions] = useState({});
  const [selectedTab, setSelectedTab] = useState({ mainIndex: 0, subIndex: 0, subSubIndex: 0 }); // State to track the globally selected tab

  useEffect(() => {
    // Automatically expand the first main accordion and select the first tab on initial render
    if (data.length > 0 && data[0].subItems && data[0].subItems.length > 0) {
      setExpandedMainTab(data[0].title);
      setExpandedInnerAccordions({ 0: 0 });
      setSelectedTab({ mainIndex: 0, subIndex: 0, subSubIndex: 0 });
      onSelect(data[0].subItems[0].subSubItems[0].label, data[0].subItems[0].title);
    }
  }, []); // Empty dependency array ensures this runs only once

  const handleMainTabChange = (title) => {
    // Toggle the expanded state for the clicked main tab
    setExpandedMainTab((prev) => (prev === title ? "" : title));
    setExpandedInnerAccordions({});
  };

  const handleInnerAccordionChange = (mainIndex, subIndex, isExpanded) => {
    setExpandedInnerAccordions((prev) => ({
      ...prev,
      [mainIndex]: isExpanded ? subIndex : null,
    }));
  };

  const handleTabClick = (mainIndex, subIndex, subSubIndex, label, subTitle) => {
    setSelectedTab({ mainIndex, subIndex, subSubIndex });
    onSelect(label, subTitle); // Call the onSelect function with the appropriate arguments
  };

  const t0panel0 = 0;
  const t1panel0 = 0;
  const t1panel1 = 1;
  const t1panel2 = 2;
  const t1panel3 = 3;
  const t1panel4 = 4;
  const t1panel5 = 5;
  const t2panel0 = 0;
  const t2panel1 = 1;
  const t2panel2 = 2;
  const t2panel3 = 3;
  const t3panel0 = 0;
  const t3panel1 = 1;
  const csrdValue = JSON.parse(localStorage.getItem("step3"));

  const step3 = JSON.parse(localStorage.getItem("step3"));
  
  const uniqueTabIndexes = [...new Set(step3.map((item) => item.tabIndex))];
  

  const t0p0 = csrdValue.filter((item1) => item1.tabIndex === 0 && item1.panelIndex === 0);
  const t0p0value = 0;
  const d0t0p0 = data[0].subItems[0].subSubItems;
  const d0t0p0Filter = t0p0.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d0t0p0Items = d0t0p0.filter((item, itemindex) => {
    const matchingIndex = d0t0p0Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t1p0 = csrdValue.filter((item1) => item1.tabIndex === 1 && item1.panelIndex === 0);
  const t1p0value = 0;
  const d1t1p0 = data[1].subItems[0].subSubItems;
  const d1t1p0Filter = t1p0.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t1p0Items = d1t1p0.filter((item, itemindex) => {
    const matchingIndex = d1t1p0Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t1p1 = csrdValue.filter((item1) => item1.tabIndex === 1 && item1.panelIndex === 1);
  const t1p1value = 1;
  const d1t1p1 = data[1].subItems[1].subSubItems;
  const d1t1p1Filter = t1p1.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t1p1Items = d1t1p1.filter((item, itemindex) => {
    const matchingIndex = d1t1p1Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t1p2 = csrdValue.filter((item1) => item1.tabIndex === 1 && item1.panelIndex === 2);
  const t1p2value = 2;
  const d1t1p2 = data[1].subItems[2].subSubItems;
  const d1t1p2Filter = t1p2.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t1p2Items = d1t1p2.filter((item, itemindex) => {
    const matchingIndex = d1t1p2Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });
  const t1p3 = csrdValue.filter((item1) => item1.tabIndex === 1 && item1.panelIndex === 3);
  const t1p3value = 3;
  const d1t1p3 = data[1].subItems[3].subSubItems;
  const d1t1p3Filter = t1p3.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t1p3Items = d1t1p3.filter((item, itemindex) => {
    const matchingIndex = d1t1p3Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });
  const t1p4 = csrdValue.filter((item1) => item1.tabIndex === 1 && item1.panelIndex === 4);
  const t1p4value = 4;
  const d1t1p4 = data[1].subItems[4].subSubItems;
  const d1t1p4Filter = t1p4.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t1p4Items = d1t1p4.filter((item, itemindex) => {
    const matchingIndex = d1t1p4Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t2p0 = csrdValue.filter((item1) => item1.tabIndex === 2 && item1.panelIndex === 0);
  const t2p0value = 0;
  const d1t2p0 = data[2].subItems[0].subSubItems;
  const d1t2p0Filter = t2p0.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t2p0Items = d1t2p0.filter((item, itemindex) => {
    const matchingIndex = d1t2p0Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });
  const t2p1 = csrdValue.filter((item1) => item1.tabIndex === 2 && item1.panelIndex === 1);
  const t2p1value = 1;
  const d1t2p1 = data[2].subItems[1].subSubItems;
  const d1t2p1Filter = t2p1.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t2p1Items = d1t2p1.filter((item, itemindex) => {
    const matchingIndex = d1t2p1Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });
  const t2p2 = csrdValue.filter((item1) => item1.tabIndex === 2 && item1.panelIndex === 2);
  const t2p2value = 2;
  const d1t2p2 = data[2].subItems[2].subSubItems;
  const d1t2p2Filter = t2p2.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t2p2Items = d1t2p2.filter((item, itemindex) => {
    const matchingIndex = d1t2p2Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t2p3 = csrdValue.filter((item1) => item1.tabIndex === 2 && item1.panelIndex === 3);
  const t2p3value = 3;
  const d1t2p3 = data[2].subItems[3].subSubItems;
  const d1t2p3Filter = t2p3.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t2p3Items = d1t2p3.filter((item, itemindex) => {
    const matchingIndex = d1t2p3Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });

  const t3p0 = csrdValue.filter((item1) => item1.tabIndex === 3 && item1.panelIndex === 0);
  const t3p0value = 0;
  const d1t3p0 = data[3].subItems[0].subSubItems;
  const d1t3p0Filter = t3p0.map((item) => ({
    checkbox: item.checkboxIndex,
  }));
  const d1t3p0Items = d1t3p0.filter((item, itemindex) => {
    const matchingIndex = d1t3p0Filter.findIndex((index) => index.checkbox === itemindex);
    return matchingIndex !== -1;
  });
  // const t3p1 = csrdValue.filter((item1) => item1.tabIndex === 3 && item1.panelIndex === 1);
  // const t3p1value = 1;
  // const d1t3p1 = data[3].subItems[1].subSubItems;
  // const d1t3p1Filter = t3p1.map((item) => ({
  //   checkbox: item.checkboxIndex,
  // }));
  // const d1t3p1Items = d1t3p1.filter((item, itemindex) => {
  //   const matchingIndex = d1t3p1Filter.findIndex((index) => index.checkbox === itemindex);
  //   return matchingIndex !== -1;
  // });

  useEffect(() => {
    const uniqueTabIndexes = [...new Set(step3.map((item) => item.tabIndex))];
    const smallestTabIndex = Math.min(...uniqueTabIndexes);
    setExpandedMainTab(data[smallestTabIndex].title);

    const filteredData = step3.filter((item) => item.tabIndex === smallestTabIndex);
    const uniquePanelIndexes = [...new Set(filteredData.map((item) => item.panelIndex))];
    const smallestPanelIndex = Math.min(...uniquePanelIndexes);

    const filteredDatas = filteredData.filter((item) => item.panelIndex === smallestPanelIndex);
    const uniqueChecboxIndexe = [...new Set(filteredDatas.map((item) => item.checkboxIndex))];
    const smallestCheckboxIndex = Math.min(...uniqueChecboxIndexe);

    const filteredData1 = data.filter((item, index) => index === smallestTabIndex);
    const filteredData2 = filteredData1[0].subItems.filter(
      (item, index) => index === smallestPanelIndex
    );
    const filteredData3 = filteredData2.filter((item, index) => index === smallestCheckboxIndex);
    onSelect(
      filteredData2[0].subSubItems[smallestCheckboxIndex].label,
      filteredData2[0].subSubItems[smallestCheckboxIndex].title
    );
  }, []);

  return (
    <div className="left">
      {data.map((item, index) => (
        <React.Fragment key={index}>
          {uniqueTabIndexes.includes(index) && (
            <Accordion
              className="main-accordian"
              key={index}
              expanded={expandedMainTab === item.title}
              onChange={() => handleMainTabChange(item.title)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{item.title}</Typography>
              </AccordionSummary>

              <AccordionDetails className="inner-accordian">
                {item.subItems && (
                  <div>
                    {item.subItems.map((subItem, subIndex) => {
                      if (
                        (t0p0.length > 0 && t0p0value === subIndex && index === 0) ||
                        (t1p0.length > 0 && t1p0value === subIndex && index === 1) ||
                        (t1p1.length > 0 && t1p1value === subIndex && index === 1) ||
                        (t1p2.length > 0 && t1p2value === subIndex && index === 1) ||
                        (t1p3.length > 0 && t1p3value === subIndex && index === 1) ||
                        (t1p4.length > 0 && t1p4value === subIndex && index === 1) ||
                        (t2p0.length > 0 && t2p0value === subIndex && index === 2) ||
                        (t2p1.length > 0 && t2p1value === subIndex && index === 2) ||
                        (t2p2.length > 0 && t2p2value === subIndex && index === 2) ||
                        (t2p3.length > 0 && t2p3value === subIndex && index === 2) ||
                        (t3p0.length > 0 && t3p0value === subIndex && index === 3)
                      ) {
                        return (
                          <Accordion
                            className="active-sub-accordion"
                            key={subIndex}
                            expanded={
                              expandedMainTab === item.title &&
                              expandedInnerAccordions[index] === subIndex
                            }
                            onChange={(event, isExpanded) =>
                              handleInnerAccordionChange(index, subIndex, isExpanded)
                            }
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardDoubleArrowRightIcon />}
                              // Add a style object with background and color properties
                              style={{
                                backgroundColor:
                                  expandedMainTab === item.title ? "#407775" : "inherit",
                                color: expandedMainTab === item.title ? "#fff" : "inherit",
                              }}
                            >
                              <Typography>{subItem.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="active-sub-tabs">
                              {subItem.subSubItems && (
                                <Box display="flex" flexDirection="column">
                                  <Tabs
                                    className="tab-container"
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={
                                      expandedMainTab === item.title &&
                                      expandedInnerAccordions[index] === subIndex &&
                                      selectedTab.mainIndex === index &&
                                      selectedTab.subIndex === subIndex
                                        ? selectedTab.subSubIndex
                                        : false
                                    }
                                    onChange={handleSubChange}
                                    TabIndicatorProps={{
                                      style: { display: "none" }, // Hide tab indicator
                                    }}
                                  >
                                    {/* {subItem.subSubItems.map((subSubItem, subSubIndex) => { */}
                                    {t0p0value === subIndex && index === 0
                                      ? d0t0p0Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t1p0value === subIndex && index === 1
                                      ? d1t1p0Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t1p1value === subIndex && index === 1
                                      ? d1t1p1Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t1p2value === subIndex && index === 1
                                      ? d1t1p2Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t1p3value === subIndex && index === 1
                                      ? d1t1p3Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}
                                    {t1p4value === subIndex && index === 1
                                      ? d1t1p4Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t2p0value === subIndex && index === 2
                                      ? d1t2p0Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}
                                    {t2p1value === subIndex && index === 2
                                      ? d1t2p1Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}
                                    {t2p2value === subIndex && index === 2
                                      ? d1t2p2Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}
                                    {t2p3value === subIndex && index === 2
                                      ? d1t2p3Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}

                                    {t3p0value === subIndex && index === 3
                                      ? d1t3p0Items.map((subSubItem, subSubIndex) => {
                                          return (
                                            <Tab
                                              className="tab-item"
                                              label={
                                                selectedTab.mainIndex === index &&
                                                selectedTab.subIndex === subIndex &&
                                                selectedTab.subSubIndex === subSubIndex
                                                  ? subSubItem.label // Show full label for selected tab
                                                  : subSubItem.label.length > 20
                                                  ? subSubItem.label.substring(0, 20) + "..." // Truncate label for unselected tabs
                                                  : subSubItem.label
                                              }
                                              onClick={() =>
                                                handleTabClick(
                                                  index,
                                                  subIndex,
                                                  subSubIndex,
                                                  subSubItem.label,
                                                  subItem.title
                                                )
                                              }
                                              key={subSubIndex}
                                            />
                                          );
                                        })
                                      : null}
                                  </Tabs>
                                </Box>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default LeftContainer;
