import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineReload } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";

function AIPopup({ aiModalOpen, onClose, question, answer, onUseText, resetAnswer }) {
  const [characterCount, setCharacterCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [generatedAnswer, setGeneratedAnswer] = useState("");

  useEffect(() => {
    if (aiModalOpen) {
      setGeneratedAnswer("");
    }
  }, [aiModalOpen]);

  const handleInputChange = (event) => {
    const count = event.target.value.length;
    setCharacterCount(count);
  };

  const generate = async () => {
    setLoading(true);
    try {
      const requestOptions = { providedInfo: answer, question: question };
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/generate-answer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestOptions),
      });
      const data = await response.json();
      if (data.success === false || data.status === 400) {
        // Handle error
      } else {
        var datar = data.data;
        setGeneratedAnswer(datar);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const generate1 = async () => {
    setLoading1(true);
    try {
      const requestOptions = { providedInfo: answer, question: question };
      const response = await fetch(`https://ecoprismapi.azurewebsites.net/generate-answer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestOptions),
      });
      const data = await response.json();
      if (data.success === false || data.status === 400) {
        // Handle error
      } else {
        var datar = data.data;
        setGeneratedAnswer(datar);
      }
      setLoading1(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal className="reports-modal ai-modal" show={aiModalOpen} onHide={onClose} centered>
      <Modal.Header closeButton style={{ color: "white" }}>
        <Modal.Title>ecoBOT suggestions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ai-box">
          <div className="ai-info-container">
            <div style={{ width: "100%" }}>
              <textarea
                className="ai-info"
                placeholder="Provide information here"
                onChange={handleInputChange}
                value={question}
              />
            </div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="generate-tooltip">Generate text</Tooltip>}
            >
              <button className="generate-button" onClick={generate} disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" /> : <BsFillSendFill />}
              </button>
            </OverlayTrigger>
          </div>
          <div className="ai-answer-container">
            <div className="ai-answer">{generatedAnswer || "Generated answer"}</div>
            <div className="refresh-container">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="reload-tooltip">New suggestion</Tooltip>}
              >
                <button className="generate-button" onClick={generate1} disabled={loading1}>
                  {loading1 ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    <AiOutlineReload />
                  )}
                </button>
              </OverlayTrigger>
              <div className="character-count">{characterCount}/500</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          className="outline-btn"
          size="medium"
          type="button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="next-button"
          onClick={() => onUseText(generatedAnswer)}
        >
          Use Text
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AIPopup;
