import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import containerTheme from "./theme/containerTheme.js";
import { PowerBIEmbed } from "powerbi-client-react";
import { appInsights } from "./AppInsights";
import AADHeader from "./components/AADHeader.js";
// const useStyles = makeStyles((theme) => containerTheme(theme));

export default function Home() {
  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [responseConfig, setResponseConfig] = useState({});
  const username = localStorage.getItem("userName");
  const supplierName = username; // Replace with the static useremail
  const powerBiRef = useRef(null);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isDatasetRefreshing, setIsDatasetRefreshing] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(
    localStorage.getItem("lastRefreshTime") || ""
  );
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    appInsights.trackPageView();
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://powerbi-dev-embedded.azurewebsites.net/api/HttpTrigger1?supplierName=${encodeURIComponent(
            supplierName
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setResponseConfig(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("lastRefreshTime", lastRefreshTime);
  }, [lastRefreshTime]);

  const handleReportLoaded = () => {
    setIsReportLoaded(true);
  };

  const handleRefresh = () => {
    if (powerBiRef.current) {
      powerBiRef.current.reload();
    }
  };

  const handleDatasetRefresh = async () => {
    try {
      setIsDatasetRefreshing(true);

      const response = await fetch(
        `https://api.powerbi.com/v1.0/myorg/groups/d60d56ef-61f6-42da-9fbe-901ed9b6e00a/datasets/78d26831-b1f3-4017-8154-60824acd24d2/refreshes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${responseConfig.AccessToken}`,
          },
        }
      );

      // Handle the response as needed
      if (response.ok) {
        console.log("Dataset refreshed successfully");
        const refreshTime = new Date().toLocaleString();
        setLastRefreshTime(refreshTime);
        setRefreshKey((prevKey) => prevKey + 1);
      } else {
        console.log("Dataset refresh failed");
      }
      setIsDatasetRefreshing(false);
    } catch (error) {
      console.log(error);
      setIsDatasetRefreshing(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container">
          {/* <button onClick={handleRefresh} disabled={!isReportLoaded}>
            Refresh Report
          </button> */}
          <div class="col-md-12 last-refresh">
            <button
              class="btn btn-primary refresh-right"
              onClick={handleDatasetRefresh}
              disabled={isDatasetRefreshing}
            >
              {isDatasetRefreshing ? "Refreshing Report..." : "Refresh Report"}
            </button>
            {lastRefreshTime && (
              <div>
                <p className="refresh-text">Last Refresh Time: {lastRefreshTime}</p>
              </div>
            )}
          </div>
          <div className="App">
            {responseConfig && responseConfig.EmbedUrl && responseConfig.EmbedToken ? (
              <PowerBIEmbed
                ref={powerBiRef}
                embedConfig={{
                  type: "report",
                  id: responseConfig.ReportId,
                  embedUrl: responseConfig.EmbedUrl,
                  accessToken: responseConfig.EmbedToken,
                  tokenType: 1,
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "Scope2Summary",
                        column: "email",
                      },
                      operator: "In",
                      values: [supplierName],
                    },
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "Scope1Summary",
                        column: "email",
                      },
                      operator: "In",
                      values: [supplierName],
                    },
                  ],
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: true,
                      },
                    },
                    background: 0,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        handleReportLoaded();
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                  ])
                }
                cssClassName={"report-style-class"}
                key={refreshKey} // Use refreshKey as the key for proper re-rendering
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
