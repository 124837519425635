import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
// import containerTheme from './theme/containerTheme.js'
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import { FaCalendarAlt } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import classnames from "classnames";
import AADHeader from "./components/AADHeader.js";
// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  const username = localStorage.getItem("userName");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const slParam = urlSearchParams.get("sl");
  const esg = urlSearchParams.get("esg");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [reporthistory, setReportHistory] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [supplier, setSupplier] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [startdate, setStartDate] = React.useState("");
  const [enddate, setEndDate] = React.useState("");
  const [sup, setSup] = React.useState("");
  const [own, setOwn] = React.useState("");
  const [electricitycost, setElectricityCost] = React.useState("");
  const [electricitysaving, setElectricitySaving] = React.useState("");
  const [electricityunitcost, setElectricityUnitCost] = React.useState("");

  async function fetchHistory() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/electricity-insights",
        requestOptions
      );
      const data = await response.json();
      if (data.success == false) {
        setLoading(false);
      } else if (data.status == 400) {
        setLoading(false);
      } else {
        setLoading(false);
        console.log(data);
        setElectricityCost(data.data.siteCostAnalysisInsights);
        setElectricitySaving(data.data.savingsAnalysis);
        setElectricityUnitCost(data.data.unitCostForCalculation);
        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    fetchHistory();
  }, []);

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input type="text" value={value} readOnly placeholder="Start date" className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  );
  const CustomDatePickerInput1 = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input type="text" value={value} readOnly placeholder="End date" className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  );
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9">
              <div Class="steps__containt-hader">
                <h3 class="title">Insights</h3>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Insights
                    </Link>
                    <Typography color="text.primary">Electricity cost evaluation</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="container">{message}</div>

          <div class="col-md-12 flex-pd">
            <div class="col-md-4 borderright pd-right">
              <h2 className="text-inputinsights font-16">INPUT PARAMETER</h2>
              <label class="formlable" for="formGroupExampleInput">
                Non-RE cost (DKK/Kwh)
              </label>
              <input
                type="text"
                value={Number(electricityunitcost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                readOnly
                class="form-control"
                required
                id="formGroupExampleInput"
                placeholder="53,11,481"
              />
            </div>
            <div class="col-md-4 pd-left">
              <h2 className="text-inputanalysis font-16">ANALYSIS SUMMARY</h2>
              <label class="formlable text-inputanalysis" for="formGroupExampleInput">
                Potential cost savings (DKK)
              </label>
              <input
                type="text"
                value={Number(electricitysaving)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                readOnly
                class="form-control bg-inputanalysis"
                required
                id="formGroupExampleInput"
                placeholder="53,11,481"
              />
            </div>
          </div>

          <div class="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Site </TableCell>
                    <TableCell align="left">Non RE unit cost(DKK/kWh)</TableCell>
                    <TableCell align="left">Non RE electricity consumption (MWh) </TableCell>
                    <TableCell align="left">Total Non RE spend (DKK)</TableCell>
                    <TableCell align="left">Target Non RE spend (DKK)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {electricitycost &&
                    electricitycost.map((row) => (
                      <TableRow
                        key={row.siteLocationName}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="left"
                          className={classnames({
                            "text-danger":
                              parseFloat(Number(electricityunitcost).toFixed(2)) <
                              parseFloat(Number(row.nonREUnitCost).toFixed(2)), // Add this class if input value is less than table data
                            "text-success":
                              parseFloat(Number(electricityunitcost).toFixed(2)) >
                              parseFloat(Number(row.nonREUnitCost).toFixed(2)), // Add this class if input value is greater than table data
                          })}
                        >
                          {row.siteLocationName}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classnames({
                            "text-danger":
                              parseFloat(Number(electricityunitcost).toFixed(2)) <
                              parseFloat(Number(row.nonREUnitCost).toFixed(2)), // Add this class if input value is less than table data
                            "text-success":
                              parseFloat(Number(electricityunitcost).toFixed(2)) >
                              parseFloat(Number(row.nonREUnitCost).toFixed(2)), // Add this class if input value is greater than table data
                          })}
                        >
                          {Number(row.nonREUnitCost).toFixed(2)}
                        </TableCell>
                        <TableCell align="left">
                          {Number(row.nonREElectricityConsumption)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {Number(row.totalNonRESpend)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="left">
                          {Number(row.targetNonRESpend)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
