import React, { useState , useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';

const ECSurveyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState('');
  // const decodedString = atob(token);
  // console.log('Decoded String:', decodedString);
  // Display the decoded data
  const username = localStorage.getItem("userName");
  const [loading, setLoading] = useState(false);
  const [sitelocation, setSiteLocation] = useState("");
  const [activesitelocation, setActiveSiteLocation] = useState("");
  const [datasitelocation, setDataSiteLocation] = useState("");

  const [surveyFromDate, setSurveyFromDate] = useState("");
  const [surveyToDate, setSurveyToDate] = useState("");
  const [validFromDate, setValidFromDate] = useState("");
  const [validToDate, setValidToDate] = useState("");
  const [surveyToken, setSurveyToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('token');
    const empEmail = queryParams.get('email');
    if (tokenFromUrl) {
      console.log(tokenFromUrl);
      const decodedString = atob(tokenFromUrl);
      const [companyid, formattedFromDate, formattedToDate, formattedExpiryDate,formattedDate,username] = decodedString.split(':');
      console.log('Decoded String:', decodedString);
      
      const fromParts = formattedFromDate.split("-");
      const fromDate = `${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`;
      
      const toParts = formattedToDate.split("-");
      const toDate = `${toParts[2]}-${toParts[1]}-${toParts[0]}`;
      setFormData({
        ...formData,
        surveyPeriod: fromDate +' to '+ toDate,
        employeeEmail: empEmail, // Set the state based on the selected location
      });
      setSurveyFromDate(formattedFromDate);
      setSurveyToDate(formattedToDate);
      setValidFromDate(formattedFromDate);
      setValidToDate(formattedToDate);
      setSurveyToken(tokenFromUrl);
      setCompanyId(companyid)
      setToken(tokenFromUrl);
      fetchSiteLocation(username);
    }
    console.log(formData);
  }, []);


  
  

  async function fetchSiteLocation(username) {
    var myHeaders = new Headers();
    myHeaders.append("username", username);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitelocation",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setSiteLocation("");
        } else if (responseJson.status == 400) {
          setSiteLocation("");
        } else {
          setSiteLocation(responseJson);
          setActiveSiteLocation(responseJson.data.filter((item) => item.isActive === true));
          setDataSiteLocation("");
        }
      } else {
        setSiteLocation("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [data, setData] = useState([
    { id: 1, equipment: "Lighting", usage: null, wattage: null },
    { id: 2, equipment: "Fan", usage: null, wattage: null },
    { id: 3, equipment: "PC/Laptop", usage: null, wattage: null },
    { id: 4, equipment: "Monitor", usage: null, wattage: null },
    { id: 5, equipment: "Printer", usage: null, wattage: null },
    { id: 6, equipment: "Mobile phone", usage: null, wattage: null },
    { id: 7, equipment: "Heating", usage: null, wattage: null },
    { id: 8, equipment: "Cooling", usage: null, wattage: null },
  ]);

  const handleInputChangeDataTable = (e, rowData, rowIndex) => {
    const { id, value } = e.target;
    let updatedData = [...data];

    updatedData[rowIndex][id] = value;
    setData(updatedData);

    // Set the state based on the row clicked
    if (rowData.equipment === "Heating") {
      setHeatingActive(true);
      setCoolingActive(false);
    } else if (rowData.equipment === "Cooling") {
      setCoolingActive(true);
      setHeatingActive(false);
    }
  };
  

  const [heatingActive, setHeatingActive] = useState(false);
  const [coolingActive, setCoolingActive] = useState(false);

  const handleRowAction = (rowData, rowIndex) => {
    // For example, you can calculate some values for this row
    console.log('Action triggered for row:', rowIndex, rowData);
    
    // Perform custom logic here, e.g., calculating based on rowData
    let newUsage = 0; // Example logic to update usage
    let newWattage = 0; // Example logic to update wattage
    if(rowData.id === 1){
      newUsage = 8;
      newWattage = 25;
    }
    else if(rowData.id === 2){
      newUsage = 8;
      newWattage = 50;
    }
    else if(rowData.id === 3){
      newUsage = 8;
      newWattage = 80;
    }
    else if(rowData.id === 4){
      newUsage = 8;
      newWattage = 30;
    }
    else if(rowData.id === 5){
      newUsage = 8;
      newWattage = 250;
    }
    else if(rowData.id === 6){
      newUsage = 8;
      newWattage = 60;
    }
    else if(rowData.id === 7){
      newUsage = 8;
      newWattage = 600;
      setHeatingActive(true);
      setCoolingActive(false); // Disable cooling when heating is active
    }
    else if(rowData.id === 8){
      newUsage = 8;
      newWattage = 2000;
      setCoolingActive(true);
      setHeatingActive(false); // Disable heating when cooling is active
    }
    else{
      newUsage = 0;
      newWattage = 0;
    }
  
    let updatedData = [...data];
    updatedData[rowIndex] = { ...rowData, usage: newUsage, wattage: newWattage };
  
    setData(updatedData);
  };


  const [formData, setFormData] = useState({
    employeeId: "",
    siteLocation: "",
    workingDays: "",
    distance: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e, rowData, rowIndex) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const siteLocations = 
    activesitelocation &&
    activesitelocation.map((status) => ({
      label: status.name,
      value: status.name,
      state: status.state,
      country: status.country,
    }));

  const workingDaysOptions = [
    { label: "1 day", value: "1" },
    { label: "2 days", value: "2" },
    { label: "3 days", value: "3" },
    { label: "4 days", value: "4" },
    { label: "5 days", value: "5" },
    { label: "6 days", value: "6" },
    { label: "7 days", value: "7" },
  ];

  // Define the number of days options
  const wfhDaysOptions = [
    { label: "1 day", value: "1" },
    { label: "2 days", value: "2" },
    { label: "3 days", value: "3" },
    { label: "4 days", value: "4" },
    { label: "5 days", value: "5" },
    { label: "6 days", value: "6" },
    { label: "7 days", value: "7" },
  ];

  const transportOptions = [
    { label: "Car", value: "Car" },
    { label: "Bus", value: "Bus" },
    { label: "Bicycle", value: "Bicycle" },
    { label: "Walking", value: "Walking" },
    // Add more transport modes as needed
  ];

  const fuelOptions = [
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
    { label: "Hybrid", value: "Hybrid" },
    // Add more fuel types as needed
  ];

  const unitOptions = [
    { label: "km/l", value: "km/l" },
    { label: "miles/gallon", value: "miles/gallon" },
    // Add more units as needed
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!formData.employeeId) newErrors.employeeId = "Employee ID is required";
    if (!formData.siteLocation) newErrors.siteLocation = "Site Location is required";
    if (!formData.workingDays) newErrors.workingDays = "No. of working days is required";
    if (!formData.distance) newErrors.distance = "Distance is required";
    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);
    const wfhh=formData.wfh;
    let wfhradio;
    if(wfhh === "true"){
      wfhradio = true;
    }
    else{
      wfhradio = false;
    }
    let validate = await fetch("https://ecoprismapi.azurewebsites.net/validate-survey", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        "companyId":companyId,
        "token": surveyToken,
        "surveyFromDate": validFromDate,
        "surveyToDate": validToDate
      }),
    });
    
    if (validate.status === 200) {
      const responseJson = await validate.json();
      if (responseJson.success === false) {
        setLoading(false);
        console.log(responseJson);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4><p style="font-size: 16px;">${responseJson.data}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else if (responseJson.status === 400) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;">${responseJson.title}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else {
        
        let res = await fetch("https://ecoprismapi.azurewebsites.net/save-survey-response", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            "surveyFromDate":surveyFromDate ,
            "surveyToDate": surveyToDate,
            "surveyToken": surveyToken,
            "employeeId": formData.employeeId,
            "employeeEmail": formData.employeeEmail,
            "employeeName": formData.employeeName,
            "siteLocation": formData.siteLocation,
            "city": "",
            "state": formData.state,
            "country": formData.country,
            "workingDaysInWeek": formData.workingDays,
            "distanceFromHomeToOfficeKm": formData.distance,
            "averageWorkFromOfficeDaysEachWeek": formData.wfhDays,
            "primaryModeOfTransport": formData.primaryTransport,
            "transportType": formData.transportType,
            "fuelUsedInVehicle": formData.fuelUsed,
            "fuelEfficiency": formData.fuelEfficiency,
            "fuelEfficiencyUnit":formData.unit ,
            "lightingUsagePerDay": data[0].usage,
            "lightingWattage": data[0].wattage,
            "fanUsagePerDay": data[1].usage,
            "fanWattage": data[1].wattage,
            "pcLaptopUsagePerDay": data[2].usage,
            "pcLaptopWattage": data[2].wattage,
            "monitorUsagePerDay": data[3].usage,
            "monitorWattage": data[3].wattage,
            "printerUsagePerDay": data[4].usage,
            "printerWattage": data[4].wattage,
            "mobilePhoneUsagePerDay": data[5].usage,
            "mobilePhoneWattage": data[5].wattage,
            "heatingUsagePerDay": data[6].usage,
            "heatingWattage": data[6].wattage,
            "coolingUsagePerDay": data[7].usage,
            "coolingWattage": data[7].wattage,
            "personAvailableAtHomeIfWorkedFromOffice": wfhradio,
            "companyId": companyId
          }),
        });
        
        if (res.status === 200) {
          const responseJson = await res.json();
          if (responseJson.success === false) {
            setLoading(false);
            console.log(responseJson);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4><p style="font-size: 16px;">${responseJson.data}</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          } else if (responseJson.status === 400) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;">${responseJson.title}</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          } else {
            
            setLoading(false);
            setFormData("");
            Swal.fire({
              icon: "success",
              html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Survey has been saved successfully</p>',
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
            });
          }
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Error in creating site location</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        }
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
        <p style="font-size: 16px;">Error in saving survey</p>`,
        iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
      });
    }
      

    setLoading(false);
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#home">
                <img
                  src="assets/img/logo.png"
                  alt="Company Logo"
                  height="30"
                  className="d-inline-block align-top"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img class="icon mr-3" src="assets/img/User.png" alt="User" />
                      Mathew
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="container survey-form p-4 p-lg-5">
          <div className="row mb-4">
            <div className="col">
              <div className="form-header">
                <h1 className="title" style={{ color: "407775!important" }}>
                  Employee Commute Survey
                </h1>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="surveyPeriod">
                      Survey Period
                    </label>
                    <input
                      type="text"
                      id="surveyPeriod"
                      value={formData.surveyPeriod}
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <h2 className="my-3">
                <img
                  className="mr-3"
                  src="./assets/img/formsapps/emp-details.svg"
                  alt="Preview of the survey form"
                />
                EMPLOYEE DETAILS
              </h2>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="employeeEmail">
                      Employee Email
                    </label>
                    <input
                      type="email"
                      id="employeeEmail"
                      value={formData.employeeEmail}
                      onChange={handleInputChange}
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="employeeId">
                      Employee ID*
                    </label>
                    <input
                      type="text"
                      id="employeeId"
                      name="employeeId"
                      className={`form-control ${errors.employeeId ? "is-invalid" : ""}`}
                      value={formData.employeeId}
                      placeholder="Enter your Employee ID"
                      onChange={(e) => {
                        setFormData({ ...formData, employeeId: e.target.value });
                        setErrors((prevErrors) => ({ ...prevErrors, employeeId: "" }));
                      }}
                    />
                    {errors.employeeId && (
                      <div className="invalid-feedback">{errors.employeeId}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="employeeName">
                      Name of employee
                    </label>
                    <input
                      type="text"
                      id="employeeName"
                      value={formData.employeeName}
                      onChange={handleInputChange}
                      class="form-control"
                      placeholder="Enter your name"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="siteLocation">
                      Site Location*
                    </label>
                    <Dropdown
                      id="siteLocation"
                      name="siteLocation"
                      className={`form-control wd-100 ${errors.siteLocation ? "is-invalid" : ""}`}
                      value={formData.siteLocation}
                      options={siteLocations}
                      placeholder="Select Site Location"
                      required
                      invalid
                      onChange={(e) => {
                        const selectedSiteLocation = e.target.value;
                        
                        const selectedLocation = siteLocations.find(
                          (location) => location.value === e.target.value
                        );
                        console.log(e);
                        if (selectedLocation) {
                          setFormData({
                            ...formData,
                            siteLocation: selectedLocation.value,
                            state: selectedLocation.state, // Set the state based on the selected location
                            country: selectedLocation.country, // If needed, you can set the country too
                          });
                        }
                        console.log(formData);
                        setErrors((prevErrors) => ({ ...prevErrors, siteLocation: "" }));
                      }}
                    />
                    {errors.siteLocation && (
                      <div className="invalid-feedback">{errors.siteLocation}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="state">
                      State*
                    </label>
                    <input
                      type="text"
                      id="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="country">
                      Country*
                    </label>
                    <input
                      type="text"
                      id="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      required
                      class="form-control"
                      placeholder="Auto-populated"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="workingDays">
                      No. of working days in a week*
                    </label>
                    <Dropdown
                      id="workingDays"
                      className={`form-control wd-100 ${errors.workingDays ? "is-invalid" : ""}`}
                      value={formData.workingDays}
                      options={workingDaysOptions}
                      onChange={(e) => setFormData({ ...formData, workingDays: e.value })}
                      placeholder="Select No. of days"
                      required
                      invalid
                      onChange={(e) => {
                        setFormData({ ...formData, workingDays: e.target.value });
                        setErrors((prevErrors) => ({ ...prevErrors, workingDays: "" }));
                      }}
                    />
                    {errors.workingDays && (
                      <div className="invalid-feedback">{errors.workingDays}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="wfhDays">
                      Average work from office days each week
                    </label>
                    <Dropdown
                      id="numberOfDays"
                      className="form-control wd-100"
                      value={formData.wfhDays}
                      options={wfhDaysOptions}
                      onChange={(e) => setFormData({ ...formData, wfhDays: e.value })}
                      placeholder="Select No. of days"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="distance">
                      One way distance from home to office (km)*
                    </label>
                    <input
                      type="text"
                      id="distance"
                      name="distance"
                      className={`form-control ${errors.distance ? "is-invalid" : ""}`}
                      value={formData.distance}
                      placeholder="Enter the distance"
                      onChange={(e) => {
                        setFormData({ ...formData, distance: e.target.value });
                        setErrors((prevErrors) => ({ ...prevErrors, distance: "" }));
                      }}
                    />
                    {errors.distance && <div className="invalid-feedback">{errors.distance}</div>}
                  </div>
                </div>
              </div>
              <h2 className="my-3">
                <img
                  className="mr-3"
                  src="./assets/img/formsapps/transport.svg"
                  alt="Preview of the survey form"
                />
                MODE OF TRANSPORTATION
              </h2>
              <p style={{ color: "#494949" }}>
                You might use multiple modes of transport to commute to the office across the year.
                In this section, provide the details about the primary transportation mode you used
                for the majority of the year. In case you use multiple modes of transport in daily
                commute, provide the mode in which you cover majority of the distance.
              </p>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="primaryTransport">
                      Primary mode of transport (>= 80% of the time)*
                    </label>
                    <Dropdown
                      id="primaryTransport"
                      className="form-control wd-100"
                      value={formData.primaryTransport}
                      options={transportOptions}
                      onChange={(e) => setFormData({ ...formData, primaryTransport: e.value })}
                      placeholder="Select Primary mode of transport"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="transportType">
                      Transport type
                    </label>
                    <input
                      type="text"
                      id="transportType"
                      value={formData.transportType}
                      onChange={handleInputChange}
                      class="form-control"
                      placeholder="Enter Transport type"
                    />
                  </div>
                </div>
              </div>
              <h2 className="my-3">
                <img
                  className="mr-3"
                  src="./assets/img/formsapps/fuel.svg"
                  alt="Preview of the survey form"
                />
                FUEL CONSUMPTION
              </h2>
              <p style={{ color: "#494949" }}>
                Provide fuel consumption data if you are commuting in your own vehicle.
              </p>
              <div className="row gx-5 gy-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="formlable" htmlFor="fuelUsed">
                      Fuel used in vehicle
                    </label>
                    <Dropdown
                      id="fuelUsed"
                      className="form-control wd-100"
                      value={formData.fuelUsed}
                      options={fuelOptions}
                      onChange={(e) => setFormData({ ...formData, fuelUsed: e.value })}
                      placeholder="Select Fuel used in vehicle"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row gx-4 gy-3">
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="formlable" htmlFor="fuelEfficiency">
                          Fuel efficiency
                        </label>
                        <input
                          type="text"
                          id="fuelEfficiency"
                          value={formData.fuelEfficiency}
                          onChange={handleInputChange}
                          class="form-control"
                          placeholder="Enter Fuel efficiency"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label className="formlable" htmlFor="fuelUnit">
                          Unit
                        </label>
                        <Dropdown
                          id="unit"
                          className="form-control wd-100"
                          value={formData.unit}
                          options={unitOptions}
                          onChange={(e) => setFormData({ ...formData, unit: e.value })}
                          placeholder="Select Unit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="my-3">
                <img
                  className="mr-3"
                  src="./assets/img/formsapps/wfh.svg"
                  alt="Preview of the survey form"
                />
                WORK FROM HOME
              </h2>
              <p>
                In this category, provide an estimated usage duration and wattage of different
                appliances while working from home which otherwise wouldn't have been used.
              </p>
              <div className="row">
                <div className="col-md-8">
                  <div className="data-responsive">
                    <DataTable
                      value={data}
                      rows={10}
                      rowsPerPageOptions={[10, 20, 25, 50]}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      emptyMessage="No data found."
                    >
                      <Column header="Equipment" field="equipment" />
                      <Column
                        header="Usage per day (hrs)"
                        field="usage"
                        body={(rowData, { rowIndex }) => (
                          <InputNumber
                            value={rowData.usage}
                            onValueChange={(e) => handleInputChangeDataTable(e,rowData, rowIndex)}
                            mode="decimal"
                            minFractionDigits={0}
                            maxFractionDigits={2}
                            className="p-inputnumber"
                            placeholder="Enter Value"
                            disabled={
                              (rowData.equipment === "Heating" && coolingActive) ||
                              (rowData.equipment === "Cooling" && heatingActive)
                            }
                          />
                        )}
                      />
                      <Column
                        header="Wattage (W)"
                        field="wattage"
                        body={(rowData, { rowIndex }) => (
                          <InputNumber
                            value={rowData.wattage}
                            onValueChange={(e) => handleInputChangeDataTable(e,rowData, rowIndex)}
                            mode="decimal"
                            minFractionDigits={0}
                            maxFractionDigits={2}
                            className="p-inputnumber"
                            placeholder="Enter Value"
                            disabled={
                              (rowData.equipment === "Heating" && coolingActive) ||
                              (rowData.equipment === "Cooling" && heatingActive)
                            }
                          />
                        )}
                      />
                    
                    <Column
                      header="Actions"
                      body={(rowData, { rowIndex }) => (
                        <Button
                        className="next-button"
                        variant="contained"
                        color="primary"
                          onClick={() => handleRowAction(rowData, rowIndex)}
                          disabled={
                            (rowData.equipment === "Heating" && coolingActive) ||
                            (rowData.equipment === "Cooling" && heatingActive)
                          }
                        >
                         Use average data
                        </Button>
                      )}
                    />
                    </DataTable>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <label className="formlable" htmlFor="wfh">
                    Would a person be available at home even if you had worked from office?
                  </label>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="wfhYes"
                        name="wfh"
                        value="true"
                        checked={formData.wfh === "true"}
                        onChange={(e) => setFormData({ ...formData, wfh: e.value })}
                      />
                      <label htmlFor="wfhYes" className="ml-2 mb-0">
                        Yes
                      </label>
                      <RadioButton
                        inputId="wfhNo"
                        name="wfh"
                        value="false"
                        checked={formData.wfh === "false"}
                        onChange={(e) => setFormData({ ...formData, wfh: e.value })}
                        className="ml-4"
                      />
                      <label htmlFor="wfhNo" className="ml-2 mb-0">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="forms-buttons-container">
              <Button
                className="outline-btn"
                variant="contained"
                color="primary"
                onClick={() => navigate("/employee-commute-data")}
              >
                Close
              </Button>
              <Button
                className="next-button"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Share
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECSurveyForm;
