import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./components/SideBar.js";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./Spinner.css";
import * as XLSX from "xlsx";
import _ from "lodash";
import Dropzone from "react-dropzone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import { appInsights } from "./AppInsights";
import AADHeader from "./components/AADHeader.js";

// const useStyles = makeStyles(theme => (containerTheme(theme)));
const username = localStorage.getItem("userName");

var myHeaders = new Headers();
myHeaders.append("username", username);

var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

export default function Home() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState("");
  const [importfile, setImportFile] = React.useState("");
  const [headerfields, setHeaderFields] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);

  async function fetchConnectorConfig() {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/report-history",
        requestOptions
      );
      const data = await response.json();
      setConfig(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchConnectorConfig();
  }, []);

  const onDrop = useCallback((acceptedFiles, site, type) => {
    setImportFile(acceptedFiles[0].name);
    console.log(acceptedFiles);
    let sendObj = {
      startDate: "",
      endDate: "",
      energyCons: 0,
    };
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
        const columnsArray = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames], { header: 1 })[0];
        setHeaderFields(columnsArray);
        const rowData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames], { header: 2 })[0];
        console.log(rowData);
        rowData.EnergyConsumption = rowData.EnergyConsumption.toString();
        const excelToDate = (excelDate) => {
          const MS_PER_DAY = 86400000;
          const EPOCH_DATE = 25569; // January 1, 1970 is the 25569th day in the Excel date system
          const epoch = (excelDate - EPOCH_DATE) * MS_PER_DAY;
          return new Date(epoch);
        };

        rowData.EndDate = excelToDate(rowData.EndDate).toLocaleDateString("en-US");
        rowData.StartDate = excelToDate(rowData.StartDate).toLocaleDateString("en-US");
        delete rowData.Unit;
        console.log(rowData);
        // Api for run report
        try {
          let res = fetch("https://ecoprismapi.azurewebsites.net/report-history", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
            },
            body: JSON.stringify(rowData),
          });
          if (res.status === 200) {
            setMessage("Report Run Successfully");
          } else {
            setMessage("Report Run Successfully");
          }
        } catch (err) {
          setMessage("Error In Run Report");
        }

        var result = columnsArray
          .map(function (val) {
            return val;
          })
          .join(",");
        var item = "";
        var results = {};

        if (result !== null) {
          result = result.split(",");

          for (var i = 0; i < result.length; i++) {
            item = result[i].trim();

            results[item] = item;
          }
        }
        /* Update state */
        const jsonData = JSON.parse(JSON.stringify(data));

        const newdata = [];
        _.forEach(jsonData, (jd) => {
          sendObj.energyCons = jd.EnergyConsumption;
          sendObj.startDate = jd.StartDate;
          sendObj.endDate = jd.EndDate;
          newdata.push({
            email: "sudies@ecoprism.com",
            energy: sendObj.energyCons,
            region: "DK",
            reportPeriod: 2,
            endTimeEpoch: sendObj.endDate,
            unit: "kWh",
          });
          //console.log(jd, 'hsdajshd')
        });
        setLoading(false);
      };
      reader.readAsBinaryString(file);
    });
  }, []);
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div Class="steps__containt-hader formpd">
          <h3 class="title">All Configurations</h3>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                Dashboard
              </Link>

              <Typography color="text.primary">Configurations</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div class="container">{message}</div>
        <div class="container">
          <div class="col-md-12">
            <table className="table custom-table ">
              <thead>
                <tr>
                  <th>Site Location Name</th>
                  <th>Report Frequency</th>
                  <th>Owner</th>
                  <th>Run Report</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={4}>
                      {" "}
                      <LoadingSpinner />{" "}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {config &&
                  config.map((data) => (
                    <tr>
                      <td>{data.siteLocationId}</td>
                      <td>{data.reportFrequency}</td>
                      <td>{username}</td>
                      <td>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            onDrop(acceptedFiles, data.siteLocationId, "Excel")
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone__section">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dropzone1">
                                  <label for="files" class="dropzone-container1">
                                    <div className="file-icon">
                                      <CloudUploadTwoToneIcon
                                        sx={{ color: "#1E4B7A", fontSize: "55px" }}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </td>
                      <td>
                        <a href="/reporthistory" className="btn btn-primary">
                          Report History
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
