// tabData.js

import { Typography } from "@mui/material";
const data = [
  {
    title: "General",
    id: "general",
    subItems: [
      {
        title: "ESRS 2",
        subSubItems: [
          {
            id: "gen-bp-1",
            label: "BP-1 – General basis for preparation of the sustainability statement",
            tooltip:
              "The undertaking shall disclose the general basis for preparation of its sustainability statement.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of how the undertaking prepares its sustainability statement, including the scope of consolidation, the upstream and downstream value chain information and, where relevant, whether the undertaking has used any of the options for omitting information referred to in points d) and e) in the following paragraph.",
          },
          {
            id: "gen-bp-2",
            label: "BP-2 – Disclosures in relation to specific circumstances",
            tooltip:
              "The undertaking shall provide disclosures in relation to specific circumstances.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the effect of these specific circumstances on the preparation of the sustainability statement.",
          },
          {
            id: "gen-gov-1",
            label: "GOV-1 – The role of the administrative, management and supervisory bodies",
            tooltip:
              "The undertaking shall disclose the composition of the administrative, management and supervisory bodies, their roles and responsibilities and access to expertise and skills with regard to sustainability matters. ",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of: \n\n(a) the composition and diversity of the administrative, management and supervisory bodies; \n(b) the roles and responsibilities of the administrative, management and supervisory bodies in exercising oversight of the process to manage material impacts, risks and opportunities, including management’s role in these processes; and \n (c) the expertise and skills of its administrative, management and supervisory bodies on sustainability matters or access to such expertise and skills.",
          },
          {
            id: "gen-gov-2",
            label:
              "GOV-2 – Information provided to and sustainability matters addressed by the undertaking’s administrative, management and supervisory bodies",
            tooltip:
              "The undertaking shall disclose how the administrative, management and supervisory bodies are informed about sustainability matters and how these matters were addressed during the reporting period.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of how administrative, management and supervisory bodies are informed about sustainability matters, as well as what information and matters they addressed during the reporting period. This in turn allows an understanding of whether the members of these bodies were adequately informed and whether they were able to fulfil their roles.",
          },
          {
            id: "gen-gov-3",
            label: "GOV-3 – Integration of sustainability-related performance in incentive schemes",
            tooltip:
              "The undertaking shall disclose information about the integration of its sustainabilityrelated performance in incentive schemes.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of whether incentive schemes are offered to members of the administrative, management and supervisory bodies that are linked to sustainability matters.",
          },
          {
            id: "gen-gov-4",
            label: "GOV–4 - Statement on due diligence",
            tooltip:
              "The undertaking shall disclose a mapping of the information provided in its sustainability statement about the due diligence process.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to facilitate an understanding of the undertaking’s due diligence process with regard to sustainability matters.",
          },
          {
            id: "gen-gov-5",
            label: "GOV–5 - Risk management and internal controls over sustainability reporting",
            tooltip:
              "The undertaking shall disclose the main features of its risk management and internal control system in relation to the sustainability reporting process.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the undertaking’s risk management and internal control processes in relation to sustainability reporting.",
          },
          {
            id: "gen-sbm-1",
            label: "SBM-1 – Strategy, business model and value chain",
            tooltip:
              "The undertaking shall disclose the elements of its strategy that relate to or impact sustainability matters, its business model and its value chain.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to describe the key elements of the undertaking’s general strategy that relate to or affect sustainability matters, and the key elements of the undertaking’s business model and value chain, in order to provide an understanding of its exposure to impacts, risks and opportunities and where they originate.",
          },
          {
            id: "gen-sbm-2",
            label: "SBM-2 – Interests and views of stakeholders",
            tooltip:
              "The undertaking shall disclose how the interests and views of its stakeholders are taken into account by the undertaking’s strategy and business model.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of how stakeholders’ interests and views inform the undertaking’s strategy and business model. ",
          },
          {
            id: "gen-sbm-3",
            label:
              "SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip:
              "The undertaking shall disclose its material impacts, risks and opportunities and how they interact with its strategy and business model.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the material impacts, risks and opportunities as they result from the undertaking’s materiality assessment and how they originate from and trigger adaptation of the undertaking’s strategy and business model including its resources allocation. The information to be disclosed about the management of the undertaking’s material impacts, risks and opportunities is prescribed in topical ESRS and in sector-specific standards, which shall be applied in conjunction with the Minimum Disclosure Requirements on policies, actions and targetsestablished in this Standard. ",
          },
          {
            id: "gen-iro-1",
            label:
              "IRO-1 - Description of the process to identify and assess material impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose its process to identify its impacts, risks and opportunities and to assess which ones are material.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the process through which the undertaking identifies impacts, risks and opportunities and assesses their materiality, as the basis for determining the disclosures in its sustainability statement (see ESRS 1 chapter 3 and its related Application Requirements, which set out requirements and principles regarding the process to identify and assess material impacts, risks and opportunities based on the principle of double materiality).",
          },
          {
            id: "gen-iro-2",
            label:
              "IRO-2 – Disclosure Requirements in ESRS covered by the undertaking’s sustainability statement",
            tooltip:
              "The undertaking shall report on the Disclosure Requirements complied with in its sustainability statements.",
            mandatory: true,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the Disclosure Requirements included in the undertaking’s sustainability statement and of the topics that have been omitted as not material, as a result of the materiality assessment. ",
          },
        ],
      },
    ],
  },
  {
    title: "Environment",
    id: "environment",
    subItems: [
      {
        title: "ESRS E1 Climate Change",
        subSubItems: [
          {
            id: "env-e1-gov-3",
            label:
              "ESRS 2 GOV-3 Integration of sustainability related performance in incentive schemes",
            obj: "",
          },
          {
            id: "env-e1-1",
            label: "E1-1 – Transition plan for climate change mitigation",
            tooltip:
              "The undertaking shall disclose its transition plan for climate change mitigation.",
           
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the undertaking’s past, current, and future mitigation efforts to ensure that its strategy and business model are compatible with the transition to a sustainable economy, and with the limiting of global warming to 1.5 °C in line with the Paris Agreement and with the objective of achieving climate neutrality by 2050 and, where relevant, the undertaking’s exposure to coal, oil and gas-related activities.",
          },
          {
            id: "env-e1-sbm-3",
            label:
              "E1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip:
              "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
            mandatory: true,
            obj: "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
          },
          {
            id: "env-e1-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material climate-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e1-2",
            label: "E1-2 – Policies related to climate change mitigation and adaptation",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to climate change mitigation and adaptation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of its material climate change mitigation and adaptation impacts, risks and opportunities.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e1-3",
            label: "E1-3 – Actions and resources in relation to climate change policies",
            tooltip:
              "The undertaking shall disclose its climate change mitigation and adaptation actions and the resources allocated for their implementation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the key actions taken and planned to achieve climate-related policy objectives and targets.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e1-4",
            label: "E1-4 – Targets related to climate change mitigation and adaptation",
            tooltip: "The undertaking shall disclose the climate-related targets it has set.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the targets the undertaking has set to support its climate change mitigation and adaptation policies and address its material climate-related impacts, risks and opportunities.",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e1-5",
            label: "E1-5 – Energy consumption and mix",
            tooltip: "The undertaking shall provide information on its energy consumption and mix.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the undertaking’s total energy consumption in absolute value, improvement in energy  efficiency, exposure to coal, oil and gas-related activities, and the share of renewable energy in its overall energy mix.",
          },
          {
            id: "env-e1-6",
            label: "E1-6 – Gross Scopes 1, 2, 3 and Total GHG emissions",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose in metric tonnes of CO<sub>2</sub> its:
                </p>
                <em>
                  <ol type="a">
                    <li>gross Scope 1 GHG emissions</li>
                    <li>gross Scope 2 GHG emissions</li>
                    <li>gross Scope 3 GHG emissions</li>
                    <li>total GHG emissions</li>
                  </ol>
                </em>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of the Disclosure Requirement in paragraph 44 in respect of: \n\n (a) gross Scope 1 GHG emissions as required by paragraph 44 (a) is to provide an understanding of the direct impacts of the undertaking on climate change and the proportion of its total GHG emissions that are regulated under emission trading schemes. \n (b) gross Scope 2 GHG emissions as required by paragraph 44 (b) is to provide an understanding of the indirect impacts on climate change caused by the undertaking’s consumed energy whether externally purchased or acquired. \n (c) gross Scope 3 GHG emissions as required by paragraph 44 (c) is to provide an understanding of the GHG emissions that occur in the undertaking’s upstream and downstream value chain beyond its Scope 1 and 2 GHG emissions. For many undertakings, Scope 3 GHG emissions may be the main component of their GHG inventory and are an important driver of the undertaking’s transition risks. \n (d) total GHG emissions as required by paragraph 44 (d) is to provide an overall understanding of the undertaking’s GHG emissions and whether they occur from its own operations or the upstream and downstream value chain. This disclosure is a prerequisite for measuring progress towards reducing GHG emissions in accordance with the undertaking’s climate-related targets and EU policy goals.",
          },
          {
            id: "env-e1-7",
            label:
              "E1-7 – GHG removals and GHG mitigation projects financed through carbon credits",
            tooltip: (
              <Typography>
                <p>The undertaking shall disclose:</p>
                <em>
                  <ol type="a">
                    <li>
                      GHG removals and storage in metric tonnes of CO<sub>2</sub> resulting from
                      projects it may have developed in its own operations, or contributed to in its
                      upstream and downstream value chain; and
                    </li>
                    <li>
                      the amount of GHG emission reductions or removals from climate change
                      mitigation projects outside its value chain it has financed or intends to
                      finance through any purchase of carbon credits.
                    </li>
                  </ol>
                </em>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is: \n\n (a) to provide an understanding of the undertaking’s actions to permanently remove or actively support the removal of GHG from the atmosphere, potentially for achieving net-zero targets (as stated in paragraph 60). \n\n (b) to provide an understanding of the extent and quality of carbon credits the undertaking has purchased or intends to purchase from the voluntary market, potentially for supporting its GHG neutrality claims (as stated in paragraph 61).",
          },
          {
            id: "env-e1-8",
            label: "E1-8 – Internal carbon pricing",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose whether it applies internal carbon pricing schemes,
                  and if so, how they support its decision making and incentivise the implementation
                  of climate-related policies and targets.
                </p>
              </Typography>
            ),
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e1-9",
            label:
              "E1-9 – Anticipated financial effects from material physical and transition risks and potential climate-related opportunities",
            tooltip: (
              <Typography>
                <p>
                  The undertaking shall disclose its:
                  <br />
                  anticipated financial effects from material physical risks; anticipated financial
                  effects from material transition risks; and potential to benefit from material
                  climate-related opportunities.
                </p>
              </Typography>
            ),
            mandatory: false,
            obj: "The information required by paragraph 64 is in addition to the information on current financial effects required under ESRS 2 SeM-3 para 48 (d). The objective of this Disclosure Requirement related to: \n\n (a) anticipated financial effects due to material physical risks and transition risks is to provide an understanding of how these risks have (or could reasonably be expected to have) a material influence on the undertaking’s financial position, financial performance and cash flows, over the short-, medium- and long- term. The results of scenario analysis used to conduct resilience analysis as required under paragraphs AR 10 to AR 13 should inform the assessment of anticipated financial effects from material physical and transition risks. \n\n (b) potential to pursue material climate-related opportunities is to enable an understanding of how the undertaking may financially benefit from material climate- related opportunities. This disclosure is complementary to the key performance indicators to be disclosed in accordance with Commission Delegated Regulation (EU) 2021/2178.",
          },
        ],
      },
      {
        title: "ESRS E2 Pollution",
        subSubItems: [
          {
            id: "env-e2-iro1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material pollution-related impacts, risks and opportunities",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e2-1",
            label: "E2-1 – Policies related to pollution",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to pollution prevention and control.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of material pollution-related impacts, risks and opportunities.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e2-2",
            label: "E2-2 – Actions and resources related to pollution",
            tooltip:
              "The undertaking shall disclose its pollution-related actions and the resources allocated to their implementation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the key actions taken and planned to achieve the pollution-related policy objectives and targets.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e2-3",
            label: "E2-3 – Targets related to pollution",
            tooltip: "The undertaking shall disclose the pollution-related targets it has set.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the targets the undertaking has set to support its pollution-related policies and to address its material pollution-related impacts, risks and opportunities.",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e2-4",
            label: "E2-4 – Pollution of air, water and soil",
            tooltip:
              "The undertaking shall disclose the pollutants that it emits through its own operations, as well as the microplastics it generates or uses.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the emissions that the undertaking generates to air, water and soil in its own operations, and of its generation and use of microplastics.",
          },
          {
            id: "env-e2-5",
            label: "E2-5 – Substances of concern and substances of very high concern",
            tooltip:
              "The undertaking shall disclose information on the production, use, distribution, commercialisation and import/export of substances of concern and substances of very high concern, on their own, in mixtures or in articles.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the impact of the undertaking on health and the environment through substances of concern and through substances of very high concern on their own. It is also to enable an understanding of the undertaking’s material risks and opportunities, including exposure to those substances and risks arising from changes in regulations.",
          },
          {
            id: "env-e2-6",
            label:
              "E2-6 – Anticipated financial effects from pollution-related impacts, risks and opportunities",
            tooltip: "",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of: \n\n (a) anticipated financial effects due to material risks arising from pollution-related impacts and dependencies and how these risks have (or could reasonably be expected to have) a material influence on the undertaking’s , financial position financial performance and cash flows, over the short-, medium- and long-term. \n (b) anticipated financial effects due to material opportunities related to pollution prevention and control.",
          },
        ],
      },
      {
        title: "ESRS E3 Water and marine resources",
        subSubItems: [
          {
            id: "env-e3-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material water and marine resources-related impacts, risks and opportunities",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e3-1",
            label: "E3-1 – Policies related to water and marine resources",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to water and marine resources",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of its material water and marine resources-related impacts, risks and opportunities.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e3-2",
            label: "E3-2 – Actions and resources related to water and marine resources",
            tooltip:
              "The undertaking shall disclose its water and marine resources-related actions and the resources allocated to their implementation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the key actions taken and planned to achieve the water and marine resources-related policy objectives and targets.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e3-3",
            label: "E3-3 – Targets related to water and marine resources",
            tooltip:
              "The undertaking shall disclose the water and marine resources-related targets it has set.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the targets the undertaking has adopted to support its water and marine resources-related policies and address its material water and marine resources-related impacts, risks and opportunities.",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e3-4",
            label: "E3-4 – Water consumption",
            tooltip:
              "The undertaking shall disclose information on its water consumption performance related to its material impacts, risks and opportunities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the undertaking’s water consumption and any progress by the undertaking in relation to its targets.",
          },
          {
            id: "env-e3-5",
            label:
              "E3-5 – Anticipated financial effects from water and marine resources-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose the anticipated financial effects of material water and marine resources-related risks and opportunities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of: \n\n (a) anticipated financial effects due to material risks arising from water and marine resources-related impacts and dependencies and how these risks have (or could reasonably be expected to have) a material influence on the undertaking’s financial position, financial performance and cash flows, over the short-, medium- and long-term; and \n (b) anticipated financial effects due to material opportunities related to water and marine resources.",
          },
        ],
      },
      {
        title: "ESRS E4 Biodiversity and ecosystems",
        subSubItems: [
          {
            id: "env-e4-sbm-3",
            label:
              "E4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e4-iro-1",
            label:
              "ESRS 2 IRO-1 Description of processes to identify and assess material biodiversity and ecosystem-related impacts, risks and opportunities ",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "env-e4-1",
            label:
              "E4-1 – Transition plan and consideration of biodiversity and ecosystems in strategy and business model",
            tooltip:
              "The undertaking shall disclose how its biodiversity and ecosystem impacts, dependencies, risks and opportunities originate from and trigger adaptation of its strategy and business model. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the resilience of the undertaking’s strategy and business model in relation to biodiversity and ecosystems, and of the compatibility of the undertaking’s strategy and business model with regard to relevant local, national and global public policy targets related to biodiversity and ecosystems. ",
          },
          {
            id: "env-e4-2",
            label: "E4-2 – Policies related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall describe its adopted policies to manage its material impacts, risks, dependencies, and opportunities related to biodiversity and ecosystems. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of its material biodiversity and ecosystem- related impacts, dependencies, risks and opportunities. ",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e4-3",
            label: "E4-3 – Actions and resources related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall disclose its biodiversity and ecosystems-related actions and the resources allocated to their implementation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the key actions taken and planned that significantly contribute to the achievement of biodiversity and ecosystems-related policy objectives and targets. ",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e4-4",
            label: "E4-4 – Targets related to biodiversity and ecosystems",
            tooltip:
              "The undertaking shall disclose the biodiversity and ecosystem-related targets it has set. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to allow an understanding of the targets the undertaking has adopted to support its biodiversity and ecosystems policies and address its material related impacts, dependencies, risks and opportunities. ",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e4-5",
            label: "E4-5 – Impact metrics related to biodiversity and ecosystems change",
            tooltip:
              "The undertaking shall report metrics related to its material impacts on biodiversity and ecosystems.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the performance of the undertaking against impacts identified as material in the materiality assessment on biodiversity and ecosystems change. ",
          },
          {
            id: "env-e4-6",
            label:
              "E4-6 – Anticipated financial effects from biodiversity and ecosystem-related risks and opportunities",
            tooltip:
              "The undertaking shall disclose its anticipated financial effects of material biodiversityand ecosystem-related risks and opportunities. ",
            mandatory: false,
            obj: "The information required by paragraph 42 is in addition to the information on current financial effects on the entity’s financial position, financial performance and cash flows for the reporting period required under ESRS 2 SBM-3 para 48 (d). ",
          },
        ],
      },
      {
        title: "ESRS E5 Resource use and circular economy",
        subSubItems: [
          {
            id: "env-e5-iro-1",
            label:
              "ESRS 2 IRO-1 – Description of the processes to identify and assess material resource use and circular economy-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts, risks and opportunities related to resource use and circular economy.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of its material impacts, risks and opportunities related to resource use and circular economy.",
          },
          {
            id: "env-e5-1",
            label: "E5-1 – Policies related to resource use and circular economy",
            tooltip: "",
            mandatory: false,
            obj: "",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e5-2",
            label: "E5-2 – Actions and resources related to resource use and circular economy",
            tooltip:
              "The undertaking shall disclose its resource use and circular economy actions and the resources allocated to their implementation.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the key actions taken and planned to achieve the resource use and circular economy-related policy objectives and targets.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e5-3",
            label: "E5-3 – Targets related to resource use and circular economy",
            tooltip:
              "The undertaking shall disclose the resource use and circular economy-related targets it has set.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the targets the undertaking has adopted to support its resource use and circular economy policy and to address its material impacts, risks and opportunities.",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "env-e5-4",
            label: "E5-4 – Resource inflows of Disclosure Requirement",
            tooltip:
              "The undertaking shall disclose information on its resource inflows related to its material impacts, risks and opportunities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the resource use in the undertaking’s own operations and its upstream value chain.",
          },
          {
            id: "env-e5-5",
            label: "E5-5 –Resource outflows",
            tooltip:
              "The undertaking shall disclose information on its resource outflows, including waste, related to its material impacts, risks and opportunities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of: \n\n (a) how the undertaking contributes to the circular economy by \n   i. designing products and materials in line with circular economy principles and \n   ii. increasing or maximising the extent to which products, materials and waste processing are recirculated in practice after first use; and \n (b) the undertaking’s waste reduction and waste management strategy, the extent to which the undertaking knows how its pre-consumer waste is managed in its own activities.",
          },
          {
            id: "env-e5-6",
            label:
              "E5-6 – Anticipated financial effects from resource use and circular economy-related impacts, risks and opportunities",
            tooltip:
              "The undertaking shall disclose the anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of: \n\n (a) anticipated financial effects due to material risks arising from material resource use and circular economy-related impacts and dependencies and how these risks have or could reasonably be expected to have a material influence on the undertaking’s financial position, financial performance performance, and cash flows over the short-, medium- and long-term; and \n (b) anticipated financial effects due to material opportunities related to resource use and circular economy.",
          },
        ],
      },
    ],
  },
  {
    title: "Social",
    id: "social",
    subItems: [
      {
        title: "ESRS S1 Own workforce",
        subSubItems: [
          {
            id: "soc-s1-sbm-3",
            label:
              "S1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "soc-s1-1",
            label: "S1-1 – Policies related to own workforce",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on its own workforce, as well as associated material risks and opportunities.",
            mandatory: false,
            obj: " The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of material impacts on the undertaking’s own workforce specifically, as well as policies that cover material impacts, risks and opportunities related to its own workforce.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s1-2",
            label:
              "S1-2 – Processes for engaging with own workers and workers’ representatives about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with people in its own workforce and workers' representatives about actual and potential impacts on its own workforce.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of how the undertaking engages, as part of its ongoing due diligence process, with people in its own workforce and workers' representatives about material, actual and potential, positive and/or negative impacts that do or are likely to affect them, and whether and how perspectives of its own workforces are taken into account in the decision-making processes of the undertaking.",
          },
          {
            id: "soc-s1-3",
            label:
              "S1-3 – Processes to remediate negative impacts and channels for own workers to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on people in its own workforce that the undertaking is connected with, as well as channels available to its own workforce to raise concerns and have them addressed.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the formal means by which the undertaking’s own workforce can make their concerns and needs known directly to the undertaking and/or through which the undertaking supports the availability of such channels (for example, grievance mechanisms) in the workplace, and how follow up is carried out with the people concerned regarding the issues raised and the effectiveness of these channels.",
          },
          {
            id: "soc-s1-4",
            label:
              "S1-4 – Taking action on material impacts on own workforce, and approaches to mitigating material risks and pursuing material opportunities related to own workforce, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material negative and positive impacts, and to manage material risks and pursue material opportunities related to its own workforce, and the effectiveness of those actions.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is twofold. Firstly, it is to enable an understanding of any actions and initiatives through which the undertaking seeks to: \n\n (a) to prevent, mitigate and remediate negative material impacts on its own workforce; and/or \n (b) to achieve positive material impacts for its own workforce.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s1-5",
            label:
              "S1-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on its own workforce; and/or </li>
                  <li>advancing positive impacts on its own workforce; and/or </li>
                  <li>managing material risks and opportunities related to its own workforce.</li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking is using outcome-oriented targets to drive and measure its progress in addressing its material negative impacts and/or advancing positive impacts on its own workforce, and/or in managing material risks and opportunities related to its own workforce. ",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s1-6",
            label: "S1-6 – Characteristics of the undertaking’s employees",
            tooltip:
              "The undertaking shall describe key characteristics of employees in its own workforce.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide insight into the undertaking’s approach to employment, including the scope and nature of impacts arising from its employment practices, to provide contextual information that aids an understanding of the information reported in other disclosures, and to serve as the basis for calculation for quantitative metricsto be disclosed under other disclosure requirements in this Standard.",
          },
          {
            id: "soc-s1-7",
            label:
              "S1-7 – Characteristics of non-employee workers in the undertaking’s own workforce",
            tooltip:
              "The undertaking shall describe key characteristics of non-employees in its own workforce. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide insight into the undertaking’s approach to employment, including the scope and nature of impacts arising from its employment practices, to provide contextual information that aids the understanding of the information reported in other disclosures, and to serve as the basis for calculation for quantitative metrics to be disclosed under other disclosure requirements in this Standard. It also allows an understanding of how much the undertaking relies on non-employees as part of its workforce.",
          },
          {
            id: "soc-s1-8",
            label: "S1-8 – Collective bargaining coverage and social dialogue",
            tooltip:
              "The undertaking shall disclose information on the extent to which the working conditions and terms of employment of its employees are determined or influenced by collective bargaining agreements and on the extent to which its employees are represented in social dialogue in the European Economic Area (EEA) at the establishment and European level. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the coverage of collective bargaining agreements and social dialogue for the undertaking’s own employees. ",
          },
          {
            id: "soc-s1-9",
            label: "S1-9 – Diversity metrics",
            tooltip:
              "The undertaking shall disclose the gender distribution at top management and the age distribution amongst its employees.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of gender diversity at top management level and the age distribution of its employees.",
          },
          {
            id: "soc-s1-10",
            label: "S1-10 – Adequate wages",
            tooltip:
              "The undertaking shall disclose whether or not its employees are paid an adequate wage, and if they are not all paid an adequate wage, the countries and percentage of employees concerned.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of whether or not all the undertaking’s employees are paid an adequate wage, in line with applicable benchmarks.",
          },
          {
            id: "soc-s1-11",
            label: "S1-11 – Social protection",
            tooltip:
              "The undertaking shall disclose whether its employees are covered by social protection against loss of income due to major life events, and, if not, the countries where this is not the case.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of whether the undertaking’s employees are covered by social protection against loss of income due to major life events, and, if not, the countries where this is not the case.",
          },
          {
            id: "soc-s1-12",
            label: "S1-12– Persons with disabilities",
            tooltip:
              "The undertaking shall disclose the percentage of its own employees with disabilities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which persons with disabilities are included among the undertaking’s employees.",
          },
          {
            id: "soc-s1-13",
            label: "S1-13 – Training and skills development metrics",
            tooltip:
              "The undertaking shall disclose the extent to which training and skills development is provided to its employees.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the training and skills development -related activities that have been offered to employees, within the context of continuous professional growth, to upgrade employees’ skills and facilitate continued employability.",
          },
          {
            id: "soc-s1-14",
            label: "S1-14 – Health and safety metrics",
            tooltip:
              "The undertaking shall disclose information on the extent to which its own workforce is covered by its health and safety management system and the number of incidents associated with work-related injuries, ill health and fatalities of its own workforce. In addition, it shall disclose the number of fatalities as a result of work-related injuries and work-related ill health of other workers working on the undertaking’s sites.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to allow an understanding of the coverage, quality and performance of the health and safety management system established to prevent work-related injuries.",
          },
          {
            id: "soc-s1-15",
            label: "S1-15 – Work-life balance metrics",
            tooltip:
              "The undertaking shall disclose the extent to which employees are entitled to and make use of family-related leave.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide an understanding of the entitlement and actual practices amongst the employees to take family-related leave in a gender equitable manner, as it is one of the dimensions of work-life balance.",
          },
          {
            id: "soc-s1-16",
            label: "S1-16 – Compensation metrics (pay gap and total compensation)",
            tooltip:
              "The undertaking shall disclose the percentage gap in pay between its female and male employees and the ratio between the remuneration of its highest paid individual and the median remuneration for its employees.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is twofold: to allow an understanding of the extent of any gap in the pay between women and men amongst the undertaking’s employees; and to provide insight into the level of remuneration inequality inside the undertaking and whether wide pay disparities exist.",
          },
          {
            id: "soc-s1-17",
            label: "S1-17 – Incidents, complaints and severe human rights impacts",
            tooltip:
              "The undertaking shall disclose the number of work-related incidents and/or complaints and severe human rights impacts within its own workforce, and any related material fines, sanctions or compensation for the reporting period.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to allow an understanding of the extent to which work-related incidents and severe cases of human rights impacts are affecting its own workforce.",
          },
        ],
      },
      {
        title: "ESRS S2 workers in the value chain",
        subSubItems: [
          {
            id: "soc-s2-sbm-3",
            label:
              "ESRS 2 SBM-3 Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "soc-s2-1",
            label: "S2-1 – Policies related to value chain workers",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on value chain workers, as well as associated material risks and opportunities.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of material impacts on value chain workers specifically, as well as policies that cover material risks or opportunities related to value chain workers.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s2-2",
            label: "S2-2 – Processes for engaging with value chain workers about impacts ",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with value chain workers and their representatives about actual and potential impacts on them.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of whether and how the undertaking engages, as part of its ongoing due diligence process, with value chain workersand their legitimate representatives, or with credible proxies, about material actual and potential positive and/or negative impacts that do or are likely to affect them, and whether and how perspectives of value chain workers are taken into account in the decision-making processes of the undertaking.",
          },
          {
            id: "soc-s2-3",
            label:
              "S2-3 – Processes to remediate negative impacts and channels for value chain workers to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on value chain workers that the undertaking is connected with, as well as channels available to value chain workers to raise concerns and have them addressed. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the formal means by which value chain workers can make their concerns and needs known directly to the undertaking and/or through which the undertaking supports the availability of such channels (for example, grievance mechanisms ) in the workplace of value chain workers, how follow up is carried out with these workers regarding the issues raised, and the effectiveness of these channels. ",
          },
          {
            id: "soc-s2-4",
            label:
              "S2-4 – Taking action on material impacts on value chain workers, and approaches to managing material risks and pursuing materials opportunities related to value chain workers, and effectiveness of those action",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on value chain workers, and to manage material risks and pursue material opportunities related to value chain workers and the effectiveness of those actions.",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is twofold. Firstly, it is to enable an understanding of any actions or initiatives through which the undertaking seeks to: \n\n (a) prevent, mitigate and remediate the negative material impacts on value chain workers; and/or \n (b) achieve positive material impacts for value chain workers. \n\n Secondly, it is to enable an understanding of the ways in which the undertaking is addressing the material risks and pursuing the material opportunities related to workers in the value chain. \n\n The undertaking shall provide a summarised description of the action plans and resources to manage its material impacts, risks, and opportunities related to value chain workers as per ESRS 2 MDR-A Actions and resources in relation to material sustainability matters.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s2-5",
            label:
              "S2-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li> reducing negative impacts on value chain workers; and/or</li>
                  <li>advancing positive impacts on value chain workers; and/or</li>
                  <li>managing material risks and opportunities related to value chain workers.</li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking is using time-bound and outcome-oriented targets to drive and measure its progress in addressing material negative impacts, and/or advancing positive impacts on value chain workers, and/or in managing material risks and opportunities related to value chain workers. ",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
        ],
      },
      {
        title: "ESRS S3 Affected communities",
        subSubItems: [
          {
            id: "soc-s3-sbm-3",
            label:
              "ESRS 2 SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  When responding to ESRS 2 SBM-3 paragraph 48, the undertaking shall disclose:
                </p>
                <ol type="a">
                  <li>
                    whether and how actual and potential impacts on affected communities as
                    identified in ESRS 2 IRO-1 Description of the processes to identify and assess
                    material impacts, risks and opportunities:
                    <ol type="i">
                      <li>
                        originate from or are connected to the undertaking’s strategy and business
                        models, and
                      </li>
                      <li>
                        inform and contribute to adapting the undertaking’s strategy and business
                        model; and
                      </li>
                    </ol>
                  </li>
                  <li>
                    the relationship between its material risks and opportunities arising from
                    impacts and dependencies on affected communities and its strategy and business
                    model.
                  </li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "",
          },
          {
            id: "soc-s3-1",
            label: "S3-1 – Policies related to affected communities",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts on affected communities, as well as associated material risks and opportunities. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of material impacts on affected communities specifically, as well as policies that cover material risks or opportunities related to affected communities. ",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s3-2",
            label: "S3-2 – Processes for engaging with affected communities about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with affected communities and their representatives about actual and potential impacts on them. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of whether and how he undertaking engages, as part of its ongoing due diligence process, with affected communities, their legitimate representatives, or with credible proxies, about material actual and potential positive and/or negative impacts that do or are likely to affect them, and whether and how perspectives of affected communities are taken into account in the decision-making processes of the undertaking.",
          },
          {
            id: "soc-s3-3",
            label:
              "S3-3 – Processes to remediate negative impacts and channels for affected communities to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on affected communities that the undertaking is connected with, as well as channels available to affected communities to raise concerns and have them addressed.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the formal means by which affected communities can make their concerns and needs known directly to the undertaking, and/or through which the undertaking supports the availability of such channels (for example, grievance mechanisms) by its business relationships, how follow up is performed with these communities regarding the issues raised, and the effectiveness of these channels. ",
          },
          {
            id: "soc-s3-4",
            label:
              "S3-4 – Taking action on material impacts on affected communities, and approaches to managing material risks and pursuing material opportunities related to affected communities, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on affected communities, and to manage material risks and pursue material opportunities related to affected communities and the effectiveness of those actions. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is twofold. Firstly, it is to provide an understanding of any actions and initiatives through which the undertaking seeks to: \n(a) prevent, mitigate and remediate the negative material impacts on affected communities; and/or\n(b) achieve positive material impacts for affected communities. \n\nSecondly, it is to enable an understanding of the ways in which the undertaking is addressing the material risks and pursuing the material opportunities related to affected communities. The undertaking shall provide a summarised description of the action plans and resources to manage its material impacts, risks, and opportunities related to affected communities as per ESRS 2 MDR-A Actions and resources in relation to material sustainability matters. ",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s3-5",
            label:
              "S3-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on affected communities; and/or </li>
                  <li>advancing positive impacts on affected communities; and/or</li>
                  <li>
                    managing material risks and opportunities related to affected communities.
                  </li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking is using time-bound and outcome-oriented targets to drive and measure its progress in addressing material negative impacts, and/or advancing positive impacts on affected communities, and/or in managing material risks and opportunities related to affected communities.",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
        ],
      },
      {
        title: "ESRS S4 Consumers and end-users",
        subSubItems: [
          {
            id: "soc-s4-sbm-3",
            label:
              "S4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  When responding to ESRS 2 SBM-3 paragraph 48, the undertaking shall disclose:
                </p>
                <ol type="a">
                  <li>
                    whether and how actual and potential impacts on consumers and/or end-users as
                    identified in Disclosure Requirements ESRS 2 IRO-1 Description of the processes
                    to identify and assess material impacts, risks and opportunities:
                    <ol type="i">
                      <li>
                        originate from or are connected to the undertaking’s strategy and business
                        model, and
                      </li>
                      <li>
                        inform and contribute to adapting the undertaking’s strategy and business
                        model, and
                      </li>
                    </ol>
                  </li>
                  <li>
                    the relationship between its material risks and opportunities arising from
                    impacts and dependencies on consumers and/or end-users and its strategy and
                    business model.
                  </li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "",
          },
          {
            id: "soc-s4-1",
            label: "S4-1 – Policies related to consumers and end-users",
            tooltip:
              "The undertaking shall describe its policies adopted to manage its material impacts of its products and/or services on consumers and end-users, as well as associated material risks and opportunities. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of material impacts on consumers and/or end-users specifically, as well as policies that cover material risks or opportunities related to consumers and/or end-users.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s4-2",
            label: "S4-2 – Processes for engaging with consumers and endusers about impacts",
            tooltip:
              "The undertaking shall disclose its general processes for engaging with consumers and end-users and their representatives about actual and potential impacts on them. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of whether and how the undertaking engages, as part of its ongoing due diligence process, with consumersand/or end-users, their legitimate representatives, or with credible proxies, about material actual and potential positive and/or negative impacts that do or are likely to affect them, and whether and how perspectives of consumers and/or end-users are taken into account in the decision-making processes of the undertaking. ",
          },
          {
            id: "soc-s4-3",
            label:
              "S4-3 – Processes to remediate negative impacts and channels for consumers and end-users to raise concerns",
            tooltip:
              "The undertaking shall describe the processes it has in place to provide for or cooperate in the remediation of negative impacts on consumers and end-users that the undertaking is connected with, as well as channels available to consumers and endusers to raise concerns and have them addressed. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the formal means by which consumers and/or end-users can make their concerns and needs known directly to the undertaking, and/or through which the undertaking supports the availability of such channels (for example, grievance mechanisms) by its business relationships, how follow up is performed with these consumers and/or end-users regarding the issues raised, and the effectiveness of these channels. ",
          },
          {
            id: "soc-s4-4",
            label:
              "S4-4 – Taking action on material impacts on consumers and end- users, and approaches to managing material risks and pursuing material opportunities related to consumers and end-users, and effectiveness of those actions",
            tooltip:
              "The undertaking shall disclose how it takes action to address material impacts on consumers and end-users, and to manage material risks and pursue material opportunities related to consumers and end-users, and effectiveness of those actions. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is twofold. Firstly, it is to provide an understanding of any actions and initiatives through which the undertaking seeks to: \n\n (a) prevent, mitigate and remediate the negative material impacts on consumers and/or end-users, and/or \n (b) achieve positive material impacts for consumers and/or end-users. \n\n Secondly, it is to enable an understanding of the ways in which the undertaking is addressing the material risks and pursuing the material opportunities related to consumers and/or end-users.",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "soc-s4-5",
            label:
              "S4-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities",
            tooltip: (
              <Typography>
                <p className="question-tip">
                  The undertaking shall disclose the time-bound and outcome-oriented targets it may
                  have set related to:
                </p>
                <ol type="a">
                  <li>reducing negative impacts on consumers and/or end-users; and/or </li>
                  <li> advancing positive impacts on consumers and/or end-users; and/or </li>
                  <li>
                    managing material risks and opportunities related to consumers and/or end-users.
                  </li>
                </ol>
              </Typography>
            ),
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking is using time-bound and outcome-oriented targets to drive and measure progress in addressing material negative impacts, and/or advancing positive impacts on consumers and/or end-users, and/or in managing material risks and opportunities related to consumers and/or end-users . ",
            mdr: "Do you have targets related to above mentioned disclosure requirement?",
          },
        ],
      },
    ],
  },
  {
    title: "Governance",
    id: "governance",
    subItems: [
      {
        title: "ESRS G1 Business Conduct",
        subSubItems: [
          {
            id: "gov-g1-gov-1",
            label:
              "ESRS 2 GOV-1 – The role of the administrative, supervisory and management bodies",
            tooltip: "",
            mandatory: false,
            obj: "",
          },
          {
            id: "gov-g1-1",
            label: "G1-1– Corporate culture and Business conduct policies and corporate culture",
            tooltip:
              "The undertaking shall disclose its policies with respect to business conduct matters and how it fosters its corporate culture",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to enable an understanding of the extent to which the undertaking has policies that address the identification, assessment, management and/or remediation of its material impacts, risks and opportunities related to business conduct matters. It also aims to provide an understanding of the undertaking’s approach to corporate culture.",
            mdr: "Do you have policies related to above mentioned disclosure requirement?",
          },
          {
            id: "gov-g1-2",
            label: "G1-2 – Management of relationships with suppliers",
            tooltip:
              "The undertaking shall provide information about the management of its relationships with its suppliers and its impacts on its supply chain.",

            mandatory: false,
            obj: "The undertaking shall provide information about the management of its relationships with its suppliers and its impacts on its supply chain. ",
          },
          {
            id: "gov-g1-3",
            label: "G1-3 – Prevention and detection of corruption and bribery",
            tooltip:
              "The undertaking shall provide information about its system to prevent and detect, investigate, and respond to allegations or incidents relating to corruption and bribery including the related training.",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide transparency on the key procedures of the undertaking to prevent, detect, and address allegations about corruption and bribery. This includes the training provided to own workers and/or information provided internally or to suppliers. ",
          },
          {
            id: "gov-g1-4",
            label: "G1-4 – Confirmed incidents of corruption or bribery",
            tooltip:
              "The undertaking shall provide information on incidents of corruption or bribery during the reporting period. ",
            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide transparency on the incidents relating to corruption or bribery during the reporting period and the related outcomes. ",
            mdr: "Do you have actions related to above mentioned disclosure requirement?",
          },
          {
            id: "gov-g1-5",
            label: "G1-5 – Political influence and lobbying activities",
            tooltip:
              "The undertaking shall provide information on the activities and commitments related to exerting its political influence, including its lobbying activities related to its material impacts, risks and opportunities. ",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide transparency on the undertaking’s activities and commitments related to exerting its political influence with political contributions, including the types and purpose of lobbying activities. ",
          },
          {
            id: "gov-g1-6",
            label: "G1-6 – Payment practices",
            tooltip:
              "The undertaking shall provide information on its payment practices, especially with respect to late payments to small and medium enterprises (SMEs).",

            mandatory: false,
            obj: "The objective of this Disclosure Requirement is to provide insights on the contractual payment terms and on its performance with regard to payment, especially as to how these impact SMEs and specifically with respect to late payments to SMEs. ",
          },
        ],
      },
    ],
  },
];

export default data;
