import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ExpandMore, ExpandLess as ExpandLessIcon, Close as CloseIcon } from "@mui/icons-material";

const submenusConfig = {
  reports: [
    "/standards",
    "/csrd",
    "/Materiality",
    "/ReportingModules",
    "/GenerateReport",
    "/PreviewReport",
  ],
  company: [
    "/listofentities",
    "/company",
    "/listofbusinessunits",
    "/Businessunit",
    "/listsitelocations",
    "/sitelocation",
  ],
  settings: ["/listofusers", "/adduser"],
};

const includesAnyPath = (pathname, paths) => paths.some((path) => pathname.includes(path));

const SideNavigation = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { reportsMenu, companyMenu, settingsMenu } = Object.entries(submenusConfig).reduce(
    (acc, [key, value]) => {
      acc[`${key}Menu`] = includesAnyPath(location.pathname, value) ? "showMenu" : "";
      return acc;
    },
    {}
  );

  // Function to toggle submenu
  const toggleSubMenu = (event) => {
    event.preventDefault();
    const listItem = event.currentTarget.parentElement;
    const allListItems = document.querySelectorAll(".nav-links > li");

    // Close all other dropdowns
    allListItems.forEach((item) => {
      if (item !== listItem && item.classList.contains("showMenu")) {
        item.classList.remove("showMenu");
      }
    });

    // Toggle the clicked dropdown
    listItem.classList.toggle("showMenu");
  };

  useEffect(() => {
    const storedSidebarOpen = JSON.parse(localStorage.getItem("sidebarOpen"));
    if (storedSidebarOpen !== null) {
      setOpen(storedSidebarOpen);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isSidebarOpen));
    setOpen(isSidebarOpen);
  }, [isSidebarOpen]);

  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "close"}`}>
      {/* Sidebar content */}
      <div
        className={`logo-container ${
          isSidebarOpen ? "logo-sidebar-expanded" : "logo-sidebar-collapsed"
        }`}
      >
        <a href="/home">
          <img
            src={isSidebarOpen ? "assets/img/logo.png" : "assets/img/favicon-light.png"}
            alt="ecoPRISM"
          />
        </a>
        {isSidebarOpen && (
          <IconButton
            className="close-icon"
            onClick={toggleSidebar}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              color: "white",
              opacity: "0.7",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>

      <ul className="nav-links">
        {/* Navigation links */}
        <li>
          <a
            href="/dashboard"
            className={`${
              location.pathname === "/dashboard" ||
              location.pathname === "/scope2" ||
              location.pathname === "/employee-commute"
                ? isSidebarOpen
                  ? "icon-list-active"
                  : "icon-list-collapse-active"
                : "main-menu-item"
            }`}
          >
            <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
              <img
                src={
                  location.pathname === "/dashboard" ||
                  location.pathname === "/scope2" ||
                  location.pathname === "/employee-commute"
                    ? "assets/img/sidebar/active/Dashboard.png"
                    : "assets/img/sidebar/Dashboard.png"
                }
                alt="Dashboard"
                className="sidebar-image"
              />
            </div>
            <span className="link_name">Dashboard</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="/dashboard">
                Dashboard
              </a>
            </li>
          </ul>
        </li>
        {/* Reports */}
        <li className={`reports-dropdown ${reportsMenu}`}>
          <div className="iocn-link" onClick={toggleSubMenu}>
            <a href="#">
              <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
                <img
                  src={
                    includesAnyPath(location.pathname, submenusConfig.reports)
                      ? "assets/img/sidebar/active/Reports.png"
                      : "assets/img/sidebar/Reports.png"
                  }
                  alt="Reports"
                  className="sidebar-image"
                />
              </div>
              <span className="link_name">Reports</span>
            </a>
            <ExpandMore className="chevron-down" />
          </div>
          <ul className="reports sub-menu submenu-list">
            <li className="submenu-header">
              <a className="link_name">Reports</a>
              <ExpandLessIcon className="chevron-up" />
            </li>
            <li>
              <a
                className={
                  location.pathname === "/standards" ||
                  location.pathname === "/csrd" ||
                  location.pathname === "/Materiality" ||
                  location.pathname === "/ReportingModules" ||
                  location.pathname === "/GenerateReport" ||
                  location.pathname === "/PreviewReport"
                    ? "icon-list-active-submenu submenu-item"
                    : "submenu-item main-menu-item"
                }
                href="/standards"
              >
                Standards & Frameworks
              </a>
            </li>
          </ul>
        </li>
        {/* Green Button */}
        <li>
          <a
            href="/comingsoon"
            className={`${
              location.pathname === "/comingsoon"
                ? isSidebarOpen
                  ? "icon-list-active"
                  : "icon-list-collapse-active"
                : "main-menu-item"
            }`}
          >
            <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
              <img
                src={
                  location.pathname === "/comingsoon"
                    ? "assets/img/sidebar/active/Greenbutton.png"
                    : "assets/img/sidebar/Green.png"
                }
                alt="Green Button"
                className="sidebar-image"
              />
            </div>
            <span className="link_name">Green Button</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name " href="/comingsoon">
                Green Button
              </a>
            </li>
          </ul>
        </li>
        {/* Forms & Apps */}
        <li>
          <a
            href="/forms-apps"
            className={`${
              location.pathname === "/forms-apps" ||
              location.pathname === "/employee-commute-data" ||
              location.pathname === "/start-new-survey"
                ? isSidebarOpen
                  ? "icon-list-active"
                  : "icon-list-collapse-active"
                : "main-menu-item"
            }`}
          >
            <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
              <img
                src={
                  location.pathname === "/forms-apps" ||
                  location.pathname === "/employee-commute-data" ||
                  location.pathname === "/start-new-survey"
                    ? "assets/img/sidebar/active/Formsapps.png"
                    : "assets/img/sidebar/Forms&apps.png"
                }
                alt="Forms & Apps"
                className="sidebar-image"
              />
            </div>
            <span className="link_name">Forms & Apps</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="/forms-apps">
                Forms & Apps
              </a>
            </li>
          </ul>
        </li>
        {/* Insights */}
        <li>
          <a
            href="/insights"
            className={`${
              location.pathname === "/insights" ||
              location.pathname === "/assessing-low-re" ||
              location.pathname === "/electricity-cost-evaluation" ||
              location.pathname === "/assessing-elec-usage"
                ? isSidebarOpen
                  ? "icon-list-active"
                  : "icon-list-collapse-active"
                : "main-menu-item"
            }`}
          >
            <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
              <img
                src={
                  location.pathname === "/insights" ||
                  location.pathname === "/assessing-low-re" ||
                  location.pathname === "/electricity-cost-evaluation" ||
                  location.pathname === "/assessing-elec-usage"
                    ? "assets/img/sidebar/active/Insights.png"
                    : "assets/img/sidebar/Insights.png"
                }
                alt="Insights"
                className="sidebar-image"
              />
            </div>
            <span className="link_name">Insights</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="/insights">
                Insights
              </a>
            </li>
          </ul>
        </li>
        {/* Configuration */}
        <li>
          <a
            href="/allconfiguration"
            className={`${
              location.pathname === "/allconfiguration" ||
              location.pathname === "/configuration" ||
              location.pathname === "/reporthistory"
                ? isSidebarOpen
                  ? "icon-list-active"
                  : "icon-list-collapse-active"
                : "main-menu-item"
            }`}
          >
            <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
              <img
                src={
                  location.pathname === "/allconfiguration" ||
                  location.pathname === "/configuration" ||
                  location.pathname === "/reporthistory"
                    ? "assets/img/sidebar/active/Configuration.png"
                    : "assets/img/sidebar/Configuration.png"
                }
                alt="Configuration"
                className="sidebar-image"
              />
            </div>
            <span className="link_name">Configuration</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="/allconfiguration">
                Configuration
              </a>
            </li>
          </ul>
        </li>
        {/* Company */}
        <li className={`company-dropdown ${companyMenu}`}>
          <div className="iocn-link" onClick={toggleSubMenu}>
            <a href="#">
              <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
                <img
                  src={
                    includesAnyPath(location.pathname, submenusConfig.company)
                      ? "assets/img/sidebar/active/Company.png"
                      : "assets/img/sidebar/Company.png"
                  }
                  alt="Company"
                  className="sidebar-image"
                />
              </div>
              <span className="link_name">Company</span>
            </a>
            <ExpandMore className="chevron-down" />
          </div>
          <ul className="company sub-menu submenu-list">
            <li className="submenu-header">
              <a className="link_name" href="#">
                Company
              </a>
              <ExpandLessIcon className="chevron-up" />
            </li>
            <li>
              <a
                className={
                  location.pathname === "/listofentities" || location.pathname === "/company"
                    ? "icon-list-active-submenu submenu-item"
                    : "submenu-item main-menu-item"
                }
                href="/listofentities"
              >
                Legal Entities
              </a>
            </li>
            <li>
              <a
                href="/listofbusinessunits"
                className={
                  location.pathname === "/listofbusinessunits" ||
                  location.pathname === "/Businessunit"
                    ? "icon-list-active-submenu submenu-item"
                    : "submenu-item main-menu-item"
                }
              >
                Business Units
              </a>
            </li>
            <li>
              <a
                href="/listsitelocations"
                className={
                  location.pathname === "/listsitelocations" ||
                  location.pathname === "/sitelocation"
                    ? "icon-list-active-submenu submenu-item"
                    : "submenu-item main-menu-item"
                }
              >
                Site Locations
              </a>
            </li>
          </ul>
        </li>
        {/* Settings */}
        <li className={`settings-dropdown ${settingsMenu}`}>
          <div className="iocn-link" onClick={toggleSubMenu}>
            <a href="#">
              <div className={isSidebarOpen ? "list-icon" : "list-icon-collapse"}>
                <img
                  src={
                    includesAnyPath(location.pathname, submenusConfig.settings)
                      ? "assets/img/sidebar/active/Settings.png"
                      : "assets/img/sidebar/Settings.png"
                  }
                  alt="Settings"
                  className="sidebar-image"
                />
              </div>
              <span className="link_name">Settings</span>
            </a>
            <ExpandMore className="chevron-down" />
          </div>
          <ul className="settings sub-menu submenu-list">
            <li className="submenu-header">
              <a className="link_name">Settings</a>
              <ExpandLessIcon className="chevron-up" />
            </li>
            <li>
              <a
                className={
                  location.pathname === "/listofusers" || location.pathname === "/adduser"
                    ? "icon-list-active-submenu submenu-item"
                    : "submenu-item main-menu-item active"
                }
                href="/listofusers"
              >
                User Management
              </a>
            </li>
          </ul>
        </li>
      </ul>
      {/* Profile section */}
      <div className="profile-details">
        <div className="job">Powered by ecoPRISM</div>
      </div>
    </div>
  );
};

export default SideNavigation;
