import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Import carousel styles

export default function ReportsCarousel() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="standards-container">
      <Carousel
        responsive={responsive}
        // customPrevArrow={<CustomPrevArrow />}
        // customNextArrow={<CustomNextArrow />}
      >
        <a className="other-image">
          <div>
            <img src="./assets\img\reports\report-01.png" className="wd-50" alt="Image 1" />
            <div class="image-overlay"></div>
          </div>
        </a>
        <a href="/csrd" className="csrd-image">
          <div>
            <img src="./assets\img\reports\report-02.png" className="wd-50" alt="Image 2" />
          </div>
        </a>

        <a className="other-image">
          <div>
            <img src="./assets\img\reports\report-03.png" className="wd-50" alt="Image 3" />
            <div class="image-overlay"></div>
          </div>
        </a>

        <a className="other-image">
          <div>
            <img src="./assets\img\reports\report-04.png" className="wd-50" alt="Image 4" />
            <div class="image-overlay"></div>
          </div>
        </a>

        <a className="other-image">
          <div>
            <img src="./assets\img\reports\report-05.png" className="wd-50" alt="Image 5" />
            <div class="image-overlay"></div>
          </div>
        </a>
      </Carousel>
    </div>
  );
}

// const CustomPrevArrow = ({ onClick }) => (
//   <button onClick={onClick} className="custom-arrow prev-arrow">
//     &lt; {/* Use your custom arrow icon or text */}
//   </button>
// );

// const CustomNextArrow = ({ onClick }) => (
//   <button onClick={onClick} className="custom-arrow next-arrow">
//     &gt; {/* Use your custom arrow icon or text */}
//   </button>
// );
